import React from "react";
import { connect } from 'react-redux'

import { addMaterialToCharacterAsync } from '../../../redux/actions/inventory.actions';
import { addFoodToCharacterAsync } from '../../../redux/actions/food.actions';
import { addMineralToCharacterAsync } from '../../../redux/actions/mineral.actions';
import { addToolToCharacterAsync } from '../../../redux/actions/tool.actions';
import { addTileToCharacterAsync } from '../../../redux/actions/tile.actions';
import { addCoinToCharacterAsync } from '../../../redux/actions/coin.actions';
import { addMetalToCharacterAsync } from '../../../redux/actions/metal.actions';
import { addKeyToCharacterAsync } from '../../../redux/actions/key.actions';
import { addWritingSurfaceToCharacterAsync } from '../../../redux/actions/writing-surface.actions';
import { addWritingImplementToCharacterAsync } from '../../../redux/actions/writing-implement.actions';
import { addWeaponToCharacterAsync } from '../../../redux/actions/weapon.actions';
import { addArmourToCharacterAsync } from '../../../redux/actions/armour.actions';
import { addClothingToCharacterAsync } from '../../../redux/actions/clothing.actions';
import { addJewelleryToCharacterAsync } from '../../../redux/actions/jewellery.actions';
import { addTentToCharacterAsync } from '../../../redux/actions/tent.actions';
import { addLockToCharacterAsync, unlockLockAsync, lockLockAsync } from '../../../redux/actions/lock.actions';
import { showQuantityInput, hideQuantityInput, showWritingSurface, showDrawingSurface } from '../../../redux/actions/keyboard-shortcuts.actions';
import {
    selectHighlight,
    selectCharacter,
    selectCurrentTiles,
    selectTileInventory,
    selectTileFood,
    selectNearbyFurniture,
    selectTileMinerals,
    selectTileTools,
    selectTileMetals,
    selectTileKeys,
    selectTileWritingSurfaces,
    selectTileWritingImplements,
    selectTileWeapons,
    selectTileArmour,
    selectTileClothing,
    selectTileJewellery,
    selectTileTents,
    selectTileLocks,
    selectIsQuantityInputOpen,
    selectServerTimeOffset,
    selectTileTiles,
    selectCurrencies,
    selectCoinTypes,
    selectCoins,
    selectTileCoins,
    selectPlantInstanceIdAtTile
} from '../../../redux/selectors';
import { client } from '../../../services/client';
import QuantityInput from '../../utils/quantity-input/QuantityInput';
import { getKeyDescription } from '../../side-bar/looking/key-description'
import { animalTypeDescribers } from '../../side-bar/looking/animal-description'
import { getTorchDurationString, getCondition } from '../../side-bar/looking/Looking';
import { BRAINCHIP_TYPE_NAMES } from '../../../services/Tilemap.service';

import Menu from '../../utils/menu/Menu';

const MAX_CHARACTER_OR_FLOOR_WEIGHT = 1000;

class TileActions extends React.Component {

    submitHandler = (event) => {
        if (this.props.isQuantityInputShowing) {
            return;
        }

        if (event.key === 'ArrowLeft') {
            this.setState({
                isDisabled: true
            })

            return;
        }

        if (event.key === 'ArrowRight') {
            this.setState({
                isDisabled: false
            })

            return;
        }
    };

    async onItemClick(materialInstance) {
        const quantity = await this.getQuantity(materialInstance);
        materialInstance = { ...materialInstance, quantity };

        this.setState({isLoading: true})
        this.props.addMaterialToCharacterAsync({ characterId: this.props.character._id, materialInstance })
            .finally(() => (this.setState({isLoading: false})));
    }

    async onFoodClick(foodInstance) {
        const quantity = await this.getQuantity(foodInstance);
        foodInstance = { ...foodInstance, quantity };

        this.setState({isLoading: true})
        this.props.addFoodToCharacterAsync({ characterId: this.props.character._id, foodInstance })
            .finally(() => (this.setState({isLoading: false})));
    }

    async onMineralClick(mineralInstance) {
        const quantity = await this.getQuantity(mineralInstance);
        mineralInstance = { ...mineralInstance, quantity };

        this.setState({isLoading: true})
        this.props.addMineralToCharacterAsync({ characterId: this.props.character._id, mineralInstance })
            .finally(() => (this.setState({isLoading: false})));
    }

    async onToolClick(tool) {
        let quantity = 1;

        if (tool.hint > 1) {
            quantity = await this.getQuantity({ ...tool, quantity: tool.hint });
        }

        this.setState({isLoading: true})

        const toolInstances = tool.instances.slice(0, quantity)
        this.props.addToolToCharacterAsync({ characterId: this.props.character._id, toolInstances })
            .finally(() => (this.setState({isLoading: false})));
    }

    async onMetalClick(metalInstance) {
        const quantity = await this.getQuantity(metalInstance);
        metalInstance = { ...metalInstance, quantity };

        this.setState({isLoading: true})
        this.props.addMetalToCharacterAsync({ characterId: this.props.character._id, metalInstance })
            .finally(() => (this.setState({isLoading: false})));
    }

    async onKeyClick(key) {
        let quantity = 1;

        if (key.hint > 1) {
            quantity = await this.getQuantity({ ...key, quantity: key.hint });
        }

        this.setState({isLoading: true})

        const keyInstances = key.instances?.slice(0, quantity) || key

        this.props.addKeyToCharacterAsync({ characterId: this.props.character._id, keyInstances })
            .finally(() => (this.setState({isLoading: false})));
    }

    async onWritingSurfaceClick(writingSurface) {
        let quantity = 1;

        if (writingSurface.hint > 1) {
            quantity = await this.getQuantity({ ...writingSurface, quantity: writingSurface.hint });
        }

        this.setState({isLoading: true})

        const writingSurfaceInstances = writingSurface.instances?.slice(0, quantity) || writingSurface

        this.props.addWritingSurfaceToCharacterAsync({ characterId: this.props.character._id, writingSurfaceInstances })
            .finally(() => (this.setState({isLoading: false})));
    }

    async onWritingImplementClick(writingImplement) {
        let quantity = 1;

        if (writingImplement.hint > 1) {
            quantity = await this.getQuantity({ ...writingImplement, quantity: writingImplement.hint });
        }

        let writingImplementInstances = writingImplement.instances?.slice(0, quantity) || writingImplement

        this.setState({isLoading: true})

        this.props.addWritingImplementToCharacterAsync({ characterId: this.props.character._id, writingImplementInstances })
            .finally(() => (this.setState({isLoading: false})));
    }

    async onWeaponClick(weapon) {
        let quantity = 1;

        if (weapon.hint > 1) {
            quantity = await this.getQuantity({ ...weapon, quantity: weapon.hint });
        }

        this.setState({isLoading: true})

        const weaponInstances = weapon.instances?.slice(0, quantity) || weapon
        
        this.props.addWeaponToCharacterAsync({ characterId: this.props.character._id, weaponInstances })
            .finally(() => (this.setState({isLoading: false})));
    }

    async onArmourClick(armour) {
        let quantity = 1;

        if (armour.hint > 1) {
            quantity = await this.getQuantity({ ...armour, quantity: armour.hint });
        }

        this.setState({isLoading: true})

        const armourInstances = armour.instances?.slice(0, quantity) || armour

        console.log('wat? ', armourInstances)

        this.props.addArmourToCharacterAsync({ characterId: this.props.character._id, armourInstances })
            .finally(() => (this.setState({isLoading: false})));
    }

    async onClothingClick(clothing) {
        let quantity = 1;

        if (clothing.hint > 1) {
            quantity = await this.getQuantity({ ...clothing, quantity: clothing.hint });
        }

        this.setState({isLoading: true})

        const clothingInstances = clothing.instances?.slice(0, quantity) || clothing

        this.props.addClothingToCharacterAsync({ characterId: this.props.character._id, clothingInstances })
            .finally(() => (this.setState({isLoading: false})));
    }

    async onJewelleryClick(jewellery) {
        let quantity = 1;

        if (jewellery.hint > 1) {
            quantity = await this.getQuantity({ ...jewellery, quantity: jewellery.hint });
        }

        this.setState({isLoading: true})

        const jewelleryInstances = jewellery.instances?.slice(0, quantity) || jewellery

        this.props.addJewelleryToCharacterAsync({ characterId: this.props.character._id, jewelleryInstances })
            .finally(() => (this.setState({isLoading: false})));
    }

    async onTentClick(tent) {
        let quantity = 1;

        if (tent.hint > 1) {
            quantity = await this.getQuantity({ ...tent, quantity: tent.hint });
        }

        this.setState({isLoading: true})

        const tentInstances = tent.instances?.slice(0, quantity) || tent

        this.props.addTentToCharacterAsync({ characterId: this.props.character._id, tentInstances })
            .finally(() => (this.setState({isLoading: false})));
    }

    async onLockClick(lock) {
        let quantity = 1;

        if (lock.hint > 1) {
            quantity = await this.getQuantity({ ...lock, quantity: lock.hint });
        }

        this.setState({isLoading: true})

        const lockInstances = lock.instances?.slice(0, quantity) || lock

        this.props.addLockToCharacterAsync({ characterId: this.props.character._id, lockInstances })
            .finally(() => (this.setState({isLoading: false})));
    }

    async onTileClick(tile) {
        if (tile.tileType.name === 'Planter' || tile.tileType.name === 'Plant Pot') {
            if (this.props.plantAtPosition) {
                return;
            }
        }

        let quantity = 1;

        if (tile.hint > 1) {
            quantity = await this.getQuantity({ ...tile, quantity: tile.hint });
        }

        for (let i=0; i < quantity; i++) {
            const tileInstance = tile.instances ? tile.instances[i] : tile

            if (i >= quantity) {
                return;
            }

            this.props.addTileToCharacterAsync({ tileInstance, characterId: this.props.character._id })
        }
    }

    async onCoinClick(coinInstance) {
        const quantity = await this.getQuantity(coinInstance);

        this.props.addCoinToCharacterAsync({ quantity, coinInstance, characterId: this.props.character._id })
    }

    getWeight(item, itemTypeKey, isTileWeightCalc) {
        if (!isTileWeightCalc) {
            this.totalWeight += (item.quantity || 1) * item[itemTypeKey]?.weight;
        }
        return `${(item.quantity || 1) * item[itemTypeKey]?.weight}u`;
    }

    getQuantity(item) {
        if (Number(item.quantity) > 1) {
            this.setState({isQuantityInputShowing: true, maxQuantity: item.quantity });
            this.props.showQuantityInput();
        }

        return new Promise((resolve, reject) => {
            if (Number(item.quantity) === 1) {
                return resolve(1);
            }

            this.onQuantitySupplied = (quantity) => {
                this.props.hideQuantityInput();
                this.setState({isQuantityInputShowing: false})
                resolve(Number(quantity));
            }

            this.onQuantityClosed = () => {
                this.props.hideQuantityInput();
                this.setState({isQuantityInputShowing: false})
                reject('No quantity supplied');
            }
        });
    }

    onLookAtWritingSurface(writingSurface) {
        if (writingSurface.graffiti) {
            this.onEditWritingSurface({ ...writingSurface, isLooking: true })
            return;
        }

        if (writingSurface.pixelsArray && writingSurface.pixelsArray.length > 0) {
            this.onEditDrawingSurface({ ...writingSurface, isLooking: true })
            return;
        }
    }

    onEditWritingSurface(writingSurface) {
        this.props.showWritingSurface({
            surfaceService: writingSurface.serviceName || 'writing-surface-instances',
            surfaceId: writingSurface._id,
            isLooking: writingSurface.isLooking,
            isEditWritingSurfaceShowing: true
        })
    }

    onEditDrawingSurface(writingSurface) {
        this.props.showDrawingSurface({
            surfaceService: writingSurface.serviceName || 'writing-surface-instances',
            surfaceId: writingSurface._id,
            isLooking: writingSurface.isLooking,
        })
    }

    correctShortcutKeys(newConversationTree) {
        let index = 0;

        return newConversationTree.map((option) => {
            const newShortcut = String.fromCharCode(97 + index)
            index++;

            return {
                ...option,
                shortcut: newShortcut
            }
        })
    }

    constructor() {
        super();
        this.state = {
            isQuantityInputShowing: false,
            isDisabled: true,
            hiddenOptions: [],
            maxQuantity: {}
        };

        document.addEventListener('keydown', this.submitHandler)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.submitHandler);
    }

    render() {
        this.totalWeight = 0;

        const menuOptions = {
            '+': {
                callback: (option) => {
                    if (this.state.isDisabled) {
                        return;
                    }
                    this.setState({
                        hiddenOptions: [ ...this.state.hiddenOptions.filter(hiddenOption => (hiddenOption !== `${option.text}.${option.parentId}`)) ]
                    })
                },
                text: 'Open'
            },
            '-': {
                callback: (option) => {
                    if (this.state.isDisabled) {
                        return;
                    }
                    this.setState({
                        hiddenOptions: [ ...this.state.hiddenOptions.filter(hiddenOption => (hiddenOption !== `${option.text}.${option.parentId}`)), `${option.text}.${option.parentId}` ]
                    })
                },
                text: 'Close'
            }
        }

        let tiles = Object.values(this.props.tileContents).map((tile, index) => {
            const isVendingMachine = tile.tile?.tileType.name === 'Vending Machine'
            const isVendingMachineOperator = true; //do we have the key?

            const materials = {
                text: 'Materials',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Materials.${tile._id}`) > -1 ? [] : Object.values(tile.tileInventory).map((material, index) => {
                    let materialName = material?.materialType?.name;

                    if (material?.plant?.name || animalTypeDescribers[material?.animal?.name]?.name || material?.fish?.name) {
                        materialName = (material?.plant?.name || animalTypeDescribers[material?.animal?.name]?.name || material?.fish?.name) + ' ' + material?.materialType?.name;
                    }

                    return (
                        {
                            ...material,
                            text: materialName,
                            condition: getCondition(material),
                            hintClassName: 'quantity',
                            hint:material.quantity || 1,
                            weight: this.getWeight(material, 'materialType'),
                            markedForTradeCount: isVendingMachine && material.quantity,
                            isMarkedWithPrice: isVendingMachine && !!material.tradePrice,
                            indentation: 2,
                            actions: {
                                'SPACE': {
                                    callback: (option) => (this.onItemClick(option)),
                                    text: 'Pick up'
                                },
                            }
                        }
                    );
                }),
                actions: {
                    ...menuOptions
                }
            }

            const foods = {
                text: 'Food',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Food.${tile._id}`) > -1 ? [] : tile.food.map((food, index) => {
                    let text = food?.foodType?.name

                    const brainchipEffect = BRAINCHIP_TYPE_NAMES[food?.effect];

                    if (brainchipEffect) {
                        text = `${text} [${brainchipEffect}]`
                    }
                    return (
                        {
                            ...food,
                            text,
                            condition: getCondition(food),
                            hintClassName: 'quantity',
                            hint:food.quantity || 1,
                            weight: this.getWeight(food, 'foodType'),
                            indentation: 2,
                            actions: {
                                'SPACE': {
                                    callback: (option) => (this.onFoodClick(option)),
                                    text: 'Pick up'
                                }
                            }
                        }
                    );
                }),
                actions: {
                    ...menuOptions
                }
            }

            const minerals = {
                text: 'Minerals',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Minerals.${tile._id}`) > -1 ? [] : tile.minerals.map((mineral, index) => {
                    return (
                        {
                            ...mineral,
                            text: mineral?.mineralType?.name,
                            condition: getCondition(mineral),
                            hintClassName: 'quantity',
                            hint:mineral.quantity || 1,
                            weight: this.getWeight(mineral, 'mineralType'),
                            indentation: 2,
                            actions: {
                                'SPACE': {
                                    callback: (option) => (this.onMineralClick(option)),
                                    text: 'Pick up'
                                }
                            }
                        }
                    );
                }),
                actions: {
                    ...menuOptions
                }
            }

            const tools = {
                text: 'Tools',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Tools.${tile._id}`) > -1 ? [] : tile.tools.map((tool, index) => {
                    const torchDurationString = getTorchDurationString(tool, this.props.serverTimeOffset, false);
                    const text = `${tool?.toolType?.name}`
                    return (
                        {
                            ...tool,
                            text: text.trim(),
                            condition: torchDurationString ? torchDurationString : getCondition(tool),
                            hintClassName: 'quantity',
                            hint:tool.quantity || 1,
                            weight: this.getWeight(tool, 'toolType'),
                            indentation: 2,
                            actions: {
                                'SPACE': {
                                    callback: (option) => (this.onToolClick(option)),
                                    text: 'Pick up'
                                }
                            }
                        }
                    )
                }),
                actions: {
                    ...menuOptions
                }
            }

            const metals = {
                text: 'Metals',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Metals.${tile._id}`) > -1 ? [] : tile.metals.map((metal, index) => {
                    return {
                        ...metal,
                        text: metal?.metalType?.name,
                        condition: getCondition(metal),
                        hintClassName: 'quantity',
                            hint:metal.quantity || 1,
                        weight: this.getWeight(metal, 'metalType'),
                        indentation: 2,
                        actions: {
                            'SPACE': {
                                callback: (option) => (this.onMetalClick(option)),
                                text: 'Pick up'
                            }
                        }

                    }
                }),
                actions: {
                    ...menuOptions
                }
            }

            const keys = {
                text: 'Keys',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Keys.${tile._id}`) > -1 ? [] : tile.keys.map((key, index) => {
                    return {
                        ...key,
                        text: `${key?.keyType?.name}(${getKeyDescription(key)})`,
                        condition: getCondition(key),
                        hintClassName: 'quantity',
                            hint:key.quantity || 1,
                        weight: this.getWeight(key, 'keyType'),
                        indentation: 2,
                        actions: {
                            'SPACE': {
                                callback: (option) => (this.onKeyClick(option)),
                                text: 'Pick up'
                            }
                        }
                    }
                }),
                actions: {
                    ...menuOptions
                }
            }

            const writingSurfaces = {
                text: 'Writing Surfaces',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Writing Surfaces.${tile._id}`) > -1 ? [] : tile.writingSurfaces.map((writingSurface, index) => {
                    return {
                        ...writingSurface,
                        text: writingSurface?.writingSurfaceType?.name,
                        condition: getCondition(writingSurface),
                        hintClassName: 'quantity',
                            hint:writingSurface.quantity || 1,
                        weight: this.getWeight(writingSurface, 'writingSurfaceType'),
                        indentation: 2,
                        actions: {
                            'SPACE': {
                                callback: (option) => (this.onWritingSurfaceClick(option)),
                                text: 'Pick up'
                            },
                            '\\': {
                                callback: (option) => (setTimeout(() => (this.onLookAtWritingSurface(option)))),
                                text: 'Look'
                            },
                        }
                    }
                }),
                actions: {
                    ...menuOptions
                }
            }

            const writingImplements = {
                text: 'Writing Implements',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Writing Implements.${tile._id}`) > -1 ? [] : tile.writingImplements.map((writingImplement, index) => {
                    return {
                        ...writingImplement,
                        text: writingImplement?.writingImplementType?.name,
                        condition: getCondition(writingImplement),
                        hintClassName: 'quantity',
                            hint:writingImplement.quantity || 1,
                        weight: this.getWeight(writingImplement, 'writingImplementType'),
                        indentation: 2,
                        actions: {
                            'SPACE': {
                                callback: (option) => (this.onWritingImplementClick(option)),
                                text: 'Pick up'
                            }
                        }
                    }
                }),
                actions: {
                    ...menuOptions
                }
            }

            const weapons = {
                text: 'Weapons',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Weapons.${tile._id}`) > -1 ? [] : tile.weapons.map((weapon, index) => {
                    const combatValues = ` ${weapon.weaponType?.damage}ϴ ${weapon.weaponType?.toHit}ϰ ${weapon.weaponType?.penetration}➚`
                    const text = `${weapon?.weaponType?.name} ${combatValues}`
                    return {
                        ...weapon,
                        text,
                        condition: getCondition(weapon),
                        hintClassName: 'quantity',
                            hint:weapon.quantity || 1,
                        weight: this.getWeight(weapon, 'weaponType'),
                        indentation: 2,
                        actions: {
                            'SPACE': {
                                callback: (option) => (this.onWeaponClick(option)),
                                text: 'Pick up'
                            }
                        }
                    }
                }),
                actions: {
                    ...menuOptions
                }
            }

            const armours = {
                text: 'Armour',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Armour.${tile._id}`) > -1 ? [] : tile.armour.map((armour) => {
                    const combatValues = `${armour.armourType?.armour}❤ ${armour.armourType?.dodge}❥`
                    const text = `${armour?.armourType?.name} ${combatValues}`
                    return {
                        ...armour,
                        text,
                        condition: getCondition(armour),
                        hintClassName: 'quantity',
                            hint:armour.quantity || 1,
                        weight: this.getWeight(armour, 'armourType'),
                        indentation: 2,
                        actions: {
                            'SPACE': {
                                callback: (option) => (this.onArmourClick(option)),
                                text: 'Pick up'
                            }
                        }
                    }
                }),
                actions: {
                    ...menuOptions
                }
            }

            const clothing = {
                text: 'Clothing',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Clothing.${tile._id}`) > -1 ? [] : tile.clothing.map((clothing, index) => {
                    const combatValues = `${clothing.clothingType?.armour}❤ ${clothing.clothingType?.dodge}❥`
                    const text = `${clothing?.clothingType?.name} ${combatValues}`
                    return {
                        ...clothing,
                        text,
                        condition: getCondition(clothing),
                        hintClassName: 'quantity',
                            hint:clothing.quantity || 1,
                        weight: this.getWeight(clothing, 'clothingType'),
                        indentation: 2,
                        actions: {
                            'SPACE': {
                                callback: (option) => (this.onClothingClick(option)),
                                text: 'Pick up'
                            }
                        }
                    }
                }),
                actions: {
                    ...menuOptions
                }
            }

            const jewellery = {
                text: 'Jewellery',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Jewellery.${tile._id}`) > -1 ? [] : tile.jewellery.map((jewellery, index) => {
                    return {
                        ...jewellery,
                        text: jewellery?.jewelleryType?.name,
                        condition: getCondition(jewellery),
                        hintClassName: 'quantity',
                        hint:jewellery.quantity || 1,
                        weight: this.getWeight(jewellery, 'jewelleryType'),
                        indentation: 2,
                        actions: {
                            'SPACE': {
                                callback: (option) => (this.onJewelleryClick(option)),
                                text: 'Pick up'
                            }
                        }
                    }
                }),
                actions: {
                    ...menuOptions
                }
            }

            const tents = {
                text: 'Tents',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Tents.${tile._id}`) > -1 ? [] : tile.tents.map((tent, index) => {
                    return {
                        ...tent,
                        text: tent?.tentType?.name,
                        condition: getCondition(tent),
                        hintClassName: 'quantity',
                        hint:tent.quantity || 1,
                        weight: this.getWeight(tent, 'tentType'),
                        indentation: 2,
                        actions: {
                            'SPACE': {
                                callback: (option) => (this.onTentClick(option)),
                                text: 'Pick up'
                            }
                        }
                    }
                }),
                actions: {
                    ...menuOptions
                }
            }

            const locks = {
                text: 'Locks',
                parentId: tile._id,
                childOptions: this.state.hiddenOptions.indexOf(`Locks.${tile._id}`) > -1 ? [] : tile.locks.filter(lock => (!lock.isInstalled)).map((lock, index) => {
                    return {
                        ...lock,
                        text: lock?.lockType?.name,
                        condition: getCondition(lock),
                        hintClassName: 'quantity',
                        hint:lock.quantity || 1,
                        weight: this.getWeight(lock, 'lockType'),
                        indentation: 2,
                        actions: {
                            'SPACE': {
                                callback: (option) => (this.onLockClick(option)),
                                text: 'Pick up'
                            },
                        }
                    }
                }),
                actions: {
                    ...menuOptions
                }
            }

            const tiles = {
                text: 'Books',
                parentId: tile._id,
                childOptions: tile.tiles.map((tile, index) => {
                    return {
                        ...tile,
                        text: tile?.tileType?.name,
                        condition: getCondition(tile),
                        hintClassName: 'quantity',
                        hint:tile.quantity || 1,
                        weight: '?u', //TODO
                        indentation: 2,
                        actions: {
                            'SPACE': {
                                callback: (option) => (this.onTileClick(option)),
                                text: 'Pick up'
                            },
                            '\\': {
                                callback: (option) => (setTimeout(() => (this.onLookAtWritingSurface({ ...option, serviceName: 'tile-instances' })))),
                                text: 'Read'
                            },
                        }
                    }
                }),
                actions: {
                    ...menuOptions
                }
            }

            const coins = {
                text: 'Coins',
                parentId: tile._id,
                childOptions: (this.state.hiddenOptions.indexOf(`Coins.${tile._id}`) > -1 || tile.coins === undefined) ? [] : tile.coins?.map((coin, index) => {
                    const coinName = coin?.coinType?.name || '';
                    const coinSymbol = this.props.currency?.symbol || ''

                    return {
                        ...coin,
                        text: `${coinName} (${coinSymbol}1)`,
                        hintClassName: 'quantity',
                        hint:coin.quantity || 1,
                        markedForTradeCount: coin.markedForTradeCount,
                        isMarkedWithPrice: !!coin.tradePrice,
                        weight: this.getWeight(coin, 'coinType'),
                        indentation: 2,
                        actions: {
                            'SPACE': {
                                callback: (option) => (this.onCoinClick(option)),
                                text: 'Pick up'
                            },
                        }
                    }
                }),
                actions: {
                    ...menuOptions
                }
            }

            const combineReducer = (combinedTools, toolInstance, index) => {
                const existingTool = combinedTools.find(tool => (tool.text === toolInstance.text && tool.graffiti === toolInstance.graffiti && tool.condition === toolInstance.condition && tool.level === toolInstance.level))

                if (existingTool) {
                    existingTool.instances.push({ ...toolInstance })
                    existingTool.hint++;
                } else {
                    combinedTools.push({
                        ...toolInstance,
                        instances: [ { ...toolInstance } ],
                        hint: toolInstance.quantity || 1
                    })
                }

                return combinedTools
            }

            let options = [
                { ...materials, indentation: 1 },
                ...materials.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...foods, indentation: 1 },
                ...foods.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...minerals, indentation: 1 },
                ...minerals.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...tools, indentation: 1 },
                ...tools.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...metals, indentation: 1 },
                ...metals.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...keys, indentation: 1 },
                ...keys.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...writingSurfaces, indentation: 1 },
                ...writingSurfaces.childOptions.reduce(combineReducer, []).sort((a, b) => {
                    if (a.graffiti && a.graffiti[0] && a.graffiti[0].children && a.graffiti[0].children[0]?.text && b.graffiti && b.graffiti[0]?.children[0]?.text) {
                        return a.graffiti[0]?.children[0]?.text.localeCompare(b.graffiti[0]?.children[0]?.text)
                    }

                    return a.text?.localeCompare(b.text)
                }),
                { ...writingImplements, indentation: 1 },
                ...writingImplements.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...weapons, indentation: 1 },
                ...weapons.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...armours, indentation: 1 },
                ...armours.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...clothing, indentation: 1 },
                ...clothing.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...jewellery, indentation: 1 },
                ...jewellery.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...tents, indentation: 1 },
                ...tents.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...locks, indentation: 1 },
                ...locks.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...tiles, indentation: 1 },
                ...tiles.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
                { ...coins, indentation: 1 },
                ...coins?.childOptions.reduce(combineReducer, []).sort((a, b) => (a.text?.localeCompare(b.text))),
            ]
            .filter(option => {
                if (option.childOptions?.length === 0 && this.state.hiddenOptions?.indexOf(`${option.text}.${option.parentId}`) === -1) {
                    return false;
                }

                return true
            });

            const installedLock = tile.locks.find(lock => (lock.isInstalled));

            let text = tile.tile?.tileType?.name === 'ITEM' ? 'Loose' : tile.tile?.tileType?.name || 'Loose'

            const isMoveableTile = !tile.tile?.tileType.isUnmoveable;

            let actions = {
                '\\': {
                    callback: (option) => (setTimeout(() => (this.onLookAtWritingSurface({ ...option, serviceName: 'tile-instances' })))),
                    text: 'Look'
                },
            }

            if (installedLock) {
                actions[','] = {
                    callback: (option) => {
                        if (installedLock.isLocked) {
                            this.props.unlockLockAsync(installedLock)
                        } else {
                            this.props.lockLockAsync(installedLock)
                        }
                    },
                    text: installedLock.isLocked ? 'Unlock' : 'Lock'
                }
                
                if (installedLock.isLocked) {
                    const description = getKeyDescription({ ...installedLock })
                    text += ` [locked ${description}]`
                } else {
                    const description = getKeyDescription({ ...installedLock })
                    text += ` [unlocked ${description}]`
                }

                if (!installedLock.isLocked) {
                    actions = {
                        'SPACE': {
                            callback: (option) => (this.onTileClick(option)),
                            text: 'Pick up'
                        },
                        ...actions,
                    }
                }
            } else if (isMoveableTile) {
                actions = {
                    'SPACE': {
                        callback: (option) => (this.onTileClick(option)),
                        text: 'Pick up'
                    },
                    ...actions,
                }
            }

            return [
                {
                    ...tile.tile,
                    text,
                    condition: getCondition(tile.tile),
                    actions: tile.tile?.tileType?.name ? actions : undefined
                },
                ...options
            ]
        })

        // to enable deep level flatten use recursion with reduce and concat
        function flatDeep(arr, d = 1) {
           return d > 0 ? arr.reduce((acc, val) => acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val), [])
                        : arr.slice();
        };

        tiles = flatDeep(tiles);

        tiles = this.correctShortcutKeys(tiles);

        let maxTileWeight = Object.values(this.props.tileContents)[0]?.tile?.maxWeight || Object.values(this.props.tileContents)[0]?.tile?.tileType?.maxWeight

        if (!maxTileWeight) {
            maxTileWeight = MAX_CHARACTER_OR_FLOOR_WEIGHT
        }

        return (
            <div className="tile-inventory">
                <p className="tile-title">&nbsp;[ <span className="title">Tile</span> ]&nbsp;</p>
                <div className="inventory-actions-menu-container">
                    <Menu
                        isDisabled={this.state.isDisabled || this.props.isQuantityInputShowing}
                        options={
                            tiles
                        }
                        highlightedOption={(item) => (this.props.onSelectedItem(tiles[item]))}
                        menuContainer="inventory-actions-menu-container"
                        tradeIcon="eye"
                        menuIndex={1}
                    />
                </div>
                <p className="tile-total-weight">{this.totalWeight}/{maxTileWeight}u</p>


                {this.props.isQuantityInputShowing && this.state.isQuantityInputShowing &&
                    <QuantityInput
                        quantity={this.state.maxQuantity}
                        onQuantitySupplied={this.onQuantitySupplied}
                        onQuantityClosed={this.onQuantityClosed}
                    />
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    const highlight = selectHighlight(state);
    const character = selectCharacter(state);
    const isQuantityInputShowing = selectIsQuantityInputOpen(state);
    const tiles = selectCurrentTiles(state, highlight || character.position);
    const plantAtPosition = selectPlantInstanceIdAtTile(state, character.position);

    const currencies = selectCurrencies(state);
    let coinTypes = selectCoinTypes(state);
    let coins = selectCoins(state);

    const currency = currencies.find(currency => (currency.groupId === character.groupId));

    let tileContents = {};

    tiles.byId.forEach(tile => {
        tileContents[tile._id] = {
            tile,
            tileInventory: [ ...selectTileInventory(state, tile._id) ],
            food: [ ...selectTileFood(state, tile._id) ],
            minerals: [ ...selectTileMinerals(state, tile._id) ],
            tools: [ ...selectTileTools(state, tile._id) ],
            metals: [ ...selectTileMetals(state, tile._id) ],
            keys: [ ...selectTileKeys(state, tile._id) ],
            writingSurfaces: [ ...selectTileWritingSurfaces(state, tile._id) ],
            writingImplements: [ ...selectTileWritingImplements(state, tile._id) ],
            weapons: [ ...selectTileWeapons(state, tile._id) ],
            armour: [ ...selectTileArmour(state, tile._id) ],
            clothing: [ ...selectTileClothing(state, tile._id) ],
            jewellery: [ ...selectTileJewellery(state, tile._id) ],
            tents: [ ...selectTileTents(state, tile._id) ],
            locks: [ ...selectTileLocks(state, tile._id) ],
            tiles: [ ...selectTileTiles(state, tile._id) ],
            coins: [ ...selectTileCoins(state, tile._id).map(coin => ({ ...coin, coinType: coinTypes.find(type => (type._id === coin.coinTypeId ))})) ]
        }
    })

    const serverTimeOffset = selectServerTimeOffset(state);

    return {
        currency,
        serverTimeOffset,
        highlight,
        character,
        tileContents,
        isQuantityInputShowing,
        plantAtPosition
    }
}

export default connect(
    mapStateToProps,
    {
        addMaterialToCharacterAsync,
        addFoodToCharacterAsync,
        addMineralToCharacterAsync,
        addToolToCharacterAsync,
        addMetalToCharacterAsync,
        addKeyToCharacterAsync,
        addWritingSurfaceToCharacterAsync,
        addWritingImplementToCharacterAsync,
        addWeaponToCharacterAsync,
        addArmourToCharacterAsync,
        addClothingToCharacterAsync,
        addJewelleryToCharacterAsync,
        addTentToCharacterAsync,
        addTileToCharacterAsync,
        addCoinToCharacterAsync,
        addLockToCharacterAsync,
        showQuantityInput,
        hideQuantityInput,
        showWritingSurface,
        showDrawingSurface,
        unlockLockAsync,
        lockLockAsync
    }
)(TileActions);