import io from 'socket.io-client';
import feathers from '@feathersjs/client';
import { batchClient } from './batch-client';
import store from '../redux/store';

import { showEvents, connected, connectionError, disconnected, reconnected } from '../redux/actions/keyboard-shortcuts.actions';

// const host = process.env.RAILWAY_ENVIRONMENT === 'production' ? 'https://api.settle-gliese.com' : 'http://localhost:3030';
const host = 'https://api.settle-gliese.com';

const socket = io(process.env.IO_URL || host);

const client = feathers();

// rest:
client.configure(feathers.socketio(socket));

client.configure(feathers.authentication({
  storage: window.localStorage
}));

export { client };

client.io.on('connect', (event) => {
  store.dispatch(connected());
  store.dispatch(showEvents());
})

client.io.on('connect_error', () => {
  store.dispatch(connectionError());
})

client.io.on('disconnect', () => {
  store.dispatch(disconnected());
})

client.io.on('reconnect', () => {
  store.dispatch(reconnected());
  window.location.reload();
})

client.io.on('reconnect_error', () => {
  store.dispatch(connectionError());
})

// let oldVisibilityState;
// document.addEventListener("visibilitychange", () => {
//   if (document.visibilityState === 'hidden') {
//     oldVisibilityState = 'hidden';
//   }

//   if (document.visibilityState === 'visible' && oldVisibilityState === 'hidden') {
//     window.location.reload();
//   }
// });
