import React from "react";
import { connect } from 'react-redux'
import { pointAtAsync } from '../../redux/actions/messages.actions';
import {
    selectMessages,
    selectCharacters,
    selectServerTimeOffset,
    selectIsInitialisingPanel
} from '../../redux/selectors';
import escapeHtml from 'escape-html'
import { Text } from 'slate'

import { PANEL_WIDTH, PANEL_HEIGHT } from '../../services/geography';

import './OnScreenDialogue.css';

// const SHOWING_ON_SCREEN_MS = 3000;
const SHOWING_ON_SCREEN_MS = 300000;

class OnScreenDialogue extends React.Component {
    state = {
        allMessages: [],
        newMessages: []
    }

    constructor() {
        super();
    }

    static getDerivedStateFromProps(props, state) {

        let newMessages = props.messages
            .filter(message => {
                return message.type === 'TALK_TO_WORLD' || message.type === 'TALK_TO_CHARACTER';
            })
            .filter(message => {
                return message.character?.position?.x !== undefined;
            })
            .filter(message => {
                const isExistingMessage = state.allMessages.find(stateMessage => (stateMessage._id === message._id));

                return !isExistingMessage;
            })

        newMessages = newMessages.map(message => {
            return {
                ...message
            }
        })

        const newMessage = newMessages[0];

        state.allMessages = props.messages;
        state.newMessages = [ ...state.newMessages, ...newMessages ]
            .filter(message => {
                return props.characters.find(character => (character._id === message.characterId))
            })
            .filter(message => (message.createdAt > new Date().getTime() - props.serverTimeOffset - SHOWING_ON_SCREEN_MS));

        if (props.isInitialisingPanel) {
            state.newMessages = []
        }

        return state;
    }

    render() {
        const canvas = document.getElementsByTagName('canvas')[0]

        const canvasWidth = Number(canvas?.style?.width.slice(0, -2));
        const canvasHeight = Number(canvas?.style?.height.slice(0, -2));

        const tileHeight = canvasHeight / PANEL_HEIGHT;
        const tileWidth = canvasWidth / PANEL_WIDTH;

        const messages = this.state.newMessages?.map((message, index) => {
            let top = 0;
            let left = 0;

            const dialogueTextContainer = document.getElementById(`on-screen-dialogue-${index}`)

            let dialogueTextContainerShape = dialogueTextContainer?.getBoundingClientRect()

            const character = this.props.characters.find(character => (character._id === message.character._id));

            if (!character) {
                return;
            }

            // I want this to say 'if its going off the top, stop it from doing that.'
            top = (tileHeight * character.position?.y) - (dialogueTextContainerShape?.height || 0);

            if (top < 0) {
                top = 0;
            }

            if (character.position?.x < PANEL_WIDTH / 2) {
                left = (tileWidth * character.position?.x) + tileWidth;
            } else {
                left = (tileWidth * character.position?.x) + tileWidth - (dialogueTextContainerShape?.width || 0);
            }

            return (
                <div key={index} id={`on-screen-dialogue-${index}`} className={dialogueTextContainer ? "on-screen-dialogue" :  "on-screen-dialogue hiding"} style={{ top, left }}>
                    {message.text}
                </div>
            )
        })

        const speechTags = this.state.newMessages?.map((message, index) => {
            let top = 0;
            let left = 0;

            const dialogueTextContainer = document.getElementById(`on-screen-dialogue-${index}`)

            let dialogueTextContainerShape = dialogueTextContainer?.getBoundingClientRect()
            let leftClassName = '';

            const character = this.props.characters.find(character => (character._id === message.character._id));

            if (!character) {
                return;
            }

            // I want this to say 'if its going off the top, stop it from doing that.'
            top = (tileHeight * character.position?.y);

            if (top < 0) {
                top = 0;
            }

            if (character.position?.x < PANEL_WIDTH / 2) {
                // to the right of the dudes head
                left = (tileWidth * character.position?.x) + tileWidth;
                leftClassName = 'right';
            } else {
                // to the left of the dudes head
                left = (tileWidth * character.position?.x);
            }

            return (
                <div key={index} id={`on-screen-dialogue-speech-tag-${index}`} className={dialogueTextContainer ? `on-screen-dialogue-speech-tag ${leftClassName}` :  `on-screen-dialogue-speech-tag hiding ${leftClassName}`} style={{ top, left }}>
                </div>
            )
        })

        return (
            <div className="on-screen-dialogue-container">
                {messages}
                {speechTags}
            </div>
        )
    }
}

const mapToStateProps = state => {
    const characters = selectCharacters(state);
    const messages = selectMessages(state);
    const serverTimeOffset = selectServerTimeOffset(state);

    const isInitialisingPanel = selectIsInitialisingPanel(state);

    return {
        messages,
        characters,
        serverTimeOffset,
        isInitialisingPanel
    }
}

export default connect(
    mapToStateProps,
    {
        pointAtAsync
    }
)(OnScreenDialogue);