import {
  CHANGE_CHARACTER,
  INITIALISING_PANEL_STARTED,
  START_NEW_GAME,
  SHOW_CHARACTER_DEATH_PAGE,
  GET_ORDERS_SUCCESS,
  ADD_ORDER,
  UPDATE_ORDER,
  REMOVE_ORDER,
  GET_COMPLETED_ORDERS_SUCCESS,
  ADD_COMPLETED_ORDER,
  UPDATE_COMPLETED_ORDER,
  REMOVE_COMPLETED_ORDER,
} from "../actionTypes";

const initialState = {
  orders: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case START_NEW_GAME:
    case INITIALISING_PANEL_STARTED:
    case CHANGE_CHARACTER:
    case SHOW_CHARACTER_DEATH_PAGE: {
      return {
        ...initialState,
      }
    }
    case GET_ORDERS_SUCCESS: {
      const orders = action.payload;

      console.log('orders: ', [...orders.map(order => ({ type: order.type, priority: order.priority, weight: order.weight }))])

      return {
        ...state,
        orders: [...orders],
      }
    }

    case GET_COMPLETED_ORDERS_SUCCESS: {
      const completedOrders = action.payload;

      const updatedOrders = [...state.orders].map(order => {
        const isCompleted = completedOrders.find(completedOrder => (completedOrder._id === order._id))

        return {
          ...order,
          isCompleted
        }
      })

      const fullyCompletedOrders = completedOrders
        .filter(completedOrder => (!updatedOrders.find(order => (order._id === completedOrder._id))))
        .map(order => ({ ...order, isCompleted: true, isFullyCompleted: true }))

      return {
        ...state,
        orders: [...updatedOrders, ...fullyCompletedOrders]
      }
    }

    case ADD_ORDER: {
      let orders = [...state.orders, action.payload];

      return {
        ...state,
        orders: [ ...orders ],
      }
    }

    case UPDATE_ORDER: {
      let orders = [...state.orders.filter(order => order._id !== action.payload._id), { ...state.orders.find(order => order._id === action.payload._id), ...action.payload }];

      return {
        ...state,
        orders: [ ...orders ],
      }
    }

    case REMOVE_ORDER: {
      return {
        ...state,
        orders: [ ...state.orders.filter(order => (order._id !== action.payload._id)) ],
      }
    }

    case ADD_COMPLETED_ORDER: {
      let orders = [...state.orders, { ...action.payload, isCompleted: true }];

      return {
        ...state,
        orders: [ ...orders ],
      }
    }

    case UPDATE_COMPLETED_ORDER: {
      let orders = [...state.orders.filter(order => order._id !== action.payload._id), { ...state.orders.find(order => order._id === action.payload._id), ...action.payload, isCompleted: true }];

      return {
        ...state,
        orders: [ ...orders ],
      }
    }

    case REMOVE_COMPLETED_ORDER: {
      return {
        ...state,
        orders: [ ...state.orders.filter(order => (order._id !== action.payload._id)) ],
      }
    }

    default:
      return state;
  }
}
