import store from '../redux/store';
import { batchActions } from 'redux-batched-actions';

import {
    selectCharacter,
    selectUser,
    selectCurrentTiles,
    selectMaterialTypes,
    selectTileTypes,
    selectPlantTypes,
    selectMineralTypes,
    selectToolTypes,
    selectMetalTypes,
    selectKeyTypes,
    selectMachineTypes,
    selectFurnitureTypes,
    selectConstructionTypes,
    selectWritingSurfaceTypes,
    selectWritingImplementTypes,
    selectBoatTypes,
    selectTentTypes,
    selectWagonTypes,
    selectWorkshopTypes,
    selectWeaponTypes,
    selectArmourTypes,
    selectClothingTypes,
    selectJewelleryTypes,
    selectFoodTypes,
    selectLockTypes,
    selectAnimalTypes,
    selectPlantProducts,
    selectConstructionRecipes,
    selectBrainchipTypes,
    selectCharacters,
    selectBoats,
    selectAnimals,
    selectBrainchips,
    selectActiveBrainchips
} from '../redux/selectors';
import { showCharacterDeathPage } from '../redux/actions/user.actions';
import { getDeadCharactersAsync } from '../redux/actions/dead-characters.actions';
import { getCharacterSuccess, addCharacter, updateCharacter, removeCharacter, getCharacterAsync } from '../redux/actions/character.actions';
import { addConstruction, updateConstruction, removeConstruction } from '../redux/actions/construction.actions';
import { addPlant, updatePlant, removePlant } from '../redux/actions/plant.actions';
import { getTileMineralsAsync, removeMineral, addMineral, updateMineral } from '../redux/actions/mineral.actions';
import { addMachine, updateMachine, removeMachine } from '../redux/actions/machine.actions';
import { groupEmbarkSuccess } from '../redux/actions/embark-character.actions';
import { addLock, updateLock, removeLock } from '../redux/actions/lock.actions';
import { addFurniture, updateFurniture, removeFurniture } from '../redux/actions/furniture.actions';
import { addWeapon, updateWeapon, removeWeapon } from '../redux/actions/weapon.actions';
import { addArmour, updateArmour, removeArmour } from '../redux/actions/armour.actions';
import { addClothing, updateClothing, removeClothing } from '../redux/actions/clothing.actions';
import { addJewellery, updateJewellery, removeJewellery } from '../redux/actions/jewellery.actions';
import { addTool, updateTool, removeTool } from '../redux/actions/tool.actions';
import { addMetal, updateMetal, removeMetal } from '../redux/actions/metal.actions';
import { addKey, updateKey, removeKey } from '../redux/actions/key.actions';
import { removeTile, addTile, updateTile } from '../redux/actions/tile.actions';
import { addFood, updateFood, removeFood } from '../redux/actions/food.actions';
import { addWritingSurface, updateWritingSurface, removeWritingSurface } from '../redux/actions/writing-surface.actions';
import { addWritingImplement, updateWritingImplement, removeWritingImplement } from '../redux/actions/writing-implement.actions';
import { removeMaterial, addMaterial, updateMaterial } from '../redux/actions/inventory.actions';
import { addAnimal, updateAnimal, removeAnimal } from '../redux/actions/animal.actions';
import { addFish, updateFish, removeFish } from '../redux/actions/fish.actions';
import { addBoat, updateBoat, removeBoat } from '../redux/actions/boat.actions';
import { addTent, updateTent, removeTent } from '../redux/actions/tent.actions';
import { addWagon, updateWagon, removeWagon } from '../redux/actions/wagon.actions';
import { addWorkshop, updateWorkshop, removeWorkshop } from '../redux/actions/workshop.actions';
import { addBrainchip, updateBrainchip, removeBrainchip } from '../redux/actions/brainchip.actions';
import { addCriminal, updateCriminal, removeCriminal } from '../redux/actions/criminal.actions';
import { showNearDeathScreen, showLevelUpMessage, showHungerWarning, showUnknownError } from '../redux/actions/keyboard-shortcuts.actions';
import { createCurrencySuccess } from '../redux/actions/currency.actions';
import { createCoinSuccess, removeCoinSuccess, updateCoinSuccess} from '../redux/actions/coin.actions';
import { getCoinTypesSuccess } from '../redux/actions/coin-types.actions';
import { getCoinRecipesSuccess } from '../redux/actions/coin-recipes.actions';
import { addPrompt, updatePrompt, removePrompt } from '../redux/actions/prompt.actions'
import { addDiaryEntry, updateDiaryEntry, removeDiaryEntry } from '../redux/actions/diary-entries.actions'
import { addCharacterPanelSuccess } from '../redux/actions/character-panel.actions';

import { getMoreMessagesSuccess } from '../redux/actions/messages.actions';
import { addOrganisation, updateOrganisationsSuccess, removeOrganisationSuccess } from '../redux/actions/organisation.actions';
import { addZone, updateZoneSuccess, removeZoneSuccess } from '../redux/actions/zone.actions';
import { addOrder, updateOrder, removeOrder, addCompletedOrder, updateCompletedOrder, removeCompletedOrder } from '../redux/actions/order.actions';
import { getTentBoundries } from './tent-helpers';

import tileMap from './tile-map';
import { addRidges, addCoast, addRivers, addLake, PANEL_WIDTH, PANEL_HEIGHT } from './geography';
import { BALANCE } from './balance-config';

import seedrandom from 'seedrandom';

import { client } from './client';
import { colyClient } from './Panel-initialisation.service';

class WebhooksService {
    character;
    user;
    tiles;
    drivenBoat;

    materialTypes;
    mineralTypes;
    plantTypes;
    tileTypes;
    isPassenger;

    animals;
    characters;

    animationService;
    characterWalkAnimations = [];
    dispatchQueue = []

    storeSubscription;

    addClothingTypes(clothingItem) {
        return {
            ...clothingItem,
            type: (
                clothingItem.itemType === 'clothing' ?
                this.clothingTypeDictionary[clothingItem.itemTypeId]
                : this.jewelleryTypeDictionary[clothingItem.itemTypeId]
            )
        }
    }

    addToQueue(action) {
        this.dispatchQueue.push(action);
    }

    constructor(tilemapService, audioService) {
        this.tilemapService = tilemapService;
        this.audioService = audioService;

        const state = store.getState();

        this.materialTypes = selectMaterialTypes(state);
        this.mineralTypes = selectMineralTypes(state);
        this.plantTypes = selectPlantTypes(state);
        this.tileTypes = selectTileTypes(state);
        this.toolTypes = selectToolTypes(state);
        this.metalTypes = selectMetalTypes(state);
        this.keyTypes = selectKeyTypes(state);
        this.machineTypes = selectMachineTypes(state);
        this.furnitureTypes = selectFurnitureTypes(state);
        this.constructionTypes = selectConstructionTypes(state);
        this.writingSurfaceTypes = selectWritingSurfaceTypes(state);
        this.writingImplementTypes = selectWritingImplementTypes(state);
        this.weaponTypes = selectWeaponTypes(state);
        this.armourTypes = selectArmourTypes(state);
        this.clothingTypes = selectClothingTypes(state);
        this.jewelleryTypes = selectJewelleryTypes(state);
        this.foodTypes = selectFoodTypes(state);
        this.lockTypes = selectLockTypes(state);
        this.animalTypes = selectAnimalTypes(state);
        this.boatTypes = selectBoatTypes(state);
        this.brainchipTypes = selectBrainchipTypes(state);
        this.tentTypes = selectTentTypes(state);
        this.workshopTypes = selectWorkshopTypes(state);
        this.wagonTypes = selectWagonTypes(state);
        this.plantProducts = selectPlantProducts(state);
        this.constructionRecipes = selectConstructionRecipes(state);

        this.armourTypeDictionary = {};
        this.armourTypes.forEach(armourType => {
            this.armourTypeDictionary[armourType._id] = armourType;
        })

        this.weaponTypeDictionary = {};
        this.weaponTypes.forEach(weaponType => {
            this.weaponTypeDictionary[weaponType._id] = weaponType;
        })

        this.clothingTypeDictionary = {}
        this.clothingTypes.forEach(clothingType => {
            this.clothingTypeDictionary[clothingType._id] = clothingType;
        })

        this.jewelleryTypeDictionary = {}
        this.jewelleryTypes.forEach(jewelleryType => {
            this.jewelleryTypeDictionary[jewelleryType._id] = jewelleryType;
        })


        this.storeSubscription = store.subscribe(() => {
            const state = store.getState();

            this.character = selectCharacter(state);
            this.user = selectUser(state);

            const boats = selectBoats(state);
            this.drivenBoat = boats.find(boat => (boat.driverId === this.character._id))
            this.isPassenger = boats.find(boat => (boat.passengerIds?.find(id => (id === this.character._id))))

            this.characters = selectCharacters(state);
        })

        setInterval(() => {
            if (this.dispatchQueue.length === 0) {
                return;
            }

            store.dispatch(batchActions(this.dispatchQueue))

            this.dispatchQueue = [];
        }, 200)
    }

    watchStateChanges(state) {
        state.coins.onAdd((response) => {
            this.addToQueue(createCoinSuccess(response))
            this.tilemapService.addItem(response);
        }, false)

        state.coins.onRemove((response) => {
            this.addToQueue(removeCoinSuccess(response));
            this.tilemapService.removeItem(response);
        })

        state.coins.onChange((response) => {
            if (!response) {
                return;
            }
            this.addToQueue(updateCoinSuccess(response));
            this.tilemapService.removeItem(response);
            this.tilemapService.addItem(response);
        }, true)

        // state.currency.onAdd((response) => {
        //     this.addToQueue(createCurrencySuccess(response));
        // });

        // state.coinTypes.onAdd((response) => {
        //     this.addToQueue(getCoinTypesSuccess([response]));
        // });

        // state.coinRecipes.onAdd((response) => {
        //     this.addToQueue(getCoinRecipesSuccess([response]));
        // });

        // state.embarkGroups.onRemove((embarkGroup) => {
        //     this.addToQueue(groupEmbarkSuccess())
        //     this.addToQueue(getCharacterAsync({ userId: this.user.user._id }))
        // })

        state.deadCharacters.onAdd((response) => {
            this.addToQueue(getDeadCharactersAsync({ ...this.character }))
            this.tilemapService.addItem(response);
        });
        state.deadCharacters.onRemove((response) => {
            this.addToQueue(getDeadCharactersAsync({ ...this.character }))
            this.tilemapService.removeItem(response);
        }, false);

        state.characters.onAdd((character) => {
            character.clothingItems = character.clothingItems?.map(clothingItem => this.addClothingTypes(clothingItem))
            character.armourType = this.armourTypeDictionary[character.armourTypeId]
            character.weaponType = this.weaponTypeDictionary[character.weaponTypeId]
            this.addToQueue(addCharacter(character))
            this.tilemapService.addCharacter(character);
        }, false);
        state.characters.onChange((_character) => {
            let character = { ..._character }
            let isChangingPanels = false;
            if (!character) {
                return;
            }

            if (character.panelId !== this.character.panelId) {
                isChangingPanels = true;
            }

            character.clothingItems = character.clothingItems?.map(clothingItem => this.addClothingTypes(clothingItem))
            character.armourType = this.armourTypeDictionary[character.armourTypeId]
            character.weaponType = this.weaponTypeDictionary[character.weaponTypeId]
            
            this.addToQueue(updateCharacter(character))

            character = { ...this.characters.find(oldCharacter => (oldCharacter._id === character._id)), ...character }

            if (character._id === this.character._id && !this.isPassenger) {
                if (character.level && character.level !== this.character.level) {
                    this.addToQueue(showLevelUpMessage())
                }

                if (character.healthPoints <= 0 && this.character.healthPoints !== character.healthPoints) {
                    this.addToQueue(showNearDeathScreen())
                }

                // if (character.hunger === BALANCE.HUNGER_IMPACTS_VALUE && this.character.hunger > 0) {
                //     this.addToQueue(showUnknownError({ message: 'Warning: You are hungry. Your HP will reduce by 1 each turn unless you eat again' }));
                // }

                if (character.position?.x === this.character.position?.x && character.position?.y === this.character.position?.y) {
                    this.addToQueue(updateCharacter(character))
                } else {
                    this.audioService.playSound('step')
                }

                if (this.character.isBeingDragged) {
                    this.addToQueue(updateCharacter({ ...character, serverPosition: character.position }))
                }
            } else {
                const previousCharacter = this.characters.find(previousCharacter => (previousCharacter._id === character._id))

                if (previousCharacter) {
                    const yDiff = Math.abs(previousCharacter.position.y - character.position.y);
                    const xDiff = Math.abs(previousCharacter.position.x - character.position.x);

                    if (yDiff > 1 || xDiff > 1) {
                        // this.animateWalking(character, previousCharacter);
                    } else {
                        this.addToQueue(updateCharacter(character))
                    }
                } else {
                    this.addToQueue(updateCharacter(character))
                }
            }

            if (isChangingPanels) {
                return;
            }

            this.tilemapService.removeCharacter(character);
            this.tilemapService.addCharacter(character);
        });
        state.characters.onRemove((character) => {
            if (character._id === this.character._id) {
                this.addToQueue(showCharacterDeathPage());
            } else {
                this.addToQueue(removeCharacter(character))
            }
            this.tilemapService.removeCharacter(character);
        });
        // characters.on('leaving', (character) => {
        //     // TODO = walking animation needs fixing
        //     this.tidyUpWalkingAnimation(character);

        //     const previousCharacter = this.characters.find(previousCharacter => (previousCharacter._id === character._id))

        //     if (previousCharacter) {
        //         const yDiff = Math.abs(previousCharacter.position.y - character.position.y);
        //         const xDiff = Math.abs(previousCharacter.position.x - character.position.x);

        //         if (yDiff > 1 || xDiff > 1) {
        //             this.animateWalking(character, previousCharacter, () => (this.addToQueue(removeCharacter(character))));
        //         } else {
        //             this.addToQueue(removeCharacter(character))
        //         }
        //     }
        // });

        state.plants.onAdd((response) => {
            response.plant = { ...this.plantTypes.find(plantType => plantType._id === response.plantTypeId )};
            response.plantProducts = [ ...this.plantProducts.filter(plantProduct => plantProduct.plantTypeId === response.plantTypeId )];
            this.addToQueue(addPlant(response))
            this.tilemapService.addItem(response);
        }, false);
        state.plants.onChange((response) => {
            if (!response) {
                return;
            }
            response.plant = { ...this.plantTypes.find(plantType => plantType._id === response.plantTypeId )};
            response.plantProducts = [ ...this.plantProducts.filter(plantProduct => plantProduct.plantTypeId === response.plantTypeId )];
            this.addToQueue(updatePlant(response))
            this.tilemapService.removeItem(response);
            this.tilemapService.addItem(response);
        });
        state.plants.onRemove((response) => {
            console.log('removed a plant my good man', response)
            this.addToQueue(removePlant(response))
            this.tilemapService.removeItem(response);
        });

        state.tools.onAdd((response) => {
            response.toolType = { ...this.toolTypes.find(toolType => toolType._id === response.toolTypeId )};
            this.addToQueue(addTool(response))
            this.tilemapService.addItem(response);
        }, false);
        state.tools.onChange((response) => {
            if (!response) {
                return;
            }
            response.toolType = { ...this.toolTypes.find(toolType => toolType._id === response.toolTypeId )};
            this.addToQueue(updateTool(response))
            this.tilemapService.removeItem(response);
            this.tilemapService.addItem(response);
        }, true);
        state.tools.onRemove((response) => {
            this.addToQueue(removeTool(response))
            this.tilemapService.removeItem(response);
        });

        state.metals.onAdd((response) => {
            response.metalType = { ...this.metalTypes.find(metalType => metalType._id === response.metalTypeId )};
            this.addToQueue(addMetal(response))
            this.tilemapService.addItem(response);
        }, false);
        state.metals.onChange((response) => {
            if (!response) {
                return;
            }
            this.addToQueue(updateMetal(response))
            this.tilemapService.removeItem(response);
            this.tilemapService.addItem(response);
        });
        state.metals.onRemove((response) => {
            this.addToQueue(removeMetal(response))
            this.tilemapService.removeItem(response);
        });

        state.keys.onAdd((response) => {
            response.keyType = { ...this.keyTypes.find(keyType => keyType._id === response.keyTypeId )};
            this.addToQueue(addKey(response))
            this.tilemapService.addItem(response);
        }, false);
        state.keys.onChange((response) => {
            if (!response) {
                return;
            }
            this.addToQueue(updateKey(response))
            this.tilemapService.removeItem(response);
            this.tilemapService.addItem(response);
        });
        state.keys.onRemove((response) => {
            this.addToQueue(removeKey(response))
            this.tilemapService.removeItem(response);
        });
        
        state.furniture.onAdd((response) => {
            response.furnitureType = { ...this.furnitureTypes.find(furnitureType => furnitureType._id === response.furnitureTypeId )};
            this.addToQueue(addFurniture(response))
            this.tilemapService.addItem(response);
        }, false);
        state.furniture.onChange((response) => {
            if (!response) {
                return;
            }
            this.addToQueue(updateFurniture(response))
            this.tilemapService.removeItem(response);
            this.tilemapService.addItem(response);
        });
        state.furniture.onRemove((response) => {
            this.addToQueue(removeFurniture(response))
            this.tilemapService.removeItem(response);
        });

        state.constructions.onAdd((response) => {
            response.construction = { ...this.constructionTypes.find(constructionType => constructionType._id === response.constructionTypeId )};
            response.recipes = [ ...this.constructionRecipes?.filter(constructionRecipe => constructionRecipe.constructionTypeId === response.constructionTypeId ) ]
            this.addToQueue(addConstruction(response))
            this.tilemapService.addItem(response);
        }, false);
        state.constructions.onChange((response) => {
            if (!response) {
                return;
            }
            response.construction = { ...this.constructionTypes.find(constructionType => constructionType._id === response.constructionTypeId )};
            response.recipes = [ ...this.constructionRecipes?.filter(constructionRecipe => constructionRecipe.constructionTypeId === response.constructionTypeId ) ]
            this.addToQueue(updateConstruction(response))
            this.tilemapService.removeItem(response);
            this.tilemapService.addItem(response);
        });
        state.constructions.onRemove((response) => {
            this.addToQueue(removeConstruction(response))
            this.tilemapService.removeItem(response);
        });

        state.writingSurfaces.onAdd((response) => {
            response.writingSurfaceType = { ...this.writingSurfaceTypes.find(writingSurfaceType => writingSurfaceType._id === response.writingSurfaceTypeId )};
            this.addToQueue(addWritingSurface(response))
            this.tilemapService.addItem(response);
        }, false);
        state.writingSurfaces.onChange((response) => {
            if (!response) {
                return;
            }
            const parsedGraffiti = JSON.parse(response.graffiti)
            response = {
                ...response,
                graffiti: parsedGraffiti
            }
            this.addToQueue(updateWritingSurface(response))
            this.tilemapService.removeItem(response);
            this.tilemapService.addItem(response);
        });
        state.writingSurfaces.onRemove((response) => {
            this.addToQueue(removeWritingSurface(response))
            this.tilemapService.removeItem(response);
        });

        state.writingImplements.onAdd((response) => {
            response.writingImplementType = { ...this.writingImplementTypes.find(writingImplementType => writingImplementType._id === response.writingImplementTypeId )};
            this.addToQueue(addWritingImplement(response))
            this.tilemapService.addItem(response);
        }, false);
        state.writingImplements.onChange((response) => {
            if (!response) {
                return;
            }
            this.addToQueue(updateWritingImplement(response))
            this.tilemapService.removeItem(response);
            this.tilemapService.addItem(response);
        });
        state.writingImplements.onRemove((response) => {
            this.addToQueue(removeWritingImplement(response))
            this.tilemapService.removeItem(response);
        });

        state.weapons.onAdd((response) => {
            response.weaponType = { ...this.weaponTypes.find(weaponType => weaponType._id === response.weaponTypeId )};
            this.addToQueue(addWeapon(response))
            this.tilemapService.addItem(response);
        }, false);
        state.weapons.onChange((response) => {
            if (!response) {
                return;
            }
            response.weaponType = { ...this.weaponTypes.find(weaponType => weaponType._id === response.weaponTypeId )};
            this.addToQueue(updateWeapon(response))
            this.tilemapService.removeItem(response);
            this.tilemapService.addItem(response);
        });
        state.weapons.onRemove((response) => {
            this.addToQueue(removeWeapon(response))
            this.tilemapService.removeItem(response);
        });

        state.armour.onAdd((response) => {
            response.armourType = { ...this.armourTypes.find(armourType => armourType._id === response.armourTypeId )};
            this.addToQueue(addArmour(response))
            this.tilemapService.addItem(response);
        }, false);
        state.armour.onChange((response) => {
            if (!response) {
                return;
            }
            this.addToQueue(updateArmour(response))
            this.tilemapService.removeItem(response);
            this.tilemapService.addItem(response);
        });
        state.armour.onRemove((response) => {
            this.addToQueue(removeArmour(response))
            this.tilemapService.removeItem(response);
        });

        state.clothing.onAdd((response) => {
            response.clothingType = { ...this.clothingTypeDictionary[response.clothingTypeId] };
            this.addToQueue(addClothing(response))
            this.tilemapService.addItem(response);
        }, false);
        state.clothing.onChange((response) => {
            if (!response) {
                return;
            }
            this.addToQueue(updateClothing(response))
            this.tilemapService.removeItem(response);
            this.tilemapService.addItem(response);
        });
            
        state.clothing.onRemove((response) => {
            this.addToQueue(removeClothing(response))
            this.tilemapService.removeItem(response);
        });

        state.jewellery.onAdd((response) => {
            response.jewelleryType = { ...this.jewelleryTypes.find(jewelleryType => jewelleryType._id === response.jewelleryTypeId )};
            this.addToQueue(addJewellery(response))
            this.tilemapService.addItem(response);
        }, false);
        state.jewellery.onChange((response) => {
            if (!response) {
                return;
            }
            this.addToQueue(updateJewellery(response))
            this.tilemapService.removeItem(response);
            this.tilemapService.addItem(response);
        });
            
        state.jewellery.onRemove((response) => {
            this.addToQueue(removeJewellery(response))
            this.tilemapService.removeItem(response);
        });

        state.food.onAdd((response) => {
            response.foodType = { ...this.foodTypes.find(foodType => foodType._id === response.foodTypeId )};
            this.addToQueue(addFood(response))
            this.tilemapService.addItem(response);
        }, false);
        state.food.onChange((response) => {
            if (!response) {
                return;
            }
            if (response.quantity === 0) {
                this.addToQueue(removeFood(response))
            } else {
                this.addToQueue(updateFood(response))
            }
            this.tilemapService.removeItem(response);
            this.tilemapService.addItem(response);
        });
        state.food.onRemove((response) => {
            this.addToQueue(removeFood(response))
            this.tilemapService.removeItem(response);
        });

        state.materials.onAdd((response) => {
            response.materialType = this.materialTypes.find(materialType => materialType._id === response.materialTypeId);
            response.plant = this.plantTypes.find(plantType => plantType._id === response.plantTypeId);
            response.animal = this.animalTypes.find(animalType => animalType._id === response.animalTypeId);
            this.addToQueue(addMaterial(response))
            this.tilemapService.addItem(response);
        }, false);
        state.materials.onChange((response) => {
            if (!response) {
                return;
            }
            this.addToQueue(updateMaterial(response))
            this.tilemapService.removeItem(response);
            this.tilemapService.addItem(response);
        });
        state.materials.onRemove((response) => {
            this.addToQueue(removeMaterial(response))
            this.tilemapService.removeItem(response);
        });

        state.locks.onAdd((response) => {
            response.lockType = { ...this.lockTypes.find(lockType => lockType._id === response.lockTypeId )};
            this.addToQueue(addLock(response))
            this.tilemapService.addItem(response);
        }, false);
        state.locks.onChange((response) => {
            if (!response) {
                return;
            }
            response.lockType = { ...this.lockTypes.find(lockType => lockType._id === response.lockTypeId )};
            this.addToQueue(updateLock(response))
            this.tilemapService.removeItem(response);
            this.tilemapService.addItem(response);
        });
        state.locks.onRemove((response) => {
            this.addToQueue(removeLock(response))
            this.tilemapService.removeItem(response);
        });

        state.tiles.onAdd((response) => {
            response.tileType = { ...this.tileTypes.find(tileType => tileType._id === response.tileTypeId )};
            store.dispatch(addTile(response))
            this.tilemapService.addItem(response);
        }, false);
        state.tiles.onChange((response) => {
            if (!response) {
                return;
            }
            store.dispatch(updateTile(response));
            this.tilemapService.removeItem(response);
            this.tilemapService.addItem(response);
        });
        state.tiles.onRemove((response) => {
            store.dispatch(removeTile(response));
            this.tilemapService.removeItem(response);
        });
        // tiles.on('leaving', (response) => {
        //     this.addToQueue(removeTile(response))
        // });

        state.minerals.onAdd((response) => {
            response.mineralType = { ...this.mineralTypes.find(mineralType => mineralType._id === response.mineralTypeId) }
            this.addToQueue(addMineral(response))
            if (!response.characterId) {
                this.tilemapService.addItem(response);
            }
        }, false);
        state.minerals.onChange((response) => {
            if (!response) {
                return;
            }
            this.addToQueue(updateMineral(response));
            if (!response.characterId) {
                this.tilemapService.removeItem(response);
                this.tilemapService.addItem(response);
            }
        })
        state.minerals.onRemove((response) => {
            this.addToQueue(removeMineral(response))
            // TODO - turn this back on :)
            if (!response.characterId) {
                this.tilemapService.removeItem(response);
            }
        });

        state.animals.onAdd((response) => {
            response.animal = { ...this.animalTypes.find(animalType => animalType._id === response.animalTypeId) }
            this.addToQueue(addAnimal(response))
            this.tilemapService.addItem(response);
        }, false);
        state.animals.onChange((response) => {
            if (!response) {
                return;
            }
            const originalAnimals = selectAnimals(store.getState())
            const originalAnimal = originalAnimals.find(animal => (animal.id === response.id))
            response = { ...originalAnimal, ...response };
            response.animal = { ...this.animalTypes.find(animalType => animalType._id === response.animalTypeId) }
            this.addToQueue(updateAnimal(response))
            this.tilemapService.removeItem(response);
            this.tilemapService.addItem(response);
        });
        state.animals.onRemove((response) => {
            this.addToQueue(removeAnimal(response))
            this.tilemapService.removeItem(response);
        });

        state.boats.onAdd((response) => {
            response.boatType = { ...this.boatTypes.find(boatType => boatType._id === response.boatTypeId )};
            this.addToQueue(addBoat(response))
            this.tilemapService.addItem(response);
        }, false);
        state.boats.onChange((response) => {
            if (!response) {
                return;
            }
            if (response.driverId === this.character._id) {
                return
            }
            if (this.drivenBoat && response.driverId === this.character._id && (this.drivenBoat?.position.x !== response.position.x || this.drivenBoat?.position.y !== response.position.y)) {
                return
            }
            this.addToQueue(updateBoat(response))
            this.tilemapService.removeItem(response);
            this.tilemapService.addItem(response);
        });
        state.boats.onRemove((response) => {
            this.addToQueue(removeBoat(response))
            this.tilemapService.removeItem(response);
        });

        state.wagons.onAdd((response) => {
            response.wagonType = { ...this.wagonTypes.find(wagonType => wagonType._id === response.wagonTypeId )};
            this.addToQueue(addWagon(response))
            this.tilemapService.addItem(response);
        }, false);
        state.wagons.onChange((response) => {
            if (!response) {
                return;
            }
            this.addToQueue(updateWagon(response))
            this.tilemapService.removeItem(response);
            this.tilemapService.addItem(response);
        });
        state.wagons.onRemove((response) => {
            this.addToQueue(removeWagon(response))
            this.tilemapService.removeItem(response);
        });

        state.workshops.onAdd((response) => {
            response.workshopType = { ...this.workshopTypes.find(workshopType => workshopType._id === response.workshopTypeId )};
            response.workshopBoundries = getTentBoundries(response.position, 'north', response.workshopType)
            this.addToQueue(addWorkshop(response))
            this.tilemapService.addItem(response);
        }, false);
        state.workshops.onChange((response) => {
            if (!response) {
                return;
            }
            response.workshopType = { ...this.workshopTypes.find(workshopType => workshopType._id === response.workshopTypeId )};
            response.workshopBoundries = getTentBoundries(response.position, 'north', response.workshopType)
            this.addToQueue(updateWorkshop(response))
            this.tilemapService.removeItem(response);
            this.tilemapService.addItem(response);
        });
        state.workshops.onRemove((response) => {
            this.addToQueue(removeWorkshop(response))
            this.tilemapService.removeItem(response);
        });

        state.brainchips.onAdd((response) => {
            response.brainchipType = { ...this.brainchipTypes.find(brainchipType => brainchipType._id === response.brainchipTypeId )};
            this.addToQueue(addBrainchip(response))
            this.tilemapService.addItem(response);
        }, false);
        state.brainchips.onChange((response) => {
            if (!response) {
                return;
            }
            response.brainchipType = { ...this.brainchipTypes.find(brainchipType => brainchipType._id === response.brainchipTypeId )};
            this.addToQueue(updateBrainchip(response))
            this.tilemapService.removeItem(response);
            this.tilemapService.addItem(response);
        });
        state.brainchips.onRemove((response) => {
            this.addToQueue(removeBrainchip(response))
            this.tilemapService.removeItem(response);
        });

        state.crimes.onAdd((response) => {
            this.addToQueue(addCriminal(response))
        }, false);
        state.crimes.onChange((response) => {
            if (!response) {
                return;
            }
            this.addToQueue(updateCriminal(response))
        });
        state.crimes.onRemove((response) => {
            this.addToQueue(removeCriminal(response))
        });

        state.messages.onAdd((message) => {
            this.audioService.playSound('speak')
            // TODO - fire breath and other thing... shooting at position not character....
            if (message.text.indexOf('breathed fire') > -1 || message.text.indexOf('shot at') > -1 || message.text.indexOf('shot webs at') > -1 || message.text.indexOf('shot fire at') > -1) {
                // do a shoot animation
                setTimeout(() => {
                    this.tilemapService.foregroundTilemapService?.animateShooting(message)
                }, 200)
                return;
            }

            this.addToQueue(getMoreMessagesSuccess({ total: 1, data: [message] }))
        }, false);
        state.messages.onChange((message) => {
            if (!message) {
                return;
            }
            this.addToQueue(getMoreMessagesSuccess(message));
        })
        state.messages.onRemove((message) => this.addToQueue(getMoreMessagesSuccess(message)));

        state.organisations.onAdd((organisation) => {
            this.addToQueue(addOrganisation(organisation))
        }, false)

        state.organisations.onChange((organisation) => {
            if (!organisation) {
                return;
            }

            this.addToQueue(updateOrganisationsSuccess(organisation))
        }, false)

        state.organisations.onRemove((organisation) => this.addToQueue(removeOrganisationSuccess(organisation)));

        state.zones.onAdd((zone) => {
            this.addToQueue(addZone(zone))
        }, false)

        state.zones.onChange((zone) => {
            if (!zone) {
                return;
            }
            this.addToQueue(updateZoneSuccess(zone))
        }, false)

        state.zones.onRemove((zone) => this.addToQueue(removeZoneSuccess(zone)));

        state.orders.onAdd((order) => {
            this.addToQueue(addOrder(order))
        }, false)

        state.orders.onChange((order) => {
            if (!order) {
                return;
            }

            if (order.type === 'create') {

                const workshopType = this.workshopTypes.find(type => type._id === order.workshop.workshopTypeId)

                switch (workshopType.name) {
                    case `Woodcollier's`:
                        this.audioService.playSound('collier')
                        break;

                    case `Stonemason's`:
                    case `Builder's Yard`:
                        this.audioService.playSound('mason')
                        break;

                    case `Carpenter's`:
                    case `Shipwright`:
                        this.audioService.playSound('carpenter')
                        break;

                    case `Blacksmith's`:
                    case `Mint`:
                    case `Jewellers`:
                        this.audioService.playSound('blacksmith')
                        break;

                    case `Doctors's`:
                    case `Apothecary`:
                        this.audioService.playSound('doctor')
                        break;

                    case `Potter's`:
                        this.audioService.playSound('potter')
                        break;

                    case `Tailor's`:
                    case `Dyer's`:
                        this.audioService.playSound('tailor')
                        break;

                    case `Tannery`:
                        this.audioService.playSound('tanner')
                        break;

                    case `Kitchen`:
                        this.audioService.playSound('kitchen')
                        break;

                    default: 
                        this.audioService.playSound('craft')
                        break;
                }
            }

            if (order.type === 'build') {
                this.audioService.playSound('mason')
            }

            if (order.type === 'gather') {
                switch (order.data.resources) {
                    case 'wood':
                    case 'brush':
                    case 'fruit & vegetables':
                    case 'farm plot':
                    case 'tend plants':
                        this.audioService.playSound('collectPlant');
                        break;
                    case 'minerals':
                        this.audioService.playSound('collectMineral');
                        break;
                    case 'fuel light':
                        this.audioService.playSound('fire');
                        break;
                    case 'repair constructions':
                        this.audioService.playSound('masonry');
                        break;
                    case 'damage constructions':
                    default:
                        this.audioService.playSound('fight');
                        break;
                }
            }

            if (order.type === 'fetch') {
                this.audioService.playSound('collectItem');
            }

            if (order.type === 'haul') {
                this.audioService.playSound('collectItem');
            }

            if (order.type === 'torch') {
                this.audioService.playSound('fire');
            }

            this.addToQueue(updateOrder(order))
        }, false)

        state.orders.onRemove((order) => {
            this.addToQueue(removeOrder(order))
        })

        state.completedOrders.onAdd((order) => {
            this.addToQueue(addCompletedOrder(order))
        }, false)

        state.completedOrders.onChange((order) => {
            if (!order) {
                return;
            }

            this.addToQueue(updateCompletedOrder(order))
        }, false)

        state.completedOrders.onRemove((order) => {
            this.addToQueue(removeCompletedOrder(order))
        })

        state.characterPanels.onAdd((characterPanel) => {
            this.addToQueue(addCharacterPanelSuccess({
                ...characterPanel
            }))
        })

        state.characterPanels.onChange((characterPanel) => {
            this.addToQueue(addCharacterPanelSuccess({
                ...characterPanel
            }))
        })
    }

    animateWalking(character, previousCharacter, onDone) {
        if (previousCharacter.panelId !== character.panelId) {
            return;
        }

        const yDiff = Math.abs(previousCharacter.position.y - character.position.y);
        const xDiff = Math.abs(previousCharacter.position.x - character.position.x);

        let tries = 0;
        let newY;
        let newX;

        newY = this.getNewPosition(newY, previousCharacter.position.y, character.position.y);
        newX = this.getNewPosition(newX, previousCharacter.position.x, character.position.x);

        this.addToQueue(updateCharacter({ ...character, position: { x: newX, y: newY } }))

        tries++;

        const walkAnimation = setInterval(() => {
            tries++;

            newY = this.getNewPosition(newY, previousCharacter.position.y, character.position.y);
            newX = this.getNewPosition(newX, previousCharacter.position.x, character.position.x);

            this.addToQueue(updateCharacter({ ...character, position: { x: newX, y: newY } }))

            if (tries === Math.max(yDiff, xDiff)) {
                clearInterval(walkAnimation);
                if (onDone) {
                    onDone()
                }
                this.characterWalkAnimations = [ ...this.characterWalkAnimations.filter(animation => (animation._id !== character._id)) ]
            }
        }, 100)

        this.characterWalkAnimations.push({ walkAnimation, _id: character._id })

        return;
    }

    getNewPosition(lastPosition, startPosition, endPosition) {
        if (startPosition === endPosition) {
            return endPosition;
        }

        if (endPosition > startPosition) {
            if (!lastPosition) {
                return startPosition + 1
            } else {
                return lastPosition === endPosition ? endPosition : lastPosition + 1
            }
        }

        if (endPosition < startPosition) {
            if (!lastPosition) {
                return startPosition - 1
            } else {
                return lastPosition === endPosition ? endPosition : lastPosition - 1
            }
        }
    }
}

export default WebhooksService;