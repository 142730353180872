import store from '../redux/store';
import seedrandom from 'seedrandom';
import { isBefore, addDays } from 'date-fns';

import {
	selectVisionRadius,
	selectCharacter,
	selectPlants,
    selectCharacters,
    selectDeadCharacters,
    selectTentById,
    selectWorkshopTypeById,
    selectWorkshops,
    selectBackgroundConstructions,
    selectWagons,
    selectBoats,
    selectItemTileIcons,
    selectFurniture,
    selectStairs,
    selectMineWalls,
    selectMinerals,
    selectCurrentTool,
    selectServerTimeOffset,
    selectBrainchipTypeByIds,
    selectBrainchipTypes,
    selectTents,
    selectPanel,
    selectCharacterWorkshopBoundries,
    selectForegroundConstructions,
    selectIsInitialisingApp,
    selectIsInitialisingPanel
} from '../redux/selectors';
import {
    WORLD_WIDTH,
    WORLD_HEIGHT,
    drawTundraClouds,
    drawMistyTundra,
    drawInglenook,
    drawWoneyed,
    drawMountains,
    drawSands,
    drawWastes,
    drawGumboRumblings,
    drawPricklelands,
    drawHeaths,
    drawSteppes,
    drawDots,
    drawTundra,
    drawTundraFern,
    drawTundraThorn,
    drawPits,
    drawPitsAndPlants,
    drawNeedlewoodForest,
    drawIcyWastes,
    drawMazeScrub,
    drawPolarWastes,
    drawPrimeordeal,
    drawPuzzlebox,
    drawThistlewoodForest,
    drawSlimes,
    drawSlabs,
    drawNeighbours,
    getNeighbours,
    getDiagonalNeighbours,
    isEmptyIndex,
    drawCoolTemperateDesertRiver,
    drawWarmTemperateDesertRiver,
    drawSubtropicalDesertRiver,
    drawTropicalDesertRiver,
    drawPainForest,
    drawFurForest,
    drawEvergreenForest,
    drawCrunchyForest,
    drawCrystalisedForest,
    drawDeepestJungle,
    drawDiscordantForest,
    drawFoetalRainforest,
    drawFossilisedForest,
    drawLusciousRainforest,
    drawOldGrowth,
    drawOvergrownFur,
    drawPrimeval,
    drawSwampyForest,
    drawHighSeas,
} from './biome-geography';
import { getMineWallsSuccess } from '../redux/actions/mine-wall.actions';
import { BALANCE } from './balance-config';
import { differenceInCalendarDays } from 'date-fns';
import tileMap from './tile-map';
import { PANEL_WIDTH, PANEL_HEIGHT, randomNumberBetween } from './geography'
import { drawFieldOfView, isWithinUserVision } from './night-radius';
import { getTentBoundries } from './tent-helpers';

import { calculateLightSources, BRAINCHIP_TYPE_NAMES } from './Tilemap.service';

import client from './client';

const TILE_WIDTH = 16;
const TILE_HEIGHT = 24;

function getMockLayer() {
    const mockLayerData = [];

    for (var y=0; y < PANEL_HEIGHT; y++) {
        mockLayerData[y] = []
        for (var x=0; x < PANEL_WIDTH; x++) {
            mockLayerData[y][x] = []
        }
    }

    return mockLayerData;
}

export default class FloorTilemapService {
	floorLayer;

	storeSubscription;
	layer;
    scene;
    panel;
    visibleTiles;
    currentActiveBrainchips;
    plants;

    isInitialising = true;

    constructor(floorLayer, scene) {
        this.scene = scene;
    	this.floorLayer = floorLayer;
    	this.layer = getMockLayer();
    }

    initialDraw(state, updatedCharacter, visibleTiles) {
        if (!state) {
            state = store.getState();
        }

        if (!updatedCharacter) {
            updatedCharacter = selectCharacter(state);
        }

        console.log('panel Id: ', updatedCharacter.panelId)

        const updatedCharacters = selectCharacters(state);
        const updatedPanel = selectPanel(state);
        const updatedVisionRadius = selectVisionRadius(state);
        const selectedConstructions = selectForegroundConstructions(state);
        const updatedConstructions = Object.values(selectedConstructions.byId);
        let updatedPlants = selectPlants(state);
        updatedPlants = Object.values(updatedPlants.byId)

        this.visibleTiles = visibleTiles

        this.currentActiveBrainchips = selectBrainchipTypeByIds(state, updatedCharacter.activeBrainchipTypeIds)
        this.timeOffset = selectServerTimeOffset(state);

        const isInitialisingApp = selectIsInitialisingApp(state);
        const isInitialisingPanel = selectIsInitialisingPanel(state);

        this.isInitialising = isInitialisingApp || isInitialisingPanel;

        let updatedMineWalls = [];

        const isNewPanelOrZIndex = this.character?.panelId !== updatedCharacter?.panelId || this.character?.z !== updatedCharacter?.z;

        if (isNewPanelOrZIndex) {
            this.layer = getMockLayer();
        }

        if (updatedCharacter.z < 0) {
            updatedMineWalls = selectMineWalls(state);

            if (updatedMineWalls) {
                this.removeItems(this.mineWalls);
                this.addItems(updatedMineWalls);
            }
        }

        if (updatedPanel?.id !== this.panel?.id || (updatedCharacter?.z === 0 && (this.character?.z < 0 || this.character?.z > 0))) {
            if (updatedCharacter.z === 0) {
                this.panel = updatedPanel

                const cliffsAndOceans = updatedPanel.floorLayer?.filter(tile => (tile.tileType === 'OCEAN' || tile.tileType === 'Cliff' || tile.tileType === 'MOUNTAIN'));
                this.addItems(cliffsAndOceans)
                this.addAllCoastlines(cliffsAndOceans);
            }
        }

        if (isNewPanelOrZIndex) {
            this.character = updatedCharacter;
        }

        const lightSourceCharacters = updatedCharacters.filter(character => {
            return character.lightSource?.expiresAt - new Date().getTime() - this.timeOffset > 0
        })

        this.lightBlockingObjects = []

        if (updatedPlants) {
            this.lightBlockingObjects = [ ...this.lightBlockingObjects, ...updatedPlants.filter(plant => {
                const a = plant.position.x - updatedCharacter.position.x
                const b = plant.position.y - updatedCharacter.position.y

                const distance = Math.hypot(a, b);

                return distance > 2
            })  ]

            this.removeFarmPlots(this.plants)
            this.addFarmPlots(updatedPlants);
        }

        // console.log(this.visibleTiles.length);

        // Finish up

        this.character = updatedCharacter;
        this.visionRadius = updatedVisionRadius;
        this.characters = updatedCharacters;
        this.panel = updatedPanel;
        this.mineWalls = updatedMineWalls;
        this.plants = updatedPlants;

        this.copyLayersOver();
    }

    removeFarmPlots(plants){
        if (!plants) {
            return;
        }

        plants.forEach(({ position }) => {
            this.layer[position.y][position.x] = this.layer[position.y][position.x].filter(item => {
                if (item.type === 'farm-plot') {
                    return false;
                }

                return true;
            });
        })
    }

    addFarmPlots(plants) {
        if (!plants) {
            return;
        }

        plants.forEach(({ position, tendedAt, tendedCount }) => {
            if (tendedCount > 0) {
                const oneDaysAgo = new Date().getTime() - BALANCE.DAY_IN_MILLISECONDS;

                if (isBefore(oneDaysAgo, new Date(tendedAt))) {
                    this.layer[position.y][position.x].push({
                        type: 'farm-plot',
                        tileType: 'Farm Plot.Untended'
                    })

                } else {
                    this.layer[position.y][position.x].push({
                        type: 'farm-plot',
                        tileType: 'Farm Plot.Tended'
                    })
                }
            }
        })
    }

    removeItems(items) {
        if (!items) {
            return;
        }

        items.forEach(({ z, position, id, _id }) => {
            if (!position || position.x === undefined || position.y < 0 || position.x < 0 || position.y >= PANEL_HEIGHT || position.x >= PANEL_WIDTH) {
                return;
            }

            this.layer[position.y][position.x] = this.layer[position.y][position.x].filter(item => {
                if (item.setAlpha) {
                    return true;
                }

                if (item._id && _id) {
                    return item._id !== _id
                }

                if (item.id && id) {
                    return item.id !== id
                }

                if (item._id && id) {
                    return true;
                }

                if (item.id && _id) {
                    return true;
                }
            });
        })
    }

    addItems(items) {
        if (!items) {
            return;
        }

        items.forEach((item) => {
            if (item.x !== undefined) {
                item.position = {
                    x: item.x,
                    y: item.y
                }
            }

            if (!item || !item.position || item.position.x === undefined || item.position.y < 0 || item.position.x < 0 || item.position.y >= PANEL_HEIGHT || item.position.x >= PANEL_WIDTH) {
                return;
            }

            if (item?.z && item?.z !== this.character?.z) {
                return;
            }

            this.layer[item.position.y][item.position.x].push({ ...item });
        })
    }

    addAllCoastlines(cliffsAndOceans) {
        this.layer.forEach((row, y) => {
            row.forEach((tile, x) => {
                const coastlineKey = this.addCoastLine(cliffsAndOceans, { x, y });

                if (coastlineKey) {
                    this.layer[y][x] = [{
                        x,
                        y,
                        tileKey: coastlineKey
                    }]
                }
            })
        })
    }

    addCoastLine(layerData, tile) {
        if (!layerData) {
            return;
        }

        const current = layerData.find(leftTile => (leftTile.x === tile.x && leftTile.y === tile.y))

        if (current) {
            return;
        }

        let top = layerData.find(leftTile => (leftTile.x === tile.x && leftTile.y === tile.y - 1))
        let topLeft = layerData.find(leftTile => (leftTile.x === tile.x - 1 && leftTile.y === tile.y - 1))
        let topRight = layerData.find(leftTile => (leftTile.x === tile.x + 1 && leftTile.y === tile.y - 1))

        let left = layerData.find(leftTile => (leftTile.x === tile.x - 1 && leftTile.y === tile.y))
        let right = layerData.find(leftTile => (leftTile.x === tile.x + 1 && leftTile.y === tile.y))

        let bottom = layerData.find(leftTile => (leftTile.x === tile.x && leftTile.y === tile.y + 1))
        let bottomLeft = layerData.find(leftTile => (leftTile.x === tile.x - 1 && leftTile.y === tile.y + 1))
        let bottomRight = layerData.find(leftTile => (leftTile.x === tile.x + 1 && leftTile.y === tile.y + 1))

        const oceanTileIndexes = [
            'OCEAN',
            'OCEAN_2',
            'OCEAN_3',
            'OCEAN_4',
            'OCEAN_5',
        ]

        let coastString = 'COAST.';

        if (oceanTileIndexes.indexOf(left?.tileType) > -1) {
            coastString += 'LEFT.'
        }

        if (oceanTileIndexes.indexOf(right?.tileType) > -1) {
            coastString += 'RIGHT.'
        }

        if (oceanTileIndexes.indexOf(top?.tileType) > -1) {
            coastString += 'TOP.'
        }

        if (oceanTileIndexes.indexOf(bottom?.tileType) > -1) {
            coastString += 'BOTTOM.'
        }

        if (coastString === 'COAST.') {
            if (oceanTileIndexes.indexOf(topLeft?.tileType) > -1) {
                coastString += 'TL-'
            }

            if (oceanTileIndexes.indexOf(topRight?.tileType) > -1) {
                coastString += 'TR-'
            }

            if (oceanTileIndexes.indexOf(bottomLeft?.tileType) > -1) {
                coastString += 'BL-'
            }

            if (oceanTileIndexes.indexOf(bottomRight?.tileType) > -1) {
                coastString += 'BR-'
            }

            if (coastString[coastString.length - 1] === '-') {
                coastString += 'GAP'
            }
        }

        if (coastString === 'COAST.') {
            return;
        }

        return coastString
    }

    copyLayersOver() {
        if (!this.floorLayer?.layer?.data) {
            return;
        }

        this.floorLayer.layer.data.forEach((row, y) => {
            row.forEach((tile, x) => {
                if (this.character.z >= 0) {
                    tile.index = tileMap['EMPTY']
                }

                if (this.character.z < 0) {
                    tile.index = tileMap['OCEAN'];
                }
            })
        })

        let rememberedTiles = JSON.parse(localStorage.getItem(`floor-panel-${this.character?.panelId}-z-${this.character?.z}`)) || []
        rememberedTiles = rememberedTiles.map(tile => ({ ...tile, index: tile.i }))

        this.memoryTiles = []

        this.layer.forEach((row, y) => {
            row.forEach((items, x) => {
                this.floorLayer.layer.data[y][x].setAlpha(1)

                let rememberedTile;
                let isRememberedTile = false;

                if (!this.isWithinLightSourceVision({ x, y })) {
                    this.floorLayer.putTileAt(-1, x, y)
                } else if (!this.character?.z && this.isWithinLightSourceVision({ x, y })) {
                    this.floorLayer.putTileAt(tileMap['EMPTY'], x, y)
                }

                if (!this.currentActiveBrainchips.find(brainchipType => (brainchipType.name === BRAINCHIP_TYPE_NAMES.NIGHT_VISION))) {
                    if (!this.isWithinLightSourceVision({ x, y })) {

                        rememberedTile = rememberedTiles.filter(tile => ((tile.x === x && tile.y === y)));

                        if (!rememberedTile || rememberedTile.length === 0) {
                            return;
                        } else {
                            isRememberedTile = true;
                            items = [ ...rememberedTile ]
                        }
                    }
                }

                const timeNow = new Date().getTime();
                let numberOfTiles = items.length;

                const timeToShow = 400
                const totalTimeToShow = numberOfTiles * timeToShow;
                const numberBetweenZeroAndTotalTimeToShow = timeNow % totalTimeToShow
                const currentlyShowing = Math.floor(numberBetweenZeroAndTotalTimeToShow / totalTimeToShow * numberOfTiles)

                if (this.character?.position?.x === x && this.character?.position?.y === y) {
                    if (this.character.isTeleporting) {
                        this.floorLayer.putTileAt(tileMap[this.findItemTileKey(this.character)], x, y)
                        return;
                    }
                }

                items.forEach((item, index) => {
                    if (item.timeOfCreation) {
                        const timeToShow = 100;
                        const timeNow = new Date().getTime();

                        if (timeNow - item.timeOfCreation < timeToShow) {
                            this.floorLayer.layer.data[y][x].setAlpha(1 - ((timeNow - item.timeOfCreation) / timeToShow))
                        }
                    }

                    if (item.setAlpha) {
                        item.setAlpha(0.4)
                    }

                    if (isRememberedTile) {
                        this.floorLayer.layer.data[y][x].setAlpha(0.4)
                        this.floorLayer.putTileAt(item.index, x, y)
                        return;
                    }

                    // MEMORY BANKS
                    if (!isRememberedTile && item.id === 'cliff' || item.construction?.name.indexOf('MINE') > -1) {
                        this.memoryTiles.push({
                            x: item.position?.x !== undefined ? item.position?.x : item.x,
                            y: item.position?.y !== undefined ? item.position?.y : item.y,
                            index: item.index || tileMap[this.findItemTileKey(item)],
                            panelId: item.panelId
                        })
                    }

                    if (currentlyShowing === index) {
                        if (new Date().getTime() - this.timeOffset > item.expiresAt) {
                            return;
                        }
                        if (item.graffiti || item.pixelsArray) {
                            // TODO - choose colour more cleverly...
                            this.floorLayer.layer.data[y][x].tint = 0x00FFF0
                        }

                        if (item.setAlpha) {
                            item.setAlpha(1)
                        }

                        this.floorLayer.putTileAt(tileMap[this.findItemTileKey(item)], x, y)

                        return;
                    }
                })
            })
        })

        this.addToMemory(this.character?.panelId, this.character?.z, this.memoryTiles)
    }

    addToMemory(panelId, z, memoryTiles) {
        if (this.isInitialising) {
            return;
        }

        memoryTiles = memoryTiles.map(tile => ({ x: tile.x, y: tile.y, i: tile.index }));

        let memory = JSON.parse(localStorage.getItem(`floor-panel-${panelId}-z-${z}`)) || []

        memoryTiles.forEach(tile => {
            memory = memory.filter(storedTile => (!(storedTile.x === tile.x && storedTile.y === tile.y)))
        })

        memory = [ ...memory, ...memoryTiles ]
        
        localStorage.setItem(`floor-panel-${panelId}-z-${z}`, JSON.stringify(memory))
    }

    isWithinLightSourceVision({ x, y }) {
        return this.visibleTiles.find(wall => wall.x === x && wall.y === y && wall.visibility);
    }

    onDestroy() {
        this.storeSubscription()
    }

    findItemTileKey(item) {
        if (item.plant?.maxTendingCount) {
            if (item.tendedCount / item.plant?.maxTendingCount < 0.25) {
                return item.tileKey.split('.')[0] + '.SEED';
            }

            if (item.tendedCount / item.plant?.maxTendingCount < 0.5) {
                return item.tileKey.split('.')[0] + '.YOUNG';
            }

            if (item.tendedCount / item.plant?.maxTendingCount < 0.75) {
                return item.tileKey.split('.')[0] + '.OLD';
            }

            return item.tileKey.split('.')[0] + '.HARVEST'
        }

        if (item.tileKey) {
            return item.tileKey;
        }

        if (item.animal?.name) {
            const TEN_DAYS = 1000 * 60 * 60 * 24 * 10;
            if (new Date().getTime() - item.createdAt < TEN_DAYS) {

                if (tileMap[item.animal?.name + '.CUB']) {
                    return item.animal?.name + '.CUB'
                } else {
                    return item.animal?.name
                }
            }
            return item.animal?.name;
        }

        if (item.construction?.name) {
            if (item.neighbours === 'NONE') {
                item.neighbours = 'NONE.'
            }
            if (item.construction?.name.indexOf('Space Wall') > -1) {
                return 'SPACE.' + item.neighbours;
            }
            if (item.construction?.name === 'Space Door') {
                return 'SPACE.DOOR'
            }
            if (item.construction?.name.indexOf('Wall') > -1) {

                if (item.groupBiome !== undefined) {
                    return `WOODEN-LOG.${item.neighbours}${item.groupBiome}`;
                }
                return `WOODEN-LOG.${item.neighbours}POLAR`;
                // return `MINE-WALL.${item.neighbours}`
            }

            return item.construction?.name;
        }

        if (item.tileType?.name) {
            return item.tileType?.name;
        }

        if (item.tileType) {
            return item.tileType;
        }

        if (item.wagonType?.name) {
            return item.wagonType?.name;
        }

        if (item.boatType?.name) {
            return item.boatType?.name;
        }

        if (item.furnitureType?.name) {
            return item.furnitureType?.name;
        }

        if (item.mineralType?.name) {
            return item.mineralType?.name;
        }

        if (item.causeOfDeath) {
            return 'DEAD_CHARACTER';
        }
    }
}