import store from '../redux/store';
import * as bres from 'bresenham-line-algorithm';

import Phaser from 'phaser';

import {
    selectHighlight,
    selectAiming,
    selectMoving,
    selectCharacter,
    selectConstructions,
    selectAreaSelect,
    selectHighlightCoords,
    selectStairs,
    selectConstructionByPosition,
    selectZones,
    selectOpenZone,
    selectWorkshopBoundries,
    selectPlants,
    selectPanel,
    selectCharacterZones,
    selectWorkshops
} from '../redux/selectors';
import { startLooking } from '../redux/actions/keyboard-shortcuts.actions';
import { PANEL_WIDTH, PANEL_HEIGHT } from './geography';

import highlightTypes from './tile-map'

const TILE_WIDTH = 16;
const TILE_HEIGHT = 24;

class HighlightTilemapService {
    scene;
    tileLayer;
    highlight;
    highlightRectangle;
    aimingRectangle;

    otherRectangles = [];
    characterZones;

    constructor(tileLayer, scene) {
        this.scene = scene;
        this.tileLayer = tileLayer;

        this.storeSubscription = store.subscribe(() => {
            const state = store.getState();
            const updatedHighlight = selectHighlight(state);
            const updatedAiming = selectAiming(state);
            const updatedMoving = selectMoving(state);
            const updatedAreaSelect = selectAreaSelect(state);
            const character = selectCharacter(state);
            const updatedConstructions = selectConstructions(state);
            const updatedCoords = selectHighlightCoords(state);
            const existingZones = selectZones(state).filter(zone => (zone.panelId === character.panelId))
            const currentZone = selectOpenZone(state)
            const workshopBoundries = selectWorkshopBoundries(state);
            const plants = selectPlants(state);
            const panel = selectPanel(state);
            const cliffsAndWater = panel.floorLayer?.filter(tile => (tile.tileType === 'OCEAN' || tile.tileType === 'Cliff' || tile.tileType === 'MOUNTAIN' || tile.tileType  === 'WATER'));
            const updatedWorkshops = selectWorkshops(state);

            this.characterZones = selectCharacterZones(state);

            if (this.highlight) {
                this.removeHighlight(this.highlight);
            }

            if (updatedHighlight) {
                this.addHighlight(updatedHighlight);
            }

            if (this.aiming) {
                this.removeAiming(this.aiming);
            }

            if (this.moving) {
                this.removeAiming(this.moving);
            }

            if (this.areaSelect) {
                this.removeAiming(this.areaSelect);
            }

            if (updatedAiming) {
                this.addAiming(updatedAiming, character, Object.values(updatedConstructions.byId));
            }

            if (updatedMoving) {
                const currentConstruction = selectConstructionByPosition(state, updatedMoving)
                const staircases = selectStairs(state);
                const isMineStaircase = staircases.find(staircase => (staircase.position.x === updatedMoving?.x && staircase.position.y === updatedMoving?.y))
                const isManmadeStaircase = currentConstruction.find(construction => (construction.construction.name.indexOf('Staircase') > -1))

                const isStaircase = isManmadeStaircase || isMineStaircase;

                this.addMoving(updatedMoving, character, Object.values(updatedConstructions.byId), isStaircase);
            }

            if (updatedAreaSelect) {
                if (updatedAreaSelect.areaSelectType === 'workshop') {
                    // The workshop area select works a little differently: we draw the square straight away and move the whole square around.
                    this.addWorkshopAreaSelect(updatedAreaSelect, character, updatedCoords, workshopBoundries, { cliffsAndWater, plants, constructions: updatedConstructions, workshops: updatedWorkshops })
                } else {
                    this.addAreaSelect(updatedAreaSelect, character, updatedCoords);
                }
            }

            this.highlight = updatedHighlight;
            this.aiming = updatedAiming;
            this.moving = updatedMoving;
            this.areaSelect = updatedAreaSelect;
            this.existingZones = existingZones;
            this.currentZone = currentZone;
            this.character = character;
        })

        scene.input.on(Phaser.Input.Events.POINTER_UP, (pointer) => {
            const { worldX, worldY } = pointer

            const targetVec = this.tileLayer.worldToTileXY(worldX, worldY)

            if (this.highlight) {
                store.dispatch(startLooking({ position: { ...targetVec } }))
            }
        })
    }

    onDestroy() {
        this.storeSubscription()
    }

    addHighlight({ x, y }) {
        const rectangleColor = Number(`0x${'#FFFFFF'.split('#')[1]}`);

        this.highlightRectangle = this.scene.add.rectangle((TILE_WIDTH * x) + (TILE_WIDTH/2), (TILE_HEIGHT * y) + (TILE_HEIGHT/2), TILE_WIDTH, TILE_HEIGHT, rectangleColor)
        this.highlightRectangle.setAlpha(0.5);
    }

    removeHighlight(highlight) {
        this.highlightRectangle.destroy();
    }

    addAiming({ x, y }, character, blockingTiles) {
        const a = x - character.position.x
        const b = y - character.position.y

        const distance = Math.hypot(a, b);

        const MAX_RANGE = 5;
        let rectangleColor = Number(`0x${'#7ADDC5'.split('#')[1]}`);

        const points = bres.bresenhamLinePoints(x, y, character.position.x, character.position.y);

        let isPointBlockingTile = false;

        points.forEach((point) => {
            const pointX = point.x;
            const pointY = point.y;

            if (blockingTiles.find(blockingTile => (blockingTile.position.x === pointX && blockingTile.position.y === pointY))) {
                isPointBlockingTile = true;
            }
        })

        if (distance > MAX_RANGE || isPointBlockingTile) {
            rectangleColor = Number(`0x${'#F23434'.split('#')[1]}`);
        }

        this.aimingRectangle = this.scene.add.rectangle((TILE_WIDTH * x) + (TILE_WIDTH/2), (TILE_HEIGHT * y) + (TILE_HEIGHT/2), TILE_WIDTH, TILE_HEIGHT, rectangleColor)
        this.aimingRectangle.setAlpha(0.5);
    }

    addMoving({ x, y }, character, blockingTiles, isStaircase) {
        const originalX = x;
        const originalY = y;
        let text = '';

        let isOffscreen = false;
        if (x < 0) {
            isOffscreen = true;
            x = 0;
            text = Math.abs(this.moving.x);
        }
        if (y < 0) {
            isOffscreen = true;
            y = 0
            text = Math.abs(this.moving.y);
        }
        if (x >= PANEL_WIDTH) {
            isOffscreen = true;
            x = PANEL_WIDTH - 1
            text = this.moving.x - PANEL_WIDTH + 1;
        }
        if (y >= PANEL_HEIGHT) {
            isOffscreen = true;
            y = PANEL_HEIGHT - 1
            text = this.moving.y - PANEL_HEIGHT + 1;
        }

        if (text === 0) {
            text = ''
        }

        const a = x - character.position.x
        const b = y - character.position.y

        const distance = Math.hypot(a, b);

        let rectangleColor = (isOffscreen || isStaircase) ? Number(`0x${'#e9a4b7'.split('#')[1]}`) : Number(`0x${'#7ADDC5'.split('#')[1]}`);

        this.aimingRectangle = this.scene.add.rectangle((TILE_WIDTH * x) + (TILE_WIDTH/2), (TILE_HEIGHT * y) + (TILE_HEIGHT/2), TILE_WIDTH, TILE_HEIGHT, rectangleColor)
        
        if (isOffscreen) {
            this.movingText = this.scene.add.text((TILE_WIDTH * x) + (TILE_WIDTH/4), (TILE_HEIGHT * y) + (TILE_HEIGHT/4), text)
        }
        this.aimingRectangle.setAlpha(0.5);
    }

    addAreaSelect({ x, y, startingCoords, areaSelectType }, character, updatedCoords) {
        this.addPreviousAreaSelect()
        this.addOtherZoneAreaSelects()

        const a = x - character.position.x
        const b = y - character.position.y

        const distance = Math.hypot(a, b);

        let rectangleColor = Number(`0x${'#7ADDC5'.split('#')[1]}`);

        let areaColor = Number(`0x${'#FFFFFF'.split('#')[1]}`);

        this.aimingRectangle = this.scene.add.rectangle((TILE_WIDTH * x) + (TILE_WIDTH/2), (TILE_HEIGHT * y) + (TILE_HEIGHT/2), TILE_WIDTH, TILE_HEIGHT, rectangleColor)
        this.aimingRectangle.setAlpha(0.5);

        const areaRectangleA = startingCoords
        const areaRectangleB = { x, y }

        if (areaRectangleA && areaRectangleB) {
            const areaWidth = (Math.abs(areaRectangleA.x - areaRectangleB.x) + 1) * TILE_WIDTH;
            const areaHeight = (Math.abs(areaRectangleA.y - areaRectangleB.y) + 1) * TILE_HEIGHT;
            const areaStartingX = Math.min(areaRectangleA.x, areaRectangleB.x) * TILE_WIDTH;
            const areaStartingY = Math.min(areaRectangleA.y, areaRectangleB.y) * TILE_HEIGHT;

            const isOverlapping = this.existingZones
                .filter(existingZone => {
                    const isExistingZoneCharacterZone = this.characterZones.find(zone => (zone._id === existingZone._id))

                    return !isExistingZoneCharacterZone;
                })
                .find(rectangleB => {
                    let { x, y, width, height } = rectangleB.panelAreas[0]

                    return Phaser.Geom.Rectangle.Overlaps({
                        x: areaStartingX / TILE_WIDTH,
                        y: areaStartingY / TILE_HEIGHT,
                        right: (areaStartingX / TILE_WIDTH) + (areaWidth / TILE_WIDTH),
                        bottom: (areaStartingY / TILE_HEIGHT) + (areaHeight / TILE_HEIGHT)
                    }, {
                        x: x,
                        y: y,
                        right: x + width,
                        bottom: y + height
                    })
                })

            areaColor = isOverlapping ? Number(`0x${'#8b0000'.split('#')[1]}`) : areaColor

            // TODO - if overlapping, disable save...

            this.areaRectangleA = this.scene.add.rectangle(areaStartingX + (areaWidth / 2), areaStartingY + (areaHeight / 2), areaWidth, areaHeight, areaColor)
            this.areaRectangleA.setAlpha(0.5);
        }
    }

    addWorkshopAreaSelect({ x, y, startingCoords, areaSelectType }, character, updatedCoords, workshopBoundries, { cliffsAndWater, plants, constructions, workshops }) {
        const a = x - character.position.x
        const b = y - character.position.y

        this.addOtherZoneAreaSelects();

        const distance = Math.hypot(a, b);

        let rectangleColor = Number(`0x${'#7ADDC5'.split('#')[1]}`);

        let areaColor = Number(`0x${'#FFFFFF'.split('#')[1]}`);

        this.aimingRectangle = this.scene.add.rectangle((TILE_WIDTH * x) + (TILE_WIDTH/2), (TILE_HEIGHT * y) + (TILE_HEIGHT/2), TILE_WIDTH, TILE_HEIGHT, rectangleColor)
        this.aimingRectangle.setAlpha(0.5);

        const areaRectangleA = { x: x-1, y: y-1 }
        const areaRectangleB = { x, y }

        if (workshopBoundries.length === 9) {
            areaRectangleB.x = areaRectangleB.x + 1
            areaRectangleB.y = areaRectangleB.y + 1
        }

        if (areaRectangleA && areaRectangleB) {
            const areaWidth = (Math.abs(areaRectangleA.x - areaRectangleB.x) + 1) * TILE_WIDTH;
            const areaHeight = (Math.abs(areaRectangleA.y - areaRectangleB.y) + 1) * TILE_HEIGHT;
            const areaStartingX = Math.min(areaRectangleA.x, areaRectangleB.x) * TILE_WIDTH;
            const areaStartingY = Math.min(areaRectangleA.y, areaRectangleB.y) * TILE_HEIGHT;

            let isOverlapping = this.existingZones
                .find(rectangleB => {
                    let { x, y, width, height } = rectangleB.panelAreas[0]

                    return Phaser.Geom.Rectangle.Overlaps({
                        x: areaStartingX / TILE_WIDTH,
                        y: areaStartingY / TILE_HEIGHT,
                        right: (areaStartingX / TILE_WIDTH) + (areaWidth / TILE_WIDTH),
                        bottom: (areaStartingY / TILE_HEIGHT) + (areaHeight / TILE_HEIGHT)
                    }, {
                        x: x,
                        y: y,
                        right: x + width,
                        bottom: y + height
                    })
                })

            if (!isOverlapping) {
                isOverlapping = [ ...cliffsAndWater, ...Object.values(plants.byId), ...Object.values(constructions.byId) ].find(tile => {
                    if (!tile.position) {
                        console.log('FAIL: ', tile);
                    }
                    const x = tile.position.x;
                    const y = tile.position.y;

                    return Phaser.Geom.Rectangle.Overlaps({
                        x: areaStartingX / TILE_WIDTH,
                        y: areaStartingY / TILE_HEIGHT,
                        right: (areaStartingX / TILE_WIDTH) + (areaWidth / TILE_WIDTH),
                        bottom: (areaStartingY / TILE_HEIGHT) + (areaHeight / TILE_HEIGHT)
                    }, {
                        x: x,
                        y: y,
                        right: x + 1,
                        bottom: y + 1
                    })
                })
            }

            if (!isOverlapping) {
                isOverlapping = workshops.find(workshop => {
                    const leftMostX = Math.min(...workshop.workshopBoundries.map(boundry => boundry.x));
                    const rightMostX = Math.max(...workshop.workshopBoundries.map(boundry => boundry.x));
                    const topMostY = Math.min(...workshop.workshopBoundries.map(boundry => boundry.y));
                    const bottomMostY = Math.max(...workshop.workshopBoundries.map(boundry => boundry.y));

                    return Phaser.Geom.Rectangle.Overlaps({
                        x: areaStartingX / TILE_WIDTH,
                        y: areaStartingY / TILE_HEIGHT,
                        right: (areaStartingX / TILE_WIDTH) + (areaWidth / TILE_WIDTH),
                        bottom: (areaStartingY / TILE_HEIGHT) + (areaHeight / TILE_HEIGHT)
                    }, {
                        x: leftMostX,
                        y: topMostY,
                        right: rightMostX + 1,
                        bottom: bottomMostY + 1
                    })
                })
            }

            areaColor = isOverlapping ? Number(`0x${'#8b0000'.split('#')[1]}`) : areaColor

            // TODO - if overlapping, disable save...

            this.areaRectangleA = this.scene.add.rectangle(areaStartingX + (areaWidth / 2), areaStartingY + (areaHeight / 2), areaWidth, areaHeight, areaColor)
            this.areaRectangleA.setAlpha(0.5);
        }
    }

    addPreviousAreaSelect() {
        const currentZone = this.existingZones.find(existingZone => (existingZone?._id === this.currentZone?._id))

        if (!currentZone || !currentZone.panelAreas) {
            return;
        }

        let { x, y, width, height } = currentZone.panelAreas[0]

        x = x * TILE_WIDTH
        width = width * TILE_WIDTH
        y = y * TILE_HEIGHT
        height = height * TILE_HEIGHT

        let rectangleColor = Number(`0x${'#008000'.split('#')[1]}`);

        const previousAreaRectangle = this.scene.add.rectangle(x + (width / 2), y + (height / 2), width, height, rectangleColor)
        previousAreaRectangle.setAlpha(0.5);

        this.otherRectangles.push(previousAreaRectangle)
    }

    addOtherZoneAreaSelects() {
        // const currentZone = this.currentZone || this.areaSelect.zone
        const otherZones = this.existingZones.filter(zone => (zone.panelId === this.character?.panelId && zone.z === this.character?.z))

        if (!otherZones || otherZones.length === 0) {
            return;
        }

        otherZones.map((otherZone) => {
            if (!otherZone) {
                return
            }

            let { x, y, width, height } = otherZone.panelAreas[0]

            x = x * TILE_WIDTH
            width = width * TILE_WIDTH
            y = y * TILE_HEIGHT
            height = height * TILE_HEIGHT

            const currentZoneColor = '#008000';
            const orgZoneColor = '#111faa';
            const foreignZoneColor = '#8b0000';

            let rectangleColor = Number(`0x${foreignZoneColor.split('#')[1]}`);

            if (this.characterZones.find(zone => zone._id === otherZone._id)) {
                rectangleColor = Number(`0x${orgZoneColor.split('#')[1]}`);
            }

            if (this.areaSelect?.zone?._id === otherZone._id) {
                rectangleColor = Number(`0x${currentZoneColor.split('#')[1]}`);
            }

            const previousAreaRectangle = this.scene.add.rectangle(x + (width / 2), y + (height / 2), width, height, rectangleColor)
            previousAreaRectangle.setAlpha(0.5);

            this.otherRectangles.push(previousAreaRectangle)
        })
    }

    removeAiming() {
        this.aimingRectangle.destroy();

        if (this.movingText) {
            this.movingText.destroy();
        }

        if (this.areaRectangleA) {
            this.areaRectangleA.destroy();
        }

        if (this.otherRectangles) {
            this.otherRectangles.map(rectangle => (rectangle.destroy()))
        }
    }
}

export default HighlightTilemapService;