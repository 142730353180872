import React from "react";
import { connect } from 'react-redux'

import Game from '../game/Game';
import Loading from '../loading/Loading';
import Startup from '../../startup/Startup';

import { getCharacterAsync } from '../../../redux/actions/character.actions';
import {
    selectHighlight,
    selectUser,
    selectCharacter,
    selectIsInitialisingApp,
    selectIsInitialisingPanel,
} from '../../../redux/selectors';

import { client } from '../../../services/client';

class Home extends React.Component {
    constructor() {
        super()

        this.state = {}
    }

    componentDidMount() {
        this.props.getCharacterAsync({ userId: this.props.user.user._id });
    }

    static getDerivedStateFromProps(nextProps, state) {
        if (!nextProps.character || !nextProps.character._id) {
            const canvasList = window.document.getElementsByTagName('canvas');

            if (canvasList && canvasList[0]) {
                canvasList[0].parentNode.removeChild(canvasList[0])
            }
        }

        return state;
    }

    render() {
        if (this.props.user.newLoginStep === 'STARTUP' && this.props.character && this.props.character._id) {
            return (
                <div className={this.props.highlight ? "highlighting" : ""}>
                    {
                        this.props.isInitialising && <Loading />
                    }
                    <Game />
                </div>
            );
        } else {
            return (
                <div>
                    {
                        this.props.isInitialising && <Loading />
                    }
                    {
                        this.props.user.newLoginStep === 'STARTUP' && <Startup />
                    }
                </div>
            )
        }
    }
}

const mapStateToProps = state => {
    const highlight = selectHighlight(state)
    const user = selectUser(state)
    const character = selectCharacter(state)
    const isInitialisingApp = selectIsInitialisingApp(state);
    const isInitialisingPanel = selectIsInitialisingPanel(state);

    const isInitialising = isInitialisingApp || isInitialisingPanel;

    return { highlight, user, character, isInitialising }
}

export default connect(
    mapStateToProps,
    {
        getCharacterAsync,
    }
)(Home);