import {
  INITIALISING_PANEL_STARTED,
  START_NEW_GAME,
  GET_ZONES_SUCCESS,
  ADD_ZONE,
  UPDATE_ZONE,
  REMOVE_ZONE,
  CLEAR_ZONES,
  SHOW_CHARACTER_DEATH_PAGE,
  CHANGE_CHARACTER
} from "../actionTypes";

const initialState = {
  zones: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case START_NEW_GAME:
    case INITIALISING_PANEL_STARTED:
    case CHANGE_CHARACTER:
    case SHOW_CHARACTER_DEATH_PAGE: {
      return {
        ...initialState,
      }
    }
    case GET_ZONES_SUCCESS: {
      const zones = action.payload;

      return {
        ...state,
        zones: [...zones],
      }
    }

    case ADD_ZONE: {
      let zones = [...state.zones];

      return {
        ...state,
        zones: [ ...zones ],
      }
    }

    case UPDATE_ZONE: {
      let zones = [...state.zones];

      zones = [
        ...zones.filter(zone => zone._id !== action.payload._id),
        { ...zones.find(zone => zone._id === action.payload._id), ...action.payload }
      ]

      return {
        ...state,
        zones: [ ...zones ],
      }
    }

    case REMOVE_ZONE: {
      return {
        ...state,
        zones: [ ...state.zones.filter(zone => (zone._id !== action.payload._id)) ],
      }
    }

    case CLEAR_ZONES: {
      return {
        ...initialState
      }
    }

    default:
      return state;
  }
}
