import React, { Component, useState } from "react";
import { connect } from 'react-redux'
import './Startup.css';
import KeyboardMenu from '../keyboard-menu/KeyboardMenu';
import Menu from '../utils/menu/Menu';
import { logOutAsync, startNewGame } from '../../redux/actions/user.actions';
import { getCharacterSuccess, newCharacterAsync } from '../../redux/actions/character.actions';
import { initialisingPanelStarted } from '../../redux/actions/keyboard-shortcuts.actions';
import {
    selectIsInitialisingApp,
    selectIsInitialisingPanel,
    selectPossibleCharacters,
    selectUser,
} from '../../redux/selectors';

class Startup extends React.Component {

	constructor() {
		super();
	}

	start(command, print) {
		this.props.startNewGame();
	}

	selectCharacter(character) {
		if (character.text === 'New Character') {
			this.props.newCharacterAsync({ userId: this.props.user.user._id })
			return;
		}

		localStorage.setItem('characterId', character._id);

		this.props.initialisingPanelStarted();
		this.props.getCharacterSuccess({ character })
	}

	continue(command, print) {
		console.log('continue');
	}

	settings(command, print) {
		console.log('settings');
	}

	logout(command, print) {
        localStorage['feathers-jwt'] = null;
        this.props.logOutAsync();
        window.location.reload();
	}

	render() {
	    return (
	    	this.props.isInitialising ? (
	    		<div className="startup">
	    		</div>
    		) : (
		      	<div className="startup">
		      		<p className="header">[ <span className="title">Settle Gliese</span> ]</p>
		      		<div className="options-container">
		      			<Menu menuContainer="options-container"
		      				optionChosen={(character) => (this.selectCharacter(character))}
		      				options={[
		      					...this.props.characters.map(character => ({
		      						...character,
		      						text: character.name,
		      					})),
		      					{
		      						text: "New Character",
		      						callback: this.start.bind(this)
		      					},
		      				]
		      			}/>
		      			<p className="footer">Copyright (<span className="action">c</span>) 2024 Harry Bishop</p>
		      		</div>
	      		</div>
    		)
	    );
	}
}

const mapStateToProps = state => {
    const isInitialisingApp = selectIsInitialisingApp(state);
    const isInitialisingPanel = selectIsInitialisingPanel(state);

    const isInitialising = isInitialisingApp || isInitialisingPanel

    const user = selectUser(state);
    const characters = selectPossibleCharacters(state);

    return { isInitialising, characters, user }
}

export default connect(
    mapStateToProps,
    {
    	logOutAsync,
    	startNewGame,
    	getCharacterSuccess,
    	initialisingPanelStarted,
    	newCharacterAsync
    }
)(Startup);