import {
    DISABLE_KEYBOARD_MOVEMENT,
    ENABLE_KEYBOARD_MOVEMENT,
    OPEN_INVENTORY,
	CLOSE_INVENTORY,
	OPEN_CRAFTING,
	CLOSE_CRAFTING,
	OPEN_ACTIONS,
	CLOSE_ACTIONS,
	CHOOSE_SEED_TO_PLANT,
	CHOOSE_FOOD_TO_EAT,
	SHOW_NO_CHARACTERS_NEARBY,
	HIDE_NO_CHARACTERS_NEARBY,
	CHOOSE_CHARACTER_TO_FIGHT,
	SHOW_FIGHT_CONFIRMATION,
	SHOW_HELP,
	HIDE_HELP,
	HIDE_ALL_MENUS,
	SHOW_QUANTITY_INPUT,
	HIDE_QUANTITY_INPUT,
	SHOW_DIRECTION_INPUT,
	HIDE_DIRECTION_INPUT,
	NEW_ORGANISATION,
	SHOW_ORGANISATION,
	SHOW_ORGANISATION_LIST,
	SHOW_ZONE_LIST,
	SHOW_ZONE,
	NEW_ZONE,
	HIDE_CRAFTING_ERROR,
	HIDE_MOVING_ERROR,
	CHOOSE_CHARACTER_TO_GIVE_TO,
	HIDE_CHARACTER_LIST,
	UNKNOWN_ERROR,
	HIDE_UNKNOWN_ERROR,
	SELECT_ERROR_MESSAGE,
	SHOW_MESSAGES,
	FOCUS_MESSAGES,
	SAVE_LAST_CRAFT,
	INITIALISING_APP_STARTED,
	INITIALISING_APP_FINISHED,
	INITIALISING_PANEL_STARTED,
	INITIALISING_PANEL_FINISHED,
	START_LOOKING,
	STOP_LOOKING,
	START_AIMING,
	STOP_AIMING,
	START_MOVING,
	STOP_MOVING,
	START_AREA_SELECT,
	STOP_AREA_SELECT,
	SHOW_CHARACTER_ACTIONS,
	SHOW_CHARACTER_PROFILE,
	SHOW_EQUIPMENT,
	SHOW_SHEET,
	SHOW_WORKSHOP,
	SHOW_CONVERSATION_TREE_WIZARD,
	SHOW_EVENTS,
	SHOW_DIARY,
	SHOW_WRITING_SURFACE,
	HIDE_WRITING_SURFACE,
	SHOW_DRAWING_SURFACE,
	HIDE_DRAWING_SURFACE,
	SHOW_WRITING_SURFACE_MENU,
	SHOW_LEVEL_UP_MESSAGE,
	SHOW_NEAR_DEATH_SCREEN,
	SHOW_HUNGER_WARNING,
	SHOW_GROUP_TERMINAL,
	SHOW_GUARD_HOUSE,
	SHOW_TABLE,
	SHOW_ACTION_SELECT_MENU,
	SHOW_VENDING_MACHINE,
	SHOW_SCHEDULER,
	NEW_TOWN_MESSAGE,
	SHOW_SELECT_ITEMS,
	HIDE_SELECT_ITEMS,
	HIDE_ACTION_SELECT_MENU,
	SET_IS_OVERENCUMBERED,
	CONNECTED,
	DISCONNECTED,
	RECONNECTED,
	CONNECTION_ERROR,
	NAME_LOCATION,
} from '../actionTypes'
import store from '../store';

import { client } from '../../services/client';

export const disableKeyboardMovement = () => ({
    type: DISABLE_KEYBOARD_MOVEMENT
})

export const enableKeyboardMovement = () => ({
    type: ENABLE_KEYBOARD_MOVEMENT
})

export const nameLocation = () => ({
	type: NAME_LOCATION
})


export const openInventory = () => ({
	type: OPEN_INVENTORY
})

export const closeInventory = () => ({
	type: CLOSE_INVENTORY
})

export const openCrafting = () => ({
	type: OPEN_CRAFTING
})

export const closeCrafting = () => ({
	type: CLOSE_CRAFTING
})

export const openActions = () => ({
	type: OPEN_ACTIONS
})

export const closeActions = () => ({
	type: CLOSE_ACTIONS
})

export const chooseSeedToPlant = () => ({
	type: CHOOSE_SEED_TO_PLANT
})

export const chooseFoodToEat = () => ({
	type: CHOOSE_FOOD_TO_EAT
})

export const showNoCharactersNearby = () => ({
	type: SHOW_NO_CHARACTERS_NEARBY
})

export const hideNoCharactersNearby = () => ({
	type: HIDE_NO_CHARACTERS_NEARBY
})

export const chooseCharacterToFight = () => ({
	type: CHOOSE_CHARACTER_TO_FIGHT
})

export const showFightConfirmation = payload => ({
	type: SHOW_FIGHT_CONFIRMATION,
	payload
})

export const showCharacterActions = payload => ({
	type: SHOW_CHARACTER_ACTIONS,
	payload
})

export const newOrganisation = () => ({
	type: NEW_ORGANISATION
})

export const showOrganisation = (payload) => ({
	type: SHOW_ORGANISATION,
	payload
})

export const showOrganisationList = () => ({
	type: SHOW_ORGANISATION_LIST
})

export const showZoneList = () => ({
	type: SHOW_ZONE_LIST
})

export const newZone = () => ({
	type: NEW_ZONE
})

export const showZone = (payload) => ({
	type: SHOW_ZONE,
	payload
})

export const showHelp = () => ({
	type: SHOW_HELP
})

export const hideHelp = () => ({
	type: HIDE_HELP
})

export const hideAllMenus = () => ({
	type: HIDE_ALL_MENUS
})

export const showQuantityInput = () => ({
	type: SHOW_QUANTITY_INPUT
})

export const hideQuantityInput = () => ({
	type: HIDE_QUANTITY_INPUT
})

export const showDirectionInput = () => ({
	type: SHOW_DIRECTION_INPUT
})

export const hideDirectionInput = () => ({
	type: HIDE_DIRECTION_INPUT
})

export const hideCraftingError = () => ({
	type: HIDE_CRAFTING_ERROR
})

export const hideMovingError = () => ({
	type: HIDE_MOVING_ERROR
})

export const chooseCharacterToGiveTo = () => ({
	type: CHOOSE_CHARACTER_TO_GIVE_TO
})

export const hideCharacterList = () => ({
	type: HIDE_CHARACTER_LIST
})

export const showUnknownError = payload => ({
	type: UNKNOWN_ERROR,
	payload
})

export const selectErrorMessage = () => ({
	type: SELECT_ERROR_MESSAGE
})

export const hideUnknownError = () => ({
	type: HIDE_UNKNOWN_ERROR
})

export const showMessages = payload => ({
	type: SHOW_MESSAGES,
	payload
})

export const focusMessages = () => ({
	type: FOCUS_MESSAGES
})

export const newTownMessage = (payload) => ({
	type: NEW_TOWN_MESSAGE,
	payload
})

export const saveLastCraft = payload => ({
	type: SAVE_LAST_CRAFT,
	payload
})

export const initialisingAppStarted = payload => ({
	type: INITIALISING_APP_STARTED,
})

export const initialisingAppFinished = payload => ({
	type: INITIALISING_APP_FINISHED,
})

export const initialisingPanelStarted = payload => ({
	type: INITIALISING_PANEL_STARTED,
})

export const initialisingPanelFinished = payload => ({
	type: INITIALISING_PANEL_FINISHED,
})

export const startLooking = payload => ({
	type: START_LOOKING,
	payload
})
export const stopLooking = payload => ({
	type: STOP_LOOKING
})

export const startAiming = payload => ({
	type: START_AIMING,
	payload
})

export const startMoving = payload => ({
	type: START_MOVING,
	payload
})

export const stopMoving = payload => ({
	type: STOP_MOVING
})

export const startAreaSelect = payload => ({
	type: START_AREA_SELECT,
	payload
})

export const stopAreaSelect = payload => ({
	type: STOP_AREA_SELECT
})
export const stopAiming = payload => ({
	type: STOP_AIMING
})

export const showCharacterProfile = payload => ({
	type: SHOW_CHARACTER_PROFILE
})

export const showEquipment = payload => ({
	type: SHOW_EQUIPMENT
})

export const showSheet = payload => ({
	type: SHOW_SHEET
})

export const showWorkshop = payload => ({
	type: SHOW_WORKSHOP,
	payload
})

export const showConversationTreeWizard = payload => ({
	type: SHOW_CONVERSATION_TREE_WIZARD
})

export const showEvents = payload => ({
	type: SHOW_EVENTS
})

export const showDiary = payload => ({
	type: SHOW_DIARY
})

export const showWritingSurface = payload => ({
	type: SHOW_WRITING_SURFACE,
	payload
})

export const hideWritingSurface = payload => ({
	type: HIDE_WRITING_SURFACE,
	payload
})

export const showDrawingSurface = payload => ({
	type: SHOW_DRAWING_SURFACE,
	payload
})

export const hideDrawingSurface = payload => ({
	type: HIDE_DRAWING_SURFACE,
	payload
})

export const showLevelUpMessage = payload => ({
	type: SHOW_LEVEL_UP_MESSAGE,
})

export const showNearDeathScreen = payload => ({
	type: SHOW_NEAR_DEATH_SCREEN,
})

export const showHungerWarning = payload => ({
	type: SHOW_HUNGER_WARNING
})

export const showGroupTerminal = payload => ({
	type: SHOW_GROUP_TERMINAL,
})

export const showGuardHouse = payload => ({
	type: SHOW_GUARD_HOUSE,
	payload
})

export const showTable = payload => ({
	type: SHOW_TABLE,
	payload
})

export const showActionSelectMenu = payload => ({
	type: SHOW_ACTION_SELECT_MENU,
	payload
})

export const hideActionSelectMenu = payload => ({
	type: HIDE_ACTION_SELECT_MENU,
	payload
})

export const showSelectItems = payload => ({
	type: SHOW_SELECT_ITEMS,
	payload
})

export const hideSelectItems = payload => ({
	type: HIDE_SELECT_ITEMS,
	payload
})

export const showScheduler = payload => ({
	type: SHOW_SCHEDULER,
	payload
})

export const setIsOverencumbered = payload => ({
	type: SET_IS_OVERENCUMBERED,
	payload
})

export const connected = payload => ({
	type: CONNECTED,
	payload
})

export const disconnected = payload => ({
	type: DISCONNECTED,
	payload
})

export const reconnected = payload => ({
	type: RECONNECTED,
	payload
})

export const connectionError = payload => ({
	type: CONNECTION_ERROR,
	payload
})
