const tileMap = {
    'ITEM': 1561,
    'IRRIGATION_CHANNEL': 12,
    // PATHS
    'Gravel Path': 141,
    'Sand Path': 140,
    'STONE_PATH.NONE': 157,
    'STONE_PATH.LEFT.': 176,
    'STONE_PATH.LEFT.RIGHT.': 128,
    'STONE_PATH.LEFT.RIGHT.TOP.': 158,
    'STONE_PATH.LEFT.RIGHT.TOP.BOTTOM.': 171,
    'STONE_PATH.LEFT.RIGHT.BOTTOM.': 159,
    'STONE_PATH.LEFT.TOP.': 144,
    'STONE_PATH.LEFT.TOP.BOTTOM.': 172,
    'STONE_PATH.LEFT.BOTTOM.': 156,
    'STONE_PATH.RIGHT.': 175,
    'STONE_PATH.RIGHT.TOP.': 127,
    'STONE_PATH.RIGHT.TOP.BOTTOM.': 170,
    'STONE_PATH.RIGHT.BOTTOM.': 154,
    'STONE_PATH.TOP.': 174,
    'STONE_PATH.TOP.BOTTOM.': 143,
    'STONE_PATH.BOTTOM.': 173,
    // TELEPORTING
    'TELEPORTING_1': 568,
    'TELEPORTING_2': 567,
    'TELEPORTING_3': 566,
    'TELEPORTING_4': 565,
    'TELEPORTING_5': 564,
    'TELEPORTING_6': 563,
    'TELEPORTING_7': 562,
    'TELEPORTING_8': 561,
    'TELEPORTING_9': 560,
    // CLIFFS
    'Cliff': 906,
    'ARROW_UP': 10,
    'ARROW_DOWN': 11,
    'ARROW_LEFT': 12,
    'ARROW_RIGHT': 13,
    // Characters
    'CHARACTER_0': 0,
    'CHARACTER_1': 1,
    'CHARACTER_2': 2,
    'CHARACTER_3': 3,
    'CHARACTER_4': 4,
    //space pauper
    'CHARACTER_5': 12,
    'CHARACTER_7': 13,
    'CHARACTER_8': 14,
    'CHARACTER_9': 15,
    'CHARACTER_10': 16,
    // in space walking
    'CHARACTER_11': 52,
    'CHARACTER_12': 53,
    'CHARACTER_13': 54,
    'CHARACTER_14': 55,
    'CHARACTER_15': 56,
    // BIOMES
    'LAVA.BOTTOM.': 0,
    'LAVA.LEFT.RIGHT.BOTTOM.BR-GAP': 1,
    'LAVA.LEFT.RIGHT.BOTTOM.BL-GAP': 2,
    'LAVA.SEDGE-GRASS': 3,
    'LAVA.RIGHT.BOTTOM.': 4,
    'LAVA.LEFT.RIGHT.BOTTOM.': 5,
    'LAVA.LEFT.BOTTOM.': 6,
    'MAGMA_CROC': 7,
    'SLIME.RIGHT.': 8,
    'SLIME.BOTTOM.': 9,
    'SLIME.RIGHT.BOTTOM.': 10,
    'SLIME.LEFT.RIGHT.BOTTOM.': 11,
    'SLIME.LEFT.BOTTOM.': 12,
    'WONEYE.VINE.BOTTOM.': 13,
    'WONEYE.EYE.BOTTOM.': 14,
    'WONEYE.VINE.RIGHT.BOTTOM.': 15,
    'WONEYE.VINE.LEFT.RIGHT.BOTTOM.': 16,
    'WONEYE.VINE.LEFT.BOTTOM.': 17,
    'TUNDRA.HAIR-GRASS': 18,
    'TUNDRA.LICHEN': 19,
    'TUNDRA.MOSS.RIGHT.BOTTOM.': 20,
    'TUNDRA.MOSS.LEFT.RIGHT.BOTTOM.': 21,
    'TUNDRA.MOSS.LEFT.BOTTOM.': 22,
    'TUNDRA.MOSS.BOTTOM.': 23,
    'TUNDRA.CLOUD.RIGHT.': 24,
    'TUNDRA.CLOUD.LEFT.RIGHT.TOP.BOTTOM.TL-GAP': 25,
    'TUNDRA.THORN.RIGHT.BOTTOM.': 26,
    'TUNDRA.THORN.LEFT.RIGHT.': 27,
    'TUNDRA.THORN.LEFT.BOTTOM.': 28,
    'TUNDRA.THORN.RIGHT.': 29,
    'TUNDRA.THORN.LEFT.RIGHT.BOTTOM.': 30,
    'TUNDRA.THORN.LEFT.': 31,
    'SALMON': 32,
    'MULBERRY.BRANCH.BOTTOM.': 33,
    'MULBERRY.LEAF.LEFT.BOTTOM.': 34,
    'MULBERRY.LEAF.RIGHT.BOTTOM.': 35,
    'MULBERRY.LEAF.RIGHT.TOP.': 36,
    'MULBERRY.LEAF.LEFT.TOP.': 37,
    'WILLOW.LEAF.RIGHT.BOTTOM.': 38,
    'WILLOW.BRANCH.BOTTOM.': 39,
    'SWAMP.WATER.RIGHT.BOTTOM.': 40,
    'SWAMP.WATER.LEFT.RIGHT.BOTTOM.': 41,
    'SWAMP.WATER.LEFT.BOTTOM.': 42,
    'SWAMP.WATER.BOTTOM.': 43,
    'SWAMP.WATER.RIGHT.': 44,
    'SWAMP.WATER.LEFT.RIGHT.': 45,
    'SWAMP.WATER.LEFT.': 46,
    'SWAMP.REED': 47,
    'LAVA.TOP.BOTTOM.': 48,
    'LAVA.RIGHT.': 49,
    'LAVA.LEFT.RIGHT.': 50,
    'LAVA.LEFT.': 51,
    'LAVA.NONE.': 51,
    'LAVA.RIGHT.TOP.BOTTOM.': 52,
    'LAVA.LEFT.RIGHT.TOP.BOTTOM.': 53,
    'LAVA.LEFT.TOP.BOTTOM.': 54,
    'STEPPE.GRASS': 55,
    'WOLF': 56,
    'SLIME.TOP.BOTTOM.': 57,
    'SLIME.TOP.': 57,
    'SLIME.RIGHT.TOP.BOTTOM.': 58,
    'SLIME.LEFT.RIGHT.TOP.BOTTOM.': 59,
    'SLIME.LEFT.TOP.BOTTOM.': 60,
    'WONEYE.EYE.TOP.BOTTOM.': 61,
    'WONEYE.VINE.TOP.BOTTOM.': 62,
    'WONEYE.VINE.RIGHT.TOP.BOTTOM.': 63,
    'WONEYE.TRUNK': 64,
    'WONEYE.VINE.LEFT.TOP.BOTTOM.': 65,
    'TUNDRA.BLACK-BEARBERRY.HARVEST': 66,
    'TUNDRA.BLACK-BEARBERRY.OFFSEASON': 67,
    'TUNDRA.MOSS.RIGHT.TOP.BOTTOM.': 68,
    'TUNDRA.MOSS.LEFT.RIGHT.TOP.BOTTOM.': 69,
    'TUNDRA.MOSS.LEFT.TOP.BOTTOM.': 70,
    'TUNDRA.MOSS.TOP.BOTTOM.': 71,
    'TUNDRA.PEARLWORT.OFFSEASON': 72,
    'TUNDRA.PEARLWORT.HARVEST': 73,
    'TUNDRA.THORN.TOP.BOTTOM.': 74,
    'BROWN_BEAR': 75,
    'TUNDRA.THORN.TOP.BOTTOM.2': 76,
    'TUNDRA.THORN.RIGHT.TOP.BOTTOM.': 77,
    'TUNDRA.THORN.LEFT.RIGHT.TOP.BOTTOM.': 78,
    'TUNDRA.THORN.LEFT.TOP.BOTTOM.': 79,
    'MULBERRY.BRANCH.RIGHT.': 80,
    'MULBERRY.TRUNK': 81,
    'MULBERRY.BRANCH.LEFT.': 82,
    'MULBERRY.LEAF.BOTTOM.': 83,
    'WHEAT.SEED': 84,
    'WHEAT.YOUNG': 85,
    'WHEAT.OLD': 86,
    'WHEAT.HARVEST': 87,
    'SWAMP.WATER.RIGHT.TOP.BOTTOM.': 88,
    'SWAMP.WATER.LEFT.RIGHT.TOP.BOTTOM.': 89,
    'SWAMP.WATER.LEFT.TOP.BOTTOM.': 90,
    'SWAMP.WATER.TOP.BOTTOM.': 91,
    'BARLEY.SEED': 92,
    'LAVA.TOP.': 96,
    'LAVA.LEFT.RIGHT.TOP.TR-GAP': 97,
    'LAVA.LEFT.RIGHT.TOP.TL-GAP.': 98,
    'LAVA.PLUME-WEED': 99,
    'LAVA.RIGHT.TOP.': 100,
    'LAVA.LEFT.RIGHT.TOP.': 101,
    'LAVA.LEFT.TOP.': 102,
    'HEATH.GRASS.SUMMER': 103,
    'RABBIT': 104,
    'SLIME.BOTTOM.': 105,
    'SLIME.RIGHT.TOP.': 106,
    'SLIME.LEFT.RIGHT.TOP.': 107,
    'SLIME.LEFT.TOP.': 108,
    'WONEYE.VINE.TOP.': 109,
    'WONEYE.EYE.TOP.': 110,
    'WONEYE.VINE.RIGHT.TOP.': 111,
    'WONEYE.VINE.LEFT.RIGHT.TOP.': 112,
    'WONEYE.VINE.LEFT.TOP.': 113,
    'TUNDRA.CROWBERRY.HARVEST.': 114,
    'TUNDRA.CROWBERRY.OFFSEASON.': 115,
    'TUNDRA.MOSS.RIGHT.TOP.': 116,
    'TUNDRA.MOSS.LEFT.RIGHT.TOP.': 117,
    'TUNDRA.MOSS.LEFT.TOP.': 118,
    'TUNDRA.MOSS.TOP.': 119,
    'TUNDRA.CLOUD.LEFT.RIGHT.TOP.BOTTOM.BL-TL-GAP': 120,
    'TUNDRA.CLOUD.LEFT.RIGHT.TOP.BOTTOM.TR-BR-GAP': 121,
    'TUNDRA.THORN.RIGHT.TOP.': 122,
    'TUNDRA.THORN.LEFT.RIGHT.': 123,
    'TUNDRA.THORN.LEFT.TOP.': 124,
    'TUNDRA.THORN.BOTTOM.': 125,
    'TUNDRA.THORN.LEFT.RIGHT.TOP.': 126,
    'TUNDRA.THORN.TOP.': 127,
    'MULBERRY.LEAF.RIGHT.': 128,
    'MULBERRY.BRANCH.TOP.': 129,
    'MULBERRY.LEAF.LEFT.': 130,
    'MULBERRY.LEAF.TOP.': 131,
    'FLAX.SEED': 132,
    'FLAX.YOUNG': 133,
    'FLAX.OLD': 134,
    'FLAX.HARVEST': 135,
    'SWAMP.WATER.RIGHT.TOP.': 136,
    'SWAMP.WATER.LEFT.RIGHT.TOP.': 137,
    'SWAMP.WATER.LEFT.TOP.': 138,
    'SWAMP.WATER.TOP.': 139,
    'OATS.SEED': 140,
    'OAT.SEED': 140,
    'WATER.RIGHT.BOTTOM.': 144,
    'WATER.LEFT.RIGHT.BOTTOM.': 145,
    'WATER.LEFT.BOTTOM.': 146,
    'WATER.BOTTOM.': 147,
    'WATER.RIGHT.': 148,
    'WATER.LEFT.RIGHT.': 149,
    'WATER.LEFT.': 150,
    'HEATH.GRASS.SPRING': 151,
    'SAIGA': 152,
    'WATER.RIGHT.BOTTOM.BR-GAP': 153,
    'WATER.LEFT.RIGHT.BOTTOM.BR-BL-GAP': 154,
    'WATER.LEFT.BOTTOM.BL-GAP': 155,
    'SLIME.SPLAT': 156,
    'SLIME_BEAST': 157,
    'WONEYE.VINE.RIGHT.': 158,
    'WONEYE.EYE.RIGHT.': 159,
    'WONEYE.VINE.LEFT.RIGHT.': 160,
    'WONEYE.EYE.LEFT.': 161,
    'TUNDRA.CLOUD.LEFT.RIGHT.TOP.BOTTOM.BL-GAP': 162,
    'TUNDRA.CLOUD.LEFT.RIGHT.TOP.BOTTOM.BR-GAP': 163,
    'TUNDRA.MOSS.RIGHT.': 164,
    'TUNDRA.MOSS.LEFT.RIGHT.': 165,
    'TUNDRA.MOSS.LEFT.': 166,
    'TUNDRA.MOSS.NONE.': 167,
    'TUNDRA.CLOUD.LEFT.RIGHT.': 168,
    'TUNDRA.CLOUD.LEFT.': 169,
    'REINDEER': 170,
    'REINDEER.CALF': 171,
    'SEAL': 172,
    'SEAL.CALF': 173,
    'SEAWEED': 174,
    'COAST.RIGHT.BOTTOM.': 175,
    'COAST.LEFT.RIGHT.': 176,
    'COAST.LEFT.BOTTOM.': 177,
    'COAST.TR-BL-GAP': 299,
    'KRAKEN': 178,
    'KRAKEN.TENTACLE-1': 179,
    'KRAKEN.TENTACLE-2': 180,
    'COAST.LEFT.RIGHT.TOP.': 181,
    'COAST.LEFT.TOP.BOTTOM.': 182,
    'COAST.BL-TL-GAP': 183,
    'COAST.TR-BR-GAP': 184,
    'COAST.RIGHT.TOP.BOTTOM.': 185,
    'BEAVER': 186,
    'WILLOW.LEAF.LEFT.BOTTOM.': 187,
    'WILLOW.LEAF.LEFT.RIGHT.TOP.BOTTOM.': 188,
    'WILLOW.LEAF.LEFT.RIGHT.TOP.': 188,
    'WILLOW.LEAF.LEFT.RIGHT.BOTTOM.': 188,
    'WILLOW.LEAF.LEFT.TOP.BOTTOM.': 188,
    'WILLOW.LEAF.RIGHT.TOP.BOTTOM.': 188,
    'SWAMP.WATER.RIGHT.BOTTOM.': 189,
    'SWAMP.WATER.LEFT.RIGHT.BOTTOM.': 190,
    'SWAMP.WATER.LEFT.BOTTOM.': 191,
    'WATER.RIGHT.TOP.BOTTOM.': 192,
    'WATER.LEFT.RIGHT.TOP.BOTTOM.': 193,
    'WATER.LEFT.TOP.BOTTOM.': 194,
    'WATER.TOP.BOTTOM.': 195,
    'BARLEY.YOUNG': 196,
    'BARLEY.OLD': 197,
    'BARLEY.HARVEST': 198,
    'HEATH.GRASS.AUTUMN': 199,
    'SAND.LEFT.': 200,
    'SAND.RIGHT.': 200,
    'WATER.RIGHT.TOP.BOTTOM.TR-BR-GAP': 201,
    'WATER.LEFT.RIGHT.TOP.BOTTOM.TR-BR-BL-TL-GAP': 202,
    'WATER.LEFT.TOP.BOTTOM.BL-TL-GAP': 203,
    'SLIME.LEFT.RIGHT.': 204,
    'SLIME.LEFT.': 205,
    'WONEYE.VINE.LEFT.': 206,
    'WONEYE.EYE.RIGHT.BOTTOM.': 207,
    'WONEYE.EYE.LEFT.BOTTOM.': 208,
    'GEYSER.LEFT.TOP.': 209,
    'TUNDRA.CLOUD.RIGHT.BOTTOM.': 210,
    'TUNDRA.CLOUD.LEFT.RIGHT.BOTTOM.': 211,
    'TUNDRA.CLOUD.LEFT.BOTTOM.': 212,
    'TUNDRA.CLOUD.LEFT.RIGHT.TOP.BOTTOM.TR-GAP': 213,
    'TUNDRA.CLOUD.LEFT.RIGHT.TOP.BOTTOM.BR-BL-GAP': 214,
    'TUNDRA.CLOUD.LEFT.RIGHT.TOP.BOTTOM.TR-TL-GAP': 215,
    'TUNDRA.CLOUD.LEFT.RIGHT.TOP.BOTTOM.TR-BL-GAP': 216,
    'TUNDRA.CLOUD.LEFT.RIGHT.TOP.BOTTOM.BR-TL-GAP': 217,
    'TUNDRA.CLOUD.BOTTOM.': 218,
    'TUNDRA.CLOUD.RIGHT.BOTTOM.BR-GAP': 219,
    'TUNDRA.CLOUD.LEFT.RIGHT.BOTTOM.BR-BL-GAP': 220,
    'TUNDRA.CLOUD.LEFT.BOTTOM.BL-GAP': 221,
    'COD': 222,
    // 'COAST.LEFT.RIGHT.TOP.BOTTOM.TR-BL-GAP': 223,
    'SLABS.SURFACE.RIGHT.BOTTOM.': 224,
    // 'COAST.LEFT.RIGHT.TOP.BOTTOM.TR-GAP': 225,
    'COAST.BR-GAP': 226,
    'COAST.BOTTOM.': 227,
    'COAST.BL-GAP': 228,
    'COAST.TR-TL-GAP': 229,
    'COAST.LEFT.TOP.': 230,
    // 'COAST.LEFT.RIGHT.TOP.BOTTOM.TL-GAP': 231,
    // 'COAST.LEFT.RIGHT.TOP.BOTTOM.TR-GAP': 232,
    'COAST.RIGHT.TOP.': 233,
    'WILLOW.BRANCH.RIGHT.': 234,
    'WILLOW.TRUNK': 235,
    'WILLOW.BRANCH.LEFT.': 236,
    'SWAMP.WATER.RIGHT.TOP.BOTTOM.TR-BR-GAP': 237,
    'SWAMP.WATER.LEFT.RIGHT.TOP.BOTTOM.TR-BR-BL-TL-GAP': 238,
    'SWAMP.WATER.LEFT.TOP.BOTTOM.BL-TL-GAP': 239,
    'WATER.RIGHT.TOP.': 240,
    'WATER.LEFT.RIGHT.TOP.': 241,
    'WATER.LEFT.TOP.': 242,
    'WATER.TOP.': 243,
    'OATS.YOUNG': 244,
    'OATS.OLD': 245,
    'OATS.HARVEST': 246,
    'OAT.YOUNG': 244,
    'OAT.OLD': 245,
    'OAT.HARVEST': 246,
    'PIKE': 247,
    'SAND.TOP.BOTTOM.': 248,
    'WATER.RIGHT.TOP.TR-GAP': 249,
    'WATER.LEFT.RIGHT.TOP.TR-TL-GAP': 250,
    'WATER.LEFT.TOP.TL-GAP': 251,
    'WONEYED_BEAST': 252,
    'WONEYED_MONKEY': 253,
    'WONEYE.EYE.LEFT.RIGHT.': 254,
    'WONEYE.EYE.RIGHT.TOP.': 255,
    'WONEYE.EYE.LEFT.TOP.': 256,
    'GEYSER.RIGHT.TOP.': 257,
    'TUNDRA.CLOUD.RIGHT.TOP.BOTTOM.': 258,
    'TUNDRA.CLOUD.LEFT.RIGHT.TOP.BOTTOM.': 259,
    'TUNDRA.CLOUD.LEFT.TOP.BOTTOM.': 260,
    'GEYSER.RIGHT.BOTTOM.': 261,
    'GEYSER.LEFT.BOTTOM.': 262,
    'TUNDRA.CLOUD.NONE.': 263,
    'TUNDRA.CLOUD.RIGHT.TOP.BOTTOM.TR-GAP': 264,
    'TUNDRA.CLOUD.LEFT.TOP.BOTTOM.TL-GAP': 265,
    'TUNDRA.CLOUD.TOP.BOTTOM.': 266,
    'TUNDRA.CLOUD.RIGHT.TOP.BOTTOM.TR-BR-GAP': 267,
    'TUNDRA.CLOUD.LEFT.RIGHT.TOP.BOTTOM.TR-BR-BL-TL-GAP': 268,
    'TUNDRA.CLOUD.LEFT.TOP.BOTTOM.BL-TL-GAP': 269,
    'MACKEREL': 270,
    'COAST.LEFT.RIGHT.TOP.BOTTOM.BL-GAP': 271,
    'SLABS.SURFACE.LEFT.RIGHT.BOTTOM': 272,
    'COAST.LEFT.RIGHT.TOP.BOTTOM.BR-GAP': 273,
    'COAST.RIGHT.': 274,
    'OCEAN': 275,
    'COAST.LEFT.': 276,
    'COAST.BR-BL-GAP': 277,
    // 'COAST.RIGHT.TOP.BOTTOM.': 278,
    'SLABS.ENTRANCE.RIGHT.BOTTOM.': 279,
    'SLABS.SURFACE.LEFT.RIGHT.TOP.BOTTOM.': 280,
    // 'COAST.LEFT.TOP.BOTTOM.': 281,
    'WILLOW.LEAF.RIGHT.TOP.': 282,
    'WILLOW.BRANCH.TOP.': 283,
    'WILLOW.LEAF.LEFT.TOP.': 284,
    'SWAMP.WATER.RIGHT.TOP.TR-GAP': 285,
    'SWAMP.WATER.LEFT.RIGHT.TOP.TR-TL-GAP': 286,
    'SWAMP.WATER.LEFT.TOP.TL-GAP': 287,
    'BLACK_BEAR': 288,
    'HARE': 289,
    'PRICKLEBUSH.HARVEST': 290,
    'PRICKLEBUSH.OFFSEASON': 291,
    'PRICKLEBUSH-FLOWER': 292,
    'SAND.RIGHT.BOTTOM.': 293,
    'SAND.LEFT.RIGHT.BOTTOM.': 294,
    'SAND.LEFT.BOTTOM.': 295,
    'SAND.RIGHT.TOP.': 296,
    'SAND.LEFT.RIGHT.': 297,
    'SAND.LEFT.TOP.': 298,
    'EMPTY': 299,
    'THISTLEWOOD.BRANCH.NONE.BL-TRUNK': 300,
    'THISTLEWOOD.LEAF.RIGHT.BOTTOM.': 301,
    'THISTLEWOOD.BRANCH.BOTTOM.': 302,
    'THISTLEWOOD.LEAF.LEFT.BOTTOM.': 303,
    'TUNDRA.CLOUD.LEFT.RIGHT.TOP.BOTTOM.TR-BR-BL-GAP': 304,
    'TUNDRA.CLOUD.LEFT.RIGHT.TOP.BOTTOM.BR-BL-TL-GAP': 305,
    'TUNDRA.CLOUD.RIGHT.TOP.': 306,
    'TUNDRA.CLOUD.LEFT.RIGHT.TOP.': 307,
    'TUNDRA.CLOUD.LEFT.TOP.': 308,
    'TUNDRA.CLOUD.LEFT.RIGHT.BOTTOM.BL-GAP': 309,
    'TUNDRA.CLOUD.LEFT.RIGHT.BOTTOM.BR-GAP': 310,
    'TUNDRA.CLOUD.RIGHT.TOP.BOTTOM.BR-GAP': 312,
    'TUNDRA.CLOUD.LEFT.TOP.BOTTOM.BL-GAP': 313,
    'TUNDRA.CLOUD.TOP.': 314,
    'TUNDRA.CLOUD.RIGHT.TOP.TR-GAP': 315,
    'TUNDRA.CLOUD.LEFT.RIGHT.TOP.TR-TL-GAP': 316,
    'TUNDRA.CLOUD.LEFT.TOP.TL-GAP': 317,
    'SLABS.SLOPE.LEFT.BOTTOM.': 318,
    // 'COAST.RIGHT.TOP.': 319,
    'COAST.LEFT.RIGHT.TOP.': 320,
    // 'COAST.LEFT.TOP.': 321,
    'COAST.TR-GAP': 322,
    'COAST.TOP.': 323,
    'COAST.TL-GAP': 324,
    'COAST.LEFT.RIGHT.BOTTOM.': 325,
    'COAST.LEFT.BOTTOM.': 326,
    // 'COAST.LEFT.RIGHT.TOP.BOTTOM.BL-GAP': 327,
    // 'COAST.LEFT.RIGHT.TOP.BOTTOM.BR-GAP': 328,
    'COAST.RIGHT.BOTTOM.': 329,
    'OCEAN_2': 330,
    'OCEAN_3': 331,
    'OCEAN_4': 332,
    'OCEAN_5': 333,
    'DESERT-CAPYBARA': 334,
    'NEEDLEWOOD.THORN.DRY.LEFT.RIGHT.': 335,
    'GUMBO.TUMBLESTICKS': 336,
    'GUMBO.RUMBLEGRASS': 337,
    'SAND.BOTTOM.': 338,
    'WASTES.PEAK_1.TOP.': 339,
    'WASTES.PEAK_2.TOP.': 340,
    'SAND.RIGHT.TOP.BOTTOM.': 341,
    'SAND.LEFT.RIGHT.TOP.BOTTOM.': 342,
    'SAND.LEFT.TOP.BOTTOM.': 343,
    'SAND.LEFT.RIGHT.TOP.': 344,
    'SAND.TOP.': 345,
    'SAND.NONE.': 346,
    'BERMUDA-GRASS': 347,
    'THISTLEWOOD.BRANCH.NONE.TR-TRUNK': 348,
    'THISTLEWOOD.BRANCH.RIGHT.': 349,
    'THISTLEWOOD.BRANCH.NONE.BR-TRUNK': 350,
    'THISTLEWOOD.BRANCH.LEFT.': 351,
    'TUNDRA.CLOUD.LEFT.RIGHT.TOP.BOTTOM.TR-BR-TL-GAP': 352,
    'TUNDRA.CLOUD.LEFT.RIGHT.TOP.BOTTOM.TR-BL-TL-GAP': 353,
    'ANTELOPE': 354,
    'COW': 355,
    'COW.CUB': 356,
    'TUNDRA.CLOUD.LEFT.RIGHT.TOP.TL-GAP': 357,
    'TUNDRA.CLOUD.LEFT.RIGHT.TOP.TR-GAP': 358,
    'CRUNCHY.DRY.LEAF.RIGHT.': 359,
    'CRUNCHY.DRY.LEAF.LEFT.': 360,
    'CRUNCHY.MONSOON.PUDDLE-WEIRD-1': 361,
    'CRUNCHY.MONSOON.LEAF.RIGHT.': 362,
    'CRUNCHY.MONSOON.LEAF.LEFT.': 363,
    'CRUNCHY.MONSOON.LEAF.NONE.': 364,
    'CRUNCHY.MONSOON.LEAF.BOTTOM.': 365,
    'CRYSTAL.GRASS.MONSOON-1': 366,
    'CRYSTAL.GRASS.MONSOON-2': 367,
    'SLOTH': 368,
    'CRYSTAL.LEAF.MONSOON.CENTRE.RIGHT-SLOPE.FIRST': 369,
    'CRYSTAL.LEAF.MONSOON.CENTRE.LEFT-SLOPE.SECOND': 370,
    'CRYSTAL.LEAF.MONSOON.RIGHT.LEFT-SLOPE.THIRD': 371,
    'CRYSTAL.LEAF.MONSOON.RIGHT.LEFT-SLOPE.SECOND': 372,
    'CRYSTAL.LEAF.MONSOON.POINK': 373,
    'CRYSTAL.LEAF.MONSOON.RIGHT.LEFT-SLOPE.TIP': 374,
    'SLABS.SLOPE.LEFT.TOP.BOTTOM.': 375,
    'SLABS.ENTRANCE.RIGHT.': 376,
    'GREY-POINK-1': 377,
    'GREY-POINK-2': 378,
    'SLABS.SLOPE.RIGHT.TOP.': 379,
    'SLABS.SLOPE.LEFT.RIGHT.TOP.': 380,
    'SLABS.SLOPE.LEFT.TOP.': 381,
    'BASS': 382,
    'NEEDLEWOOD.THORN.DRY.LEFT.': 383,
    'WASTES.START.LEFT.': 384,
    'WASTES.SECOND.LEFT.': 385,
    'WASTES.THIRD.LEFT.': 386,
    'WASTES.PEAK_1.BOTTOM.': 387,
    'WASTES.PEAK_2.BOTTOM.': 388,
    'WASTES.THIRD.RIGHT.': 389,
    'WASTES.BUMP_1': 390,
    'WASTES.BUMP_2': 391,
    'WASTES.SECOND.RIGHT.': 392,
    'WASTES.FIRST.RIGHT.': 393,
    'LION': 394,
    'KANGAROO-PAW': 395,
    'THISTLEWOOD.BRANCH.NONE.TL-TRUNK': 396,
    'THISTLEWOOD.LEAF.RIGHT.TOP.': 397,
    'THISTLEWOOD.BRANCH.TOP.': 398,
    'THISTLEWOOD.LEAF.LEFT.TOP.': 399,
    'THISTLEWOOD.TRUNK.BOTTOM.': 400,
    'THISTLEWOOD.TRUNK.LEFT.': 401,
    'THISTLEWOOD.TRUNK.RIGHT.': 402,
    'THISTLEWOOD.TRUNK.TOP.': 403,
    'CRUNCHY.DRY.LEAF.RIGHT.BOTTOM.': 404,
    'CRUNCHY.DRY.LEAF.LEFT.RIGHT.BOTTOM.': 405,
    'CRUNCHY.DRY.LEAF.LEFT.BOTTOM.': 406,
    'CRUNCHY.DRY.LEAF.BOTTOM.': 407,
    'JAGUAR': 408,
    'CRUNCHY.MONSOON.PUDDLE-WEIRD-2': 409,
    'CRUNCHY.MONSOON.LEAF.RIGHT.BOTTOM.': 410,
    'CRUNCHY.MONSOON.LEAF.LEFT.RIGHT.BOTTOM.': 411,
    'CRUNCHY.MONSOON.LEAF.LEFT.BOTTOM.': 412,
    'CRUNCHY.MONSOON.LEAF.TOP.': 413,
    'CRYSTAL.LEAF.DRY.LEFT.FIRST': 414,
    'CRYSTAL.LEAF.DRY.CENTRE.FIRST': 415,
    'CRYSTAL.LEAF.DRY.CENTRE.RIGHT-SLOPE.FIRST': 416,
    'CRYSTAL.LEAF.MONSOON.RIGHT.LEFT-SLOPE.FOURTH': 417,
    'CRYSTAL.LEAF.DRY.POINK': 418,
    'CRYSTAL.LEAF.DRY.RIGHT.LEFT-SLOPE.TIP': 419,
    'CRYSTAL.LEAF.MONSOON.LEFT.FIRST': 420,
    'CRYSTAL.LEAF.MONSOON.CENTRE.FIRST': 421,
    'CRYSTAL.LEAF.MONSOON.RIGHT.LEFT-SLOPE.FIRST': 422,
    'MAIZE.SEED': 423,
    'MAIZE.YOUNG': 424,
    'MAIZE.OLD': 425,
    'MAIZE.HARVEST': 426,
    'BEAN.SEED': 427,
    'BEAN.YOUNG': 428,
    'BEAN.OLD': 429,
    'BEAN.HARVEST': 430,
    'NEEDLEWOOD.THORN.MONSOON.LEFT.': 431,
    'INGLENOOK.VINE.RIGHT.BOTTOM.': 432,
    'INGLENOOK.VINE.LEFT.RIGHT.BOTTOM.': 433,
    'INGLENOOK.VINE.LEFT.BOTTOM.': 434,
    'INGLENOOK.VINE.BOTTOM.': 435,
    'INGLENOOK.VINE.CONNECTED.RIGHT.BOTTOM.': 436,
    'INGLENOOK.VINE.CONNECTED.LEFT.RIGHT.BOTTOM.': 437,
    'INGLENOOK.VINE.CONNECTED.LEFT.BOTTOM.': 438,
    'INGLENOOK.VINE.CONNECTED.BOTTOM.': 439,
    'MOUNTAIN.BOTTOM.': 440,
    'MOUNTAIN.RIGHT.BOTTOM.': 441,
    'MOUNTAIN.LEFT.RIGHT.BOTTOM.': 442,
    'MOUNTAIN.LEFT.BOTTOM.': 443,
    'CUSHION-PLANT.HARVEST': 444,
    'THISTLEWOOD.BRANCH.HARVEST.NONE.BL-TRUNK': 445,
    'THISTLEWOOD.LEAF.HARVEST.RIGHT.BOTTOM.': 446,
    'THISTLEWOOD.BRANCH.HARVEST.BOTTOM.': 447,
    'THISTLEWOOD.LEAF.HARVEST.LEFT.BOTTOM.': 448,
    'PUZZLEBOX.LEAF.RIGHT.BOTTOM.': 449,
    'PUZZLEBOX.LEAF.LEFT.RIGHT.BOTTOM.': 450,
    'PUZZLEBOX.LEAF.BOTTOM.': 450,
    'PUZZLEBOX.LEAF.LEFT.BOTTOM.': 451,
    'CRUNCHY.DRY.LEAF.RIGHT.TOP.BOTTOM.': 452,
    'CRUNCHY.DRY.LEAF.LEFT.RIGHT.TOP.BOTTOM.': 453,
    'CRUNCHY.DRY.LEAF.LEFT.RIGHT.': 453,
    'CRUNCHY.DRY.LEAF.TOP.BOTTOM.': 453,
    'CRUNCHY.DRY.LEAF.LEFT.TOP.BOTTOM.': 454,
    'CRUNCHY.DRY.LEAF.TOP.': 455,
    'CRUNCHY.MONSOON.PUDDLE-POINK-1': 456,
    'ARMADILLO': 457,
    'CRUNCHY.MONSOON.LEAF.RIGHT.TOP.BOTTOM.': 458,
    'CRUNCHY.MONSOON.LEAF.LEFT.RIGHT.TOP.BOTTOM.': 459,
    'CRUNCHY.MONSOON.LEAF.TOP.BOTTOM.': 459,
    'CRUNCHY.MONSOON.LEAF.LEFT.RIGHT.': 459,
    'CRUNCHY.MONSOON.LEAF.LEFT.TOP.BOTTOM.': 460,
    'CRUSTY-MONKEY.CUB': 461,
    'CRYSTAL.LEAF.DRY.LEFT.LEFT-SLOPE.SECOND': 462,
    'CRYSTAL.LEAF.DRY.LEFT.RIGHT-SLOPE.SECOND': 463,
    'CRYSTAL.LEAF.DRY.CENTRE.LEFT-SLOPE.SECOND': 464,
    'CRYSTAL.LEAF.DRY.RIGHT.LEFT-SLOPE.THIRD': 465,
    'CRYSTAL.LEAF.DRY.RIGHT.LEFT-SLOPE.SECOND': 466,
    'CRYSTAL.LEAF.DRY.RIGHT.LEFT-SLOPE.FIRST': 467,
    'CRYSTAL.LEAF.MONSOON.LEFT.LEFT-SLOPE.SECOND': 468,
    'CRYSTAL.LEAF.MONSOON.LEFT.RIGHT-SLOPE.SECOND': 469,
    'NEEDLEWOOD.THORN.DRY.RIGHT.': 470,
    'NEEDLEWOOD.THORN.DRY.RIGHT.BOTTOM.': 471,
    'NEEDLEWOOD.THORN.DRY.LEFT.RIGHT.BOTTOM.': 472,
    'NEEDLEWOOD.THORN.DRY.LEFT.BOTTOM.': 473,
    'NEEDLEWOOD.THORN.DRY.BOTTOM.': 474,
    'NEEDLEWOOD.THORN.MONSOON.RIGHT.BOTTOM.': 475,
    'NEEDLEWOOD.THORN.MONSOON.LEFT.RIGHT.BOTTOM.': 476,
    'NEEDLEWOOD.THORN.MONSOON.LEFT.BOTTOM.': 477,
    'NEEDLEWOOD.THORN.MONSOON.BOTTOM.': 478,
    'NEEDLEWOOD.THORN.MONSOON.LEFT.RIGHT.': 479,
    'INGLENOOK.VINE.RIGHT.TOP.BOTTOM.': 480,
    'INGLENOOK.TRUNK': 481,
    'INGLENOOK.VINE.LEFT.RIGHT.TOP.BOTTOM.': 481,
    'INGLENOOK.VINE.LEFT.TOP.BOTTOM.': 482,
    'INGLENOOK.VINE.TOP.BOTTOM.': 483,
    'INGLENOOK.VINE.CONNECTED.RIGHT.TOP.BOTTOM.': 484,
    'INGLENOOK.VINE.CONNECTED.LEFT.TOP.BOTTOM.': 486,
    'INGLENOOK.VINE.CONNECTED.TOP.BOTTOM.': 487,
    'MOUNTAIN.TOP.BOTTOM.': 488,
    'MOUNTAIN.RIGHT.TOP.BOTTOM.': 489,
    'MOUNTAIN.LEFT.RIGHT.TOP.BOTTOM.': 490,
    'MOUNTAIN.LEFT.TOP.BOTTOM.': 491,
    'CUSHION-PLANT': 492,
    'THISTLEWOOD.BRANCH.HARVEST.NONE.TR-TRUNK': 493,
    'THISTLEWOOD.BRANCH.HARVEST.RIGHT.': 494,
    'THISTLEWOOD.BRANCH.HARVEST.NONE.BR-TRUNK': 495,
    'THISTLEWOOD.BRANCH.HARVEST.LEFT.': 496,
    'PUZZLEBOX.LEAF.RIGHT.TOP.BOTTOM.': 497,
    'PUZZLEBOX.LEAF.RIGHT.': 497,
    'PUZZLEBOX.LEAF.LEFT.RIGHT.TOP.BOTTOM.': 498,
    'PUZZLEBOX.LEAF.LEFT.RIGHT.': 498,
    'PUZZLEBOX.LEAF.TOP.BOTTOM.': 498,
    'PUZZLEBOX.LEAF.LEFT.TOP.BOTTOM.': 499,
    'PUZZLEBOX.LEAF.LEFT.': 499,
    'CRUNCHY.DRY.LEAF.RIGHT.TOP.': 500,
    'CRUNCHY.DRY.LEAF.LEFT.RIGHT.TOP.': 501,
    'CRUNCHY.DRY.LEAF.LEFT.TOP.': 502,
    'CRUNCHY.DRY.LEAF.NONE.': 503,
    'CRUNCHY.MONSOON.PUDDLE-POINK-2': 504,
    'CRUNCHY.MONSOON.GROWTH': 505,
    'CRUNCHY.MONSOON.LEAF.RIGHT.TOP.': 506,
    'CRUNCHY.MONSOON.LEAF.LEFT.RIGHT.TOP.': 507,
    'CRUNCHY.MONSOON.LEAF.LEFT.TOP.': 508,
    'CRUSTY_MONKEY': 509,
    'CRYSTAL.LEAF.DRY.LEFT.LEFT-SLOPE.THIRD': 510,
    'CRYSTAL.LEAF.DRY.LEFT.RIGHT.TOP.BOTTOM.2': 511,
    'CRYSTAL.LEAF.DRY.LEFT.RIGHT.TOP.BOTTOM.': 512,
    'CRYSTAL.LEAF.DRY.CENTRE.RIGHT-SLOPE.FOURTH': 513,
    'CRYSTAL.LEAF.DRY.RIGHT.RIGHT-SLOPE.THIRD': 514,
    'CRYSTAL.LEAF.MONSOON.LEFT.LEFT-SLOPE.THIRD': 515,
    'CRYSTAL.LEAF.MONSOON.LEFT.RIGHT.TOP.BOTTOM.2': 516,
    'CRYSTAL.LEAF.MONSOON.LEFT.RIGHT.TOP.BOTTOM.': 517,
    'CRYSTAL.LEAF.MONSOON.CENTRE.RIGHT-SLOPE.FOURTH': 518,
    'NEEDLEWOOD.THORN.DRY.RIGHT.TOP.BOTTOM.': 519,
    'NEEDLEWOOD.THORN.DRY.LEFT.RIGHT.TOP.BOTTOM.': 520,
    'NEEDLEWOOD.THORN.DRY.LEFT.TOP.BOTTOM.': 521,
    'NEEDLEWOOD.THORN.DRY.TOP.BOTTOM.': 522,
    'NEEDLEWOOD.THORN.MONSOON.RIGHT.TOP.BOTTOM.': 523,
    'NEEDLEWOOD.THORN.MONSOON.LEFT.RIGHT.TOP.BOTTOM.': 524,
    'NEEDLEWOOD.THORN.MONSOON.LEFT.TOP.BOTTOM.': 525,
    'NEEDLEWOOD.THORN.MONSOON.TOP.BOTTOM.': 526,
    'NEEDLEWOOD.THORN.MONSOON.RIGHT.': 527,
    'INGLENOOK.VINE.RIGHT.TOP.': 528,
    'INGLENOOK.VINE.LEFT.RIGHT.TOP.': 529,
    'INGLENOOK.VINE.LEFT.TOP.': 530,
    'INGLENOOK.VINE.TOP.': 531,
    'INGLENOOK.VINE.CONNECTED.RIGHT.TOP.': 532,
    'INGLENOOK.VINE.CONNECTED.LEFT.RIGHT.TOP.': 533,
    'INGLENOOK.VINE.CONNECTED.LEFT.TOP.': 534,
    'INGLENOOK.VINE.CONNECTED.TOP.': 535,
    'MOUNTAIN.TOP.': 536,
    'MOUNTAIN.RIGHT.TOP.': 537,
    'MOUNTAIN.LEFT.RIGHT.TOP.': 538,
    'MOUNTAIN.LEFT.TOP.': 539,
    'SCRUB-GRASS': 540,
    'THISTLEWOOD.BRANCH.HARVEST.NONE.TL-TRUNK': 541,
    'THISTLEWOOD.LEAF.HARVEST.RIGHT.TOP.': 542,
    'THISTLEWOOD.BRANCH.HARVEST.TOP.': 543,
    'THISTLEWOOD.LEAF.HARVEST.LEFT.TOP.': 544,
    'PUZZLEBOX.LEAF.RIGHT.TOP.': 545,
    'PUZZLEBOX.LEAF.LEFT.RIGHT.TOP.': 546,
    'PUZZLEBOX.LEAF.TOP.': 546,
    'PUZZLEBOX.LEAF.LEFT.TOP.': 547,
    'CRUNCHY.BRANCH.DRY.LEFT.RIGHT.TOP.BOTTOM.': 548,
    'CRUNCHY.TRUNK.DRY': 549,
    'CRUNCHY.BRANCH.DRY.LEFT.RIGHT.TOP.BOTTOM-2': 550,
    'CRUNCHY.PATH': 551,
    'CRUNCHY.MONSOON.PUDDLE-POINK-3': 552,
    'CRUNCHY.MONSOON.PUDDLE-POINK-4': 553,
    'CRUNCHY.BRANCH.MONSOON.LEFT.RIGHT.TOP.BOTTOM.': 554,
    'CRUNCHY.TRUNK.MONSOON': 555,
    'CRUNCHY.BRANCH.MONSOON.LEFT.RIGHT.TOP.BOTTOM-2': 556,
    'CRYSTAL.GRASS.DRY-2': 557,
    'CRYSTAL.LEAF.DRY.RIGHT.LEFT-SLOPE.FOURTH': 558,
    'CRYSTAL.LEAF.DRY.LEFT.LEFT-SLOPE.FOURTH': 559,
    'CRYSTAL.TRUNK.DRY.RIGHT.': 560,
    'CRYSTAL.TRUNK.DRY.LEFT.': 561,
    'CRYSTAL.GRASS.DRY-1': 562,
    'CRYSTAL.LEAF.MONSOON.LEFT.LEFT-SLOPE.FOURTH': 563,
    'CRYSTAL.TRUNK.MONSOON.RIGHT.': 564,
    'CRYSTAL.TRUNK.MONSOON.LEFT.': 565,
    'CRYSTAL.LEAF.MONSOON.RIGHT.RIGHT-SLOPE.THIRD': 566,
    'NEEDLEWOOD.THORN.DRY.RIGHT.TOP.': 567,
    'NEEDLEWOOD.THORN.DRY.LEFT.RIGHT.TOP.': 568,
    'NEEDLEWOOD.THORN.DRY.LEFT.TOP.': 569,
    'NEEDLEWOOD.THORN.DRY.TOP.': 570,
    'NEEDLEWOOD.THORN.MONSOON.RIGHT.TOP.': 571,
    'NEEDLEWOOD.THORN.MONSOON.LEFT.RIGHT.TOP.': 572,
    'NEEDLEWOOD.THORN.MONSOON.LEFT.TOP.': 573,
    'NEEDLEWOOD.THORN.MONSOON.TOP.': 574,
    'KOALA': 575,
    'INGLENOOK.VINE.RIGHT.': 576,
    'INGLENOOK.VINE.LEFT.RIGHT.': 577,
    'INGLENOOK.VINE.LEFT.': 578,
    'INGLENOOK.LEAF.BOTTOM.': 579,
    'INGLENOOK.VINE.CONNECTED.RIGHT.': 580,
    'INGLENOOK.VINE.CONNECTED.LEFT.RIGHT.': 581,
    'INGLENOOK.VINE.CONNECTED.LEFT.': 582,
    'INGLENOOK.LEAF.TOP.': 583,
    'MOUNTAIN.RIGHT.': 584,
    'MOUNTAIN.LEFT.RIGHT.': 585,
    'MOUNTAIN.NONE.': 3125,
    'MOUNTAIN.LEFT.': 586,
    'MOUNTAIN.GAZEBO.': 587,
    'PUZZLEBOX.BRANCH.RIGHT.': 588,
    'PUZZLEBOX.TRUNK': 589,
    'PUZZLEBOX.BRANCH.LEFT.': 590,
    'PUZZLEBOX.BRANCH.BOTTOM.': 591,
    'PUZZLEBOX.BRANCH.TOP.': 592,
    'FOSSILISED.BRANCH.NONE.TR-TRUNK': 593,
    'FOSSILISED.BRANCH.NONE.BR-TRUNK': 594,
    'FOSSILISED.LEAF.LEFT.RIGHT.BOTTOM.': 595,
    'FOSSILISED.BRANCH.BOTTOM.': 596,
    'FOSSILISED.LEAF.LEFT.RIGHT.TOP.': 597,
    'POLAR.SNOW.BOTTOM.': 598,
    'POLAR.SNOW.RIGHT.BOTTOM.': 599,
    'POLAR.SNOW.LEFT.RIGHT.BOTTOM.': 600,
    'POLAR.SNOW.LEFT.BOTTOM.': 601,
    'POLAR.SNOW.TOP.BOTTOM.': 602,
    'ANTIARIS.LEAF.RIGHT.BOTTOM.': 603,
    'ANTIARIS.LEAF.LEFT.BOTTOM.': 604,
    'ANTIARIS.BRANCH.RIGHT.': 605,
    'ANTIARIS.BRANCH.BOTTOM.': 606,
    'ANTIARIS.BRANCH.LEFT.': 607,
    'COTTON.SEED': 608,
    'COTTON.YOUNG': 609,
    'COTTON.OLD': 610,
    'COTTON.HARVEST': 611,
    'UAPACAA.BRANCH.LEFT.': 612,
    'UAPACAA.BRANCH.BOTTOM.': 613,
    'UAPACAA.LEAF.BOTTOM.': 614,
    'MAZE-SCRUB.LEAF.RIGHT.TOP.': 615,
    'MAZE-SCRUB.LEAF.LEFT.TOP.': 616,
    'MAZE-SCRUB.LEAF.BOTTOM.': 617,
    'MAZE-SCRUB.LEAF.TOP.': 618,
    'KANGAROO': 619,
    'NEEDLEWOOD.GRASS': 620,
    'NEEDLEWOOD.GRASS.2': 621,
    'GOAT': 622,
    'GOAT.CUB': 623,
    'INGLENOOK.LEAF.RIGHT.BOTTOM.': 624,
    'INGLENOOK.LEAF.LEFT.RIGHT.BOTTOM.': 625,
    'INGLENOOK.LEAF.LEFT.BOTTOM.': 626,
    'INGLENOOK.LEAF.TOP.BOTTOM.': 627,
    'INGLENOOK.LEAF.RIGHT.': 628,
    'INGLENOOK.LEAF.LEFT.RIGHT.': 629,
    'INGLENOOK.LEAF.LEFT.': 630,
    'PAINFOREST.PRONG.CENTRE-RIGHT.CENTRE.': 631,
    'PAINFOREST.PRONG.CENTRE-BOTTOM.RIGHT.': 632,
    'PAINFOREST.TRUNK.RIGHT': 633,
    'PAINFOREST.PRONG.CENTRE-TOP.LEFT': 634,
    'PAINFOREST.PRONG.CENTRE-TOP.CENTRE-LEFT': 635,
    'PAINFOREST.PRONG.CENTRE-TOP.CENTRE-RIGHT': 636,
    'PAINFOREST.PRONG.CENTRE-TOP.RIGHT': 637,
    'PAINFOREST.PRONG.TOP-RIGHT.SECOND-ROW.LEFT': 638,
    'RACOON': 639,
    'SUGAR-BUSH.HARVEST': 640,
    'FOSSILISED.BRANCH.TOP.': 641,
    'FOSSILISED.LEAF.RIGHT.BOTTOM.': 642,
    'FOSSILISED.TRUNK.LARGE.RIGHT.BOTTOM.': 643,
    'FOSSILISED.TRUNK.LARGE.LEFT.RIGHT.BOTTOM.': 644,
    'FOSSILISED.TRUNK.LARGE.LEFT.BOTTOM.': 645,
    'FOSSILISED.LEAF.LEFT.BOTTOM.': 646,
    'POLAR.SNOW.RIGHT.TOP.BOTTOM.': 647,
    'POLAR.SNOW.LEFT.RIGHT.TOP.BOTTOM.': 648,
    'POLAR.SNOW.LEFT.TOP.BOTTOM.': 649,
    'POLAR.SNOW.TOP.': 650,
    'ANTIARIS.LEAF.RIGHT.TOP.': 651,
    'ANTIARIS.LEAF.LEFT.TOP.': 652,
    'ANTIARIS.LEAF.LEFT.RIGHT.TOP.BOTTOM.': 653,
    'ANTIARIS.TRUNK': 654,
    'CATFISH': 655,
    'SWEET-POTATO.SEED': 656,
    'SWEET-POTATO.YOUNG': 657,
    'SWEET-POTATO.OLD': 658,
    'SWEET-POTATO.HARVEST': 659,
    'UAPACAA.BRANCH.RIGHT.': 660,
    'UAPACAA.TRUNK': 661,
    'UAPACAA.LEAF.LEFT.RIGHT.TOP.BOTTOM.': 662,
    'MAZE-SCRUB.LEAF.RIGHT.': 663,
    'MAZE-SCRUB.LEAF.LEFT.RIGHT.': 664,
    'MAZE-SCRUB.TRUNK.': 665,
    'MAZE-SCRUB.LEAF.LEFT.': 666,
    'MAZE-SCRUB.LEAF.RIGHT-BIG': 667,
    'MAZE-SCRUB.LEAF.LEFT.RIGHT.TOP.BOTTOM.': 668,
    'MAZE-SCRUB.LEAF.LEFT.TOP.BOTTOM.': 668,
    'MAZE-SCRUB.LEAF.RIGHT.TOP.BOTTOM.': 668,
    'MAZE-SCRUB.LEAF.LEFT.RIGHT.TOP.': 668,
    'MAZE-SCRUB.LEAF.TOP.BOTTOM.': 668,
    'MAZE-SCRUB.LEAF.RIGHT.BOTTOM.': 668,
    'MAZE-SCRUB.LEAF.LEFT.BOTTOM.': 668,
    'MAZE-SCRUB.LEAF.LEFT.RIGHT.BOTTOM.': 668,
    'MAZE-SCRUB.LEAF.LEFT-BIG': 669,
    'MAZE-SCRUB.LEAF.HARVEST.RIGHT.TOP.': 670,
    'MAZE-SCRUB.LEAF.HARVEST.LEFT.TOP.': 671,
    'INGLENOOK.LEAF.RIGHT.TOP.BOTTOM.': 672,
    'INGLENOOK.LEAF.LEFT.RIGHT.TOP.BOTTOM.': 673,
    'INGLENOOK.LEAF.LEFT.TOP.BOTTOM.': 674,
    'GOOSEFOOT.SEED': 675,
    'GOOSEFOOT.YOUNG': 676,
    'GOOSEFOOT.OLD': 677,
    'GOOSEFOOT.HARVEST': 678,
    'PAINFOREST.TRUNK.BOTTOM-RIGHT': 679,
    'PAINFOREST.BRANCH.CENTRE-RIGHT': 680,
    'PAINFOREST.BRANCH.CENTRE-TOP.LEFT': 681,
    'PAINFOREST.BRANCH.CENTRE-TOP.RIGHT': 682,
    'PAINFOREST.PRONG.CENTRE-TOP.SECOND-ROW.LEFT': 683,
    'PAINFOREST.PRONG.CENTRE-TOP.SECOND-ROW.RIGHT': 684,
    'PAINFOREST.BRANCH.CENTRE-LEFT': 685,
    'PAINFOREST.PRONG.TOP-LEFT.CENTRE-TOP': 686,
    'PAINFOREST.PRONG.TOP-LEFT.RIGHT-TOP': 687,
    'SUGAR-BUSH': 688,
    'FOSSILISED.BRANCH.NONE.TL-TRUNK': 689,
    'FOSSILISED.BRANCH.RIGHT.': 690,
    'FOSSILISED.TRUNK.LARGE.RIGHT.TOP.BOTTOM.': 691,
    'FOSSILISED.TRUNK.LARGE.LEFT.RIGHT.TOP.BOTTOM.': 692,
    'FOSSILISED.TRUNK.LARGE.LEFT.TOP.BOTTOM.': 693,
    'FOSSILISED.BRANCH.LEFT.': 694,
    'POLAR.SNOW.RIGHT.TOP.': 695,
    'POLAR.SNOW.LEFT.RIGHT.TOP.': 696,
    'POLAR.SNOW.LEFT.TOP.': 697,
    'POLAR.ICE.RIGHT.BOTTOM.BR-GAP': 698,
    'POLAR.ICE.RIGHT.TOP.BOTTOM.': 698,
    'POLAR.ICE.LEFT.RIGHT.BOTTOM.BR-BL-GAP': 699,
    'POLAR.ICE.LEFT.RIGHT.': 699,
    'POLAR.ICE.LEFT.RIGHT.TOP.': 699,
    'POLAR.ICE.LEFT.': 699,
    'POLAR.ICE.LEFT.BOTTOM.BL-GAP': 700,
    'POLAR.ICE.LEFT.BOTTOM.': 700,
    'POLAR.ICE.RIGHT.BOTTOM.TR-BL-TL-GAP': 701,
    'POLAR.ICE.RIGHT.BOTTOM.': 701,
    'POLAR.ICE.LEFT.BOTTOM.TR-BR-TL-GAP': 702,
    'ICY-WASTE.RIGHT.BOTTOM.': 703,
    'ICY-WASTE.NONE.': 704,
    'ICY-WASTE.NONE.': 704,
    'ICY-WASTE.LEFT.BOTTOM.': 705,
    'ICY-WASTE.BOTTOM.': 706,
    'PRIMEORDEAL.VINE.RIGHT.BOTTOM.': 707,
    'PRIMEORDEAL.VINE.LEFT.RIGHT.': 708,
    'PRIMEORDEAL.VINE.LEFT.BOTTOM.': 709,
    'UAPACAA.LEAF.LEFT.': 710,
    'MAZE-SCRUB.LEAF.HARVEST.RIGHT.': 711,
    'MAZE-SCRUB.LEAF.HARVEST.LEFT.RIGHT.': 712,
    'MAZE-SCRUB.TRUNK.HARVEST.': 713,
    'MAZE-SCRUB.LEAF.HARVEST.LEFT.': 714,
    'MAZE-SCRUB.LEAF.HARVEST.RIGHT-BIG': 715,
    'MAZE-SCRUB.LEAF.HARVEST.LEFT.RIGHT.TOP.BOTTOM.': 716,
    'MAZE-SCRUB.LEAF.HARVEST.RIGHT.BOTTOM.': 716,
    'MAZE-SCRUB.LEAF.HARVEST.LEFT.BOTTOM.': 716,
    'MAZE-SCRUB.LEAF.HARVEST.TOP.BOTTOM.': 716,
    'MAZE-SCRUB.LEAF.HARVEST.LEFT-BIG': 717,
    'MAZE-SCRUB.LEAF.HARVEST.BOTTOM.': 718,
    'MAZE-SCRUB.LEAF.HARVEST.TOP.': 719,
    'INGLENOOK.LEAF.RIGHT.TOP.': 720,
    'INGLENOOK.LEAF.LEFT.RIGHT.TOP.': 721,
    'INGLENOOK.LEAF.LEFT.TOP.': 722,
    'TURNIP.SEED': 723,
    'TURNIP.YOUNG': 724,
    'TURNIP.OLD': 725,
    'TURNIP.HARVEST': 726,
    'PAINFOREST.PRONG.CENTRE-RIGHT.BOTTOM': 727,
    'PAINFOREST.PRONG.TOP-RIGHT.BOTTOM-ROW.LEFT': 728,
    'PAINFOREST.TRUNK.CENTRE-TOP.LEFT': 729,
    'PAINFOREST.TRUNK.CENTRE-TOP.RIGHT': 730,
    'PAINFOREST.PRONG.TOP-LEFT.SECOND-ROW.LEFT': 731,
    'PAINFOREST.PRONG.TOP-LEFT.SECOND-ROW.CENTRE': 732,
    'PAINFOREST.PRONG.TOP-LEFT.SECOND-ROW.RIGHT': 733,
    'PAINFOREST.BRANCH.BOTTOM-RIGHT.START': 734,
    'PAINFOREST.PRONG.CENTRE-RIGHT.SECOND-ROW': 735,
    'FOSSILISED.TRUNK.SMALL': 736,
    'FOSSILISED.BRANCH.NONE.BL-TRUNK': 737,
    'FOSSILISED.LEAF.RIGHT.TOP.': 738,
    'FOSSILISED.TRUNK.LARGE.RIGHT.TOP.': 739,
    'FOSSILISED.TRUNK.LARGE.LEFT.RIGHT.TOP.': 740,
    'FOSSILISED.TRUNK.LARGE.LEFT.TOP.': 741,
    'FOSSILISED.LEAF.LEFT.TOP.': 742,
    'POLAR.SNOW.RIGHT.': 743,
    'POLAR.SNOW.LEFT.RIGHT.': 744,
    'POLAR.SNOW.LEFT.': 745,
    'POLAR.ICE.TOP.BOTTOM.TR-BR-GAP': 746,
    'POLAR.SNOW.NONE.': 747,
    'POLAR.ICE.TOP.BOTTOM.BL-TL-GAP': 748,
    'POLAR.ICE.TOP.BOTTOM.': 748,
    'POLAR.ICE.TOP.': 748,
    'POLAR.ICE.BOTTOM.': 748,
    'POLAR.ICE.RIGHT.TOP.': 749,
    'POLAR.ICE.RIGHT.': 749,
    'POLAR.ICE.NONE.': 749,
    'POLAR.ICE.LEFT.TOP.TR-BR-BL-GAP': 750,
    'POLAR.ICE.LEFT.TOP.': 750,
    'ICY-WASTE.RIGHT.TOP.': 751,
    'ICY-WASTE.RIGHT.TOP.BOTTOM.': 751,
    'ICY-WASTE.ROCKS': 752,
    'ICY-WASTE.LEFT.TOP.': 753,
    'ICY-WASTE.LEFT.TOP.BOTTOM.': 754,
    'ICY-WASTE.TOP.BOTTOM.': 754,
    'PRIMEORDEAL.VINE.TOP.BOTTOM.2': 755,
    'PRIMEORDEAL.TRUNK': 756,
    'PRIMEORDEAL.VINE.LEFT.RIGHT.TOP.BOTTOM.': 756,
    'PRIMEORDEAL.VINE.TOP.BOTTOM.': 757,
    'PRIMEORDEAL.VINE.LEFT.RIGHT.GROWTH': 758,
    'PRIMEORDEAL.VINE.LEFT.': 758,
    'PRIMEORDEAL.VINE.RIGHT.': 758,
    'PRIMEORDEAL.VINE.NONE.': -1,
    'PRIMEORDEAL.VINE.TOP.': 758,
    'PRIMEORDEAL.VINE.BOTTOM.': 758,
    'PRIMEORDEAL.VINE.LEFT.RIGHT.BOTTOM.': 759,
    'PANDA': 760,
    'BAMBOO': 761,
    'PRIMEORDEAL.LEAF.RIGHT.BOTTOM.': 762,
    'PRIMEORDEAL.LEAF.LEFT.RIGHT.BOTTOM.': 763,
    'PRIMEORDEAL.LEAF.LEFT.BOTTOM.': 764,
    'PRIMEORDEAL.LEAF.RIGHT.TOP.': 765,
    'PRIMEORDEAL.LEAF.LEFT.RIGHT.TOP.': 766,
    'PRIMEORDEAL.LEAF.LEFT.TOP.': 767,
    'EVERGREEN.RIGHT.BOTTOM.': 768,
    'EVERGREEN.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-TOP': 769,
    'EVERGREEN.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-BOTTOM': 770,
    'EVERGREEN.LEFT.RIGHT.TOP.BOTTOM.CONNECTOR-TOP-RIGHT-BOTTOM-LEFT': 770,
    'EVERGREEN.LEFT.RIGHT.TOP.BOTTOM.CONNECTOR-TOP-LEFT-BOTTOM-RIGHT': 770,
    'EVERGREEN.LEFT.RIGHT.BOTTOM.': 771,
    'EVERGREEN.LEFT.BOTTOM.': 772,
    'PAINFOREST.BRANCH.TOP-LEFT.END': 773,
    'PAINFOREST.TRUNK.LEFT': 774,
    'PAINFOREST.BRANCH.TOP-LEFT.START': 775,
    'PAINFOREST.TRUNK.TOP-LEFT': 776,
    'PAINFOREST.TRUNK.CENTRE.LEFT': 777,
    'PAINFOREST.TRUNK.CENTRE.RIGHT': 778,
    'PAINFOREST.PRONG.TOP-LEFT.BOTTOM-ROW.LEFT': 779,
    'PAINFOREST.PRONG.TOP-LEFT.BOTTOM-ROW.CENTRE': 780,
    'PAINFOREST.PRONG.TOP-LEFT.BOTTOM-ROW.RIGHT': 781,
    'PAINFOREST.BRANCH.BOTTOM-RIGHT.END': 782,
    'PAINFOREST.TRUNK.RIGHT.TOP': 783,
    'PAINFOREST.BRANCH.TOP-RIGHT.START': 784,
    'PAINFOREST.BRANCH.CENTRE-TOP.START': 785,
    'LUSCIOUS.ALOE.RIGHT.BOTTOM.': 786,
    'LUSCIOUS.ALOE.LEFT.RIGHT.BOTTOM.': 787,
    'LUSCIOUS.ALOE.LEFT.BOTTOM.': 788,
    'LUSCIOUS.PALM.RIGHT.BOTTOM.': 789,
    'LUSCIOUS.PALM.LEFT.RIGHT.BOTTOM.': 790,
    'LUSCIOUS.PALM.LEFT.BOTTOM.': 791,
    'MONKEY.CUB': 792,
    'POLAR_BEAR': 793,
    'POLAR.ICE.RIGHT.TOP.TR-GAP': 794,
    'POLAR.ICE.LEFT.RIGHT.TOP.TR-TL-GAP': 795,
    'POLAR.ICE.LEFT.TOP.TL-GAP': 796,
    'ICY-WASTE.RIGHT.': 797,
    'ICY-WASTE.LEFT.RIGHT.': 798,
    'ICY-WASTE.LEFT.RIGHT.TOP.': 798,
    'ICY-WASTE.LEFT.RIGHT.BOTTOM.': 798,
    'ICY-WASTE.LEFT.': 799,
    'SNUB_NOSED_MONKEY.CUB': 800,
    'SNUB_NOSED_MONKEY': 801,
    'ICY-WASTE.TOP.': 802,
    'PRIMEORDEAL.VINE.RIGHT.TOP.': 803,
    'PRIMEORDEAL.VINE.LEFT.RIGHT.2': 804,
    'PRIMEORDEAL.VINE.LEFT.TOP.': 805,
    'SABLE': 806,
    'PRIMEORDEAL.VINE.RIGHT.TOP.BOTTOM.': 807,
    'PRIMEORDEAL.VINE.LEFT.TOP.BOTTOM.': 808,
    'PRIMEORDEAL.VINE.LEFT.RIGHT.TOP.': 809,
    'PRIMEORDEAL.LEAF.RIGHT.TOP.BOTTOM.': 810,
    'PRIMEORDEAL.LEAF.LEFT.RIGHT.TOP.BOTTOM.': 811,
    'PRIMEORDEAL.LEAF.LEFT.RIGHT.': 811,
    'PRIMEORDEAL.LEAF.TOP.BOTTOM.': 811,
    'PRIMEORDEAL.LEAF.LEFT.TOP.BOTTOM.': 812,
    'PRIMEORDEAL.LEAF.NONE.': 813,
    'PRIMEORDEAL.TRUNK.LARGE.LEFT.': 814,
    'PRIMEORDEAL.TRUNK.LARGE.RIGHT.': 815,
    'EVERGREEN.RIGHT.TOP.BOTTOM.': 816,
    'EVERGREEN.LEFT.RIGHT.TOP.BOTTOM.FACING-LEFT': 817,
    'EVERGREEN.LEFT.RIGHT.TOP.BOTTOM.': 818,
    'EVERGREEN.LEFT.RIGHT.TOP.BOTTOM.FACING-RIGHT': 819,
    'EVERGREEN.LEFT.TOP.BOTTOM.': 820,
    'PAINFOREST.PRONG.TOP-RIGHT.BOTTOM-ROW.RIGHT': 821,
    'PAINTREE_MONKEY': 822,
    'PAINFOREST.PRONG.LEFT.TOP': 823,
    'PAINFOREST.TRUNK.LEFT.BOTTOM': 824,
    'PAINFOREST.BRANCH.BOTTOM-LEFT.START': 825,
    'PAINFOREST.PRONG.BOTTOM-LEFT.LEFT-TOP': 826,
    'PAINFOREST.PRONG.BOTTOM-LEFT.CENTRE-TOP': 827,
    'PAINFOREST.PRONG.BOTTOM-LEFT.RIGHT-TOP': 828,
    'PAINFOREST.PRONG.BOTTOM.SECOND-ROW.RIGHT': 829,
    'PAINFOREST.PRONG.BOTTOM-RIGHT.LEFT-TOP': 830,
    'PAINFOREST.PRONG.BOTTOM-RIGHT.CENTRE-TOP': 831,
    'PAINFOREST.PRONG.BOTTOM-RIGHT.RIGHT-TOP': 832,
    'PAINFOREST.PRONG.TOP-RIGHT.SECOND-ROW.CENTRE': 833,
    'LUSCIOUS.ALOE.RIGHT.TOP.BOTTOM.': 834,
    'LUSCIOUS.ALOE.LEFT.RIGHT.TOP.BOTTOM.': 835,
    'LUSCIOUS.ALOE.LEFT.TOP.BOTTOM.': 836,
    'LUSCIOUS.PALM.RIGHT.TOP.BOTTOM.': 837,
    'LUSCIOUS.PALM.LEFT.RIGHT.TOP.BOTTOM.': 838,
    'LUSCIOUS.PALM.LEFT.TOP.BOTTOM.': 839,
    'PALM-BUSH.HARVEST': 840,
    'PALM-BUSH': 841,
    'OLD-GROWTH.ONE.A1': 842,
    'OLD-GROWTH.ONE.A2': 843,
    'OLD-GROWTH.ONE.A3': 844,
    'OLD-GROWTH.TWO.A1': 845,
    'OLD-GROWTH.TWO.A2': 846,
    'OLD-GROWTH.TWO.A3': 847,
    'OLD-GROWTH.TWO.A4': 848,
    'OLD-GROWTH.LEAF.BOTTOM.': 849,
    'FERN': 853,
    'PRIMEVAL.INNER.LEFT.RIGHT.TOP.BOTTOM.BR-GAP': 854,
    'PRIMEVAL.INNER.LEFT.RIGHT.TOP.': 855,
    'PRIMEVAL.INNER.LEFT.RIGHT.TOP.BOTTOM.BL-GAP': 856,
    'PRIMEVAL.OUTER.RIGHT.BOTTOM.': 857,
    'PRIMEVAL.OUTER.LEFT.RIGHT.BOTTOM.': 858,
    'PRIMEVAL.OUTER.LEFT.BOTTOM.': 859,
    'PRIMEVAL.OUTER.LEFT.RIGHT.TOP.BOTTOM.TL-GAP': 860,
    'PRIMEVAL.OUTER.LEFT.RIGHT.TOP.BOTTOM.TR-GAP': 861,
    'PRIMEORDEAL.LEAF.RIGHT.': 862,
    'PRIMEORDEAL.LEAF.BOTTOM.': 863,
    'EVERGREEN.RIGHT.TOP.': 864,
    'EVERGREEN.WOLF': 865,
    'DEER': 866,
    'EVERGREEN.LEFT.RIGHT.TOP.': 867,
    'EVERGREEN.LEFT.TOP.': 868,
    'PAINFOREST.PRONG.RIGHT.TOP.': 869,
    'PAINFOREST.PRONG.TOP-RIGHT.TOP.LEFT': 870,
    'PAINFOREST.PRONG.LEFT.CENTRE': 871,
    'PAINFOREST.PRONG.LEFT.SECOND-ROW': 872,
    'PAINFOREST.BRANCH.BOTTOM-LEFT.END': 873,
    'PAINFOREST.PRONG.BOTTOM-LEFT.SECOND-ROW.LEFT': 874,
    'PAINFOREST.PRONG.BOTTOM-LEFT.SECOND-ROW.CENTRE': 875,
    'PAINFOREST.PRONG.BOTTOM-LEFT.SECOND-ROW.RIGHT': 876,
    'PAINFOREST.PRONG.BOTTOM.LEFT': 877,
    'PAINFOREST.PRONG.BOTTOM-RIGHT.SECOND-ROW.LEFT': 878,
    'PAINFOREST.PRONG.BOTTOM-RIGHT.SECOND-ROW.CENTRE': 879,
    'PAINFOREST.PRONG.BOTTOM-RIGHT.SECOND-ROW.RIGHT': 880,
    'PAINFOREST.PRONG.TOP-RIGHT.BOTTOM-ROW.CENTRE': 881,
    'LUSCIOUS.ALOE.RIGHT.TOP.': 882,
    'LUSCIOUS.ALOE.LEFT.RIGHT.TOP.': 883,
    'LUSCIOUS.ALOE.LEFT.TOP.': 884,
    'LUSCIOUS-BUSH.HARVEST': 885,
    'LUSCIOUS-BUSH': 886,
    'OLD-GROWTH.LEAF.RIGHT.BOTTOM.': 887,
    'OLD-GROWTH.LEAF.LEFT.BOTTOM.': 888,
    'OLD-GROWTH.LEAF.RIGHT.TOP.BOTTOM.': 889,
    'OLD-GROWTH.ONE.B1': 890,
    'OLD-GROWTH.ONE.B2': 891,
    'OLD-GROWTH.ONE.B3': 892,
    'OLD-GROWTH.TWO.B1': 893,
    'OLD-GROWTH.TWO.B2': 894,
    'OLD-GROWTH.TWO.B3': 895,
    'OLD-GROWTH.LEAF.TOP.': 896,
    'OLD-GROWTH.THREE.A1': 897,
    'OLD-GROWTH.THREE.A2': 898,
    'OLD-GROWTH.THREE.A3': 899,
    'LIVER-WORT': 900,
    'LIVER-WORT.HARVEST': 901,
    'PRIMEVAL.INNER.LEFT.TOP.BOTTOM.': 902,
    'JUNGLE-PLANT.2': 903,
    'PRIMEVAL.INNER.RIGHT.TOP.BOTTOM.': 904,
    'PRIMEVAL.OUTER.RIGHT.TOP.BOTTOM.': 905,
    'PRIMEVAL.LEFT.RIGHT.TOP.BOTTOM.': 906,
    'PRIMEVAL.OUTER.LEFT.RIGHT.TOP.BOTTOM.': 906,
    'PRIMEVAL.OUTER.RIGHT.': 906,
    'PRIMEVAL.OUTER.LEFT.RIGHT.TOP.BOTTOM.BL-TL-GAP': 906,
    'PRIMEVAL.OUTER.LEFT.RIGHT.': 906,
    'PRIMEVAL.OUTER.LEFT.RIGHT.BOTTOM.BL-GAP': 906,
    'PRIMEVAL.OUTER.LEFT.TOP.BOTTOM.BL-GAP': 906,
    'PRIMEVAL.OUTER.TOP.BOTTOM.': 906,
    'PRIMEVAL.OUTER.LEFT.RIGHT.TOP.TL-GAP': 906,
    'PRIMEVAL.OUTER.LEFT.TOP.BOTTOM.': 907,
    'PRIMEVAL.OUTER.LEFT.RIGHT.TOP.BOTTOM.BL-GAP': 908,
    'PRIMEVAL.OUTER.LEFT.RIGHT.TOP.BOTTOM.BR-GAP': 909,
    'PRIMEORDEAL.LEAF.LEFT.': 910,
    'PRIMEORDEAL.LEAF.TOP.': 911,
    'FUR.RIGHT.BOTTOM.': 912,
    'OVERGROWN-FUR.LEAF.RIGHT.BOTTOM.': 912,
    'FUR.LEFT.RIGHT.BOTTOM.': 913,
    'OVERGROWN-FUR.LEAF.LEFT.RIGHT.BOTTOM.': 913,
    'FUR.LEFT.BOTTOM.': 914,
    'OVERGROWN-FUR.LEAF.LEFT.BOTTOM.': 914,
    'FUR.LEFT.RIGHT.TOP.BOTTOM.CONNECTOR-TOP-RIGHT-BOTTOM-LEFT': 915,
    'OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.BOTTOM.CONNECTOR-TOP-RIGHT-BOTTOM-LEFT': 915,
    'CLOUDBERRY.OFFSEASON': 916,
    'PAINFOREST.TRUNK.RIGHT.BOTTOM': 917,
    'PAINFOREST.PRONG.BOTTOM.CENTRE-RIGHT': 918,
    'PAINFOREST.PRONG.LEFT.BOTTOM': 919,
    'PAINFOREST.TRUNK.BOTTOM.CENTRE-LEFT': 920,
    'PAINFOREST.TRUNK.BOTTOM.CENTRE-RIGHT': 921,
    'PAINFOREST.PRONG.BOTTOM.SECOND-ROW.LEFT': 922,
    'PAINFOREST.PRONG.BOTTOM-LEFT.CENTRE-BOTTOM': 923,
    'PAINFOREST.PRONG.BOTTOM-LEFT.RIGHT-BOTTOM': 924,
    'PAINFOREST.PRONG.BOTTOM.CENTRE-LEFT': 925,
    'PAINFOREST.PRONG.BOTTOM-RIGHT.BOTTOM-LEFT': 926,
    'PAINFOREST.PRONG.BOTTOM-RIGHT.BOTTOM-CENTRE': 927,
    'PAINFOREST.BRANCH.TOP-RIGHT.END': 928,
    'PAINFOREST.PRONG.TOP-RIGHT.SECOND-ROW.RIGHT': 929,
    'OLD-GROWTH.FOUR.A1': 930,
    'OLD-GROWTH.FOUR.A2': 931,
    'OLD-GROWTH.FOUR.A3': 932,
    'OLD-GROWTH.FIVE.A1': 933,
    'OLD-GROWTH.FIVE.A2': 934,
    'OLD-GROWTH.LEAF.RIGHT.TOP.': 935,
    'OLD-GROWTH.LEAF.LEFT.TOP.': 936,
    'OLD-GROWTH.LEAF.LEFT.TOP.BOTTOM.': 937,
    'OLD-GROWTH.ONE.C1': 938,
    'OLD-GROWTH.ONE.C2': 939,
    'OLD-GROWTH.LEAF.LEFT.RIGHT.BOTTOM.': 940,
    'OLD-GROWTH.TWO.C1': 941,
    'OLD-GROWTH.TWO.C2': 942,
    'OLD-GROWTH.TWO.C3': 943,
    'OLD-GROWTH.THREE.B1': 944,
    'OLD-GROWTH.THREE.B2': 945,
    'OLD-GROWTH.THREE.B3': 946,
    'OLD-GROWTH.THREE.B4': 947,
    'OLD-GROWTH.THREE.B5': 948,
    'JUNGLE-PLANT.1': 949,
    'PRIMEVAL.INNER.LEFT.RIGHT.TOP.BOTTOM.TR-GAP': 950,
    'PRIMEVAL.INNER.LEFT.RIGHT.BOTTOM.': 951,
    'PRIMEVAL.INNER.LEFT.RIGHT.TOP.BOTTOM.TL-GAP': 952,
    'PRIMEVAL.OUTER.RIGHT.TOP.': 953,
    'PRIMEVAL.OUTER.LEFT.RIGHT.TOP.': 954,
    'PRIMEVAL.OUTER.LEFT.TOP.': 955,
    'PRIMEVAL.INNER.LEFT.TOP.': 956,
    'PRIMEVAL.INNER.RIGHT.TOP.': 957,
    'PRIMEVAL.INNER.LEFT.BOTTOM.': 958,
    'PRIMEVAL.INNER.RIGHT.BOTTOM.': 959,
    'FUR.RIGHT.TOP.BOTTOM.': 960,
    'OVERGROWN-FUR.LEAF.RIGHT.TOP.BOTTOM.': 960,
    'FUR.LEFT.RIGHT.TOP.BOTTOM.': 961,
    'FUR.LEFT.TOP.BOTTOM.': 962,
    'OVERGROWN-FUR.LEAF.LEFT.TOP.BOTTOM.': 962,
    'FUR.LEFT.RIGHT.TOP.BOTTOM.CONNECTOR-TOP-LEFT-BOTTOM-RIGHT': 963,
    'OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.BOTTOM.CONNECTOR-TOP-LEFT-BOTTOM-RIGHT': 963,
    'ALBINO-FUR.RIGHT.BOTTOM.': 964,
    'ALBINO-FUR.LEFT.RIGHT.BOTTOM.': 965,
    'ALBINO-FUR.LEFT.BOTTOM.': 966,
    'DISCORDANT-YELLOW.RIGHT.BOTTOM.': 967,
    'DISCORDANT-YELLOW.LEFT.RIGHT.BOTTOM': 968,
    'DISCORDANT-YELLOW.LEFT.RIGHT.TOP.BOTTOM.UP.': 969,
    'ORDEAL.LEAF.RIGHT.BOTTOM.': 970,
    'ORDEAL.LEAF.LEFT.RIGHT.BOTTOM.': 971,
    'ORDEAL.LEAF.LEFT.BOTTOM.': 972,
    'ORDEAL.BRANCH.BOTTOM.': 973,
    'ORDEAL.NONE.': 974,
    'OVERGROWN-FUR.ONE.A1': 975,
    'OVERGROWN-FUR.ONE.A2': 976,
    'OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.BOTTOM.TL-GAP': 977,
    'OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.BOTTOM.TR-GAP': 978,
    'OLD-GROWTH.FOUR.B1': 979,
    'OLD-GROWTH.FOUR.B2': 980,
    'OLD-GROWTH.FIVE.B1': 981,
    'OLD-GROWTH.FIVE.B2': 982,
    'OLD-GROWTH.FIVE.B3': 983,
    'OLD-GROWTH.SIX.A1': 984,
    'OLD-GROWTH.SIX.A2': 985,
    'OLD-GROWTH.SEVEN.A1': 986,
    'OLD-GROWTH.SEVEN.A2': 987,
    'OLD-GROWTH.SEVEN.A3': 988,
    'OLD-GROWTH.LEAF.RIGHT.': 989,
    'OLD-GROWTH.LEAF.LEFT.': 990,
    'OLD-GROWTH.EIGHT.A1': 991,
    'OLD-GROWTH.THREE.C1': 992,
    'OLD-GROWTH.THREE.C2': 993,
    'TIGER': 994,
    'OLD-GROWTH.THREE.C3': 995,
    'OLD-GROWTH.THREE.C4': 996,
    'DEEPEST-JUNGLE.ONE.TRUNK.A1': 997,
    'DEEPEST-JUNGLE.ONE.TRUNK.A2': 998,
    'DEEPEST-JUNGLE.TWO.TRUNK.A1': 999,
    'DEEPEST-JUNGLE.TWO.TRUNK.A2': 1000,
    'DEEPEST-JUNGLE.THREE.TRUNK.A1': 1001,
    'DEEPEST-JUNGLE.THREE.TRUNK.A2': 1002,
    'DEEPEST-JUNGLE.TWO.LEAF.RIGHT.': 1003,
    'DEEPEST-JUNGLE.TWO.LEAF.RIGHT.TOP.BOTTOM.': 1003,
    'DEEPEST-JUNGLE.TWO.LEAF.LEFT.': 1004,
    'DEEPEST-JUNGLE.TWO.LEAF.LEFT.TOP.BOTTOM.': 1004,
    'DEEPEST-JUNGLE.TWO.LEAF.LEFT.RIGHT.TOP.BOTTOM.HARVEST': 1005,
    'DEEPEST-JUNGLE.THREE.LEAF.LEFT.RIGHT.TOP.BOTTOM.HARVEST': 1006,
    'JUNGLE-PLANT.5': 1007,
    'FUR.RIGHT.TOP.': 1008,
    'OVERGROWN-FUR.LEAF.RIGHT.TOP.': 1008,
    'FUR.LEFT.RIGHT.TOP.': 1009,
    'OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.': 1009,
    'FUR.LEFT.TOP.': 1010,
    'OVERGROWN-FUR.LEAF.LEFT.TOP.': 1010,
    'CLOUDBERRY.HARVEST': 1011,
    'ALBINO-FUR.RIGHT.TOP.BOTTOM.': 1012,
    'ALBINO-FUR.LEFT.RIGHT.TOP.BOTTOM.': 1013,
    'ALBINO-FUR.LEFT.TOP.BOTTOM.': 1014,
    'DISCORDANT-YELLOW.RIGHT.TOP.BOTTOM.': 1015,
    'DISCORDANT-YELLOW.LEFT.RIGHT.TOP.BOTTOM.WEST': 1016,
    'DISCORDANT-YELLOW.LEFT.RIGHT.TOP.BOTTOM.BIG': 1017,
    'ORDEAL.LEAF.RIGHT.TOP.BOTTOM.': 1018,
    'ORDEAL.LEAF.LEFT.RIGHT.TOP.BOTTOM.': 1019,
    'ORDEAL.LEAF.LEFT.TOP.BOTTOM.': 1020,
    'ORDEAL.BRANCH.RIGHT.': 1021,
    'ORDEAL.LEAF.LEFT.': 1022,
    'OVERGROWN-FUR.ONE.B1': 1023,
    'OVERGROWN-FUR.ONE.B2': 1024,
    'OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.BOTTOM.BL-GAP': 1025,
    'OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.BOTTOM.BR-GAP': 1026,
    'OVERGROWN-FUR.BRANCH.LEFT.RIGHT.TOP.BOTTOM.VERTICAL-UP': 1027,
    'OLD-GROWTH.FIVE.C1': 1028,
    'OLD-GROWTH.FIVE.C2': 1029,
    'OLD-GROWTH.FIVE.C3': 1030,
    'OLD-GROWTH.SIX.B1': 1031,
    'OLD-GROWTH.SIX.B2': 1032,
    'OLD-GROWTH.SIX.B3': 1033,
    'OLD-GROWTH.SEVEN.B1': 1034,
    'OLD-GROWTH.SEVEN.B2': 1035,
    'OLD-GROWTH.SEVEN.B3': 1036,
    'OLD-GROWTH.LEAF.LEFT.RIGHT.TOP.': 1037,
    'OLD-GROWTH.EIGHT.B1': 1038,
    'OLD-GROWTH.EIGHT.B2': 1039,
    'OLD-GROWTH.THREE.D1': 1040,
    'OLD-GROWTH.THREE.D2': 1041,
    'OLD-GROWTH.THREE.D3': 1042,
    'OLD-GROWTH.THREE.D4': 1043,
    'OLD-GROWTH.THREE.D5': 1044,
    'DEEPEST-JUNGLE.ONE.TRUNK.B1': 1045,
    'DEEPEST-JUNGLE.ONE.TRUNK.B2': 1046,
    'DEEPEST-JUNGLE.TWO.TRUNK.B1': 1047,
    'DEEPEST-JUNGLE.TWO.TRUNK.B2': 1048,
    'DEEPEST-JUNGLE.THREE.TRUNK.B1': 1049,
    'DEEPEST-JUNGLE.THREE.TRUNK.B2': 1050,
    'DEEPEST-JUNGLE.TWO.LEAF.BOTTOM.': 1051,
    'DEEPEST-JUNGLE.TWO.LEAF.LEFT.RIGHT.BOTTOM.': 1052,
    'DEEPEST-JUNGLE.THREE.LEAF.BOTTOM.': 1053,
    'DEEPEST-JUNGLE.THREE.LEAF.LEFT.RIGHT.BOTTOM.': 1054,
    'JUNGLE-PLANT.4': 1055,
    'FUR.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-BOTTOM': 1056,
    'OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-BOTTOM': 1056,
    'LINGONBERRY.HARVEST': 1057,
    'FUR.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-TOP': 1058,
    'OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.BOTTOM.CONNECTED-TOP': 1058,
    'LINGONBERRY.OFFSEASON': 1059,
    'ALBINO-FUR.RIGHT.TOP.': 1060,
    'ALBINO-FUR.LEFT.RIGHT.TOP.': 1061,
    'ALBINO-FUR.LEFT.TOP.': 1062,
    'DISCORDANT-YELLOW.RIGHT.TOP.': 1063,
    'DISCORDANT-YELLOW.LEFT.RIGHT.TOP.': 1064,
    'DISCORDANT-YELLOW.LEFT.RIGHT.TOP.BOTTOM.DOWN.': 1065,
    'ORDEAL.LEAF.RIGHT.TOP.': 1066,
    'ORDEAL.LEAF.LEFT.RIGHT.TOP.': 1067,
    'ORDEAL.LEAF.LEFT.TOP.': 1068,
    'ORDEAL.BRANCH.TOP.': 1069,
    'ORDEAL.BRANCH.LEFT.': 1070,
    'OVERGROWN-FUR.TWO.A1': 1071,
    'OVERGROWN-FUR.TWO.A2': 1072,
    'OVERGROWN-FUR.TWO.A3': 1073,
    'OVERGROWN-FUR.LEAF.RIGHT.': 1074,
    'OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.BOTTOM.': 1074,
    'OVERGROWN-FUR.BRANCH.LEFT.RIGHT.TOP.BOTTOM.VERTICAL-DOWN': 1075,
    'OLD-GROWTH.NINE.A1': 1076,
    'OLD-GROWTH.NINE.A2': 1077,
    'OLD-GROWTH.NINE.A3': 1078,
    'OLD-GROWTH.NINE.A4': 1079,
    'OLD-GROWTH.SIX.C1': 1080,
    'OLD-GROWTH.SIX.C2': 1081,
    'OLD-GROWTH.SIX.C3': 1082,
    'OLD-GROWTH.SEVEN.C1': 1083,
    'OLD-GROWTH.SEVEN.C2': 1084,
    'OLD-GROWTH.TEN.A1': 1085,
    'OLD-GROWTH.TEN.A2': 1086,
    'OLD-GROWTH.EIGHT.C1': 1087,
    'OLD-GROWTH.EIGHT.A2': 1088,
    'OLD-GROWTH.THREE.E1': 1089,
    'OLD-GROWTH.THREE.E2': 1090,
    'OLD-GROWTH.THREE.E3': 1091,
    'JUNGLE-PLANT.3': 1092,
    'DEEPEST-JUNGLE.ONE.LEAF.LEFT.RIGHT.TOP.BOTTOM.': 1093,
    'DEEPEST-JUNGLE.ONE.LEAF.LEFT.RIGHT.TOP.BOTTOM.HARVEST': 1093,
    'DEEPEST-JUNGLE.ONE.LEAF.RIGHT.TOP.BOTTOM.': 1093,
    'DEEPEST-JUNGLE.ONE.LEAF.LEFT.TOP.BOTTOM.': 1093,
    'DEEPEST-JUNGLE.ONE.LEAF.TOP.BOTTOM.': 1094,
    'DEEPEST-JUNGLE.TWO.LEAF.LEFT.RIGHT.TOP.BOTTOM.': 1095,
    'DEEPEST-JUNGLE.TWO.LEAF.TOP.BOTTOM.': 1096,
    'DEEPEST-JUNGLE.THREE.LEAF.LEFT.RIGHT.TOP.BOTTOM.': 1097,
    'DEEPEST-JUNGLE.THREE.LEAF.RIGHT.TOP.BOTTOM.': 1097,
    'DEEPEST-JUNGLE.THREE.LEAF.LEFT.TOP.BOTTOM.': 1097,
    'DEEPEST-JUNGLE.THREE.LEAF.TOP.BOTTOM.': 1098,
    'DEEPEST-JUNGLE.TWO.LEAF.TOP.': 1099,
    'DEEPEST-JUNGLE.TWO.LEAF.LEFT.RIGHT.TOP.': 1100,
    'DEEPEST-JUNGLE.THREE.LEAF.TOP.': 1101,
    'DEEPEST-JUNGLE.THREE.LEAF.LEFT.RIGHT.TOP.': 1102,
    'DEEPEST-JUNGLE.VINE.RIGHT.BOTTOM.': 1103,
    'DISCORDANT-PINK.LEFT.RIGHT.TOP.BOTTOM.DOWN.': 1104,
    'DISCORDANT-YELLOW.LEFT.': 1105,
    'DISCORDANT_MONKEY': 1106,
    'CAPYBARA': 1107,
    'DISCORDANT.CAPYBARA.PINK': 1108,
    'DISCORDANT-PINK.LEFT.RIGHT.BOTTOM': 1109,
    'DISCORDANT-PINK.LEFT.RIGHT.TOP.BOTTOM.EAST': 1110,
    'DISCORDANT-PINK.LEFT.BOTTOM.': 1111,
    'DISCORDANT-YELLOW.LEFT.BOTTOM.': 1112,
    'DISCORDANT-YELLOW.LEFT.RIGHT.TOP.BOTTOM.EAST': 1113,
    'ORDEAL.TRUNK.ONE.A1': 1114,
    'ORDEAL.TRUNK.ONE.A2': 1115,
    'ORDEAL.TRUNK.TWO.A1': 1116,
    'ORDEAL.TRUNK.TWO.A2': 1117,
    'ORDEAL.LEAF.BOTTOM.': 1118,
    'OVERGROWN-FUR.TWO.B1': 1119,
    'OVERGROWN-FUR.TWO.B2': 1120,
    'OVERGROWN-FUR.TWO.B3': 1121,
    'OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.BOTTOM.HORIZONTAL-LEFT': 1122,
    'OVERGROWN-FUR.THREE.A1': 1123,
    'OVERGROWN-FUR.THREE.A2': 1124,
    'OLD-GROWTH.NINE.B1': 1125,
    'OLD-GROWTH.NINE.B2': 1126,
    'OLD-GROWTH.NINE.B3': 1127,
    'OLD-GROWTH.ELEVEN.A1': 1128,
    'OLD-GROWTH.ELEVEN.A2': 1129,
    'OLD-GROWTH.TWELVE.A1': 1130,
    'OLD-GROWTH.TWELVE.A2': 1131,
    'OLD-GROWTH.TWELVE.A3': 1132,
    'OLD-GROWTH.TEN.B1': 1133,
    'OLD-GROWTH.TEN.B2': 1134,
    'OLD-GROWTH.TEN.B3': 1135,
    'OLD-GROWTH.EIGHT.B3': 1136,
    'DEEPEST-JUNGLE.ONE.LEAF.RIGHT.BOTTOM.': 1137,
    'DEEPEST-JUNGLE.ONE.LEAF.LEFT.BOTTOM.': 1138,
    'DEEPEST-JUNGLE.ONE.LEAF.BOTTOM.': 1139,
    'DEEPEST-JUNGLE.ONE.LEAF.LEFT.RIGHT.BOTTOM.': 1140,
    'DEEPEST-JUNGLE.ONE.LEAF.LEFT.TOP.BOTTOM.': 1141,
    'DEEPEST-JUNGLE.ONE.LEAF.LEFT.RIGHT.': 1142,
    'DEEPEST-JUNGLE.ONE.LEAF.LEFT.RIGHT.TOP.BOTTOM.HORIZONTAL-LEFT': 1142,
    'DEEPEST-JUNGLE.TWO.LEAF.LEFT.RIGHT.': 1143,
    'DEEPEST-JUNGLE.TWO.LEAF.LEFT.RIGHT.TOP.BOTTOM.HORIZONTAL-LEFT': 1144,
    'DEEPEST-JUNGLE.THREE.LEAF.LEFT.RIGHT.': 1145,
    'DEEPEST-JUNGLE.THREE.LEAF.LEFT.RIGHT.TOP.BOTTOM.HORIZONTAL-LEFT': 1146,
    'DEEPEST-JUNGLE.THREE.LEAF.RIGHT.BOTTOM.': 1147,
    'DEEPEST-JUNGLE.THREE.LEAF.LEFT.BOTTOM.': 1148,
    'DEEPEST-JUNGLE.THREE.LEAF.RIGHT.': 1149,
    'DEEPEST-JUNGLE.THREE.LEAF.LEFT.': 1150,
    'DEEPEST-JUNGLE.VINE.TOP.BOTTOM.': 1151,
    'DEEPEST-JUNGLE.VINE.TOP.': 1151,
    'DEEPEST-JUNGLE.VINE.BOTTOM.': 1151,
    'DISCORDANT-PINK.RIGHT.BOTTOM.': 1152,
    'DISCORDANT-PINK.RIGHT.TOP.BOTTOM.': 1153,
    'DISCORDANT-PINK.RIGHT.TOP.': 1154,
    'DISCORDANT-PINK.LEFT.RIGHT.TOP.': 1155,
    'DISCORDANT-PINK.LEFT.RIGHT.TOP.BOTTOM.UP.': 1156,
    'DISCORDANT-PINK.LEFT.TOP.': 1157,
    'DISCORDANT-PINK.LEFT.RIGHT.TOP.BOTTOM.WEST': 1158,
    'DISCORDANT-PINK.LEFT.RIGHT.TOP.BOTTOM.BIG': 1159,
    'DISCORDANT-PINK.LEFT.': 1160,
    'DISCORDANT-YELLOW.LEFT.TOP.': 1161,
    'ORDEAL.TRUNK.ONE.B1': 1162,
    'ORDEAL.TRUNK.ONE.B2': 1163,
    'ORDEAL.TRUNK.TWO.B1': 1164,
    'ORDEAL.TRUNK.TWO.B2': 1165,
    'ORDEAL.LEAF.TOP.': 1166,
    'OVERGROWN-FUR.TWO.C1': 1167,
    'OVERGROWN-FUR.TWO.C2': 1168,
    'OVERGROWN-FUR.LEAF.LEFT.RIGHT.TOP.BOTTOM.HORIZONTAL-RIGHT': 1169,
    'OVERGROWN-FUR.THREE.B1': 1170,
    'OVERGROWN-FUR.THREE.B2': 1171,
    'OVERGROWN-FUR.THREE.B3': 1172,
    'OLD-GROWTH.NINE.C1': 1173,
    'OLD-GROWTH.NINE.C2': 1174,
    'OLD-GROWTH.NINE.C3': 1175,
    'OLD-GROWTH.ELEVEN.B1': 1176,
    'OLD-GROWTH.ELEVEN.B2': 1177,
    'OLD-GROWTH.TWELVE.B1': 1178,
    'OLD-GROWTH.TWELVE.B2': 1179,
    'OLD-GROWTH.LEAF.LEFT.RIGHT.TOP.BOTTOM.': 1180,
    'OLD-GROWTH.LEAF.LEFT.RIGHT.': 1180,
    'OLD-GROWTH.LEAF.NONE.': -1,
    'OLD-GROWTH.LEAF.TOP.BOTTOM.': 1180,
    'OLD-GROWTH.TEN.C1': 1181,
    'OLD-GROWTH.TEN.C2': 1182,
    'OLD-GROWTH.LEAF.LEFT.RIGHT.TOP.BOTTOM.VERTICAL': 1183,
    'OLD-GROWTH.EIGHT.C2': 1184,
    'DEEPEST-JUNGLE.ONE.LEAF.RIGHT.TOP.': 1185,
    'DEEPEST-JUNGLE.ONE.LEAF.LEFT.TOP.': 1186,
    'DEEPEST-JUNGLE.ONE.LEAF.LEFT.RIGHT.TOP.': 1187,
    'DEEPEST-JUNGLE.ONE.LEAF.TOP.': 1188,
    'DEEPEST-JUNGLE.ONE.LEAF.NONE.': -1,
    'DEEPEST-JUNGLE.TWO.LEAF.NONE.': -1,
    'DEEPEST-JUNGLE.THREE.LEAF.NONE.': -1,
    'DEEPEST-JUNGLE.ONE.LEAF.RIGHT.': 1189,
    'DEEPEST-JUNGLE.ONE.LEAF.LEFT.': 1190,
    'DEEPEST-JUNGLE.TWO.LEAF.RIGHT.BOTTOM.': 1191,
    'DEEPEST-JUNGLE.TWO.LEAF.LEFT.BOTTOM.': 1192,
    'DEEPEST-JUNGLE.TWO.LEAF.RIGHT.TOP.': 1193,
    'DEEPEST-JUNGLE.TWO.LEAF.LEFT.TOP.': 1194,
    'DEEPEST-JUNGLE.THREE.LEAF.RIGHT.TOP.': 1195,
    'DEEPEST-JUNGLE.THREE.LEAF.LEFT.TOP.': 1196,
    'DEEPEST-JUNGLE.VINE.LEFT.RIGHT.': 1197,
    'DEEPEST-JUNGLE.VINE.NONE.': 1197,
    'DEEPEST-JUNGLE.VINE.RIGHT.': 1197,
    'DEEPEST-JUNGLE.VINE.LEFT.': 1197,
    'DEEPEST-JUNGLE.VINE.LEFT.BOTTOM.': 1198,
    'DEEPEST-JUNGLE.VINE.RIGHT.TOP.': 1199,
    'SNAPJAW': 1200,
    'POISONER': 1201,
    'SWARMER': 1202,
    'TROLL': 1203,
    'SCARY': 1204,
    'SHOOTER': 1205,
    'ARROW': 1206,
    'AOE': 1207,
    'RUSTER': 1208,
    'SWAPPER': 1209,
    'PHASER': 1210,
    'PHASED_SHOOTER': 1211,
    'DISARMER': 1212,
    'LIFE_LEECH': 1213,
    'SLUGGISHER': 1214,
    'HP_HALVER': 1215,
    'TELEPORTER': 1216,
    'HORN_BLOWER': 1217,
    'BEASTIE_SPAWNER': 1218,
    'BEASTIE': 1219,
    'DEATH_SONG': 1220,
    'TELEPORT_LEVEL': 1221,
    'BLINDNESSER': 1222,
    'WEB_SHOOTER': 1223,
    'WEB': 1224,
    'ACID_SPLASH': 1225,
    'ACID-PROJECTILE': 1226,
    'ACID-PUDDLE': 1227,
    'Kitchen.A1': 1228,
    'LEOPARD': 1229,
    'THISTLE-BUSH.HARVEST': 1230,
    'THISTLE-BUSH': 1231,
    'ELEPHANT-GRASS': 1232,
    'TALL-GRASS': 1233,
    'KOHLRABI.SEED': 1234,
    'KOHLRABI.YOUNG': 1235,
    'KOHLRABI.OLD': 1236,
    'KOHLRABI.HARVEST': 1237,
    'OKAPI': 1238,
    'CHIMPANZEE': 1239,
    'HOWLER_MONKEY': 1240,
    'DEEPEST-JUNGLE.VINE.LEFT.RIGHT.TOP.': 1241,
    'DEEPEST-JUNGLE.VINE.RIGHT.TOP.BOTTOM.': 1242,
    'DEEPEST-JUNGLE.VINE.LEFT.RIGHT.BOTTOM.': 1243,
    'DEEPEST-JUNGLE.VINE.LEFT.TOP.': 1244,
    'DEEPEST-JUNGLE.VINE.LEFT.RIGHT.': 1245,
    'DEEPEST-JUNGLE.VINE.LEFT.TOP.BOTTOM.': 1246,
    'DEEPEST-JUNGLE.VINE.LEFT.RIGHT.TOP.BOTTOM.': 1247,
    'FIRE_SHOOTER': 1248,
    'FIRE-PROJECTILE': 1249,
    'FIRE-PUDDLE': 1250,
    'MINE-WALL.BOTTOM.': 1251,
    'MINE-WALL.LEFT.RIGHT.TOP.BOTTOM.BR-GAP': 1252,
    'MINE-WALL.LEFT.RIGHT.TOP.BOTTOM.BR-TL-GAP': 1252,
    'MINE-WALL.LEFT.RIGHT.TOP.': 1253,
    'MINE-WALL.LEFT.RIGHT.TOP.BOTTOM.BL-GAP': 1254,
    'MINE-WALL.LEFT.RIGHT.TOP.BOTTOM.TR-BL-GAP': 1254,
    'MINE-WALL.LEFT.TOP.': 1255,
    'MINE-WALL.LEFT.RIGHT.TOP.BOTTOM.BR-BL-GAP': 1256,
    'MINE-WALL.RIGHT.TOP.': 1257,
    'MINE-WALL.RIGHT.BOTTOM.BR-GAP': 1258,
    'MINE-WALL.LEFT.RIGHT.BOTTOM.BR-BL-GAP': 1259,
    'MINE-WALL.LEFT.BOTTOM.BL-GAP': 1260,
    'MINE-WALL.LEFT.RIGHT.BOTTOM.BR-GAP': 1261,
    'MINE-WALL.LEFT.RIGHT.BOTTOM.BL-GAP': 1262,
    'FOSSILISED.LEAF.RIGHT.TOP.BOTTOM.': 1263,
    'FOSSILISED.LEAF.LEFT.TOP.BOTTOM.': 1264,
    'STONE-DECATION.1': 1296,
    'STONE-DECATION.2': 1297,
    'STAIRS.UP': 1298,
    'Staircase': 1298,
    'Mansion Staircase': 1298,
    'Alien Staircase': 1298,
    'MINE-WALL.TOP.BOTTOM.': 1299,
    'MINE-WALL.LEFT.TOP.BOTTOM.': 1300,
    'STAIRS.DOWN': 1301,
    'Staircase down': 1301,
    'Mansion Staircase down': 1301,
    'Alien Staircase down': 1301,
    'MINE-WALL.RIGHT.TOP.BOTTOM.': 1302,
    'MINE-WALL.LEFT.RIGHT.TOP.BOTTOM.': 1303,
    'MINE-WALL.RIGHT.TOP.BOTTOM.BR-GAP': 1304,
    'MINE-WALL.LEFT.RIGHT.TOP.BOTTOM.BL-TL-GAP': 1305,
    'MINE-WALL.LEFT.RIGHT.TOP.BOTTOM.BL-TL-GAP': 1305,
    'MINE-WALL.RIGHT.TOP.BOTTOM.TR-BR-GAP': 1306,
    'MINE-WALL.LEFT.RIGHT.TOP.BOTTOM.TR-BR-GAP': 1307,
    'MINE-WALL.LEFT.RIGHT.TOP.BOTTOM.BR-BL-TL-GAP': 1307,
    'MINE-WALL.LEFT.RIGHT.TOP.BOTTOM.TR-BR-TL-GAP': 1307,
    'MINE-WALL.LEFT.RIGHT.TOP.BOTTOM.TR-BR-BL-GAP': 1307,
    'MINE-WALL.LEFT.RIGHT.TOP.BOTTOM.TR-BR-BL-TL-GAP': 1307,
    'MINE-WALL.LEFT.TOP.BOTTOM.BL-TL-GAP': 1308,
    'MINE-WALL.LEFT.RIGHT.TOP.TL-GAP': 1309,
    'MINE-WALL.RIGHT.TOP.BOTTOM.TR-GAP': 1310,
    'MINE-WALL.LEFT.': 1346,
    'MINE-WALL.LEFT.RIGHT.': 1345,
    'MINE-WALL.RIGHT.': 1344,
    'MINE-WALL.TOP.': 1347,
    'MINE-WALL.LEFT.RIGHT.TOP.BOTTOM.TR-GAP': 1348,
    'MINE-WALL.LEFT.RIGHT.BOTTOM.': 1349,
    'MINE-WALL.LEFT.RIGHT.TOP.BOTTOM.TL-GAP': 1350,
    'MINE-WALL.LEFT.BOTTOM.': 1351,
    'MINE-WALL.LEFT.RIGHT.TOP.BOTTOM.TR-TL-GAP': 1352,
    'MINE-WALL.RIGHT.BOTTOM.': 1353,
    'MINE-WALL.RIGHT.TOP.TR-GAP': 1354,
    'MINE-WALL.LEFT.RIGHT.TOP.TR-TL-GAP': 1355,
    'MINE-WALL.LEFT.RIGHT.TOP.TR-GAP': 1355,
    'MINE-WALL.LEFT.TOP.TL-GAP': 1356,
    'MINE-WALL.LEFT.TOP.BOTTOM.TL-GAP': 1357,
    'MINE-WALL.LEFT.TOP.BOTTOM.BL-GAP': 1358,
    // SPACE STUFFS
    'SPACE': 1466,
    'SPACE_2': 1466,
    'SPACE_3': 1466,
    'SPACE_4': 1466,
    // SPACE WALLS
    'SPACE.NONE': 1,
    'SPACE.LEFT.': 23,
    'SPACE.LEFT.RIGHT.': 2,
    'SPACE.LEFT.RIGHT.2': 8,
    'SPACE.LEFT.RIGHT.3': 25,
    'SPACE.LEFT.RIGHT.4': 42,
    'SPACE.LEFT.RIGHT.5': 59,
    'SPACE.LEFT.RIGHT.6': 76,
    'SPACE.LEFT.RIGHT.TOP.': 41,
    'SPACE.LEFT.RIGHT.TOP.BOTTOM.': 23,
    'SPACE.LEFT.RIGHT.BOTTOM.': 19,
    'SPACE.LEFT.TOP.': 4,
    'SPACE.LEFT.TOP.BOTTOM.': 24,
    'SPACE.LEFT.BOTTOM.': 3,
    'SPACE.RIGHT.': 21,
    //'SPACE.RIGHT.TOP.': 6,
    'SPACE.RIGHT.TOP.': 35,
    'SPACE.RIGHT.TOP.BOTTOM.': 7,
    'SPACE.RIGHT.BOTTOM.': 1,
    'SPACE.TOP.': 39,
    'SPACE.TOP.BOTTOM.': 18,
    'SPACE.TOP.BOTTOM.': 20,
    'SPACE.BOTTOM.': 5,
    // SPACE DOOR
    'SPACE.TELEPORTER': 9,
    'SPACE.BED': 9,
    'SPACE.OUR-BED': 79,
    'SPACE.BOX-CHAIR': 62,
    'SPACE.COMPUTER': 26,
    'SPACE.PC': 63,
    'SPACE.DESK': 43,
    'SPACE.CHAIR': 60,
    'SPACE.DOOR': 52,
    'INNER_OUTSIDE.LEFT': 52,
    'INNER_OUTSIDE.LEFT.2': 55,
    'INNER_OUTSIDE.TOP': 53,
    'INNER_OUTSIDE.RIGHT': 54,
    'CENTER_OUTSIDE.RIGHT': 56,
    'OUTER_OUTSIDE.RIGHT': 57,
    'OUTER_BACKGROUND.RIGHT.BOTTOM': 68,
    'OUTER_BACKGROUND.LEFT.RIGHT': 69,
    'OUTER_BACKGROUND.LEFT.BOTTOM': 70,
    'OUTER_BACKGROUND.RIGHT.TOP': 71,
    'OUTER_BACKGROUND.LEFT.TOP': 72,
    'Space Floor': 77,
    'Space Fill': 31,
    'CAPTAIN': 110,
    'MIDSHIP': 111,
    'DYSTOPIA.RIGHT.TOP.': 10,
    'DYSTOPIA.LEFT.BOTTOM.': 11,
    'DYSTOPIA.TOP.BOTTOM.': 12,
    'DYSTOPIA.RIGHT.TOP.BOTTOM.': 32,
    'DYSTOPIA.LEFT.RIGHT.': 28,
    'DYSTOPIA.LEFT.TOP.': 29,
    'DYSTOPIA.RIGHT.BOTTOM.': 44,
    'DYSTOPIA.LEFT.RIGHT.2': 45,
    'DYSTOPIA.LEFT.RIGHT.3': 46,
    'DYSTOPIA.BOTTOM.': 61,//Door
    'DYSTOPIA.CHAIR': 62,
    'DYSTOPIA.COMPUTER': 63,
    // WAGONS
    // 'Wagon': 26,
    'Kayak': 26,
    // TENTS
    'Tent': 26,
    // TILES
    'Web': 1224,
    'Fire': 50,
    'Acid': 1227,
    'Lava': 50,
    // BULLETS
    'Bullet': 1044,
    // WORKSHOPS
    'Carpenters.A1': 1265,
    'Carpenters.A2': 1266,
    'Blacksmiths.A1': 1267,
    'Blacksmiths.A2': 1268,
    'Blacksmiths.A3': 1269,
    'Masons.B1': 1270,
    'Apothecary.A2': 1271,
    'Apothecary.A3': 1272,
    'Tailors.B3': 1273,
    'Tailors.C1': 1274,
    'Tailors.C2': 1275,
    'Kitchen.B1': 1276,
    'Kitchen.B2': 1277,
    'Builders.A1': 1278,
    'Builders.A3': 1279,
    'Colliers.A2': 1280,
    'Potters.A1': 1311,
    'Potters.A2': 1312,
    'Blacksmiths.B3': 1313,
    'Carpenters.B2': 1314,
    'Doctors.C1': 1315,
    'Jewellers.A1': 1316,
    'Jewellers.A2': 1317,
    'Apothecary.B1': 1318,
    'Tanners.A1': 1319,
    'Masons.A2': 1320,
    'Masons.A3': 1321,
    'Dyers.B1': 1322,
    'Colliers.A1': 1323,
    'Shipwright.B1': 1324,
    'Shipwright.B2': 1325,
    'Builders.B1': 1326,
    'Builders.B3': 1327,
    'Colliers.B2': 1328,
    'Blacksmiths.C1': 1359,
    'Potters.B1': 1360,
    'Doctors.A1': 1361,
    'Doctors.A2': 1362,
    'Doctors.A3': 1363,
    'Doctors.C3': 1364,
    'Jewellers.B2': 1365,
    'Tanners.A3': 1366,
    'Tanners.B1': 1367,
    'Masons.C2': 1368,
    'Masons.C3': 1369,
    'Dyers.C2': 1370,
    'Dyers.C3': 1371,
    'Shipwright.C1': 1372,
    'Shipwright.C2': 1373,
    'Shipwright.C3': 1374,
    'Builders.C3': 1375,
    // Minerals
    'Glass-Salt': 93,
    'Granite': 141,
    'Limestone': 94,
    'Claystone': 142,
    'Marble': 95,
    'Basalt': 143,
    'Lead Ore': 320,
    'Gold Ore': 232,
    'Silver Ore': 278,
    'Garnet': 281,
    'Ruby': 328,
    'Aquamarine': 311,
    'Diamond': 1376,
    'Emerald': 1281,
    'Peridot': 1282,
    'Citrine': 1283,
    'Janssium Ore': 1284,
    'Exuperium Ore': 1285,
    'Tolkium Ore': 1286,
    'Octavium Ore': 1287,
    'Moonstone': 1329,
    'Sapphire': 1330,
    'Turquoise': 1331,
    'Matsunium Ore': 1332,
    'Moebium Ore': 1333,
    'Lowrium Ore': 1334,
    'Amethyst': 1377,
    'Opal': 1378,
    'Amber': 1379,
    'Sugarium Ore': 1380,
    'Waldium Ore': 1381,
    'Ursulium Ore': 1382,

    // Furniture
    'Terminal': 1554,

    // Tents
    'TENT.CANVAS.LEFT-WALL.TOP.BOTTOM.': 1288,
    'TENT.CANVAS.TOP-WALL.RIGHT.BOTTOM.': 1289,
    'TENT.CANVAS.TOP-WALL.LEFT.RIGHT.': 1290,
    'TENT.CANVAS.TOP-WALL.LEFT.BOTTOM.': 1291,
    'TENT.CANVAS.RIGHT-WALL.TOP.BOTTOM.': 1292,
    'TENT.GOATI.LEFT-WALL.TOP.BOTTOM.': 1293,
    'TENT.GOATI.TOP-WALL.RIGHT.BOTTOM.': 1294,
    'TENT.GOATI.TOP-WALL.LEFT.RIGHT.': 1295,
    'TENT.CANVAS.LEFT-WALL.LEFT.BOTTOM.': 1335,
    'TENT.CANVAS.BOTTOM-WALL.RIGHT.TOP.': 1336,
    'TENT.CANVAS.LEFT-WALL.LEFT.TOP.': 1337,
    'TENT.CANVAS.BOTTOM-WALL.LEFT.RIGHT.': 1338,
    'TENT.CANVAS.RIGHT-WALL.RIGHT.TOP.': 1339,
    'TENT.CANVAS.BOTTOM-WALL.LEFT.TOP.': 1340,
    'TENT.GOATI.BOTTOM-WALL.RIGHT.TOP.': 1341,
    'TENT.GOATI.LEFT-WALL.LEFT.TOP.': 1342,
    'TENT.GOATI.BOTTOM-WALL.LEFT.RIGHT.': 1343,
    'TENT.CANVAS.RIGHT-WALL.RIGHT.BOTTOM.': 1383,
    'TENT.GOATI.LEFT-WALL.LEFT.BOTTOM.': 1385,
    'TENT.GOATI.RIGHT-WALL.RIGHT.BOTTOM.': 1386,
    'TENT.GOATI.TOP-WALL.LEFT.BOTTOM.': 1387,
    'TENT.GOATI.RIGHT-WALL.RIGHT.TOP.': 1388,
    'TENT.GOATI.BOTTOM-WALL.LEFT.TOP.': 1389,
    'TENT.GOATI.RIGHT-WALL.TOP.BOTTOM.': 1390,
    'TENT.GER.TOP-WALL.RIGHT.BOTTOM.': 1392,
    'TENT.GER.TOP-WALL.LEFT.RIGHT.': 1393,
    'TENT.GER.TOP-WALL.LEFT.BOTTOM.': 1394,
    'TENT.WIGWAM.TOP-WALL.RIGHT.BOTTOM.': 1395,
    'TENT.WIGWAM.TOP-WALL.LEFT.RIGHT.': 1396,
    'TENT.WIGWAM.TOP-WALL.LEFT.BOTTOM.': 1397,
    'TENT.TIPI.TOP-WALL.RIGHT.BOTTOM.': 1398,
    'TENT.TIPI.TOP-WALL.LEFT.RIGHT.': 1399,
    'TENT.TIPI.TOP-WALL.LEFT.BOTTOM.': 1400,
    'TENT.LAVVU.LEFT-WALL.TOP.BOTTOM.': 1401,
    'TENT.LAVVU.TOP-WALL.RIGHT.BOTTOM.': 1402,
    'TENT.LAVVU.TOP-WALL.LEFT.RIGHT.': 1403,
    'TENT.LAVVU.TOP-WALL.LEFT.BOTTOM.': 1404,
    'TENT.LAVVU.RIGHT-WALL.TOP.BOTTOM.': 1405,
    'TENT.LAVVU.LEFT-WALL.LEFT.BOTTOM.': 1406,
    'TENT.CHUM.LEFT-WALL.TOP.BOTTOM.': 1407,
    'TENT.CHUM.TOP-WALL.RIGHT.BOTTOM.': 1408,
    'TENT.CHUM.TOP-WALL.LEFT.RIGHT.': 1409,
    'TENT.CHUM.TOP-WALL.LEFT.BOTTOM.': 1410,
    'TENT.CHUM.RIGHT-WALL.TOP.BOTTOM.': 1411,
    'TENT.CHUM.LEFT-WALL.LEFT.BOTTOM.': 1412,
    'TENT.IGLOO.RIGHT.BOTTOM.': 1413,
    'TENT.IGLOO.LEFT.RIGHT.BOTTOM.': 1414,
    'TENT.IGLOO.LEFT.BOTTOM.': 1415,
    'TENT.IGLOO.RIGHT.TOP.': 1416,
    'TENT.IGLOO.LEFT.RIGHT.TOP.': 1417,
    'TENT.IGLOO.LEFT.TOP.': 1418,
    'TENT.IGLOO.BOTTOM.': 1419,
    'TENT.IGLOO.TOP.': 1420,
    'TENT.PIT-HOUSE.RIGHT.BOTTOM.': 1421,
    'TENT.PIT-HOUSE.LEFT.RIGHT.BOTTOM.': 1422,
    'TENT.PIT-HOUSE.LEFT.BOTTOM.': 1423,
    'TENT.PIT-HOUSE.RIGHT.TOP.': 1424,
    'TENT.PIT-HOUSE.LEFT.RIGHT.TOP.': 1425,
    'TENT.PIT-HOUSE.LEFT.TOP.': 1426,
    'TENT.PIT-HOUSE.BOTTOM.': 1427,
    'TENT.SOD-HOUSE.RIGHT.BOTTOM.': 1428,
    'TENT.SOD-HOUSE.LEFT.RIGHT.BOTTOM.': 1429,
    'TENT.SOD-HOUSE.LEFT.BOTTOM.': 1430,
    'TENT.SOD-HOUSE.RIGHT.TOP.': 1431,
    'TENT.SOD-HOUSE.LEFT.RIGHT.TOP.': 1432,
    'TENT.SOD-HOUSE.LEFT.TOP.': 1433,
    'TENT.SOD-HOUSE.BOTTOM.': 1434,
    'TENT.SOD-HOUSE.TOP.': 1435,
    'TENT.GER.BOTTOM-WALL.RIGHT.TOP.': 1440,
    'TENT.GER.BOTTOM-WALL.LEFT.RIGHT.': 1441,
    'TENT.GER.BOTTOM-WALL.LEFT.TOP.': 1442,
    'TENT.WIGWAM.BOTTOM-WALL.RIGHT.TOP.': 1443,
    'TENT.WIGWAM.BOTTOM-WALL.LEFT.RIGHT.': 1444,
    'TENT.WIGWAM.BOTTOM-WALL.LEFT.TOP.': 1445,
    'TENT.TIPI.BOTTOM-WALL.RIGHT.TOP.': 1446,
    'TENT.TIPI.BOTTOM-WALL.LEFT.RIGHT.': 1447,
    'TENT.TIPI.BOTTOM-WALL.LEFT.TOP.': 1448,
    'TENT.LAVVU.BOTTOM-WALL.RIGHT.TOP.': 1449,
    'TENT.LAVVU.LEFT-WALL.LEFT.TOP.': 1450,
    'TENT.LAVVU.BOTTOM-WALL.LEFT.RIGHT.': 1451,
    'TENT.LAVVU.RIGHT-WALL.RIGHT.TOP.': 1452,
    'TENT.LAVVU.BOTTOM-WALL.LEFT.TOP.': 1453,
    'TENT.LAVVU.RIGHT-WALL.RIGHT.BOTTOM.': 1454,
    'TENT.CHUM.BOTTOM-WALL.RIGHT.TOP.': 1455,
    'TENT.CHUM.LEFT-WALL.LEFT.TOP.': 1456,
    'TENT.CHUM.BOTTOM-WALL.LEFT.RIGHT.': 1457,
    'TENT.CHUM.RIGHT-WALL.RIGHT.TOP.': 1458,
    'TENT.CHUM.BOTTOM-WALL.LEFT.TOP.': 1459,
    'TENT.CHUM.RIGHT-WALL.RIGHT.BOTTOM.': 1460,
    'TENT.IGLOO.RIGHT.TOP.BOTTOM.': 1461,
    'TENT.IGLOO.LEFT.RIGHT.TOP.BOTTOM.': 1462,
    'TENT.IGLOO.LEFT.TOP.BOTTOM.': 1463,
    'TENT.IGLOO.RIGHT.': 1464,
    'TENT.IGLOO.LEFT.RIGHT.': 1465,
    'TENT.IGLOO.LEFT.': 1466,
    'TENT.IGLOO.TOP.BOTTOM.': 1467,
    'TENT.PIT-HOUSE.TOP.': 1468,
    'TENT.PIT-HOUSE.RIGHT.TOP.BOTTOM.': 1469,
    'TENT.PIT-HOUSE.LEFT.RIGHT.TOP.BOTTOM.': 1470,
    'TENT.PIT-HOUSE.LEFT.TOP.BOTTOM.': 1471,
    'TENT.PIT-HOUSE.RIGHT.': 1472,
    'TENT.PIT-HOUSE.LEFT.RIGHT.': 1473,
    'TENT.PIT-HOUSE.LEFT.': 1474,
    'TENT.PIT-HOUSE.TOP.BOTTOM.': 1475,
    'TENT.SOD-HOUSE.RIGHT.TOP.BOTTOM.': 1476,
    'TENT.SOD-HOUSE.LEFT.RIGHT.TOP.BOTTOM.': 1477,
    'TENT.SOD-HOUSE.LEFT.TOP.BOTTOM.': 1478,
    'TENT.SOD-HOUSE.RIGHT.': 1479,
    'TENT.SOD-HOUSE.LEFT.RIGHT.': 1480,
    'TENT.SOD-HOUSE.LEFT.': 1481,

    'Wooden fence.BOTTOM.': 1488,
    'Wooden fence.LEFT.BOTTOM.': 1500,
    'Wooden fence.RIGHT.TOP.BOTTOM.': 1501,
    'Wooden fence.LEFT.RIGHT.TOP.': 1496,
    'Wooden fence.LEFT.TOP.BOTTOM.': 1497,
    'Wooden fence.RIGHT.BOTTOM.': 1498,
    'Wooden fence.LEFT.RIGHT.': 1499,
    'Wooden fence.NONE.': 1499,
    'Wooden fence.TOP.': 1536,
    'Wooden fence.TOP.BOTTOM.': 1540,
    'Wooden fence.LEFT.RIGHT.BOTTOM.': 1544,
    'Wooden fence.RIGHT.': 1545,
    'Wooden fence.LEFT.': 1546,
    'Wooden fence.LEFT.RIGHT.TOP.BOTTOM.': 1547,
    'Wooden fence.RIGHT.TOP.': 1548,
    'Wooden fence.LEFT.TOP.': 1549,
    'Wooden gate.OPEN': 1550,

    'Wooden fence': 1535,

    'Wooden gate': 1502,

    // 'ERROR': 32,
    //
    'NIGHT_STALKER': 1384,
    'SHEEP': 1507,
    'HORSE': 1508,
    'HORSE.CALF': 1509,
    'CAMEL': 1510,
    'FARM_PLOT': 1511,
    'Mint.A1': 1512,
    'Mint.B3': 1560,
    'Mint.B1': 1559,
    'DEAD_CHARACTER': 1513,
    'Teleporter': 1514,
    'Wooden Door.DESERT': 1515,
    'Wooden Door.SWAMP': 1516,
    'Wooden Door.TAIGA': 1517,
    'Wooden Door.JUNGLE': 1518,
    'Wooden Door.ARID': 1519,
    'Wooden Door.OLD-GROWTH': 1520,
    'Wooden Door.RIVER': 1521,
    'Wooden Door.POLAR': 1522,
    // 
    // 
    'POISONED': 1524,
    'BURNING': 1525,
    'MOVING_1': 520,
    'MOVING_2': 521,
    'MOVING_3': 522,
    'MOVING_4': 523,

    'COMPUTER': 1554,
    'SHEEP.CALF': 1555,
    'HORSE.SADDLE': 1556,
    'CAMEL.CALF': 1557,
    'CAMEL.SADDLE': 1558,

    'HUNGRY': 1562,

    'Post Box': 1503,
    'Counter': 1504,
    'Clay Brick Floor': 1505,
    'Granite Floor': 1506,
    'Limestone Floor': 1523,

    'Mailbox': 1551,
    'Guard House': 1552,
    'Wooden Floor': 1553,
    'Marble Floor': 1571,
    'Alien Floor': 1571,
    'Mansion Floor': 1571,
    'Basalt Floor': 1572,
    'Gravel Floor': 1573,

    "Marble Brick Wall.BOTTOM.DESERT": 1584,
    "Alien Wall.BOTTOM.DESERT": 1584,
    "Mansion Wall.BOTTOM.DESERT": 1584,
    "Marble Brick Wall.RIGHT.BOTTOM.DESERT": 1585,
    "Alien Wall.RIGHT.BOTTOM.DESERT": 1585,
    "Mansion Wall.RIGHT.BOTTOM.DESERT": 1585,
    "Marble Brick Wall.LEFT.RIGHT.BOTTOM.DESERT": 1586,
    "Alien Wall.LEFT.RIGHT.BOTTOM.DESERT": 1586,
    "Mansion Wall.LEFT.RIGHT.BOTTOM.DESERT": 1586,
    "Marble Brick Wall.LEFT.BOTTOM.DESERT": 1587,
    "Alien Wall.LEFT.BOTTOM.DESERT": 1587,
    "Mansion Wall.LEFT.BOTTOM.DESERT": 1587,
    "Granite Wall.BOTTOM.DESERT": 1588,
    "Granite Wall.RIGHT.BOTTOM.DESERT": 1589,
    "Granite Wall.LEFT.RIGHT.BOTTOM.DESERT": 1590,
    "Granite Wall.LEFT.BOTTOM.DESERT": 1591,
    "Wooden Log Wall.BOTTOM.DESERT": 1592,
    "Wooden Log Wall.RIGHT.BOTTOM.DESERT": 1593,
    "Wooden Log Wall.LEFT.RIGHT.BOTTOM.DESERT": 1594,
    "Wooden Log Wall.LEFT.BOTTOM.DESERT": 1595,
    "Granite Brick Wall.BOTTOM.DESERT": 1596,
    "Granite Brick Wall.RIGHT.BOTTOM.DESERT": 1597,
    "Granite Brick Wall.LEFT.RIGHT.BOTTOM.DESERT": 1598,
    "Granite Brick Wall.LEFT.BOTTOM.DESERT": 1599,
    "Basalt Wall.BOTTOM.DESERT": 1600,
    "Basalt Wall.RIGHT.BOTTOM.DESERT": 1601,
    "Basalt Wall.LEFT.RIGHT.BOTTOM.DESERT": 1602,
    "Basalt Wall.LEFT.BOTTOM.DESERT": 1603,
    "Wooden Pallisade Wall.BOTTOM.DESERT": 1604,
    "Wooden Pallisade Wall.RIGHT.BOTTOM.DESERT": 1605,
    "Wooden Pallisade Wall.LEFT.RIGHT.BOTTOM.DESERT": 1606,
    "Wooden Pallisade Wall.LEFT.BOTTOM.DESERT": 1607,
    "Mud Brick Wall.BOTTOM.DESERT": 1608,
    "Mud Brick Wall.RIGHT.BOTTOM.DESERT": 1609,
    "Mud Brick Wall.LEFT.RIGHT.BOTTOM.DESERT": 1610,
    "Mud Brick Wall.LEFT.BOTTOM.DESERT": 1611,
    "Limestone Wall.BOTTOM.DESERT": 1612,
    "Limestone Wall.RIGHT.BOTTOM.DESERT": 1613,
    "Limestone Wall.LEFT.RIGHT.BOTTOM.DESERT": 1614,
    "Limestone Wall.LEFT.BOTTOM.DESERT": 1615,
    "Basalt Brick Wall.BOTTOM.DESERT": 1616,
    "Basalt Brick Wall.RIGHT.BOTTOM.DESERT": 1617,
    "Basalt Brick Wall.LEFT.RIGHT.BOTTOM.DESERT": 1618,
    "Basalt Brick Wall.LEFT.BOTTOM.DESERT": 1619,
    "Wattle and Daub Wall.BOTTOM.DESERT": 1620,
    "Wattle and Daub Wall.RIGHT.BOTTOM.DESERT": 1621,
    "Wattle and Daub Wall.LEFT.RIGHT.BOTTOM.DESERT": 1622,
    "Wattle and Daub Wall.LEFT.BOTTOM.DESERT": 1623,
    "Limestone Brick Wall.BOTTOM.DESERT": 1624,
    "Limestone Brick Wall.RIGHT.BOTTOM.DESERT": 1625,
    "Limestone Brick Wall.LEFT.RIGHT.BOTTOM.DESERT": 1626,
    "Limestone Brick Wall.LEFT.BOTTOM.DESERT": 1627,
    "Clay Brick Wall.BOTTOM.DESERT": 1628,
    "Clay Brick Wall.RIGHT.BOTTOM.DESERT": 1629,
    "Clay Brick Wall.LEFT.RIGHT.BOTTOM.DESERT": 1630,
    "Clay Brick Wall.LEFT.BOTTOM.DESERT": 1631,
    "Marble Brick Wall.TOP.BOTTOM.DESERT": 1632,
    "Alien Wall.TOP.BOTTOM.DESERT": 1632,
    "Mansion Wall.TOP.BOTTOM.DESERT": 1632,
    "Marble Brick Wall.RIGHT.TOP.BOTTOM.DESERT": 1633,
    "Alien Wall.RIGHT.TOP.BOTTOM.DESERT": 1633,
    "Mansion Wall.RIGHT.TOP.BOTTOM.DESERT": 1633,
    "Marble Brick Wall.LEFT.RIGHT.TOP.BOTTOM.DESERT": 1634,
    "Alien Wall.LEFT.RIGHT.TOP.BOTTOM.DESERT": 1634,
    "Mansion Wall.LEFT.RIGHT.TOP.BOTTOM.DESERT": 1634,
    "Marble Brick Wall.LEFT.TOP.BOTTOM.DESERT": 1635,
    "Alien Wall.LEFT.TOP.BOTTOM.DESERT": 1635,
    "Mansion Wall.LEFT.TOP.BOTTOM.DESERT": 1635,
    "Granite Wall.TOP.BOTTOM.DESERT": 1636,
    "Granite Wall.RIGHT.TOP.BOTTOM.DESERT": 1637,
    "Granite Wall.LEFT.RIGHT.TOP.BOTTOM.DESERT": 1638,
    "Granite Wall.LEFT.TOP.BOTTOM.DESERT": 1639,
    "Wooden Log Wall.TOP.BOTTOM.DESERT": 1640,
    "Wooden Log Wall.RIGHT.TOP.BOTTOM.DESERT": 1641,
    "Wooden Log Wall.LEFT.RIGHT.TOP.BOTTOM.DESERT": 1642,
    "Wooden Log Wall.LEFT.TOP.BOTTOM.DESERT": 1643,
    "Granite Brick Wall.TOP.BOTTOM.DESERT": 1644,
    "Granite Brick Wall.RIGHT.TOP.BOTTOM.DESERT": 1645,
    "Granite Brick Wall.LEFT.RIGHT.TOP.BOTTOM.DESERT": 1646,
    "Granite Brick Wall.LEFT.TOP.BOTTOM.DESERT": 1647,
    "Basalt Wall.TOP.BOTTOM.DESERT": 1648,
    "Basalt Wall.RIGHT.TOP.BOTTOM.DESERT": 1649,
    "Basalt Wall.LEFT.RIGHT.TOP.BOTTOM.DESERT": 1650,
    "Basalt Wall.LEFT.TOP.BOTTOM.DESERT": 1651,
    "Wooden Pallisade Wall.TOP.BOTTOM.DESERT": 1652,
    "Wooden Pallisade Wall.RIGHT.TOP.BOTTOM.DESERT": 1653,
    "Wooden Pallisade Wall.LEFT.RIGHT.TOP.BOTTOM.DESERT": 1654,
    "Wooden Pallisade Wall.LEFT.TOP.BOTTOM.DESERT": 1655,
    "Mud Brick Wall.TOP.BOTTOM.DESERT": 1656,
    "Mud Brick Wall.RIGHT.TOP.BOTTOM.DESERT": 1657,
    "Mud Brick Wall.LEFT.RIGHT.TOP.BOTTOM.DESERT": 1658,
    "Mud Brick Wall.LEFT.TOP.BOTTOM.DESERT": 1659,
    "Limestone Wall.TOP.BOTTOM.DESERT": 1660,
    "Limestone Wall.RIGHT.TOP.BOTTOM.DESERT": 1661,
    "Limestone Wall.LEFT.RIGHT.TOP.BOTTOM.DESERT": 1662,
    "Limestone Wall.LEFT.TOP.BOTTOM.DESERT": 1663,
    "Basalt Brick Wall.TOP.BOTTOM.DESERT": 1664,
    "Basalt Brick Wall.RIGHT.TOP.BOTTOM.DESERT": 1665,
    "Basalt Brick Wall.LEFT.RIGHT.TOP.BOTTOM.DESERT": 1666,
    "Basalt Brick Wall.LEFT.TOP.BOTTOM.DESERT": 1667,
    "Wattle and Daub Wall.TOP.BOTTOM.DESERT": 1668,
    "Wattle and Daub Wall.RIGHT.TOP.BOTTOM.DESERT": 1669,
    "Wattle and Daub Wall.LEFT.RIGHT.TOP.BOTTOM.DESERT": 1670,
    "Wattle and Daub Wall.LEFT.TOP.BOTTOM.DESERT": 1671,
    "Limestone Brick Wall.TOP.BOTTOM.DESERT": 1672,
    "Limestone Brick Wall.RIGHT.TOP.BOTTOM.DESERT": 1673,
    "Limestone Brick Wall.LEFT.RIGHT.TOP.BOTTOM.DESERT": 1674,
    "Limestone Brick Wall.LEFT.TOP.BOTTOM.DESERT": 1675,
    "Clay Brick Wall.TOP.BOTTOM.DESERT": 1676,
    "Clay Brick Wall.RIGHT.TOP.BOTTOM.DESERT": 1677,
    "Clay Brick Wall.LEFT.RIGHT.TOP.BOTTOM.DESERT": 1678,
    "Clay Brick Wall.LEFT.TOP.BOTTOM.DESERT": 1679,
    "Marble Brick Wall.TOP.DESERT": 1680,
    "Alien Wall.TOP.DESERT": 1680,
    "Mansion Wall.TOP.DESERT": 1680,
    "Marble Brick Wall.RIGHT.TOP.DESERT": 1681,
    "Alien Wall.RIGHT.TOP.DESERT": 1681,
    "Mansion Wall.RIGHT.TOP.DESERT": 1681,
    "Marble Brick Wall.LEFT.RIGHT.TOP.DESERT": 1682,
    "Alien Wall.LEFT.RIGHT.TOP.DESERT": 1682,
    "Mansion Wall.LEFT.RIGHT.TOP.DESERT": 1682,
    "Marble Brick Wall.LEFT.TOP.DESERT": 1683,
    "Alien Wall.LEFT.TOP.DESERT": 1683,
    "Mansion Wall.LEFT.TOP.DESERT": 1683,
    "Granite Wall.TOP.DESERT": 1684,
    "Granite Wall.RIGHT.TOP.DESERT": 1685,
    "Granite Wall.LEFT.RIGHT.TOP.DESERT": 1686,
    "Granite Wall.LEFT.TOP.DESERT": 1687,
    "Wooden Log Wall.TOP.DESERT": 1688,
    "Wooden Log Wall.RIGHT.TOP.DESERT": 1689,
    "Wooden Log Wall.LEFT.RIGHT.TOP.DESERT": 1690,
    "Wooden Log Wall.LEFT.TOP.DESERT": 1691,
    "Granite Brick Wall.TOP.DESERT": 1692,
    "Granite Brick Wall.RIGHT.TOP.DESERT": 1693,
    "Granite Brick Wall.LEFT.RIGHT.TOP.DESERT": 1694,
    "Granite Brick Wall.LEFT.TOP.DESERT": 1695,
    "Basalt Wall.TOP.DESERT": 1696,
    "Basalt Wall.RIGHT.TOP.DESERT": 1697,
    "Basalt Wall.LEFT.RIGHT.TOP.DESERT": 1698,
    "Basalt Wall.LEFT.TOP.DESERT": 1699,
    "Wooden Pallisade Wall.TOP.DESERT": 1700,
    "Wooden Pallisade Wall.RIGHT.TOP.DESERT": 1701,
    "Wooden Pallisade Wall.LEFT.RIGHT.TOP.DESERT": 1702,
    "Wooden Pallisade Wall.LEFT.TOP.DESERT": 1703,
    "Mud Brick Wall.TOP.DESERT": 1704,
    "Mud Brick Wall.RIGHT.TOP.DESERT": 1705,
    "Mud Brick Wall.LEFT.RIGHT.TOP.DESERT": 1706,
    "Mud Brick Wall.LEFT.TOP.DESERT": 1707,
    "Limestone Wall.TOP.DESERT": 1708,
    "Limestone Wall.RIGHT.TOP.DESERT": 1709,
    "Limestone Wall.LEFT.RIGHT.TOP.DESERT": 1710,
    "Limestone Wall.LEFT.TOP.DESERT": 1711,
    "Basalt Brick Wall.TOP.DESERT": 1712,
    "Basalt Brick Wall.RIGHT.TOP.DESERT": 1713,
    "Basalt Brick Wall.LEFT.RIGHT.TOP.DESERT": 1714,
    "Basalt Brick Wall.LEFT.TOP.DESERT": 1715,
    "Wattle and Daub Wall.TOP.DESERT": 1716,
    "Wattle and Daub Wall.RIGHT.TOP.DESERT": 1717,
    "Wattle and Daub Wall.LEFT.RIGHT.TOP.DESERT": 1718,
    "Wattle and Daub Wall.LEFT.TOP.DESERT": 1719,
    "Limestone Brick Wall.TOP.DESERT": 1720,
    "Limestone Brick Wall.RIGHT.TOP.DESERT": 1721,
    "Limestone Brick Wall.LEFT.RIGHT.TOP.DESERT": 1722,
    "Limestone Brick Wall.LEFT.TOP.DESERT": 1723,
    "Clay Brick Wall.TOP.DESERT": 1724,
    "Clay Brick Wall.RIGHT.TOP.DESERT": 1725,
    "Clay Brick Wall.LEFT.RIGHT.TOP.DESERT": 1726,
    "Clay Brick Wall.LEFT.TOP.DESERT": 1727,
    "Marble Brick Wall.NONE.DESERT": 1728,
    "Alien Wall.NONE.DESERT": 1728,
    "Mansion Wall.NONE.DESERT": 1728,
    "Marble Brick Wall.RIGHT.DESERT": 1729,
    "Alien Wall.RIGHT.DESERT": 1729,
    "Mansion Wall.RIGHT.DESERT": 1729,
    "Marble Brick Wall.LEFT.RIGHT.DESERT": 1730,
    "Alien Wall.LEFT.RIGHT.DESERT": 1730,
    "Mansion Wall.LEFT.RIGHT.DESERT": 1730,
    "Marble Brick Wall.LEFT.DESERT": 1731,
    "Alien Wall.LEFT.DESERT": 1731,
    "Mansion Wall.LEFT.DESERT": 1731,
    "Granite Wall.NONE.DESERT": 1732,
    "Granite Wall.RIGHT.DESERT": 1733,
    "Granite Wall.LEFT.RIGHT.DESERT": 1734,
    "Granite Wall.LEFT.DESERT": 1735,
    "Wooden Log Wall.NONE.DESERT": 1736,
    "Wooden Log Wall.RIGHT.DESERT": 1737,
    "Wooden Log Wall.LEFT.RIGHT.DESERT": 1738,
    "Wooden Log Wall.LEFT.DESERT": 1739,
    "Granite Brick Wall.NONE.DESERT": 1740,
    "Granite Brick Wall.RIGHT.DESERT": 1741,
    "Granite Brick Wall.LEFT.RIGHT.DESERT": 1742,
    "Granite Brick Wall.LEFT.DESERT": 1743,
    "Basalt Wall.NONE.DESERT": 1744,
    "Basalt Wall.RIGHT.DESERT": 1745,
    "Basalt Wall.LEFT.RIGHT.DESERT": 1746,
    "Basalt Wall.LEFT.DESERT": 1747,
    "Wooden Pallisade Wall.NONE.DESERT": 1748,
    "Wooden Pallisade Wall.RIGHT.DESERT": 1749,
    "Wooden Pallisade Wall.LEFT.RIGHT.DESERT": 1750,
    "Wooden Pallisade Wall.LEFT.DESERT": 1751,
    "Mud Brick Wall.NONE.DESERT": 1752,
    "Mud Brick Wall.RIGHT.DESERT": 1753,
    "Mud Brick Wall.LEFT.RIGHT.DESERT": 1754,
    "Mud Brick Wall.LEFT.DESERT": 1755,
    "Limestone Wall.NONE.DESERT": 1756,
    "Limestone Wall.RIGHT.DESERT": 1757,
    "Limestone Wall.LEFT.RIGHT.DESERT": 1758,
    "Limestone Wall.LEFT.DESERT": 1759,
    "Basalt Brick Wall.NONE.DESERT": 1760,
    "Basalt Brick Wall.RIGHT.DESERT": 1761,
    "Basalt Brick Wall.LEFT.RIGHT.DESERT": 1762,
    "Basalt Brick Wall.LEFT.DESERT": 1763,
    "Wattle and Daub Wall.NONE.DESERT": 1764,
    "Wattle and Daub Wall.RIGHT.DESERT": 1765,
    "Wattle and Daub Wall.LEFT.RIGHT.DESERT": 1766,
    "Wattle and Daub Wall.LEFT.DESERT": 1767,
    "Limestone Brick Wall.NONE.DESERT": 1768,
    "Limestone Brick Wall.RIGHT.DESERT": 1769,
    "Limestone Brick Wall.LEFT.RIGHT.DESERT": 1770,
    "Limestone Brick Wall.LEFT.DESERT": 1771,
    "Clay Brick Wall.NONE.DESERT": 1772,
    "Clay Brick Wall.RIGHT.DESERT": 1773,
    "Clay Brick Wall.LEFT.RIGHT.DESERT": 1774,
    "Clay Brick Wall.LEFT.DESERT": 1775,
    "Marble Wall.BOTTOM.DESERT": 1776,
    "Marble Wall.RIGHT.BOTTOM.DESERT": 1777,
    "Marble Wall.LEFT.RIGHT.BOTTOM.DESERT": 1778,
    "Marble Wall.LEFT.BOTTOM.DESERT": 1779,
    "Marble Brick Wall.BOTTOM.SWAMP": 1780,
    "Marble Brick Wall.RIGHT.BOTTOM.SWAMP": 1781,
    "Marble Brick Wall.LEFT.RIGHT.BOTTOM.SWAMP": 1782,
    "Marble Brick Wall.LEFT.BOTTOM.SWAMP": 1783,
    "Granite Wall.BOTTOM.SWAMP": 1784,
    "Granite Wall.RIGHT.BOTTOM.SWAMP": 1785,
    "Granite Wall.LEFT.RIGHT.BOTTOM.SWAMP": 1786,
    "Granite Wall.LEFT.BOTTOM.SWAMP": 1787,
    "Wooden Log Wall.BOTTOM.SWAMP": 1788,
    "Wooden Log Wall.RIGHT.BOTTOM.SWAMP": 1789,
    "Wooden Log Wall.LEFT.RIGHT.BOTTOM.SWAMP": 1790,
    "Wooden Log Wall.LEFT.BOTTOM.SWAMP": 1791,
    "Granite Brick Wall.BOTTOM.SWAMP": 1792,
    "Granite Brick Wall.RIGHT.BOTTOM.SWAMP": 1793,
    "Granite Brick Wall.LEFT.RIGHT.BOTTOM.SWAMP": 1794,
    "Granite Brick Wall.LEFT.BOTTOM.SWAMP": 1795,
    "Basalt Wall.BOTTOM.SWAMP": 1796,
    "Basalt Wall.RIGHT.BOTTOM.SWAMP": 1797,
    "Basalt Wall.LEFT.RIGHT.BOTTOM.SWAMP": 1798,
    "Basalt Wall.LEFT.BOTTOM.SWAMP": 1799,
    "Wooden Pallisade Wall.BOTTOM.SWAMP": 1800,
    "Wooden Pallisade Wall.RIGHT.BOTTOM.SWAMP": 1801,
    "Wooden Pallisade Wall.LEFT.RIGHT.BOTTOM.SWAMP": 1802,
    "Wooden Pallisade Wall.LEFT.BOTTOM.SWAMP": 1803,
    "Mud Brick Wall.BOTTOM.SWAMP": 1804,
    "Mud Brick Wall.RIGHT.BOTTOM.SWAMP": 1805,
    "Mud Brick Wall.LEFT.RIGHT.BOTTOM.SWAMP": 1806,
    "Mud Brick Wall.LEFT.BOTTOM.SWAMP": 1807,
    "Limestone Wall.BOTTOM.SWAMP": 1808,
    "Limestone Wall.RIGHT.BOTTOM.SWAMP": 1809,
    "Limestone Wall.LEFT.RIGHT.BOTTOM.SWAMP": 1810,
    "Limestone Wall.LEFT.BOTTOM.SWAMP": 1811,
    "Basalt Brick Wall.BOTTOM.SWAMP": 1812,
    "Basalt Brick Wall.RIGHT.BOTTOM.SWAMP": 1813,
    "Basalt Brick Wall.LEFT.RIGHT.BOTTOM.SWAMP": 1814,
    "Basalt Brick Wall.LEFT.BOTTOM.SWAMP": 1815,
    "Wattle and Daub Wall.BOTTOM.SWAMP": 1816,
    "Wattle and Daub Wall.RIGHT.BOTTOM.SWAMP": 1817,
    "Wattle and Daub Wall.LEFT.RIGHT.BOTTOM.SWAMP": 1818,
    "Wattle and Daub Wall.LEFT.BOTTOM.SWAMP": 1819,
    "Limestone Brick Wall.BOTTOM.SWAMP": 1820,
    "Limestone Brick Wall.RIGHT.BOTTOM.SWAMP": 1821,
    "Limestone Brick Wall.LEFT.RIGHT.BOTTOM.SWAMP": 1822,
    "Limestone Brick Wall.LEFT.BOTTOM.SWAMP": 1823,
    "Marble Wall.TOP.BOTTOM.DESERT": 1824,
    "Marble Wall.RIGHT.TOP.BOTTOM.DESERT": 1825,
    "Marble Wall.LEFT.RIGHT.TOP.BOTTOM.DESERT": 1826,
    "Marble Wall.LEFT.TOP.BOTTOM.DESERT": 1827,
    "Marble Brick Wall.TOP.BOTTOM.SWAMP": 1828,
    "Marble Brick Wall.RIGHT.TOP.BOTTOM.SWAMP": 1829,
    "Marble Brick Wall.LEFT.RIGHT.TOP.BOTTOM.SWAMP": 1830,
    "Marble Brick Wall.LEFT.TOP.BOTTOM.SWAMP": 1831,
    "Granite Wall.TOP.BOTTOM.SWAMP": 1832,
    "Granite Wall.RIGHT.TOP.BOTTOM.SWAMP": 1833,
    "Granite Wall.LEFT.RIGHT.TOP.BOTTOM.SWAMP": 1834,
    "Granite Wall.LEFT.TOP.BOTTOM.SWAMP": 1835,
    "Wooden Log Wall.TOP.BOTTOM.SWAMP": 1836,
    "Wooden Log Wall.RIGHT.TOP.BOTTOM.SWAMP": 1837,
    "Wooden Log Wall.LEFT.RIGHT.TOP.BOTTOM.SWAMP": 1838,
    "Wooden Log Wall.LEFT.TOP.BOTTOM.SWAMP": 1839,
    "Granite Brick Wall.TOP.BOTTOM.SWAMP": 1840,
    "Granite Brick Wall.RIGHT.TOP.BOTTOM.SWAMP": 1841,
    "Granite Brick Wall.LEFT.RIGHT.TOP.BOTTOM.SWAMP": 1842,
    "Granite Brick Wall.LEFT.TOP.BOTTOM.SWAMP": 1843,
    "Basalt Wall.TOP.BOTTOM.SWAMP": 1844,
    "Basalt Wall.RIGHT.TOP.BOTTOM.SWAMP": 1845,
    "Basalt Wall.LEFT.RIGHT.TOP.BOTTOM.SWAMP": 1846,
    "Basalt Wall.LEFT.TOP.BOTTOM.SWAMP": 1847,
    "Wooden Pallisade Wall.TOP.BOTTOM.SWAMP": 1848,
    "Wooden Pallisade Wall.RIGHT.TOP.BOTTOM.SWAMP": 1849,
    "Wooden Pallisade Wall.LEFT.RIGHT.TOP.BOTTOM.SWAMP": 1850,
    "Wooden Pallisade Wall.LEFT.TOP.BOTTOM.SWAMP": 1851,
    "Mud Brick Wall.TOP.BOTTOM.SWAMP": 1852,
    "Mud Brick Wall.RIGHT.TOP.BOTTOM.SWAMP": 1853,
    "Mud Brick Wall.LEFT.RIGHT.TOP.BOTTOM.SWAMP": 1854,
    "Mud Brick Wall.LEFT.TOP.BOTTOM.SWAMP": 1855,
    "Limestone Wall.TOP.BOTTOM.SWAMP": 1856,
    "Limestone Wall.RIGHT.TOP.BOTTOM.SWAMP": 1857,
    "Limestone Wall.LEFT.RIGHT.TOP.BOTTOM.SWAMP": 1858,
    "Limestone Wall.LEFT.TOP.BOTTOM.SWAMP": 1859,
    "Basalt Brick Wall.TOP.BOTTOM.SWAMP": 1860,
    "Basalt Brick Wall.RIGHT.TOP.BOTTOM.SWAMP": 1861,
    "Basalt Brick Wall.LEFT.RIGHT.TOP.BOTTOM.SWAMP": 1862,
    "Basalt Brick Wall.LEFT.TOP.BOTTOM.SWAMP": 1863,
    "Wattle and Daub Wall.TOP.BOTTOM.SWAMP": 1864,
    "Wattle and Daub Wall.RIGHT.TOP.BOTTOM.SWAMP": 1865,
    "Wattle and Daub Wall.LEFT.RIGHT.TOP.BOTTOM.SWAMP": 1866,
    "Wattle and Daub Wall.LEFT.TOP.BOTTOM.SWAMP": 1867,
    "Limestone Brick Wall.TOP.BOTTOM.SWAMP": 1868,
    "Limestone Brick Wall.RIGHT.TOP.BOTTOM.SWAMP": 1869,
    "Limestone Brick Wall.LEFT.RIGHT.TOP.BOTTOM.SWAMP": 1870,
    "Limestone Brick Wall.LEFT.TOP.BOTTOM.SWAMP": 1871,
    "Marble Wall.TOP.DESERT": 1872,
    "Marble Wall.RIGHT.TOP.DESERT": 1873,
    "Marble Wall.LEFT.RIGHT.TOP.DESERT": 1874,
    "Marble Wall.LEFT.TOP.DESERT": 1875,
    "Marble Brick Wall.TOP.SWAMP": 1876,
    "Marble Brick Wall.RIGHT.TOP.SWAMP": 1877,
    "Marble Brick Wall.LEFT.RIGHT.TOP.SWAMP": 1878,
    "Marble Brick Wall.LEFT.TOP.SWAMP": 1879,
    "Granite Wall.TOP.SWAMP": 1880,
    "Granite Wall.RIGHT.TOP.SWAMP": 1881,
    "Granite Wall.LEFT.RIGHT.TOP.SWAMP": 1882,
    "Granite Wall.LEFT.TOP.SWAMP": 1883,
    "Wooden Log Wall.TOP.SWAMP": 1884,
    "Wooden Log Wall.RIGHT.TOP.SWAMP": 1885,
    "Wooden Log Wall.LEFT.RIGHT.TOP.SWAMP": 1886,
    "Wooden Log Wall.LEFT.TOP.SWAMP": 1887,
    "Granite Brick Wall.TOP.SWAMP": 1888,
    "Granite Brick Wall.RIGHT.TOP.SWAMP": 1889,
    "Granite Brick Wall.LEFT.RIGHT.TOP.SWAMP": 1890,
    "Granite Brick Wall.LEFT.TOP.SWAMP": 1891,
    "Basalt Wall.TOP.SWAMP": 1892,
    "Basalt Wall.RIGHT.TOP.SWAMP": 1893,
    "Basalt Wall.LEFT.RIGHT.TOP.SWAMP": 1894,
    "Basalt Wall.LEFT.TOP.SWAMP": 1895,
    "Wooden Pallisade Wall.TOP.SWAMP": 1896,
    "Wooden Pallisade Wall.RIGHT.TOP.SWAMP": 1897,
    "Wooden Pallisade Wall.LEFT.RIGHT.TOP.SWAMP": 1898,
    "Wooden Pallisade Wall.LEFT.TOP.SWAMP": 1899,
    "Mud Brick Wall.TOP.SWAMP": 1900,
    "Mud Brick Wall.RIGHT.TOP.SWAMP": 1901,
    "Mud Brick Wall.LEFT.RIGHT.TOP.SWAMP": 1902,
    "Mud Brick Wall.LEFT.TOP.SWAMP": 1903,
    "Limestone Wall.TOP.SWAMP": 1904,
    "Limestone Wall.RIGHT.TOP.SWAMP": 1905,
    "Limestone Wall.LEFT.RIGHT.TOP.SWAMP": 1906,
    "Limestone Wall.LEFT.TOP.SWAMP": 1907,
    "Basalt Brick Wall.TOP.SWAMP": 1908,
    "Basalt Brick Wall.RIGHT.TOP.SWAMP": 1909,
    "Basalt Brick Wall.LEFT.RIGHT.TOP.SWAMP": 1910,
    "Basalt Brick Wall.LEFT.TOP.SWAMP": 1911,
    "Wattle and Daub Wall.TOP.SWAMP": 1912,
    "Wattle and Daub Wall.RIGHT.TOP.SWAMP": 1913,
    "Wattle and Daub Wall.LEFT.RIGHT.TOP.SWAMP": 1914,
    "Wattle and Daub Wall.LEFT.TOP.SWAMP": 1915,
    "Limestone Brick Wall.TOP.SWAMP": 1916,
    "Limestone Brick Wall.RIGHT.TOP.SWAMP": 1917,
    "Limestone Brick Wall.LEFT.RIGHT.TOP.SWAMP": 1918,
    "Limestone Brick Wall.LEFT.TOP.SWAMP": 1919,
    "Marble Wall.NONE.DESERT": 1920,
    "Marble Wall.RIGHT.DESERT": 1921,
    "Marble Wall.LEFT.RIGHT.DESERT": 1922,
    "Marble Wall.LEFT.DESERT": 1923,
    "Marble Brick Wall.NONE.SWAMP": 1924,
    "Marble Brick Wall.RIGHT.SWAMP": 1925,
    "Marble Brick Wall.LEFT.RIGHT.SWAMP": 1926,
    "Marble Brick Wall.LEFT.SWAMP": 1927,
    "Granite Wall.NONE.SWAMP": 1928,
    "Granite Wall.RIGHT.SWAMP": 1929,
    "Granite Wall.LEFT.RIGHT.SWAMP": 1930,
    "Granite Wall.LEFT.SWAMP": 1931,
    "Wooden Log Wall.NONE.SWAMP": 1932,
    "Wooden Log Wall.RIGHT.SWAMP": 1933,
    "Wooden Log Wall.LEFT.RIGHT.SWAMP": 1934,
    "Wooden Log Wall.LEFT.SWAMP": 1935,
    "Granite Brick Wall.NONE.SWAMP": 1936,
    "Granite Brick Wall.RIGHT.SWAMP": 1937,
    "Granite Brick Wall.LEFT.RIGHT.SWAMP": 1938,
    "Granite Brick Wall.LEFT.SWAMP": 1939,
    "Basalt Wall.NONE.SWAMP": 1940,
    "Basalt Wall.RIGHT.SWAMP": 1941,
    "Basalt Wall.LEFT.RIGHT.SWAMP": 1942,
    "Basalt Wall.LEFT.SWAMP": 1943,
    "Wooden Pallisade Wall.NONE.SWAMP": 1944,
    "Wooden Pallisade Wall.RIGHT.SWAMP": 1945,
    "Wooden Pallisade Wall.LEFT.RIGHT.SWAMP": 1946,
    "Wooden Pallisade Wall.LEFT.SWAMP": 1947,
    "Mud Brick Wall.NONE.SWAMP": 1948,
    "Mud Brick Wall.RIGHT.SWAMP": 1949,
    "Mud Brick Wall.LEFT.RIGHT.SWAMP": 1950,
    "Mud Brick Wall.LEFT.SWAMP": 1951,
    "Limestone Wall.NONE.SWAMP": 1952,
    "Limestone Wall.RIGHT.SWAMP": 1953,
    "Limestone Wall.LEFT.RIGHT.SWAMP": 1954,
    "Limestone Wall.LEFT.SWAMP": 1955,
    "Basalt Brick Wall.NONE.SWAMP": 1956,
    "Basalt Brick Wall.RIGHT.SWAMP": 1957,
    "Basalt Brick Wall.LEFT.RIGHT.SWAMP": 1958,
    "Basalt Brick Wall.LEFT.SWAMP": 1959,
    "Wattle and Daub Wall.NONE.SWAMP": 1960,
    "Wattle and Daub Wall.RIGHT.SWAMP": 1961,
    "Wattle and Daub Wall.LEFT.RIGHT.SWAMP": 1962,
    "Wattle and Daub Wall.LEFT.SWAMP": 1963,
    "Limestone Brick Wall.NONE.SWAMP": 1964,
    "Limestone Brick Wall.RIGHT.SWAMP": 1965,
    "Limestone Brick Wall.LEFT.RIGHT.SWAMP": 1966,
    "Limestone Brick Wall.LEFT.SWAMP": 1967,
    "Clay Brick Wall.BOTTOM.SWAMP": 1968,
    "Clay Brick Wall.RIGHT.BOTTOM.SWAMP": 1969,
    "Clay Brick Wall.LEFT.RIGHT.BOTTOM.SWAMP": 1970,
    "Clay Brick Wall.LEFT.BOTTOM.SWAMP": 1971,
    "Marble Wall.BOTTOM.SWAMP": 1972,
    "Marble Wall.RIGHT.BOTTOM.SWAMP": 1973,
    "Marble Wall.LEFT.RIGHT.BOTTOM.SWAMP": 1974,
    "Marble Wall.LEFT.BOTTOM.SWAMP": 1975,
    "Wooden Log Wall.TOP.TAIGA": 1976,
    "Wooden Log Wall.RIGHT.TOP.TAIGA": 1977,
    "Wooden Log Wall.LEFT.RIGHT.TOP.TAIGA": 1978,
    "Wooden Log Wall.LEFT.TOP.TAIGA": 1979,
    "Marble Wall.TOP.POLAR": 1980,
    "Marble Wall.RIGHT.TOP.POLAR": 1981,
    "Marble Wall.LEFT.RIGHT.TOP.POLAR": 1982,
    "Marble Wall.LEFT.TOP.POLAR": 1983,
    "Granite Brick Wall.BOTTOM.TAIGA": 1596,
    "Granite Brick Wall.RIGHT.BOTTOM.TAIGA": 1597,
    "Granite Brick Wall.LEFT.RIGHT.BOTTOM.TAIGA": 1598,
    "Granite Brick Wall.LEFT.BOTTOM.TAIGA": 1599,
    "Wooden Pallisade Wall.TOP.TAIGA": 1988,
    "Wooden Pallisade Wall.RIGHT.TOP.TAIGA": 1989,
    "Wooden Pallisade Wall.LEFT.RIGHT.TOP.TAIGA": 1990,
    "Wooden Pallisade Wall.LEFT.TOP.TAIGA": 1991,
    "Mud Brick Wall.TOP.TAIGA": 1992,
    "Mud Brick Wall.RIGHT.TOP.TAIGA": 1993,
    "Mud Brick Wall.LEFT.RIGHT.TOP.TAIGA": 1994,
    "Mud Brick Wall.LEFT.TOP.TAIGA": 1995,
    "Basalt Brick Wall.TOP.TAIGA": 2084,
    "Basalt Brick Wall.RIGHT.TOP.TAIGA": 2085,
    "Basalt Brick Wall.LEFT.RIGHT.TOP.TAIGA": 2086,
    "Basalt Brick Wall.LEFT.TOP.TAIGA": 2087,
    "Limestone Brick Wall.TOP.TAIGA": 2000,
    "Limestone Brick Wall.RIGHT.TOP.TAIGA": 2001,
    "Limestone Brick Wall.LEFT.RIGHT.TOP.TAIGA": 2002,
    "Limestone Brick Wall.LEFT.TOP.TAIGA": 2003,
    "Marble Wall.TOP.TAIGA": 2004,
    "Marble Wall.RIGHT.TOP.TAIGA": 2005,
    "Marble Wall.LEFT.RIGHT.TOP.TAIGA": 2006,
    "Marble Wall.LEFT.TOP.TAIGA": 2007,
    "Granite Wall.TOP.JUNGLE": 2008,
    "Granite Wall.RIGHT.TOP.JUNGLE": 2009,
    "Granite Wall.LEFT.RIGHT.TOP.JUNGLE": 2010,
    "Granite Wall.LEFT.TOP.JUNGLE": 2011,
    "Granite Brick Wall.TOP.JUNGLE": 2012,
    "Granite Brick Wall.RIGHT.TOP.JUNGLE": 2013,
    "Granite Brick Wall.LEFT.RIGHT.TOP.JUNGLE": 2014,
    "Granite Brick Wall.LEFT.TOP.JUNGLE": 2015,
    "Clay Brick Wall.TOP.BOTTOM.SWAMP": 2016,
    "Clay Brick Wall.RIGHT.TOP.BOTTOM.SWAMP": 2017,
    "Clay Brick Wall.LEFT.RIGHT.TOP.BOTTOM.SWAMP": 2018,
    "Clay Brick Wall.LEFT.TOP.BOTTOM.SWAMP": 2019,
    "Marble Wall.TOP.BOTTOM.SWAMP": 2020,
    "Marble Wall.RIGHT.TOP.BOTTOM.SWAMP": 2021,
    "Marble Wall.LEFT.RIGHT.TOP.BOTTOM.SWAMP": 2022,
    "Marble Wall.LEFT.TOP.BOTTOM.SWAMP": 2023,
    "Wooden Log Wall.NONE.TAIGA": 2024,
    "Wooden Log Wall.RIGHT.TAIGA": 2025,
    "Wooden Log Wall.LEFT.RIGHT.TAIGA": 2026,
    "Wooden Log Wall.LEFT.TAIGA": 2027,
    "Marble Wall.NONE.POLAR": 2028,
    "Marble Wall.RIGHT.POLAR": 2029,
    "Marble Wall.LEFT.RIGHT.POLAR": 2030,
    "Marble Wall.LEFT.POLAR": 2031,
    "Granite Brick Wall.TOP.BOTTOM.TAIGA": 1644,
    "Granite Brick Wall.RIGHT.TOP.BOTTOM.TAIGA": 1645,
    "Granite Brick Wall.LEFT.RIGHT.TOP.BOTTOM.TAIGA": 1646,
    "Granite Brick Wall.LEFT.TOP.BOTTOM.TAIGA": 1647,
    "Wooden Pallisade Wall.NONE.TAIGA": 2036,
    "Wooden Pallisade Wall.RIGHT.TAIGA": 2037,
    "Wooden Pallisade Wall.LEFT.RIGHT.TAIGA": 2038,
    "Wooden Pallisade Wall.LEFT.TAIGA": 2039,
    "Mud Brick Wall.NONE.TAIGA": 2040,
    "Mud Brick Wall.RIGHT.TAIGA": 2041,
    "Mud Brick Wall.LEFT.RIGHT.TAIGA": 2042,
    "Mud Brick Wall.LEFT.TAIGA": 2043,
    "Basalt Brick Wall.NONE.TAIGA": 2132,
    "Basalt Brick Wall.RIGHT.TAIGA": 2133,
    "Basalt Brick Wall.LEFT.RIGHT.TAIGA": 2134,
    "Basalt Brick Wall.LEFT.TAIGA": 2135,
    "Limestone Brick Wall.NONE.TAIGA": 2048,
    "Limestone Brick Wall.RIGHT.TAIGA": 2049,
    "Limestone Brick Wall.LEFT.RIGHT.TAIGA": 2050,
    "Limestone Brick Wall.LEFT.TAIGA": 2051,
    "Marble Wall.NONE.TAIGA": 2052,
    "Marble Wall.RIGHT.TAIGA": 2053,
    "Marble Wall.LEFT.RIGHT.TAIGA": 2054,
    "Marble Wall.LEFT.TAIGA": 2055,
    "Granite Wall.NONE.JUNGLE": 2056,
    "Granite Wall.RIGHT.JUNGLE": 2057,
    "Granite Wall.LEFT.RIGHT.JUNGLE": 2058,
    "Granite Wall.LEFT.JUNGLE": 2059,
    "Granite Brick Wall.NONE.JUNGLE": 2060,
    "Granite Brick Wall.RIGHT.JUNGLE": 2061,
    "Granite Brick Wall.LEFT.RIGHT.JUNGLE": 2062,
    "Granite Brick Wall.LEFT.JUNGLE": 2063,
    "Clay Brick Wall.TOP.SWAMP": 2064,
    "Clay Brick Wall.RIGHT.TOP.SWAMP": 2065,
    "Clay Brick Wall.LEFT.RIGHT.TOP.SWAMP": 2066,
    "Clay Brick Wall.LEFT.TOP.SWAMP": 2067,
    "Marble Wall.TOP.SWARMP": 2068,
    "Marble Wall.RIGHT.TOP.SWARMP": 2069,
    "Marble Wall.LEFT.RIGHT.TOP.SWARMP": 2070,
    "Marble Wall.LEFT.TOP.SWARMP": 2071,
    "Marble Brick Wall.TOP.TAIGA": 2072,
    "Marble Brick Wall.RIGHT.TOP.TAIGA": 2073,
    "Marble Brick Wall.LEFT.RIGHT.TOP.TAIGA": 2074,
    "Marble Brick Wall.LEFT.TOP.TAIGA": 2075,
    "Granite Wall.TOP.TAIGA": 2076,
    "Granite Wall.RIGHT.TOP.TAIGA": 2077,
    "Granite Wall.LEFT.RIGHT.TOP.TAIGA": 2078,
    "Granite Wall.LEFT.TOP.TAIGA": 2079,
    "Granite Brick Wall.TOP.TAIGA": 2080,
    "Granite Brick Wall.RIGHT.TOP.TAIGA": 2081,
    "Granite Brick Wall.LEFT.RIGHT.TOP.TAIGA": 2082,
    "Granite Brick Wall.LEFT.TOP.TAIGA": 2083,
    "Limestone Wall.TOP.TAIGA": 2088,
    "Limestone Wall.RIGHT.TOP.TAIGA": 2089,
    "Limestone Wall.LEFT.RIGHT.TOP.TAIGA": 2090,
    "Limestone Wall.LEFT.TOP.TAIGA": 2091,
    "Wattle and Daub Wall.TOP.TAIGA": 2092,
    "Wattle and Daub Wall.RIGHT.TOP.TAIGA": 2093,
    "Wattle and Daub Wall.LEFT.RIGHT.TOP.TAIGA": 2094,
    "Wattle and Daub Wall.LEFT.TOP.TAIGA": 2095,
    "Clay Brick Wall.TOP.TAIGA": 2096,
    "Clay Brick Wall.RIGHT.TOP.TAIGA": 2097,
    "Clay Brick Wall.LEFT.RIGHT.TOP.TAIGA": 2098,
    "Clay Brick Wall.LEFT.TOP.TAIGA": 2099,
    "Marble Brick Wall.TOP.JUNGLE": 2100,
    "Marble Brick Wall.RIGHT.TOP.JUNGLE": 2101,
    "Marble Brick Wall.LEFT.RIGHT.TOP.JUNGLE": 2102,
    "Marble Brick Wall.LEFT.TOP.JUNGLE": 2103,
    "Wooden Log Wall.TOP.JUNGLE": 2104,
    "Wooden Log Wall.RIGHT.TOP.JUNGLE": 2105,
    "Wooden Log Wall.LEFT.RIGHT.TOP.JUNGLE": 2106,
    "Wooden Log Wall.LEFT.TOP.JUNGLE": 2107,
    "Wooden Pallisade Wall.TOP.JUNGLE": 2108,
    "Wooden Pallisade Wall.RIGHT.TOP.JUNGLE": 2109,
    "Wooden Pallisade Wall.LEFT.RIGHT.TOP.JUNGLE": 2110,
    "Wooden Pallisade Wall.LEFT.TOP.JUNGLE": 2111,
    "Clay Brick Wall.NONE.SWAMP": 2112,
    "Clay Brick Wall.RIGHT.SWAMP": 2113,
    "Clay Brick Wall.LEFT.RIGHT.SWAMP": 2114,
    "Clay Brick Wall.LEFT.SWAMP": 2115,
    "Marble Wall.NONE.SWARMP": 2116,
    "Marble Wall.RIGHT.SWARMP": 2117,
    "Marble Wall.LEFT.RIGHT.SWARMP": 2118,
    "Marble Wall.LEFT.SWARMP": 2119,
    "Marble Brick Wall.NONE.TAIGA": 2120,
    "Marble Brick Wall.RIGHT.TAIGA": 2121,
    "Marble Brick Wall.LEFT.RIGHT.TAIGA": 2122,
    "Marble Brick Wall.LEFT.TAIGA": 2123,
    "Granite Wall.NONE.TAIGA": 2124,
    "Granite Wall.RIGHT.TAIGA": 2125,
    "Granite Wall.LEFT.RIGHT.TAIGA": 2126,
    "Granite Wall.LEFT.TAIGA": 2127,
    "Granite Brick Wall.NONE.TAIGA": 2128,
    "Granite Brick Wall.RIGHT.TAIGA": 2129,
    "Granite Brick Wall.LEFT.RIGHT.TAIGA": 2130,
    "Granite Brick Wall.LEFT.TAIGA": 2131,
    "Limestone Wall.NONE.TAIGA": 2136,
    "Limestone Wall.RIGHT.TAIGA": 2137,
    "Limestone Wall.LEFT.RIGHT.TAIGA": 2138,
    "Limestone Wall.LEFT.TAIGA": 2139,
    "Wattle and Daub Wall.NONE.TAIGA": 2140,
    "Wattle and Daub Wall.RIGHT.TAIGA": 2141,
    "Wattle and Daub Wall.LEFT.RIGHT.TAIGA": 2142,
    "Wattle and Daub Wall.LEFT.TAIGA": 2143,
    "Clay Brick Wall.NONE.TAIGA": 2144,
    "Clay Brick Wall.RIGHT.TAIGA": 2145,
    "Clay Brick Wall.LEFT.RIGHT.TAIGA": 2146,
    "Clay Brick Wall.LEFT.TAIGA": 2147,
    "Marble Brick Wall.NONE.JUNGLE": 2148,
    "Marble Brick Wall.RIGHT.JUNGLE": 2149,
    "Marble Brick Wall.LEFT.RIGHT.JUNGLE": 2150,
    "Marble Brick Wall.LEFT.JUNGLE": 2151,
    "Wooden Log Wall.NONE.JUNGLE": 2152,
    "Wooden Log Wall.RIGHT.JUNGLE": 2153,
    "Wooden Log Wall.LEFT.RIGHT.JUNGLE": 2154,
    "Wooden Log Wall.LEFT.JUNGLE": 2155,
    "Wooden Pallisade Wall.NONE.JUNGLE": 2156,
    "Wooden Pallisade Wall.RIGHT.JUNGLE": 2157,
    "Wooden Pallisade Wall.LEFT.RIGHT.JUNGLE": 2158,
    "Wooden Pallisade Wall.LEFT.JUNGLE": 2159,
    "Basalt Wall.BOTTOM.JUNGLE": 2160,
    "Basalt Wall.RIGHT.BOTTOM.JUNGLE": 2161,
    "Basalt Wall.LEFT.RIGHT.BOTTOM.JUNGLE": 2162,
    "Basalt Wall.LEFT.BOTTOM.JUNGLE": 2163,
    "Mud Brick Wall.TOP.JUNGLE": 2164,
    "Mud Brick Wall.RIGHT.TOP.JUNGLE": 2165,
    "Mud Brick Wall.LEFT.RIGHT.TOP.JUNGLE": 2166,
    "Mud Brick Wall.LEFT.TOP.JUNGLE": 2167,
    "Basalt Brick Wall.TOP.JUNGLE": 2168,
    "Basalt Brick Wall.RIGHT.TOP.JUNGLE": 2169,
    "Basalt Brick Wall.LEFT.RIGHT.TOP.JUNGLE": 2170,
    "Basalt Brick Wall.LEFT.TOP.JUNGLE": 2171,
    "Limestone Brick Wall.TOP.JUNGLE": 2172,
    "Limestone Brick Wall.RIGHT.TOP.JUNGLE": 2173,
    "Limestone Brick Wall.LEFT.RIGHT.TOP.JUNGLE": 2174,
    "Limestone Brick Wall.LEFT.TOP.JUNGLE": 2175,
    "Marble Wall.TOP.JUNGLE": 2176,
    "Marble Wall.RIGHT.TOP.JUNGLE": 2177,
    "Marble Wall.LEFT.RIGHT.TOP.JUNGLE": 2178,
    "Marble Wall.LEFT.TOP.JUNGLE": 2179,
    "Granite Wall.TOP.ARID": 2180,
    "Granite Wall.RIGHT.TOP.ARID": 2181,
    "Granite Wall.LEFT.RIGHT.TOP.ARID": 2182,
    "Granite Wall.LEFT.TOP.ARID": 2183,
    "Granite Brick Wall.TOP.ARID": 2184,
    "Granite Brick Wall.RIGHT.TOP.ARID": 2185,
    "Granite Brick Wall.LEFT.RIGHT.TOP.ARID": 2186,
    "Granite Brick Wall.LEFT.TOP.ARID": 2187,
    "Wooden Pallisade Wall.TOP.ARID": 2188,
    "Wooden Pallisade Wall.RIGHT.TOP.ARID": 2189,
    "Wooden Pallisade Wall.LEFT.RIGHT.TOP.ARID": 2190,
    "Wooden Pallisade Wall.LEFT.TOP.ARID": 2191,
    "Limestone Wall.BOTTOM.ARID": 1612,
    "Limestone Wall.RIGHT.BOTTOM.ARID": 1613,
    "Limestone Wall.LEFT.RIGHT.BOTTOM.ARID": 1614,
    "Limestone Wall.LEFT.BOTTOM.ARID": 1615,
    "Basalt Brick Wall.TOP.ARID": 2196,
    "Basalt Brick Wall.RIGHT.TOP.ARID": 2197,
    "Basalt Brick Wall.LEFT.RIGHT.TOP.ARID": 2198,
    "Basalt Brick Wall.LEFT.TOP.ARID": 2199,
    "Limestone Brick Wall.TOP.ARID": 2200,
    "Limestone Brick Wall.RIGHT.TOP.ARID": 2201,
    "Limestone Brick Wall.LEFT.RIGHT.TOP.ARID": 2202,
    "Limestone Brick Wall.LEFT.TOP.ARID": 2203,
    "Marble Wall.TOP.ARID": 2204,
    "Marble Wall.RIGHT.TOP.ARID": 2205,
    "Marble Wall.LEFT.RIGHT.TOP.ARID": 2206,
    "Marble Wall.LEFT.TOP.ARID": 2207,
    "Basalt Wall.TOP.BOTTOM.JUNGLE": 2208,
    "Basalt Wall.RIGHT.TOP.BOTTOM.JUNGLE": 2209,
    "Basalt Wall.LEFT.RIGHT.TOP.BOTTOM.JUNGLE": 2210,
    "Basalt Wall.LEFT.TOP.BOTTOM.JUNGLE": 2211,
    "Mud Brick Wall.NONE.JUNGLE": 2212,
    "Mud Brick Wall.RIGHT.JUNGLE": 2213,
    "Mud Brick Wall.LEFT.RIGHT.JUNGLE": 2214,
    "Mud Brick Wall.LEFT.JUNGLE": 2215,
    "Basalt Brick Wall.NONE.JUNGLE": 2216,
    "Basalt Brick Wall.RIGHT.JUNGLE": 2217,
    "Basalt Brick Wall.LEFT.RIGHT.JUNGLE": 2218,
    "Basalt Brick Wall.LEFT.JUNGLE": 2219,
    "Limestone Brick Wall.NONE.JUNGLE": 2220,
    "Limestone Brick Wall.RIGHT.JUNGLE": 2221,
    "Limestone Brick Wall.LEFT.RIGHT.JUNGLE": 2222,
    "Limestone Brick Wall.LEFT.JUNGLE": 2223,
    "Marble Wall.NONE.JUNGLE": 2224,
    "Marble Wall.RIGHT.JUNGLE": 2225,
    "Marble Wall.LEFT.RIGHT.JUNGLE": 2226,
    "Marble Wall.LEFT.JUNGLE": 2227,
    "Granite Wall.NONE.ARID": 2228,
    "Granite Wall.RIGHT.ARID": 2229,
    "Granite Wall.LEFT.RIGHT.ARID": 2230,
    "Granite Wall.LEFT.ARID": 2231,
    "Granite Brick Wall.NONE.ARID": 2232,
    "Granite Brick Wall.RIGHT.ARID": 2233,
    "Granite Brick Wall.LEFT.RIGHT.ARID": 2234,
    "Granite Brick Wall.LEFT.ARID": 2235,
    "Wooden Pallisade Wall.NONE.ARID": 2236,
    "Wooden Pallisade Wall.RIGHT.ARID": 2237,
    "Wooden Pallisade Wall.LEFT.RIGHT.ARID": 2238,
    "Wooden Pallisade Wall.LEFT.ARID": 2239,
    "Limestone Wall.TOP.BOTTOM.ARID": 1660,
    "Limestone Wall.RIGHT.TOP.BOTTOM.ARID": 1661,
    "Limestone Wall.LEFT.RIGHT.TOP.BOTTOM.ARID": 1662,
    "Limestone Wall.LEFT.TOP.BOTTOM.ARID": 1663,
    "Basalt Brick Wall.NONE.ARID": 2244,
    "Basalt Brick Wall.RIGHT.ARID": 2245,
    "Basalt Brick Wall.LEFT.RIGHT.ARID": 2246,
    "Basalt Brick Wall.LEFT.ARID": 2247,
    "Limestone Brick Wall.NONE.ARID": 2248,
    "Limestone Brick Wall.RIGHT.ARID": 2249,
    "Limestone Brick Wall.LEFT.RIGHT.ARID": 2250,
    "Limestone Brick Wall.LEFT.ARID": 2251,
    "Marble Wall.NONE.ARID": 2252,
    "Marble Wall.RIGHT.ARID": 2253,
    "Marble Wall.LEFT.RIGHT.ARID": 2254,
    "Marble Wall.LEFT.ARID": 2255,
    "Basalt Wall.TOP.JUNGLE": 2256,
    "Basalt Wall.RIGHT.TOP.JUNGLE": 2257,
    "Basalt Wall.LEFT.RIGHT.TOP.JUNGLE": 2258,
    "Basalt Wall.LEFT.TOP.JUNGLE": 2259,
    "Limestone Wall.TOP.JUNGLE": 2260,
    "Limestone Wall.RIGHT.TOP.JUNGLE": 2261,
    "Limestone Wall.LEFT.RIGHT.TOP.JUNGLE": 2262,
    "Limestone Wall.LEFT.TOP.JUNGLE": 2263,
    "Wattle and Daub Wall.TOP.JUNGLE": 2264,
    "Wattle and Daub Wall.RIGHT.TOP.JUNGLE": 2265,
    "Wattle and Daub Wall.LEFT.RIGHT.TOP.JUNGLE": 2266,
    "Wattle and Daub Wall.LEFT.TOP.JUNGLE": 2267,
    "Clay Brick Wall.TOP.JUNGLE": 2268,
    "Clay Brick Wall.RIGHT.TOP.JUNGLE": 2269,
    "Clay Brick Wall.LEFT.RIGHT.TOP.JUNGLE": 2270,
    "Clay Brick Wall.LEFT.TOP.JUNGLE": 2271,
    "Marble Brick Wall.TOP.ARID": 2272,
    "Marble Brick Wall.RIGHT.TOP.ARID": 2273,
    "Marble Brick Wall.LEFT.RIGHT.TOP.ARID": 2274,
    "Marble Brick Wall.LEFT.TOP.ARID": 2275,
    "Wooden Log Wall.TOP.ARID": 2276,
    "Wooden Log Wall.RIGHT.TOP.ARID": 2277,
    "Wooden Log Wall.LEFT.RIGHT.TOP.ARID": 2278,
    "Wooden Log Wall.LEFT.TOP.ARID": 2279,
    "Basalt Wall.TOP.ARID": 2280,
    "Basalt Wall.RIGHT.TOP.ARID": 2281,
    "Basalt Wall.LEFT.RIGHT.TOP.ARID": 2282,
    "Basalt Wall.LEFT.TOP.ARID": 2283,
    "Mud Brick Wall.TOP.ARID": 2284,
    "Mud Brick Wall.RIGHT.TOP.ARID": 2285,
    "Mud Brick Wall.LEFT.RIGHT.TOP.ARID": 2286,
    "Mud Brick Wall.LEFT.TOP.ARID": 2287,
    "Limestone Wall.TOP.ARID": 2288,
    "Limestone Wall.RIGHT.TOP.ARID": 2289,
    "Limestone Wall.LEFT.RIGHT.TOP.ARID": 2290,
    "Limestone Wall.LEFT.TOP.ARID": 2291,
    "Wattle and Daub Wall.TOP.ARID": 2292,
    "Wattle and Daub Wall.RIGHT.TOP.ARID": 2293,
    "Wattle and Daub Wall.LEFT.RIGHT.TOP.ARID": 2294,
    "Wattle and Daub Wall.LEFT.TOP.ARID": 2295,
    "Clay Brick Wall.TOP.ARID": 2296,
    "Clay Brick Wall.RIGHT.TOP.ARID": 2297,
    "Clay Brick Wall.LEFT.RIGHT.TOP.ARID": 2298,
    "Clay Brick Wall.LEFT.TOP.ARID": 2299,
    "Marble Brick Wall.TOP.OLD-GROWTH": 2300,
    "Marble Brick Wall.RIGHT.TOP.OLD-GROWTH": 2301,
    "Marble Brick Wall.LEFT.RIGHT.TOP.OLD-GROWTH": 2302,
    "Marble Brick Wall.LEFT.TOP.OLD-GROWTH": 2303,
    "Basalt Wall.NONE.JUNGLE": 2304,
    "Basalt Wall.RIGHT.JUNGLE": 2305,
    "Basalt Wall.LEFT.RIGHT.JUNGLE": 2306,
    "Basalt Wall.LEFT.JUNGLE": 2307,
    "Limestone Wall.NONE.JUNGLE": 2308,
    "Limestone Wall.RIGHT.JUNGLE": 2309,
    "Limestone Wall.LEFT.RIGHT.JUNGLE": 2310,
    "Limestone Wall.LEFT.JUNGLE": 2311,
    "Wattle and Daub Wall.NONE.JUNGLE": 2312,
    "Wattle and Daub Wall.RIGHT.JUNGLE": 2313,
    "Wattle and Daub Wall.LEFT.RIGHT.JUNGLE": 2314,
    "Wattle and Daub Wall.LEFT.JUNGLE": 2315,
    "Clay Brick Wall.NONE.JUNGLE": 2316,
    "Clay Brick Wall.RIGHT.JUNGLE": 2317,
    "Clay Brick Wall.LEFT.RIGHT.JUNGLE": 2318,
    "Clay Brick Wall.LEFT.JUNGLE": 2319,
    "Marble Brick Wall.NONE.ARID": 2320,
    "Marble Brick Wall.RIGHT.ARID": 2321,
    "Marble Brick Wall.LEFT.RIGHT.ARID": 2322,
    "Marble Brick Wall.LEFT.ARID": 2323,
    "Wooden Log Wall.NONE.ARID": 2324,
    "Wooden Log Wall.RIGHT.ARID": 2325,
    "Wooden Log Wall.LEFT.RIGHT.ARID": 2326,
    "Wooden Log Wall.LEFT.ARID": 2327,
    "Basalt Wall.NONE.ARID": 2328,
    "Basalt Wall.RIGHT.ARID": 2329,
    "Basalt Wall.LEFT.RIGHT.ARID": 2330,
    "Basalt Wall.LEFT.ARID": 2331,
    "Mud Brick Wall.NONE.ARID": 2332,
    "Mud Brick Wall.RIGHT.ARID": 2333,
    "Mud Brick Wall.LEFT.RIGHT.ARID": 2334,
    "Mud Brick Wall.LEFT.ARID": 2335,
    "Limestone Wall.NONE.ARID": 2336,
    "Limestone Wall.RIGHT.ARID": 2337,
    "Limestone Wall.LEFT.RIGHT.ARID": 2338,
    "Limestone Wall.LEFT.ARID": 2339,
    "Wattle and Daub Wall.NONE.ARID": 2340,
    "Wattle and Daub Wall.RIGHT.ARID": 2341,
    "Wattle and Daub Wall.LEFT.RIGHT.ARID": 2342,
    "Wattle and Daub Wall.LEFT.ARID": 2343,
    "Clay Brick Wall.NONE.ARID": 2344,
    "Clay Brick Wall.RIGHT.ARID": 2345,
    "Clay Brick Wall.LEFT.RIGHT.ARID": 2346,
    "Clay Brick Wall.LEFT.ARID": 2347,
    "Marble Brick Wall.NONE.OLD-GROWTH": 2348,
    "Marble Brick Wall.RIGHT.OLD-GROWTH": 2349,
    "Marble Brick Wall.LEFT.RIGHT.OLD-GROWTH": 2350,
    "Marble Brick Wall.LEFT.OLD-GROWTH": 2351,
    "Granite Wall.TOP.OLD-GROWTH": 2352,
    "Granite Wall.RIGHT.TOP.OLD-GROWTH": 2353,
    "Granite Wall.LEFT.RIGHT.TOP.OLD-GROWTH": 2354,
    "Granite Wall.LEFT.TOP.OLD-GROWTH": 2355,
    "Granite Brick Wall.TOP.OLD-GROWTH": 2356,
    "Granite Brick Wall.RIGHT.TOP.OLD-GROWTH": 2357,
    "Granite Brick Wall.LEFT.RIGHT.TOP.OLD-GROWTH": 2358,
    "Granite Brick Wall.LEFT.TOP.OLD-GROWTH": 2359,
    "Wooden Pallisade Wall.TOP.OLD-GROWTH": 2360,
    "Wooden Pallisade Wall.RIGHT.TOP.OLD-GROWTH": 2361,
    "Wooden Pallisade Wall.LEFT.RIGHT.TOP.OLD-GROWTH": 2362,
    "Wooden Pallisade Wall.LEFT.TOP.OLD-GROWTH": 2363,
    "Limestone Wall.TOP.OLD-GROWTH": 2364,
    "Limestone Wall.RIGHT.TOP.OLD-GROWTH": 2365,
    "Limestone Wall.LEFT.RIGHT.TOP.OLD-GROWTH": 2366,
    "Limestone Wall.LEFT.TOP.OLD-GROWTH": 2367,
    "Wattle and Daub Wall.BOTTOM.OLD-GROWTH": 2368,
    "Wattle and Daub Wall.RIGHT.BOTTOM.OLD-GROWTH": 2369,
    "Wattle and Daub Wall.LEFT.RIGHT.BOTTOM.OLD-GROWTH": 2370,
    "Wattle and Daub Wall.LEFT.BOTTOM.OLD-GROWTH": 2371,
    "Limestone Brick Wall.TOP.OLD-GROWTH": 2372,
    "Limestone Brick Wall.RIGHT.TOP.OLD-GROWTH": 2373,
    "Limestone Brick Wall.LEFT.RIGHT.TOP.OLD-GROWTH": 2374,
    "Limestone Brick Wall.LEFT.TOP.OLD-GROWTH": 2375,
    "Marble Wall.TOP.OLD-GROWTH": 2376,
    "Marble Wall.RIGHT.TOP.OLD-GROWTH": 2377,
    "Marble Wall.LEFT.RIGHT.TOP.OLD-GROWTH": 2378,
    "Marble Wall.LEFT.TOP.OLD-GROWTH": 2379,
    "Granite Wall.TOP.RIVER": 2380,
    "Granite Wall.RIGHT.TOP.RIVER": 2381,
    "Granite Wall.LEFT.RIGHT.TOP.RIVER": 2382,
    "Granite Wall.LEFT.TOP.RIVER": 2383,
    "Granite Brick Wall.TOP.RIVER": 2384,
    "Granite Brick Wall.RIGHT.TOP.RIVER": 2385,
    "Granite Brick Wall.LEFT.RIGHT.TOP.RIVER": 2386,
    "Granite Brick Wall.LEFT.TOP.RIVER": 2387,
    "Wooden Pallisade Wall.TOP.RIVER": 2388,
    "Wooden Pallisade Wall.RIGHT.TOP.RIVER": 2389,
    "Wooden Pallisade Wall.LEFT.RIGHT.TOP.RIVER": 2390,
    "Wooden Pallisade Wall.LEFT.TOP.RIVER": 2391,
    "Limestone Wall.TOP.RIVER": 2392,
    "Limestone Wall.RIGHT.TOP.RIVER": 2393,
    "Limestone Wall.LEFT.RIGHT.TOP.RIVER": 2394,
    "Limestone Wall.LEFT.TOP.RIVER": 2395,
    "Limestone Brick Wall.BOTTOM.RIVER": 1820,
    "Limestone Brick Wall.RIGHT.BOTTOM.RIVER": 1821,
    "Limestone Brick Wall.LEFT.RIGHT.BOTTOM.RIVER": 1822,
    "Limestone Brick Wall.LEFT.BOTTOM.RIVER": 1823,
    "Granite Wall.NONE.OLD-GROWTH": 2400,
    "Granite Wall.RIGHT.OLD-GROWTH": 2401,
    "Granite Wall.LEFT.RIGHT.OLD-GROWTH": 2402,
    "Granite Wall.LEFT.OLD-GROWTH": 2403,
    "Granite Brick Wall.NONE.OLD-GROWTH": 2404,
    "Granite Brick Wall.RIGHT.OLD-GROWTH": 2405,
    "Granite Brick Wall.LEFT.RIGHT.OLD-GROWTH": 2406,
    "Granite Brick Wall.LEFT.OLD-GROWTH": 2407,
    "Wooden Pallisade Wall.NONE.OLD-GROWTH": 2408,
    "Wooden Pallisade Wall.RIGHT.OLD-GROWTH": 2409,
    "Wooden Pallisade Wall.LEFT.RIGHT.OLD-GROWTH": 2410,
    "Wooden Pallisade Wall.LEFT.OLD-GROWTH": 2411,
    "Limestone Wall.NONE.OLD-GROWTH": 2412,
    "Limestone Wall.RIGHT.OLD-GROWTH": 2413,
    "Limestone Wall.LEFT.RIGHT.OLD-GROWTH": 2414,
    "Limestone Wall.LEFT.OLD-GROWTH": 2415,
    "Wattle and Daub Wall.TOP.BOTTOM.OLD-GROWTH": 2416,
    "Wattle and Daub Wall.RIGHT.TOP.BOTTOM.OLD-GROWTH": 2417,
    "Wattle and Daub Wall.LEFT.RIGHT.TOP.BOTTOM.OLD-GROWTH": 2418,
    "Wattle and Daub Wall.LEFT.TOP.BOTTOM.OLD-GROWTH": 2419,
    "Limestone Brick Wall.NONE.OLD-GROWTH": 2420,
    "Limestone Brick Wall.RIGHT.OLD-GROWTH": 2421,
    "Limestone Brick Wall.LEFT.RIGHT.OLD-GROWTH": 2422,
    "Limestone Brick Wall.LEFT.OLD-GROWTH": 2423,
    "Marble Wall.NONE.OLD-GROWTH": 2424,
    "Marble Wall.RIGHT.OLD-GROWTH": 2425,
    "Marble Wall.LEFT.RIGHT.OLD-GROWTH": 2426,
    "Marble Wall.LEFT.OLD-GROWTH": 2427,
    "Granite Wall.NONE.RIVER": 2428,
    "Granite Wall.RIGHT.RIVER": 2429,
    "Granite Wall.LEFT.RIGHT.RIVER": 2430,
    "Granite Wall.LEFT.RIVER": 2431,
    "Granite Brick Wall.NONE.RIVER": 2432,
    "Granite Brick Wall.RIGHT.RIVER": 2433,
    "Granite Brick Wall.LEFT.RIGHT.RIVER": 2434,
    "Granite Brick Wall.LEFT.RIVER": 2435,
    "Wooden Pallisade Wall.NONE.RIVER": 2436,
    "Wooden Pallisade Wall.RIGHT.RIVER": 2437,
    "Wooden Pallisade Wall.LEFT.RIGHT.RIVER": 2438,
    "Wooden Pallisade Wall.LEFT.RIVER": 2439,
    "Limestone Wall.NONE.RIVER": 2440,
    "Limestone Wall.RIGHT.RIVER": 2441,
    "Limestone Wall.LEFT.RIGHT.RIVER": 2442,
    "Limestone Wall.LEFT.RIVER": 2443,
    "Limestone Brick Wall.TOP.BOTTOM.RIVER": 1868,
    "Limestone Brick Wall.RIGHT.TOP.BOTTOM.RIVER": 1869,
    "Limestone Brick Wall.LEFT.RIGHT.TOP.BOTTOM.RIVER": 1870,
    "Limestone Brick Wall.LEFT.TOP.BOTTOM.RIVER": 1871,
    "Wooden Log Wall.TOP.OLD-GROWTH": 2448,
    "Wooden Log Wall.RIGHT.TOP.OLD-GROWTH": 2449,
    "Wooden Log Wall.LEFT.RIGHT.TOP.OLD-GROWTH": 2450,
    "Wooden Log Wall.LEFT.TOP.OLD-GROWTH": 2451,
    "Basalt Wall.TOP.OLD-GROWTH": 2452,
    "Basalt Wall.RIGHT.TOP.OLD-GROWTH": 2453,
    "Basalt Wall.LEFT.RIGHT.TOP.OLD-GROWTH": 2454,
    "Basalt Wall.LEFT.TOP.OLD-GROWTH": 2455,
    "Mud Brick Wall.TOP.OLD-GROWTH": 2456,
    "Mud Brick Wall.RIGHT.TOP.OLD-GROWTH": 2457,
    "Mud Brick Wall.LEFT.RIGHT.TOP.OLD-GROWTH": 2458,
    "Mud Brick Wall.LEFT.TOP.OLD-GROWTH": 2459,
    "Basalt Brick Wall.TOP.OLD-GROWTH": 2460,
    "Basalt Brick Wall.RIGHT.TOP.OLD-GROWTH": 2461,
    "Basalt Brick Wall.LEFT.RIGHT.TOP.OLD-GROWTH": 2462,
    "Basalt Brick Wall.LEFT.TOP.OLD-GROWTH": 2463,
    "Wattle and Daub Wall.TOP.OLD-GROWTH": 2464,
    "Wattle and Daub Wall.RIGHT.TOP.OLD-GROWTH": 2465,
    "Wattle and Daub Wall.LEFT.RIGHT.TOP.OLD-GROWTH": 2466,
    "Wattle and Daub Wall.LEFT.TOP.OLD-GROWTH": 2467,
    "Clay Brick Wall.TOP.OLD-GROWTH": 2468,
    "Clay Brick Wall.RIGHT.TOP.OLD-GROWTH": 2469,
    "Clay Brick Wall.LEFT.RIGHT.TOP.OLD-GROWTH": 2470,
    "Clay Brick Wall.LEFT.TOP.OLD-GROWTH": 2471,
    "Marble Brick Wall.TOP.RIVER": 2472,
    "Marble Brick Wall.RIGHT.TOP.RIVER": 2473,
    "Marble Brick Wall.LEFT.RIGHT.TOP.RIVER": 2474,
    "Marble Brick Wall.LEFT.TOP.RIVER": 2475,
    "Wooden Log Wall.TOP.RIVER": 2476,
    "Wooden Log Wall.RIGHT.TOP.RIVER": 2477,
    "Wooden Log Wall.LEFT.RIGHT.TOP.RIVER": 2478,
    "Wooden Log Wall.LEFT.TOP.RIVER": 2479,
    "Basalt Wall.TOP.RIVER": 2480,
    "Basalt Wall.RIGHT.TOP.RIVER": 2481,
    "Basalt Wall.LEFT.RIGHT.TOP.RIVER": 2482,
    "Basalt Wall.LEFT.TOP.RIVER": 2483,
    "Mud Brick Wall.TOP.RIVER": 2484,
    "Mud Brick Wall.RIGHT.TOP.RIVER": 2485,
    "Mud Brick Wall.LEFT.RIGHT.TOP.RIVER": 2486,
    "Mud Brick Wall.LEFT.TOP.RIVER": 2487,
    "Basalt Brick Wall.TOP.RIVER": 2488,
    "Basalt Brick Wall.RIGHT.TOP.RIVER": 2489,
    "Basalt Brick Wall.LEFT.RIGHT.TOP.RIVER": 2490,
    "Basalt Brick Wall.LEFT.TOP.RIVER": 2491,
    "Limestone Brick Wall.TOP.RIVER": 2492,
    "Limestone Brick Wall.RIGHT.TOP.RIVER": 2493,
    "Limestone Brick Wall.LEFT.RIGHT.TOP.RIVER": 2494,
    "Limestone Brick Wall.LEFT.TOP.RIVER": 2495,
    "Wooden Log Wall.NONE.OLD-GROWTH": 2496,
    "Wooden Log Wall.RIGHT.OLD-GROWTH": 2497,
    "Wooden Log Wall.LEFT.RIGHT.OLD-GROWTH": 2498,
    "Wooden Log Wall.LEFT.OLD-GROWTH": 2499,
    "Basalt Wall.NONE.OLD-GROWTH": 2500,
    "Basalt Wall.RIGHT.OLD-GROWTH": 2501,
    "Basalt Wall.LEFT.RIGHT.OLD-GROWTH": 2502,
    "Basalt Wall.LEFT.OLD-GROWTH": 2503,
    "Mud Brick Wall.NONE.OLD-GROWTH": 2504,
    "Mud Brick Wall.RIGHT.OLD-GROWTH": 2505,
    "Mud Brick Wall.LEFT.RIGHT.OLD-GROWTH": 2506,
    "Mud Brick Wall.LEFT.OLD-GROWTH": 2507,
    "Basalt Brick Wall.NONE.OLD-GROWTH": 2508,
    "Basalt Brick Wall.RIGHT.OLD-GROWTH": 2509,
    "Basalt Brick Wall.LEFT.RIGHT.OLD-GROWTH": 2510,
    "Basalt Brick Wall.LEFT.OLD-GROWTH": 2511,
    "Wattle and Daub Wall.NONE.OLD-GROWTH": 2512,
    "Wattle and Daub Wall.RIGHT.OLD-GROWTH": 2513,
    "Wattle and Daub Wall.LEFT.RIGHT.OLD-GROWTH": 2514,
    "Wattle and Daub Wall.LEFT.OLD-GROWTH": 2515,
    "Clay Brick Wall.NONE.OLD-GROWTH": 2516,
    "Clay Brick Wall.RIGHT.OLD-GROWTH": 2517,
    "Clay Brick Wall.LEFT.RIGHT.OLD-GROWTH": 2518,
    "Clay Brick Wall.LEFT.OLD-GROWTH": 2519,
    "Marble Brick Wall.NONE.RIVER": 2520,
    "Marble Brick Wall.RIGHT.RIVER": 2521,
    "Marble Brick Wall.LEFT.RIGHT.RIVER": 2522,
    "Marble Brick Wall.LEFT.RIVER": 2523,
    "Wooden Log Wall.NONE.RIVER": 2524,
    "Wooden Log Wall.RIGHT.RIVER": 2525,
    "Wooden Log Wall.LEFT.RIGHT.RIVER": 2526,
    "Wooden Log Wall.LEFT.RIVER": 2527,
    "Basalt Wall.NONE.RIVER": 2528,
    "Basalt Wall.RIGHT.RIVER": 2529,
    "Basalt Wall.LEFT.RIGHT.RIVER": 2530,
    "Basalt Wall.LEFT.RIVER": 2531,
    "Mud Brick Wall.NONE.RIVER": 2532,
    "Mud Brick Wall.RIGHT.RIVER": 2533,
    "Mud Brick Wall.LEFT.RIGHT.RIVER": 2534,
    "Mud Brick Wall.LEFT.RIVER": 2535,
    "Basalt Brick Wall.NONE.RIVER": 2536,
    "Basalt Brick Wall.RIGHT.RIVER": 2537,
    "Basalt Brick Wall.LEFT.RIGHT.RIVER": 2538,
    "Basalt Brick Wall.LEFT.RIVER": 2539,
    "Limestone Brick Wall.NONE.RIVER": 2540,
    "Limestone Brick Wall.RIGHT.RIVER": 2541,
    "Limestone Brick Wall.LEFT.RIGHT.RIVER": 2542,
    "Limestone Brick Wall.LEFT.RIVER": 2543,
    "Wattle and Daub Wall.TOP.RIVER": 2544,
    "Wattle and Daub Wall.RIGHT.TOP.RIVER": 2545,
    "Wattle and Daub Wall.LEFT.RIGHT.TOP.RIVER": 2546,
    "Wattle and Daub Wall.LEFT.TOP.RIVER": 2547,
    "Marble Wall.TOP.RIVER": 2548,
    "Marble Wall.RIGHT.TOP.RIVER": 2549,
    "Marble Wall.LEFT.RIGHT.TOP.RIVER": 2550,
    "Marble Wall.LEFT.TOP.RIVER": 2551,
    "Marble Brick Wall.BOTTOM.POLAR": 1780,
    "Marble Brick Wall.RIGHT.BOTTOM.POLAR": 1781,
    "Marble Brick Wall.LEFT.RIGHT.BOTTOM.POLAR": 1782,
    "Marble Brick Wall.LEFT.BOTTOM.POLAR": 1783,
    "Wooden Log Wall.TOP.POLAR": 2556,
    "Wooden Log Wall.RIGHT.TOP.POLAR": 2557,
    "Wooden Log Wall.LEFT.RIGHT.TOP.POLAR": 2558,
    "Wooden Log Wall.LEFT.TOP.POLAR": 2559,
    "Basalt Wall.TOP.POLAR": 2560,
    "Basalt Wall.RIGHT.TOP.POLAR": 2561,
    "Basalt Wall.LEFT.RIGHT.TOP.POLAR": 2562,
    "Basalt Wall.LEFT.TOP.POLAR": 2563,
    "Mud Brick Wall.TOP.POLAR": 2564,
    "Mud Brick Wall.RIGHT.TOP.POLAR": 2565,
    "Mud Brick Wall.LEFT.RIGHT.TOP.POLAR": 2566,
    "Mud Brick Wall.LEFT.TOP.POLAR": 2567,
    "Basalt Brick Wall.TOP.POLAR": 2568,
    "Basalt Brick Wall.RIGHT.TOP.POLAR": 2569,
    "Basalt Brick Wall.LEFT.RIGHT.TOP.POLAR": 2570,
    "Basalt Brick Wall.LEFT.TOP.POLAR": 2571,
    "Limestone Brick Wall.TOP.POLAR": 2572,
    "Limestone Brick Wall.RIGHT.TOP.POLAR": 2573,
    "Limestone Brick Wall.LEFT.RIGHT.TOP.POLAR": 2574,
    "Limestone Brick Wall.LEFT.TOP.POLAR": 2575,
    "empty..empty": 2687,
    "Wattle and Daub Wall.NONE.RIVER": 2592,
    "Wattle and Daub Wall.RIGHT.RIVER": 2593,
    "Wattle and Daub Wall.LEFT.RIGHT.RIVER": 2594,
    "Wattle and Daub Wall.LEFT.RIVER": 2595,
    "Marble Wall.NONE.RIVER": 2596,
    "Marble Wall.RIGHT.RIVER": 2597,
    "Marble Wall.LEFT.RIGHT.RIVER": 2598,
    "Marble Wall.LEFT.RIVER": 2599,
    "Marble Brick Wall.TOP.BOTTOM.POLAR": 1828,
    "Marble Brick Wall.RIGHT.TOP.BOTTOM.POLAR": 1829,
    "Marble Brick Wall.LEFT.RIGHT.TOP.BOTTOM.POLAR": 1830,
    "Marble Brick Wall.LEFT.TOP.BOTTOM.POLAR": 1831,
    "Wooden Log Wall.NONE.POLAR": 2604,
    "Wooden Log Wall.RIGHT.POLAR": 2605,
    "Wooden Log Wall.LEFT.RIGHT.POLAR": 2606,
    "Wooden Log Wall.LEFT.POLAR": 2607,
    "Basalt Wall.NONE.POLAR": 2608,
    "Basalt Wall.RIGHT.POLAR": 2609,
    "Basalt Wall.LEFT.RIGHT.POLAR": 2610,
    "Basalt Wall.LEFT.POLAR": 2611,
    "Mud Brick Wall.NONE.POLAR": 2612,
    "Mud Brick Wall.RIGHT.POLAR": 2613,
    "Mud Brick Wall.LEFT.RIGHT.POLAR": 2614,
    "Mud Brick Wall.LEFT.POLAR": 2615,
    "Basalt Brick Wall.NONE.POLAR": 2616,
    "Basalt Brick Wall.RIGHT.POLAR": 2617,
    "Basalt Brick Wall.LEFT.RIGHT.POLAR": 2618,
    "Basalt Brick Wall.LEFT.POLAR": 2619,
    "Limestone Brick Wall.NONE.POLAR": 2620,
    "Limestone Brick Wall.RIGHT.POLAR": 2621,
    "Limestone Brick Wall.LEFT.RIGHT.POLAR": 2622,
    "Limestone Brick Wall.LEFT.POLAR": 2623,
    "Clay Brick Wall.TOP.RIVER": 2640,
    "Clay Brick Wall.RIGHT.TOP.RIVER": 2641,
    "Clay Brick Wall.LEFT.RIGHT.TOP.RIVER": 2642,
    "Clay Brick Wall.LEFT.TOP.RIVER": 2643,
    "Granite Wall.TOP.POLAR": 2644,
    "Granite Wall.RIGHT.TOP.POLAR": 2645,
    "Granite Wall.LEFT.RIGHT.TOP.POLAR": 2646,
    "Granite Wall.LEFT.TOP.POLAR": 2647,
    "Marble Brick Wall.TOP.POLAR": 2648,
    "Marble Brick Wall.RIGHT.TOP.POLAR": 2649,
    "Marble Brick Wall.LEFT.RIGHT.TOP.POLAR": 2650,
    "Marble Brick Wall.LEFT.TOP.POLAR": 2651,
    "Granite Brick Wall.TOP.POLAR": 2652,
    "Granite Brick Wall.RIGHT.TOP.POLAR": 2653,
    "Granite Brick Wall.LEFT.RIGHT.TOP.POLAR": 2654,
    "Granite Brick Wall.LEFT.TOP.POLAR": 2655,
    "Wooden Pallisade Wall.TOP.POLAR": 2656,
    "Wooden Pallisade Wall.RIGHT.TOP.POLAR": 2657,
    "Wooden Pallisade Wall.LEFT.RIGHT.TOP.POLAR": 2658,
    "Wooden Pallisade Wall.LEFT.TOP.POLAR": 2659,
    "Limestone Wall.TOP.POLAR": 2660,
    "Limestone Wall.RIGHT.TOP.POLAR": 2661,
    "Limestone Wall.LEFT.RIGHT.TOP.POLAR": 2662,
    "Limestone Wall.LEFT.TOP.POLAR": 2663,
    "Wattle and Daub Wall.TOP.POLAR": 2664,
    "Wattle and Daub Wall.RIGHT.TOP.POLAR": 2665,
    "Wattle and Daub Wall.LEFT.RIGHT.TOP.POLAR": 2666,
    "Wattle and Daub Wall.LEFT.TOP.POLAR": 2667,
    "Clay Brick Wall.TOP.POLAR": 2668,
    "Clay Brick Wall.RIGHT.TOP.POLAR": 2669,
    "Clay Brick Wall.LEFT.RIGHT.TOP.POLAR": 2670,
    "Clay Brick Wall.LEFT.TOP.POLAR": 2671,
    "Clay Brick Wall.NONE.RIVER": 2688,
    "Clay Brick Wall.RIGHT.RIVER": 2689,
    "Clay Brick Wall.LEFT.RIGHT.RIVER": 2690,
    "Clay Brick Wall.LEFT.RIVER": 2691,
    "Granite Wall.NONE.POLAR": 2692,
    "Granite Wall.RIGHT.POLAR": 2693,
    "Granite Wall.LEFT.RIGHT.POLAR": 2694,
    "Granite Wall.LEFT.POLAR": 2695,
    "Marble Brick Wall.NONE.POLAR": 2696,
    "Marble Brick Wall.RIGHT.POLAR": 2697,
    "Marble Brick Wall.LEFT.RIGHT.POLAR": 2698,
    "Marble Brick Wall.LEFT.POLAR": 2699,
    "Granite Brick Wall.NONE.POLAR": 2700,
    "Granite Brick Wall.RIGHT.POLAR": 2701,
    "Granite Brick Wall.LEFT.RIGHT.POLAR": 2702,
    "Granite Brick Wall.LEFT.POLAR": 2703,
    "Wooden Pallisade Wall.NONE.POLAR": 2704,
    "Wooden Pallisade Wall.RIGHT.POLAR": 2705,
    "Wooden Pallisade Wall.LEFT.RIGHT.POLAR": 2706,
    "Wooden Pallisade Wall.LEFT.POLAR": 2707,
    "Limestone Wall.NONE.POLAR": 2708,
    "Limestone Wall.RIGHT.POLAR": 2709,
    "Limestone Wall.LEFT.RIGHT.POLAR": 2710,
    "Limestone Wall.LEFT.POLAR": 2711,
    "Wattle and Daub Wall.NONE.POLAR": 2712,
    "Wattle and Daub Wall.RIGHT.POLAR": 2713,
    "Wattle and Daub Wall.LEFT.RIGHT.POLAR": 2714,
    "Wattle and Daub Wall.LEFT.POLAR": 2715,
    "Clay Brick Wall.NONE.POLAR": 2716,
    "Clay Brick Wall.RIGHT.POLAR": 2717,
    "Clay Brick Wall.LEFT.RIGHT.POLAR": 2718,
    "Clay Brick Wall.LEFT.POLAR": 2719,
    "Marble Brick Wall.BOTTOM.TAIGA": 1584,
    "Granite Wall.BOTTOM.TAIGA": 1588,
    "Wooden Log Wall.BOTTOM.TAIGA": 1592,
    "Basalt Wall.BOTTOM.TAIGA": 1600,
    "Wooden Pallisade Wall.BOTTOM.TAIGA": 1604,
    "Mud Brick Wall.BOTTOM.TAIGA": 1608,
    "Limestone Wall.BOTTOM.TAIGA": 1612,
    "Basalt Brick Wall.BOTTOM.TAIGA": 1616,
    "Wattle and Daub Wall.BOTTOM.TAIGA": 1620,
    "Limestone Brick Wall.BOTTOM.TAIGA": 1624,
    "Clay Brick Wall.BOTTOM.TAIGA": 1628,
    "Marble Brick Wall.RIGHT.BOTTOM.TAIGA": 1585,
    "Granite Wall.RIGHT.BOTTOM.TAIGA": 1589,
    "Wooden Log Wall.RIGHT.BOTTOM.TAIGA": 1593,
    "Basalt Wall.RIGHT.BOTTOM.TAIGA": 1601,
    "Wooden Pallisade Wall.RIGHT.BOTTOM.TAIGA": 1605,
    "Mud Brick Wall.RIGHT.BOTTOM.TAIGA": 1609,
    "Limestone Wall.RIGHT.BOTTOM.TAIGA": 1613,
    "Basalt Brick Wall.RIGHT.BOTTOM.TAIGA": 1617,
    "Wattle and Daub Wall.RIGHT.BOTTOM.TAIGA": 1621,
    "Limestone Brick Wall.RIGHT.BOTTOM.TAIGA": 1625,
    "Clay Brick Wall.RIGHT.BOTTOM.TAIGA": 1629,
    "Marble Brick Wall.LEFT.RIGHT.BOTTOM.TAIGA": 1586,
    "Granite Wall.LEFT.RIGHT.BOTTOM.TAIGA": 1590,
    "Wooden Log Wall.LEFT.RIGHT.BOTTOM.TAIGA": 1594,
    "Basalt Wall.LEFT.RIGHT.BOTTOM.TAIGA": 1602,
    "Wooden Pallisade Wall.LEFT.RIGHT.BOTTOM.TAIGA": 1606,
    "Mud Brick Wall.LEFT.RIGHT.BOTTOM.TAIGA": 1610,
    "Limestone Wall.LEFT.RIGHT.BOTTOM.TAIGA": 1614,
    "Basalt Brick Wall.LEFT.RIGHT.BOTTOM.TAIGA": 1618,
    "Wattle and Daub Wall.LEFT.RIGHT.BOTTOM.TAIGA": 1622,
    "Limestone Brick Wall.LEFT.RIGHT.BOTTOM.TAIGA": 1626,
    "Clay Brick Wall.LEFT.RIGHT.BOTTOM.TAIGA": 1630,
    "Marble Brick Wall.LEFT.BOTTOM.TAIGA": 1587,
    "Granite Wall.LEFT.BOTTOM.TAIGA": 1591,
    "Wooden Log Wall.LEFT.BOTTOM.TAIGA": 1595,
    "Basalt Wall.LEFT.BOTTOM.TAIGA": 1603,
    "Wooden Pallisade Wall.LEFT.BOTTOM.TAIGA": 1607,
    "Mud Brick Wall.LEFT.BOTTOM.TAIGA": 1611,
    "Limestone Wall.LEFT.BOTTOM.TAIGA": 1615,
    "Basalt Brick Wall.LEFT.BOTTOM.TAIGA": 1619,
    "Wattle and Daub Wall.LEFT.BOTTOM.TAIGA": 1623,
    "Limestone Brick Wall.LEFT.BOTTOM.TAIGA": 1627,
    "Clay Brick Wall.LEFT.BOTTOM.TAIGA": 1631,
    "Marble Brick Wall.TOP.BOTTOM.TAIGA": 1632,
    "Granite Wall.TOP.BOTTOM.TAIGA": 1636,
    "Wooden Log Wall.TOP.BOTTOM.TAIGA": 1640,
    "Basalt Wall.TOP.BOTTOM.TAIGA": 1648,
    "Wooden Pallisade Wall.TOP.BOTTOM.TAIGA": 1652,
    "Mud Brick Wall.TOP.BOTTOM.TAIGA": 1656,
    "Limestone Wall.TOP.BOTTOM.TAIGA": 1660,
    "Basalt Brick Wall.TOP.BOTTOM.TAIGA": 1664,
    "Wattle and Daub Wall.TOP.BOTTOM.TAIGA": 1668,
    "Limestone Brick Wall.TOP.BOTTOM.TAIGA": 1672,
    "Clay Brick Wall.TOP.BOTTOM.TAIGA": 1676,
    "Marble Brick Wall.RIGHT.TOP.BOTTOM.TAIGA": 1633,
    "Granite Wall.RIGHT.TOP.BOTTOM.TAIGA": 1637,
    "Wooden Log Wall.RIGHT.TOP.BOTTOM.TAIGA": 1641,
    "Basalt Wall.RIGHT.TOP.BOTTOM.TAIGA": 1649,
    "Wooden Pallisade Wall.RIGHT.TOP.BOTTOM.TAIGA": 1653,
    "Mud Brick Wall.RIGHT.TOP.BOTTOM.TAIGA": 1657,
    "Limestone Wall.RIGHT.TOP.BOTTOM.TAIGA": 1661,
    "Basalt Brick Wall.RIGHT.TOP.BOTTOM.TAIGA": 1665,
    "Wattle and Daub Wall.RIGHT.TOP.BOTTOM.TAIGA": 1669,
    "Limestone Brick Wall.RIGHT.TOP.BOTTOM.TAIGA": 1673,
    "Clay Brick Wall.RIGHT.TOP.BOTTOM.TAIGA": 1677,
    "Marble Brick Wall.LEFT.RIGHT.TOP.BOTTOM.TAIGA": 1634,
    "Granite Wall.LEFT.RIGHT.TOP.BOTTOM.TAIGA": 1638,
    "Wooden Log Wall.LEFT.RIGHT.TOP.BOTTOM.TAIGA": 1642,
    "Basalt Wall.LEFT.RIGHT.TOP.BOTTOM.TAIGA": 1650,
    "Wooden Pallisade Wall.LEFT.RIGHT.TOP.BOTTOM.TAIGA": 1654,
    "Mud Brick Wall.LEFT.RIGHT.TOP.BOTTOM.TAIGA": 1658,
    "Limestone Wall.LEFT.RIGHT.TOP.BOTTOM.TAIGA": 1662,
    "Basalt Brick Wall.LEFT.RIGHT.TOP.BOTTOM.TAIGA": 1666,
    "Wattle and Daub Wall.LEFT.RIGHT.TOP.BOTTOM.TAIGA": 1670,
    "Limestone Brick Wall.LEFT.RIGHT.TOP.BOTTOM.TAIGA": 1674,
    "Clay Brick Wall.LEFT.RIGHT.TOP.BOTTOM.TAIGA": 1678,
    "Marble Brick Wall.LEFT.TOP.BOTTOM.TAIGA": 1635,
    "Granite Wall.LEFT.TOP.BOTTOM.TAIGA": 1639,
    "Wooden Log Wall.LEFT.TOP.BOTTOM.TAIGA": 1643,
    "Basalt Wall.LEFT.TOP.BOTTOM.TAIGA": 1651,
    "Wooden Pallisade Wall.LEFT.TOP.BOTTOM.TAIGA": 1655,
    "Mud Brick Wall.LEFT.TOP.BOTTOM.TAIGA": 1659,
    "Limestone Wall.LEFT.TOP.BOTTOM.TAIGA": 1663,
    "Basalt Brick Wall.LEFT.TOP.BOTTOM.TAIGA": 1667,
    "Wattle and Daub Wall.LEFT.TOP.BOTTOM.TAIGA": 1671,
    "Limestone Brick Wall.LEFT.TOP.BOTTOM.TAIGA": 1675,
    "Clay Brick Wall.LEFT.TOP.BOTTOM.TAIGA": 1679,
    "Marble Brick Wall.BOTTOM.JUNGLE": 1584,
    "Granite Wall.BOTTOM.JUNGLE": 1588,
    "Wooden Log Wall.BOTTOM.JUNGLE": 1592,
    "Granite Brick Wall.BOTTOM.JUNGLE": 1596,
    "Wooden Pallisade Wall.BOTTOM.JUNGLE": 1604,
    "Mud Brick Wall.BOTTOM.JUNGLE": 1608,
    "Limestone Wall.BOTTOM.JUNGLE": 1612,
    "Basalt Brick Wall.BOTTOM.JUNGLE": 1616,
    "Wattle and Daub Wall.BOTTOM.JUNGLE": 1620,
    "Limestone Brick Wall.BOTTOM.JUNGLE": 1624,
    "Clay Brick Wall.BOTTOM.JUNGLE": 1628,
    "Marble Brick Wall.RIGHT.BOTTOM.JUNGLE": 1585,
    "Granite Wall.RIGHT.BOTTOM.JUNGLE": 1589,
    "Wooden Log Wall.RIGHT.BOTTOM.JUNGLE": 1593,
    "Granite Brick Wall.RIGHT.BOTTOM.JUNGLE": 1597,
    "Wooden Pallisade Wall.RIGHT.BOTTOM.JUNGLE": 1605,
    "Mud Brick Wall.RIGHT.BOTTOM.JUNGLE": 1609,
    "Limestone Wall.RIGHT.BOTTOM.JUNGLE": 1613,
    "Basalt Brick Wall.RIGHT.BOTTOM.JUNGLE": 1617,
    "Wattle and Daub Wall.RIGHT.BOTTOM.JUNGLE": 1621,
    "Limestone Brick Wall.RIGHT.BOTTOM.JUNGLE": 1625,
    "Clay Brick Wall.RIGHT.BOTTOM.JUNGLE": 1629,
    "Marble Brick Wall.LEFT.RIGHT.BOTTOM.JUNGLE": 1586,
    "Granite Wall.LEFT.RIGHT.BOTTOM.JUNGLE": 1590,
    "Wooden Log Wall.LEFT.RIGHT.BOTTOM.JUNGLE": 1594,
    "Granite Brick Wall.LEFT.RIGHT.BOTTOM.JUNGLE": 1598,
    "Wooden Pallisade Wall.LEFT.RIGHT.BOTTOM.JUNGLE": 1606,
    "Mud Brick Wall.LEFT.RIGHT.BOTTOM.JUNGLE": 1610,
    "Limestone Wall.LEFT.RIGHT.BOTTOM.JUNGLE": 1614,
    "Basalt Brick Wall.LEFT.RIGHT.BOTTOM.JUNGLE": 1618,
    "Wattle and Daub Wall.LEFT.RIGHT.BOTTOM.JUNGLE": 1622,
    "Limestone Brick Wall.LEFT.RIGHT.BOTTOM.JUNGLE": 1626,
    "Clay Brick Wall.LEFT.RIGHT.BOTTOM.JUNGLE": 1630,
    "Marble Brick Wall.LEFT.BOTTOM.JUNGLE": 1587,
    "Granite Wall.LEFT.BOTTOM.JUNGLE": 1591,
    "Wooden Log Wall.LEFT.BOTTOM.JUNGLE": 1595,
    "Granite Brick Wall.LEFT.BOTTOM.JUNGLE": 1599,
    "Wooden Pallisade Wall.LEFT.BOTTOM.JUNGLE": 1607,
    "Mud Brick Wall.LEFT.BOTTOM.JUNGLE": 1611,
    "Limestone Wall.LEFT.BOTTOM.JUNGLE": 1615,
    "Basalt Brick Wall.LEFT.BOTTOM.JUNGLE": 1619,
    "Wattle and Daub Wall.LEFT.BOTTOM.JUNGLE": 1623,
    "Limestone Brick Wall.LEFT.BOTTOM.JUNGLE": 1627,
    "Clay Brick Wall.LEFT.BOTTOM.JUNGLE": 1631,
    "Marble Brick Wall.TOP.BOTTOM.JUNGLE": 1632,
    "Granite Wall.TOP.BOTTOM.JUNGLE": 1636,
    "Wooden Log Wall.TOP.BOTTOM.JUNGLE": 1640,
    "Granite Brick Wall.TOP.BOTTOM.JUNGLE": 1644,
    "Wooden Pallisade Wall.TOP.BOTTOM.JUNGLE": 1652,
    "Mud Brick Wall.TOP.BOTTOM.JUNGLE": 1656,
    "Limestone Wall.TOP.BOTTOM.JUNGLE": 1660,
    "Basalt Brick Wall.TOP.BOTTOM.JUNGLE": 1664,
    "Wattle and Daub Wall.TOP.BOTTOM.JUNGLE": 1668,
    "Limestone Brick Wall.TOP.BOTTOM.JUNGLE": 1672,
    "Clay Brick Wall.TOP.BOTTOM.JUNGLE": 1676,
    "Marble Brick Wall.RIGHT.TOP.BOTTOM.JUNGLE": 1633,
    "Granite Wall.RIGHT.TOP.BOTTOM.JUNGLE": 1637,
    "Wooden Log Wall.RIGHT.TOP.BOTTOM.JUNGLE": 1641,
    "Granite Brick Wall.RIGHT.TOP.BOTTOM.JUNGLE": 1645,
    "Wooden Pallisade Wall.RIGHT.TOP.BOTTOM.JUNGLE": 1653,
    "Mud Brick Wall.RIGHT.TOP.BOTTOM.JUNGLE": 1657,
    "Limestone Wall.RIGHT.TOP.BOTTOM.JUNGLE": 1661,
    "Basalt Brick Wall.RIGHT.TOP.BOTTOM.JUNGLE": 1665,
    "Wattle and Daub Wall.RIGHT.TOP.BOTTOM.JUNGLE": 1669,
    "Limestone Brick Wall.RIGHT.TOP.BOTTOM.JUNGLE": 1673,
    "Clay Brick Wall.RIGHT.TOP.BOTTOM.JUNGLE": 1677,
    "Marble Brick Wall.LEFT.RIGHT.TOP.BOTTOM.JUNGLE": 1634,
    "Granite Wall.LEFT.RIGHT.TOP.BOTTOM.JUNGLE": 1638,
    "Wooden Log Wall.LEFT.RIGHT.TOP.BOTTOM.JUNGLE": 1642,
    "Granite Brick Wall.LEFT.RIGHT.TOP.BOTTOM.JUNGLE": 1646,
    "Wooden Pallisade Wall.LEFT.RIGHT.TOP.BOTTOM.JUNGLE": 1654,
    "Mud Brick Wall.LEFT.RIGHT.TOP.BOTTOM.JUNGLE": 1658,
    "Limestone Wall.LEFT.RIGHT.TOP.BOTTOM.JUNGLE": 1662,
    "Basalt Brick Wall.LEFT.RIGHT.TOP.BOTTOM.JUNGLE": 1666,
    "Wattle and Daub Wall.LEFT.RIGHT.TOP.BOTTOM.JUNGLE": 1670,
    "Limestone Brick Wall.LEFT.RIGHT.TOP.BOTTOM.JUNGLE": 1674,
    "Clay Brick Wall.LEFT.RIGHT.TOP.BOTTOM.JUNGLE": 1678,
    "Marble Brick Wall.LEFT.TOP.BOTTOM.JUNGLE": 1635,
    "Granite Wall.LEFT.TOP.BOTTOM.JUNGLE": 1639,
    "Wooden Log Wall.LEFT.TOP.BOTTOM.JUNGLE": 1643,
    "Granite Brick Wall.LEFT.TOP.BOTTOM.JUNGLE": 1647,
    "Wooden Pallisade Wall.LEFT.TOP.BOTTOM.JUNGLE": 1655,
    "Mud Brick Wall.LEFT.TOP.BOTTOM.JUNGLE": 1659,
    "Limestone Wall.LEFT.TOP.BOTTOM.JUNGLE": 1663,
    "Basalt Brick Wall.LEFT.TOP.BOTTOM.JUNGLE": 1667,
    "Wattle and Daub Wall.LEFT.TOP.BOTTOM.JUNGLE": 1671,
    "Limestone Brick Wall.LEFT.TOP.BOTTOM.JUNGLE": 1675,
    "Clay Brick Wall.LEFT.TOP.BOTTOM.JUNGLE": 1679,
    "Marble Brick Wall.BOTTOM.ARID": 1584,
    "Granite Wall.BOTTOM.ARID": 1588,
    "Wooden Log Wall.BOTTOM.ARID": 1592,
    "Granite Brick Wall.BOTTOM.ARID": 1596,
    "Basalt Wall.BOTTOM.ARID": 1600,
    "Wooden Pallisade Wall.BOTTOM.ARID": 1604,
    "Mud Brick Wall.BOTTOM.ARID": 1608,
    "Basalt Brick Wall.BOTTOM.ARID": 1616,
    "Wattle and Daub Wall.BOTTOM.ARID": 1620,
    "Limestone Brick Wall.BOTTOM.ARID": 1624,
    "Clay Brick Wall.BOTTOM.ARID": 1628,
    "Marble Brick Wall.RIGHT.BOTTOM.ARID": 1585,
    "Granite Wall.RIGHT.BOTTOM.ARID": 1589,
    "Wooden Log Wall.RIGHT.BOTTOM.ARID": 1593,
    "Granite Brick Wall.RIGHT.BOTTOM.ARID": 1597,
    "Basalt Wall.RIGHT.BOTTOM.ARID": 1601,
    "Wooden Pallisade Wall.RIGHT.BOTTOM.ARID": 1605,
    "Mud Brick Wall.RIGHT.BOTTOM.ARID": 1609,
    "Basalt Brick Wall.RIGHT.BOTTOM.ARID": 1617,
    "Wattle and Daub Wall.RIGHT.BOTTOM.ARID": 1621,
    "Limestone Brick Wall.RIGHT.BOTTOM.ARID": 1625,
    "Clay Brick Wall.RIGHT.BOTTOM.ARID": 1629,
    "Marble Brick Wall.LEFT.RIGHT.BOTTOM.ARID": 1586,
    "Granite Wall.LEFT.RIGHT.BOTTOM.ARID": 1590,
    "Wooden Log Wall.LEFT.RIGHT.BOTTOM.ARID": 1594,
    "Granite Brick Wall.LEFT.RIGHT.BOTTOM.ARID": 1598,
    "Basalt Wall.LEFT.RIGHT.BOTTOM.ARID": 1602,
    "Wooden Pallisade Wall.LEFT.RIGHT.BOTTOM.ARID": 1606,
    "Mud Brick Wall.LEFT.RIGHT.BOTTOM.ARID": 1610,
    "Basalt Brick Wall.LEFT.RIGHT.BOTTOM.ARID": 1618,
    "Wattle and Daub Wall.LEFT.RIGHT.BOTTOM.ARID": 1622,
    "Limestone Brick Wall.LEFT.RIGHT.BOTTOM.ARID": 1626,
    "Clay Brick Wall.LEFT.RIGHT.BOTTOM.ARID": 1630,
    "Marble Brick Wall.LEFT.BOTTOM.ARID": 1587,
    "Granite Wall.LEFT.BOTTOM.ARID": 1591,
    "Wooden Log Wall.LEFT.BOTTOM.ARID": 1595,
    "Granite Brick Wall.LEFT.BOTTOM.ARID": 1599,
    "Basalt Wall.LEFT.BOTTOM.ARID": 1603,
    "Wooden Pallisade Wall.LEFT.BOTTOM.ARID": 1607,
    "Mud Brick Wall.LEFT.BOTTOM.ARID": 1611,
    "Basalt Brick Wall.LEFT.BOTTOM.ARID": 1619,
    "Wattle and Daub Wall.LEFT.BOTTOM.ARID": 1623,
    "Limestone Brick Wall.LEFT.BOTTOM.ARID": 1627,
    "Clay Brick Wall.LEFT.BOTTOM.ARID": 1631,
    "Marble Brick Wall.TOP.BOTTOM.ARID": 1632,
    "Granite Wall.TOP.BOTTOM.ARID": 1636,
    "Wooden Log Wall.TOP.BOTTOM.ARID": 1640,
    "Granite Brick Wall.TOP.BOTTOM.ARID": 1644,
    "Basalt Wall.TOP.BOTTOM.ARID": 1648,
    "Wooden Pallisade Wall.TOP.BOTTOM.ARID": 1652,
    "Mud Brick Wall.TOP.BOTTOM.ARID": 1656,
    "Basalt Brick Wall.TOP.BOTTOM.ARID": 1664,
    "Wattle and Daub Wall.TOP.BOTTOM.ARID": 1668,
    "Limestone Brick Wall.TOP.BOTTOM.ARID": 1672,
    "Clay Brick Wall.TOP.BOTTOM.ARID": 1676,
    "Marble Brick Wall.RIGHT.TOP.BOTTOM.ARID": 1633,
    "Granite Wall.RIGHT.TOP.BOTTOM.ARID": 1637,
    "Wooden Log Wall.RIGHT.TOP.BOTTOM.ARID": 1641,
    "Granite Brick Wall.RIGHT.TOP.BOTTOM.ARID": 1645,
    "Basalt Wall.RIGHT.TOP.BOTTOM.ARID": 1649,
    "Wooden Pallisade Wall.RIGHT.TOP.BOTTOM.ARID": 1653,
    "Mud Brick Wall.RIGHT.TOP.BOTTOM.ARID": 1657,
    "Basalt Brick Wall.RIGHT.TOP.BOTTOM.ARID": 1665,
    "Wattle and Daub Wall.RIGHT.TOP.BOTTOM.ARID": 1669,
    "Limestone Brick Wall.RIGHT.TOP.BOTTOM.ARID": 1673,
    "Clay Brick Wall.RIGHT.TOP.BOTTOM.ARID": 1677,
    "Marble Brick Wall.LEFT.RIGHT.TOP.BOTTOM.ARID": 1634,
    "Granite Wall.LEFT.RIGHT.TOP.BOTTOM.ARID": 1638,
    "Wooden Log Wall.LEFT.RIGHT.TOP.BOTTOM.ARID": 1642,
    "Granite Brick Wall.LEFT.RIGHT.TOP.BOTTOM.ARID": 1646,
    "Basalt Wall.LEFT.RIGHT.TOP.BOTTOM.ARID": 1650,
    "Wooden Pallisade Wall.LEFT.RIGHT.TOP.BOTTOM.ARID": 1654,
    "Mud Brick Wall.LEFT.RIGHT.TOP.BOTTOM.ARID": 1658,
    "Basalt Brick Wall.LEFT.RIGHT.TOP.BOTTOM.ARID": 1666,
    "Wattle and Daub Wall.LEFT.RIGHT.TOP.BOTTOM.ARID": 1670,
    "Limestone Brick Wall.LEFT.RIGHT.TOP.BOTTOM.ARID": 1674,
    "Clay Brick Wall.LEFT.RIGHT.TOP.BOTTOM.ARID": 1678,
    "Marble Brick Wall.LEFT.TOP.BOTTOM.ARID": 1635,
    "Granite Wall.LEFT.TOP.BOTTOM.ARID": 1639,
    "Wooden Log Wall.LEFT.TOP.BOTTOM.ARID": 1643,
    "Granite Brick Wall.LEFT.TOP.BOTTOM.ARID": 1647,
    "Basalt Wall.LEFT.TOP.BOTTOM.ARID": 1651,
    "Wooden Pallisade Wall.LEFT.TOP.BOTTOM.ARID": 1655,
    "Mud Brick Wall.LEFT.TOP.BOTTOM.ARID": 1659,
    "Basalt Brick Wall.LEFT.TOP.BOTTOM.ARID": 1667,
    "Wattle and Daub Wall.LEFT.TOP.BOTTOM.ARID": 1671,
    "Limestone Brick Wall.LEFT.TOP.BOTTOM.ARID": 1675,
    "Clay Brick Wall.LEFT.TOP.BOTTOM.ARID": 1679,
    "Marble Brick Wall.BOTTOM.OLD-GROWTH": 1584,
    "Granite Wall.BOTTOM.OLD-GROWTH": 1588,
    "Wooden Log Wall.BOTTOM.OLD-GROWTH": 1592,
    "Granite Brick Wall.BOTTOM.OLD-GROWTH": 1596,
    "Basalt Wall.BOTTOM.OLD-GROWTH": 1600,
    "Wooden Pallisade Wall.BOTTOM.OLD-GROWTH": 1604,
    "Mud Brick Wall.BOTTOM.OLD-GROWTH": 1608,
    "Limestone Wall.BOTTOM.OLD-GROWTH": 1612,
    "Basalt Brick Wall.BOTTOM.OLD-GROWTH": 1616,
    "Limestone Brick Wall.BOTTOM.OLD-GROWTH": 1624,
    "Clay Brick Wall.BOTTOM.OLD-GROWTH": 1628,
    "Marble Brick Wall.RIGHT.BOTTOM.OLD-GROWTH": 1585,
    "Granite Wall.RIGHT.BOTTOM.OLD-GROWTH": 1589,
    "Wooden Log Wall.RIGHT.BOTTOM.OLD-GROWTH": 1593,
    "Granite Brick Wall.RIGHT.BOTTOM.OLD-GROWTH": 1597,
    "Basalt Wall.RIGHT.BOTTOM.OLD-GROWTH": 1601,
    "Wooden Pallisade Wall.RIGHT.BOTTOM.OLD-GROWTH": 1605,
    "Mud Brick Wall.RIGHT.BOTTOM.OLD-GROWTH": 1609,
    "Limestone Wall.RIGHT.BOTTOM.OLD-GROWTH": 1613,
    "Basalt Brick Wall.RIGHT.BOTTOM.OLD-GROWTH": 1617,
    "Limestone Brick Wall.RIGHT.BOTTOM.OLD-GROWTH": 1625,
    "Clay Brick Wall.RIGHT.BOTTOM.OLD-GROWTH": 1629,
    "Marble Brick Wall.LEFT.RIGHT.BOTTOM.OLD-GROWTH": 1586,
    "Granite Wall.LEFT.RIGHT.BOTTOM.OLD-GROWTH": 1590,
    "Wooden Log Wall.LEFT.RIGHT.BOTTOM.OLD-GROWTH": 1594,
    "Granite Brick Wall.LEFT.RIGHT.BOTTOM.OLD-GROWTH": 1598,
    "Basalt Wall.LEFT.RIGHT.BOTTOM.OLD-GROWTH": 1602,
    "Wooden Pallisade Wall.LEFT.RIGHT.BOTTOM.OLD-GROWTH": 1606,
    "Mud Brick Wall.LEFT.RIGHT.BOTTOM.OLD-GROWTH": 1610,
    "Limestone Wall.LEFT.RIGHT.BOTTOM.OLD-GROWTH": 1614,
    "Basalt Brick Wall.LEFT.RIGHT.BOTTOM.OLD-GROWTH": 1618,
    "Limestone Brick Wall.LEFT.RIGHT.BOTTOM.OLD-GROWTH": 1626,
    "Clay Brick Wall.LEFT.RIGHT.BOTTOM.OLD-GROWTH": 1630,
    "Marble Brick Wall.LEFT.BOTTOM.OLD-GROWTH": 1587,
    "Granite Wall.LEFT.BOTTOM.OLD-GROWTH": 1591,
    "Wooden Log Wall.LEFT.BOTTOM.OLD-GROWTH": 1595,
    "Granite Brick Wall.LEFT.BOTTOM.OLD-GROWTH": 1599,
    "Basalt Wall.LEFT.BOTTOM.OLD-GROWTH": 1603,
    "Wooden Pallisade Wall.LEFT.BOTTOM.OLD-GROWTH": 1607,
    "Mud Brick Wall.LEFT.BOTTOM.OLD-GROWTH": 1611,
    "Limestone Wall.LEFT.BOTTOM.OLD-GROWTH": 1615,
    "Basalt Brick Wall.LEFT.BOTTOM.OLD-GROWTH": 1619,
    "Limestone Brick Wall.LEFT.BOTTOM.OLD-GROWTH": 1627,
    "Clay Brick Wall.LEFT.BOTTOM.OLD-GROWTH": 1631,
    "Marble Brick Wall.TOP.BOTTOM.OLD-GROWTH": 1632,
    "Granite Wall.TOP.BOTTOM.OLD-GROWTH": 1636,
    "Wooden Log Wall.TOP.BOTTOM.OLD-GROWTH": 1640,
    "Granite Brick Wall.TOP.BOTTOM.OLD-GROWTH": 1644,
    "Basalt Wall.TOP.BOTTOM.OLD-GROWTH": 1648,
    "Wooden Pallisade Wall.TOP.BOTTOM.OLD-GROWTH": 1652,
    "Mud Brick Wall.TOP.BOTTOM.OLD-GROWTH": 1656,
    "Limestone Wall.TOP.BOTTOM.OLD-GROWTH": 1660,
    "Basalt Brick Wall.TOP.BOTTOM.OLD-GROWTH": 1664,
    "Limestone Brick Wall.TOP.BOTTOM.OLD-GROWTH": 1672,
    "Clay Brick Wall.TOP.BOTTOM.OLD-GROWTH": 1676,
    "Marble Brick Wall.RIGHT.TOP.BOTTOM.OLD-GROWTH": 1633,
    "Granite Wall.RIGHT.TOP.BOTTOM.OLD-GROWTH": 1637,
    "Wooden Log Wall.RIGHT.TOP.BOTTOM.OLD-GROWTH": 1641,
    "Granite Brick Wall.RIGHT.TOP.BOTTOM.OLD-GROWTH": 1645,
    "Basalt Wall.RIGHT.TOP.BOTTOM.OLD-GROWTH": 1649,
    "Wooden Pallisade Wall.RIGHT.TOP.BOTTOM.OLD-GROWTH": 1653,
    "Mud Brick Wall.RIGHT.TOP.BOTTOM.OLD-GROWTH": 1657,
    "Limestone Wall.RIGHT.TOP.BOTTOM.OLD-GROWTH": 1661,
    "Basalt Brick Wall.RIGHT.TOP.BOTTOM.OLD-GROWTH": 1665,
    "Limestone Brick Wall.RIGHT.TOP.BOTTOM.OLD-GROWTH": 1673,
    "Clay Brick Wall.RIGHT.TOP.BOTTOM.OLD-GROWTH": 1677,
    "Marble Brick Wall.LEFT.RIGHT.TOP.BOTTOM.OLD-GROWTH": 1634,
    "Granite Wall.LEFT.RIGHT.TOP.BOTTOM.OLD-GROWTH": 1638,
    "Wooden Log Wall.LEFT.RIGHT.TOP.BOTTOM.OLD-GROWTH": 1642,
    "Granite Brick Wall.LEFT.RIGHT.TOP.BOTTOM.OLD-GROWTH": 1646,
    "Basalt Wall.LEFT.RIGHT.TOP.BOTTOM.OLD-GROWTH": 1650,
    "Wooden Pallisade Wall.LEFT.RIGHT.TOP.BOTTOM.OLD-GROWTH": 1654,
    "Mud Brick Wall.LEFT.RIGHT.TOP.BOTTOM.OLD-GROWTH": 1658,
    "Limestone Wall.LEFT.RIGHT.TOP.BOTTOM.OLD-GROWTH": 1662,
    "Basalt Brick Wall.LEFT.RIGHT.TOP.BOTTOM.OLD-GROWTH": 1666,
    "Limestone Brick Wall.LEFT.RIGHT.TOP.BOTTOM.OLD-GROWTH": 1674,
    "Clay Brick Wall.LEFT.RIGHT.TOP.BOTTOM.OLD-GROWTH": 1678,
    "Marble Brick Wall.LEFT.TOP.BOTTOM.OLD-GROWTH": 1635,
    "Granite Wall.LEFT.TOP.BOTTOM.OLD-GROWTH": 1639,
    "Wooden Log Wall.LEFT.TOP.BOTTOM.OLD-GROWTH": 1643,
    "Granite Brick Wall.LEFT.TOP.BOTTOM.OLD-GROWTH": 1647,
    "Basalt Wall.LEFT.TOP.BOTTOM.OLD-GROWTH": 1651,
    "Wooden Pallisade Wall.LEFT.TOP.BOTTOM.OLD-GROWTH": 1655,
    "Mud Brick Wall.LEFT.TOP.BOTTOM.OLD-GROWTH": 1659,
    "Limestone Wall.LEFT.TOP.BOTTOM.OLD-GROWTH": 1663,
    "Basalt Brick Wall.LEFT.TOP.BOTTOM.OLD-GROWTH": 1667,
    "Limestone Brick Wall.LEFT.TOP.BOTTOM.OLD-GROWTH": 1675,
    "Clay Brick Wall.LEFT.TOP.BOTTOM.OLD-GROWTH": 1679,
    "Marble Brick Wall.BOTTOM.RIVER": 1780,
    "Granite Wall.BOTTOM.RIVER": 1784,
    "Wooden Log Wall.BOTTOM.RIVER": 1788,
    "Granite Brick Wall.BOTTOM.RIVER": 1792,
    "Basalt Wall.BOTTOM.RIVER": 1796,
    "Wooden Pallisade Wall.BOTTOM.RIVER": 1800,
    "Mud Brick Wall.BOTTOM.RIVER": 1804,
    "Limestone Wall.BOTTOM.RIVER": 1808,
    "Basalt Brick Wall.BOTTOM.RIVER": 1812,
    "Wattle and Daub Wall.BOTTOM.RIVER": 1816,
    "Clay Brick Wall.BOTTOM.RIVER": 1968,
    "Marble Brick Wall.RIGHT.BOTTOM.RIVER": 1781,
    "Granite Wall.RIGHT.BOTTOM.RIVER": 1785,
    "Wooden Log Wall.RIGHT.BOTTOM.RIVER": 1789,
    "Granite Brick Wall.RIGHT.BOTTOM.RIVER": 1793,
    "Basalt Wall.RIGHT.BOTTOM.RIVER": 1797,
    "Wooden Pallisade Wall.RIGHT.BOTTOM.RIVER": 1801,
    "Mud Brick Wall.RIGHT.BOTTOM.RIVER": 1805,
    "Limestone Wall.RIGHT.BOTTOM.RIVER": 1809,
    "Basalt Brick Wall.RIGHT.BOTTOM.RIVER": 1813,
    "Wattle and Daub Wall.RIGHT.BOTTOM.RIVER": 1817,
    "Clay Brick Wall.RIGHT.BOTTOM.RIVER": 1969,
    "Marble Brick Wall.LEFT.RIGHT.BOTTOM.RIVER": 1782,
    "Granite Wall.LEFT.RIGHT.BOTTOM.RIVER": 1786,
    "Wooden Log Wall.LEFT.RIGHT.BOTTOM.RIVER": 1790,
    "Granite Brick Wall.LEFT.RIGHT.BOTTOM.RIVER": 1794,
    "Basalt Wall.LEFT.RIGHT.BOTTOM.RIVER": 1798,
    "Wooden Pallisade Wall.LEFT.RIGHT.BOTTOM.RIVER": 1802,
    "Mud Brick Wall.LEFT.RIGHT.BOTTOM.RIVER": 1806,
    "Limestone Wall.LEFT.RIGHT.BOTTOM.RIVER": 1810,
    "Basalt Brick Wall.LEFT.RIGHT.BOTTOM.RIVER": 1814,
    "Wattle and Daub Wall.LEFT.RIGHT.BOTTOM.RIVER": 1818,
    "Clay Brick Wall.LEFT.RIGHT.BOTTOM.RIVER": 1970,
    "Marble Brick Wall.LEFT.BOTTOM.RIVER": 1783,
    "Granite Wall.LEFT.BOTTOM.RIVER": 1787,
    "Wooden Log Wall.LEFT.BOTTOM.RIVER": 1791,
    "Granite Brick Wall.LEFT.BOTTOM.RIVER": 1795,
    "Basalt Wall.LEFT.BOTTOM.RIVER": 1799,
    "Wooden Pallisade Wall.LEFT.BOTTOM.RIVER": 1803,
    "Mud Brick Wall.LEFT.BOTTOM.RIVER": 1807,
    "Limestone Wall.LEFT.BOTTOM.RIVER": 1811,
    "Basalt Brick Wall.LEFT.BOTTOM.RIVER": 1815,
    "Wattle and Daub Wall.LEFT.BOTTOM.RIVER": 1819,
    "Clay Brick Wall.LEFT.BOTTOM.RIVER": 1971,
    "Marble Brick Wall.TOP.BOTTOM.RIVER": 1828,
    "Granite Wall.TOP.BOTTOM.RIVER": 1832,
    "Wooden Log Wall.TOP.BOTTOM.RIVER": 1836,
    "Granite Brick Wall.TOP.BOTTOM.RIVER": 1840,
    "Basalt Wall.TOP.BOTTOM.RIVER": 1844,
    "Wooden Pallisade Wall.TOP.BOTTOM.RIVER": 1848,
    "Mud Brick Wall.TOP.BOTTOM.RIVER": 1852,
    "Limestone Wall.TOP.BOTTOM.RIVER": 1856,
    "Basalt Brick Wall.TOP.BOTTOM.RIVER": 1860,
    "Wattle and Daub Wall.TOP.BOTTOM.RIVER": 1864,
    "Clay Brick Wall.TOP.BOTTOM.RIVER": 2016,
    "Marble Brick Wall.RIGHT.TOP.BOTTOM.RIVER": 1829,
    "Granite Wall.RIGHT.TOP.BOTTOM.RIVER": 1833,
    "Wooden Log Wall.RIGHT.TOP.BOTTOM.RIVER": 1837,
    "Granite Brick Wall.RIGHT.TOP.BOTTOM.RIVER": 1841,
    "Basalt Wall.RIGHT.TOP.BOTTOM.RIVER": 1845,
    "Wooden Pallisade Wall.RIGHT.TOP.BOTTOM.RIVER": 1849,
    "Mud Brick Wall.RIGHT.TOP.BOTTOM.RIVER": 1853,
    "Limestone Wall.RIGHT.TOP.BOTTOM.RIVER": 1857,
    "Basalt Brick Wall.RIGHT.TOP.BOTTOM.RIVER": 1861,
    "Wattle and Daub Wall.RIGHT.TOP.BOTTOM.RIVER": 1865,
    "Clay Brick Wall.RIGHT.TOP.BOTTOM.RIVER": 2017,
    "Marble Brick Wall.LEFT.RIGHT.TOP.BOTTOM.RIVER": 1830,
    "Granite Wall.LEFT.RIGHT.TOP.BOTTOM.RIVER": 1834,
    "Wooden Log Wall.LEFT.RIGHT.TOP.BOTTOM.RIVER": 1838,
    "Granite Brick Wall.LEFT.RIGHT.TOP.BOTTOM.RIVER": 1842,
    "Basalt Wall.LEFT.RIGHT.TOP.BOTTOM.RIVER": 1846,
    "Wooden Pallisade Wall.LEFT.RIGHT.TOP.BOTTOM.RIVER": 1850,
    "Mud Brick Wall.LEFT.RIGHT.TOP.BOTTOM.RIVER": 1854,
    "Limestone Wall.LEFT.RIGHT.TOP.BOTTOM.RIVER": 1858,
    "Basalt Brick Wall.LEFT.RIGHT.TOP.BOTTOM.RIVER": 1862,
    "Wattle and Daub Wall.LEFT.RIGHT.TOP.BOTTOM.RIVER": 1866,
    "Clay Brick Wall.LEFT.RIGHT.TOP.BOTTOM.RIVER": 2018,
    "Marble Brick Wall.LEFT.TOP.BOTTOM.RIVER": 1831,
    "Granite Wall.LEFT.TOP.BOTTOM.RIVER": 1835,
    "Wooden Log Wall.LEFT.TOP.BOTTOM.RIVER": 1839,
    "Granite Brick Wall.LEFT.TOP.BOTTOM.RIVER": 1843,
    "Basalt Wall.LEFT.TOP.BOTTOM.RIVER": 1847,
    "Wooden Pallisade Wall.LEFT.TOP.BOTTOM.RIVER": 1851,
    "Mud Brick Wall.LEFT.TOP.BOTTOM.RIVER": 1855,
    "Limestone Wall.LEFT.TOP.BOTTOM.RIVER": 1859,
    "Basalt Brick Wall.LEFT.TOP.BOTTOM.RIVER": 1863,
    "Wattle and Daub Wall.LEFT.TOP.BOTTOM.RIVER": 1867,
    "Clay Brick Wall.LEFT.TOP.BOTTOM.RIVER": 2019,
    "Granite Wall.BOTTOM.POLAR": 1784,
    "Wooden Log Wall.BOTTOM.POLAR": 1788,
    "Granite Brick Wall.BOTTOM.POLAR": 1792,
    "Basalt Wall.BOTTOM.POLAR": 1796,
    "Wooden Pallisade Wall.BOTTOM.POLAR": 1800,
    "Mud Brick Wall.BOTTOM.POLAR": 1804,
    "Limestone Wall.BOTTOM.POLAR": 1808,
    "Basalt Brick Wall.BOTTOM.POLAR": 1812,
    "Wattle and Daub Wall.BOTTOM.POLAR": 1816,
    "Limestone Brick Wall.BOTTOM.POLAR": 1820,
    "Clay Brick Wall.BOTTOM.POLAR": 1968,
    "Granite Wall.RIGHT.BOTTOM.POLAR": 1785,
    "Wooden Log Wall.RIGHT.BOTTOM.POLAR": 1789,
    "Granite Brick Wall.RIGHT.BOTTOM.POLAR": 1793,
    "Basalt Wall.RIGHT.BOTTOM.POLAR": 1797,
    "Wooden Pallisade Wall.RIGHT.BOTTOM.POLAR": 1801,
    "Mud Brick Wall.RIGHT.BOTTOM.POLAR": 1805,
    "Limestone Wall.RIGHT.BOTTOM.POLAR": 1809,
    "Basalt Brick Wall.RIGHT.BOTTOM.POLAR": 1813,
    "Wattle and Daub Wall.RIGHT.BOTTOM.POLAR": 1817,
    "Limestone Brick Wall.RIGHT.BOTTOM.POLAR": 1821,
    "Clay Brick Wall.RIGHT.BOTTOM.POLAR": 1969,
    "Granite Wall.LEFT.RIGHT.BOTTOM.POLAR": 1786,
    "Wooden Log Wall.LEFT.RIGHT.BOTTOM.POLAR": 1790,
    "Granite Brick Wall.LEFT.RIGHT.BOTTOM.POLAR": 1794,
    "Basalt Wall.LEFT.RIGHT.BOTTOM.POLAR": 1798,
    "Wooden Pallisade Wall.LEFT.RIGHT.BOTTOM.POLAR": 1802,
    "Mud Brick Wall.LEFT.RIGHT.BOTTOM.POLAR": 1806,
    "Limestone Wall.LEFT.RIGHT.BOTTOM.POLAR": 1810,
    "Basalt Brick Wall.LEFT.RIGHT.BOTTOM.POLAR": 1814,
    "Wattle and Daub Wall.LEFT.RIGHT.BOTTOM.POLAR": 1818,
    "Limestone Brick Wall.LEFT.RIGHT.BOTTOM.POLAR": 1822,
    "Clay Brick Wall.LEFT.RIGHT.BOTTOM.POLAR": 1970,
    "Granite Wall.LEFT.BOTTOM.POLAR": 1787,
    "Wooden Log Wall.LEFT.BOTTOM.POLAR": 1791,
    "Granite Brick Wall.LEFT.BOTTOM.POLAR": 1795,
    "Basalt Wall.LEFT.BOTTOM.POLAR": 1799,
    "Wooden Pallisade Wall.LEFT.BOTTOM.POLAR": 1803,
    "Mud Brick Wall.LEFT.BOTTOM.POLAR": 1807,
    "Limestone Wall.LEFT.BOTTOM.POLAR": 1811,
    "Basalt Brick Wall.LEFT.BOTTOM.POLAR": 1815,
    "Wattle and Daub Wall.LEFT.BOTTOM.POLAR": 1819,
    "Limestone Brick Wall.LEFT.BOTTOM.POLAR": 1823,
    "Clay Brick Wall.LEFT.BOTTOM.POLAR": 1971,
    "Granite Wall.TOP.BOTTOM.POLAR": 1832,
    "Wooden Log Wall.TOP.BOTTOM.POLAR": 1836,
    "Granite Brick Wall.TOP.BOTTOM.POLAR": 1840,
    "Basalt Wall.TOP.BOTTOM.POLAR": 1844,
    "Wooden Pallisade Wall.TOP.BOTTOM.POLAR": 1848,
    "Mud Brick Wall.TOP.BOTTOM.POLAR": 1852,
    "Limestone Wall.TOP.BOTTOM.POLAR": 1856,
    "Basalt Brick Wall.TOP.BOTTOM.POLAR": 1860,
    "Wattle and Daub Wall.TOP.BOTTOM.POLAR": 1864,
    "Limestone Brick Wall.TOP.BOTTOM.POLAR": 1868,
    "Clay Brick Wall.TOP.BOTTOM.POLAR": 2016,
    "Granite Wall.RIGHT.TOP.BOTTOM.POLAR": 1833,
    "Wooden Log Wall.RIGHT.TOP.BOTTOM.POLAR": 1837,
    "Granite Brick Wall.RIGHT.TOP.BOTTOM.POLAR": 1841,
    "Basalt Wall.RIGHT.TOP.BOTTOM.POLAR": 1845,
    "Wooden Pallisade Wall.RIGHT.TOP.BOTTOM.POLAR": 1849,
    "Mud Brick Wall.RIGHT.TOP.BOTTOM.POLAR": 1853,
    "Limestone Wall.RIGHT.TOP.BOTTOM.POLAR": 1857,
    "Basalt Brick Wall.RIGHT.TOP.BOTTOM.POLAR": 1861,
    "Wattle and Daub Wall.RIGHT.TOP.BOTTOM.POLAR": 1865,
    "Limestone Brick Wall.RIGHT.TOP.BOTTOM.POLAR": 1869,
    "Clay Brick Wall.RIGHT.TOP.BOTTOM.POLAR": 2017,
    "Granite Wall.LEFT.RIGHT.TOP.BOTTOM.POLAR": 1834,
    "Wooden Log Wall.LEFT.RIGHT.TOP.BOTTOM.POLAR": 1838,
    "Granite Brick Wall.LEFT.RIGHT.TOP.BOTTOM.POLAR": 1842,
    "Basalt Wall.LEFT.RIGHT.TOP.BOTTOM.POLAR": 1846,
    "Wooden Pallisade Wall.LEFT.RIGHT.TOP.BOTTOM.POLAR": 1850,
    "Mud Brick Wall.LEFT.RIGHT.TOP.BOTTOM.POLAR": 1854,
    "Limestone Wall.LEFT.RIGHT.TOP.BOTTOM.POLAR": 1858,
    "Basalt Brick Wall.LEFT.RIGHT.TOP.BOTTOM.POLAR": 1862,
    "Wattle and Daub Wall.LEFT.RIGHT.TOP.BOTTOM.POLAR": 1866,
    "Limestone Brick Wall.LEFT.RIGHT.TOP.BOTTOM.POLAR": 1870,
    "Clay Brick Wall.LEFT.RIGHT.TOP.BOTTOM.POLAR": 2018,
    "Granite Wall.LEFT.TOP.BOTTOM.POLAR": 1835,
    "Wooden Log Wall.LEFT.TOP.BOTTOM.POLAR": 1839,
    "Granite Brick Wall.LEFT.TOP.BOTTOM.POLAR": 1843,
    "Basalt Wall.LEFT.TOP.BOTTOM.POLAR": 1847,
    "Wooden Pallisade Wall.LEFT.TOP.BOTTOM.POLAR": 1851,
    "Mud Brick Wall.LEFT.TOP.BOTTOM.POLAR": 1855,
    "Limestone Wall.LEFT.TOP.BOTTOM.POLAR": 1859,
    "Basalt Brick Wall.LEFT.TOP.BOTTOM.POLAR": 1863,
    "Wattle and Daub Wall.LEFT.TOP.BOTTOM.POLAR": 1867,
    "Limestone Brick Wall.LEFT.TOP.BOTTOM.POLAR": 1871,
    "Clay Brick Wall.LEFT.TOP.BOTTOM.POLAR": 2019,

    "Fire pit.OFF": 2576,
    "Fire pit.1": 2577,
    "Fire pit.2": 3047,
    "Fire pit.3": 3048,
    "Fire pit.4": 3049,
    "Fire pit.5": 3050,
    "Fire pit.6": 3051,
    "Fire pit.7": 3052,
    "Fire pit.8": 3053,
    "Fire pit.9": 3054,
    "Fire pit.10": 3055,

    "Sign in Desk": 2578,
    "Desk": 2579,
    "Plant Pot": 2580,
    "Plant Pot.2": 2581,
    "Plant Pot.3": 2582,
    "Plant Pot.4": 2583,
    "Backpack.DESERT": 2584,
    "Backpack.SWAMP": 2585,
    "Backpack.TAIGA": 2586,
    "Backpack.JUNGLE": 2587,
    "Backpack.ARID": 2588,
    "Backpack.OLD-GROWTH": 2589,
    "Backpack.RIVER": 2590,
    "Backpack.POLAR": 2591,
    "Sign Post": 2624,
    "Mile Stone": 2624,
    "Fountain": 2625,
    "Bed": 2626,
    "Comfy Bed": 2626,
    "Voting Machine": 2627,
    "Sun Dial": 2628,
    "Bird Table": 2629,
    "Basalt Obelisk": 2630,
    "Marble Obelisk": 2631,
    "Chair.DESERT": 2632,
    "Chair.SWAMP": 2633,
    "Chair.TAIGA": 2634,
    "Chair.JUNGLE": 2635,
    "Chair.ARID": 2636,
    "Chair.OLD-GROWTH": 2637,
    // DEV
    // "Chair.RIVER": 2638,
    "Chair": 2638,
    // END DEV
    "Chair.POLAR": 2639,
    "Studded Wooden Door.DESERT": 2672,
    "Studded Wooden Door.SWAMP": 2673,
    "Studded Wooden Door.TAIGA": 2674,
    "Studded Wooden Door.JUNGLE": 2675,
    // "Studded Wooden Door": 2675,
    "Studded Wooden Door.ARID": 2676,
    "Studded Wooden Door.OLD-GROWTH": 2677,
    "Studded Wooden Door.RIVER": 2678,
    "Studded Wooden Door.POLAR": 2679,

    "Wagon.DESERT": 2680,
    "Wagon.SWAMP": 2681,
    "Wagon.TAIGA": 2682,
    "Wagon.JUNGLE": 2683,
    "Wagon.ARID": 2684,
    "Wagon.OLD-GROWTH": 2685,
    "Wagon.RIVER": 2686,
    "Wagon.POLAR": 2687,

    "Metal Door.DESERT": 2720,
    "Metal Door.SWAMP": 2721,
    "Metal Door.TAIGA": 2722,
    "Metal Door": 2723,
    "Metal Door.JUNGLE": 2723,
    "Metal Door.ARID": 2724,
    "Metal Door.OLD-GROWTH": 2725,
    "Metal Door.RIVER": 2726,
    "Metal Door.POLAR": 2727,
    "Shop Sign.DESERT": 2728,
    "Shop Sign.SWAMP": 2729,
    "Shop Sign.TAIGA": 2730,
    "Shop Sign.JUNGLE": 2731,
    "Shop Sign.ARID": 2732,
    "Shop Sign.OLD-GROWTH": 2733,
    "Shop Sign.RIVER": 2734,
    "Shop Sign.POLAR": 2735,

    // "Clay Pot": 2739,
    "Clay Pot.DESERT": 2736,
    "Clay Pot.SWAMP": 2737,
    "Clay Pot.TAIGA": 2738,
    "Clay Pot.JUNGLE": 2739,
    "Clay Pot.ARID": 2740,
    "Clay Pot.OLD-GROWTH": 2741,
    "Clay Pot.RIVER": 2742,
    "Clay Pot.POLAR": 2743,

    "Barrel.DESERT": 2736,
    "Barrel.SWAMP": 2737,
    "Barrel.TAIGA": 2738,
    "Barrel.JUNGLE": 2739,
    "Barrel.ARID": 2740,
    "Barrel.OLD-GROWTH": 2741,
    "Barrel.RIVER": 2742,
    "Barrel.POLAR": 2743,

    "Door Key": 2744,
    "Spear.DESERT": 2745,
    "Spear.SWAMP": 2746,
    "Spear.TAIGA": 2747,
    "Spear.JUNGLE": 2748,
    "Spear.ARID": 2749,
    "Spear.OLD-GROWTH": 2750,
    "Spear.RIVER": 2751,
    "Spear.POLAR": 2752,

    "Meat.1": 2753,
    "Wool": 2754,
    "Quill": 2755,
    "Hammer": 2756,
    "Scythe": 2757,
    "Spade": 2758,
    "Wood.1": 2759,
    "Wooden Chest": 2760,
    "Sack": 2761,
    "Seed Drill": 2762,
    "Maize": 2763,
    "Mango": 2764,
    "Banana": 2765,
    "Torch": 2766,
    "Torch.ON": 2767,

    "Wardrobe.DESERT": 2768,
    "Wardrobe.SWAMP": 2769,
    "Wardrobe.TAIGA": 2770,
    "Wardrobe.JUNGLE": 2771,
    "Wardrobe.ARID": 2772,
    "Wardrobe.OLD-GROWTH": 2773,
    "Wardrobe.RIVER": 2774,
    "Wardrobe.POLAR": 2775,

    "Noticeboard.DESERT": 2776,
    "Noticeboard.SWAMP": 2777,
    "Noticeboard.TAIGA": 2778,
    "Noticeboard.JUNGLE": 2779,
    "Noticeboard.ARID": 2780,
    "Noticeboard.OLD-GROWTH": 2781,
    "Noticeboard.RIVER": 2782,
    "Noticeboard.POLAR": 2783,

    "Special Pot.DESERT": 2784,
    "Special Pot.SWAMP": 2785,
    "Special Pot.TAIGA": 2786,
    "Special Pot.JUNGLE": 2787,
    "Special Pot.ARID": 2788,
    "Special Pot.OLD-GROWTH": 2789,
    "Special Pot.RIVER": 2790,
    "Special Pot.POLAR": 2791,

    "Hide Rug": 2792,
    "Hide": 2792,

    "Axe.DESERT": 2793,
    "Axe.SWAMP": 2794,
    "Axe.TAIGA": 2795,
    "Axe.JUNGLE": 2796,
    "Axe.ARID": 2797,
    "Axe.OLD-GROWTH": 2798,
    "Axe.RIVER": 2799,
    "Axe.POLAR": 2800,

    "Meat.2": 2801,
    "Linen Cloth": 2802,
    "Leather": 2802,
    "Cotton Cloth": 2802,
    "Engraving Chisel": 2803,
    "Primitive Engraving Chisel": 2803,
    "Wood Axe": 2804,
    "Primitive Axe": 2804,
    "Paintbrush": 2805,
    "Hand Plough": 2806,
    "Primitive Plough": 2806,
    "Wood.2": 2807,
    "Bridle": 2808,
    "Saddle": 2809,
    "Berries": 2810,
    "Cooked Meat.1": 2811,
    "Cooked Meat.2": 2812,
    "Cooked Meat.3": 2813,
    "Bread": 2814,
    "Cage": 2815,

    "Cupboard.DESERT": 2816,
    "Cupboard.SWAMP": 2817,
    "Cupboard.TAIGA": 2818,
    "Cupboard.JUNGLE": 2819,
    "Cupboard.ARID": 2820,
    "Cupboard.OLD-GROWTH": 2821,
    "Cupboard.RIVER": 2822,
    "Cupboard.POLAR": 2823,

    "Calendar.DESERT": 2824,
    "Calendar.SWAMP": 2825,
    "Calendar.TAIGA": 2826,
    "Calendar.JUNGLE": 2827,
    "Calendar.ARID": 2828,
    "Calendar.OLD-GROWTH": 2829,
    "Calendar.RIVER": 2830,
    "Calendar.POLAR": 2831,

    "Dagger.DESERT": 2832,
    "Dagger.SWAMP": 2833,
    "Dagger.TAIGA": 2834,
    "Dagger.JUNGLE": 2835,
    "Dagger.ARID": 2836,
    "Dagger.OLD-GROWTH": 2837,
    "Dagger.RIVER": 2838,
    "Dagger.POLAR": 2839,

    "Paintbucket": 2840,

    "Helmet.DESERT": 2841,
    "Helmet.SWAMP": 2842,
    "Helmet.TAIGA": 2843,
    "Helmet.JUNGLE": 2844,
    "Helmet.ARID": 2845,
    "Helmet.OLD-GROWTH": 2846,
    "Helmet.RIVER": 2847,
    "Helmet.POLAR": 2848,

    "Meat.3": 2849,
    "Clay Tablet": 2850,
    "Wooden Stylus": 2851,
    "Sickle": 2852,
    "Pickaxe": 2853,
    "Primitive Pickaxe": 2853,
    "Tent Pole": 2854,
    "Wood.3": 2855,
    "Reins": 2856,
    "Plough": 2857,
    "Turnip": 2858,
    "Grass.1": 2859,
    "Grass.2": 2860,
    "Trough": 2862,
    "Table": 2863,

    "Sofa.DESERT": 2864,
    "Sofa.SWAMP": 2865,
    "Sofa.TAIGA": 2866,
    "Sofa.JUNGLE": 2867,
    "Sofa.ARID": 2868,
    "Sofa.OLD-GROWTH": 2869,
    "Sofa.RIVER": 2870,
    "Sofa.POLAR": 2871,

    "Statue.DESERT": 2872,
    "Statue.SWAMP": 2873,
    "Statue.TAIGA": 2874,
    "Statue.JUNGLE": 2875,
    "Statue.ARID": 2876,
    "Statue.OLD-GROWTH": 2877,
    "Statue.RIVER": 2878,
    "Statue.POLAR": 2879,

    "Bow.DESERT": 2880,
    "Bow.SWAMP": 2881,
    "Bow.TAIGA": 2882,
    "Bow.JUNGLE": 2883,
    "Bow.ARID": 2884,
    "Bow.OLD-GROWTH": 2885,
    "Bow.RIVER": 2886,
    "Bow.POLAR": 2887,

    "Metal": 2888,
    'Moebium': 2888,
    'Tolkium': 2888,
    'Lead': 2888,
    'Gold': 2888,
    'Silver': 2888,
    'Janssium': 2888,
    'Exuperium': 2888,
    'Octavium': 2888,
    'Matsunium': 2888,
    'Lowrium': 2888,
    'Sugarium': 2888,
    'Waldium': 2888,
    'Ursulium': 2888,
    'Grinblatium': 2888,
    'Tarnium': 2888,
    'Bucklewium': 2888,
    'Elkinkium': 2888,
    'Zacharium': 2888,

    "Chestplate.DESERT": 2889,
    "Chestplate.SWAMP": 2890,
    "Chestplate.TAIGA": 2891,
    "Chestplate.JUNGLE": 2892,
    "Chestplate.ARID": 2893,
    "Chestplate.OLD-GROWTH": 2894,
    "Chestplate.RIVER": 2895,
    "Chestplate.POLAR": 2896,

    "Brush": 2897,
    "Stone Tablet": 2898,
    "Gauntlet": 2899,
    "Sledgehammer": 2900,
    "Primitive Sledgehammer": 2900,
    "Seed Planter": 2901,
    "Furniture Mover": 2902,
    "Marble Plinth": 2903,
    "Basalt Plinth": 2904,
    "Granite Plinth": 2905,
    "Limestone Plinth": 2906,
    "Grass.3": 2907,
    "Grass.4": 2908,
    "Vending Machine": 2911,

    "Rug.DESERT": 2912,
    "Rug.SWAMP": 2913,
    "Rug.TAIGA": 2914,
    "Rug.JUNGLE": 2915,
    "Rug.ARID": 2916,
    "Rug.OLD-GROWTH": 2917,
    "Rug.RIVER": 2918,
    "Rug.POLAR": 2919,

    "Gravestone.DESERT": 2920,
    "Gravestone.SWAMP": 2921,
    "Gravestone.TAIGA": 2922,
    "Gravestone.JUNGLE": 2923,
    "Gravestone.ARID": 2924,
    "Gravestone.OLD-GROWTH": 2925,
    "Gravestone.RIVER": 2926,
    "Gravestone.POLAR": 2927,

    "Sword.DESERT": 2928,
    "Sword.SWAMP": 2929,
    "Sword.TAIGA": 2930,
    "Sword.JUNGLE": 2931,
    "Sword.ARID": 2932,
    "Sword.OLD-GROWTH": 2933,
    "Sword.RIVER": 2934,
    "Sword.POLAR": 2935,

    "Charcoal": 2936,

    "Shield.DESERT": 2937,
    "Wooden Shield.DESERT": 2937,
    "Metal Shield.DESERT": 2937,
    "Shield.SWAMP": 2938,
    "Wooden Shield.SWAMP": 2938,
    "Metal Shield.SWAMP": 2938,
    "Shield.TAIGA": 2939,
    "Wooden Shield.TAIGA": 2939,
    "Metal Shield.TAIGA": 2939,
    "Shield.JUNGLE": 2940,
    "Wooden Shield.JUNGLE": 2940,
    "Metal Shield.JUNGLE": 2940,
    "Shield.ARID": 2941,
    "Wooden Shield.ARID": 2941,
    "Metal Shield.ARID": 2941,
    "Shield.OLD-GROWTH": 2942,
    "Wooden Shield.OLD-GROWTH": 2942,
    "Metal Shield.OLD-GROWTH": 2942,
    "Shield.RIVER": 2943,
    "Wooden Shield.RIVER": 2943,
    "Metal Shield.RIVER": 2943,
    "Shield.POLAR": 2944,
    "Wooden Shield.POLAR": 2944,
    "Metal Shield.POLAR": 2944,

    "Straw": 2945,
    "Paper": 2946,
    "Book": 2946,
    "Pauldrons": 2947,
    "Boots": 2948,
    "Hoe": 2949,
    "Primitive Hoe": 2949,
    "Marble Column": 2951,
    "Granite Column": 2952,
    "Limestone Column": 2953,
    "Basalt Column": 2954,

    "Planter.1": 2955,
    "Planter": 2956,
    "Planter.3": 2957,
    "Planter.4": 2958,

    "Reverse Vending Machine": 2959,

    "Bedside Table.DESERT": 2960,
    "Bedside Table.SWAMP": 2961,
    "Bedside Table.TAIGA": 2962,
    "Bedside Table.JUNGLE": 2963,
    "Bedside Table.ARID": 2964,
    "Bedside Table.OLD-GROWTH": 2965,
    "Bedside Table.RIVER": 2966,
    "Bedside Table.POLAR": 2967,

    "Bench.DESERT": 2968,
    "Bench.DESERT": 2968,
    "Bench.SWAMP": 2969,
    "Bench.TAIGA": 2970,
    "Bench.JUNGLE": 2971,
    "Bench.ARID": 2972,
    "Bench.OLD-GROWTH": 2973,
    "Bench.RIVER": 2974,
    "Bench.POLAR": 2975,

    "Stone Bench.DESERT": 2968,
    "Stone Bench.DESERT": 2968,
    "Stone Bench.SWAMP": 2969,
    "Stone Bench.TAIGA": 2970,
    "Stone Bench.JUNGLE": 2971,
    "Stone Bench.ARID": 2972,
    "Stone Bench.OLD-GROWTH": 2973,
    "Stone Bench.RIVER": 2974,
    "Stone Bench.POLAR": 2975,

    "Display Case.DESERT": 2976,
    "Display Case.SWAMP": 2977,
    "Display Case.TAIGA": 2978,
    "Display Case.JUNGLE": 2979,
    "Display Case.ARID": 2980,
    "Display Case.OLD-GROWTH": 2981,
    "Display Case.RIVER": 2982,
    "Display Case.POLAR": 2983,

    "Marble Bust": 2984,

    "Book Shelf.DESERT": 2985,
    "Book Shelf.SWAMP": 2986,
    "Book Shelf.TAIGA": 2987,
    "Book Shelf.JUNGLE": 2988,
    "Book Shelf.ARID": 2989,
    "Book Shelf.OLD-GROWTH": 2990,
    "Book Shelf.RIVER": 2991,
    "Book Shelf.POLAR": 2992,

    "Granite Bust": 2993,
    "Limestone Bust": 2994,
    "Basalt Bust": 2995,

    "Ruins.1.Column_1": 2996,
    "Ruins.1.Column_2": 2997,

    "Ruins.2.Column_1": 2998,

    "Ruins.3.Column_1": 3038,
    "Ruins.3.Column_2": 3039,
    "Ruins.3.Column_3": 3040,
    "Ruins.3.Column_4": 3041,
    "Ruins.3.Column_5": 3042,

    "Ruins.1.Column_3": 3004,
    "Ruins.1.Column_4": 3005,
    "Ruins.1.Column_5": 3006,



    "Town Bell.DESERT": 3008,
    "Town Bell.SWAMP": 3009,
    "Town Bell.TAIGA": 3010,
    "Town Bell.JUNGLE": 3011,
    "Town Bell.ARID": 3012,
    "Town Bell.OLD-GROWTH": 3013,
    "Town Bell.RIVER": 3014,
    "Town Bell.POLAR": 3015,

    "Town Horn.DESERT": 3016,
    "Town Horn.SWAMP": 3017,
    "Town Horn.TAIGA": 3018,
    "Town Horn.JUNGLE": 3019,
    "Town Horn.ARID": 3020,
    "Town Horn.OLD-GROWTH": 3021,
    "Town Horn.RIVER": 3022,
    "Town Horn.POLAR": 3023,

    // Ruined Walls
    "Destroyed Wall.DESERT": 3024,
    "Broken Wall.DESERT": 3025,
    "Damaged Wall.DESERT": 3026,

    "Destroyed Wall.SWAMP": 3027,
    "Broken Wall.SWAMP": 3028,
    "Damaged Wall.SWAMP": 3029,

    "Destroyed Wall.TAIGA": 3030,
    "Broken Wall.TAIGA": 3031,
    "Damaged Wall.TAIGA": 3032,

    "Destroyed Wall.JUNGLE": 3033,
    "Broken Wall.JUNGLE": 3034,
    "Damaged Wall.JUNGLE": 3035,

    "Destroyed Wall.ARID": 3036,
    "Broken Wall.ARID": 3037,
    "Damaged Wall.ARID": 3046,

    "Destroyed Wall.OLD-GROWTH": 3056,
    "Broken Wall.OLD-GROWTH": 3057,
    "Damaged Wall.OLD-GROWTH": 3058,

    "Destroyed Wall.RIVER": 3059,
    "Broken Wall.RIVER": 3060,
    "Damaged Wall.RIVER": 3061,

    "Destroyed Wall.POLAR": 3062,
    "Broken Wall.POLAR": 3063,
    "Damaged Wall.POLAR": 3064,

    // Clothing
    'Space tshirt.Equipped': 5,
    'Space trousers.Equipped': 40,
    'Space hat.Equipped': 41,
    'Space shirt.Equipped': 42,
    'Space shoes.Equipped': 44,

    'Cotton Work Top.DESERT.Equipped': 7,
    'Hide Work Top.DESERT.Equipped': 8,
    'Work Top.DESERT.Equipped': 9,

    'Leather Work Bottoms.DESERT.Equipped': 10,
    'Woolen Work Bottoms.DESERT.Equipped': 11,
    'Cotton Work Bottoms.DESERT.Equipped': 12,

    'Leather Work Hat.DESERT.Equipped': 13,
    'Work Hat.DESERT.Equipped': 14,
    'Hide Work Hat.DESERT.Equipped': 15,

    'Smart Formal Shoes.DESERT.Equipped': 16,

    'Pot Hat.Equipped': 13,
    'Backpack suit.Equipped': 17,

    'Cotton Work Top.SWAMP.Equipped': 17,
    'Hide Work Top.SWAMP.Equipped': 18,
    'Work Top.SWAMP.Equipped': 19,

    'Leather Work Bottoms.SWAMP.Equipped': 20,
    'Woolen Work Bottoms.SWAMP.Equipped': 21,
    'Cotton Work Bottoms.SWAMP.Equipped': 22,

    'Leather Work Hat.SWAMP.Equipped': 23,
    'Work Hat.SWAMP.Equipped': 24,
    'Hide Work Hat.SWAMP.Equipped': 25,

    'Smart Formal Shoes.SWAMP.Equipped': 26,

    'Cotton Work Top.TAIGA.Equipped': 27,
    'Hide Work Top.TAIGA.Equipped': 28,
    'Work Top.TAIGA.Equipped': 29,

    'Leather Work Bottoms.TAIGA.Equipped': 30,
    'Woolen Work Bottoms.TAIGA.Equipped': 31,
    'Cotton Work Bottoms.TAIGA.Equipped': 32,

    'Leather Work Hat.TAIGA.Equipped': 33,
    'Work Hat.TAIGA.Equipped': 34,
    'Hide Work Hat.TAIGA.Equipped': 35,

    'Smart Formal Shoes.TAIGA.Equipped': 36,

    'Bejewelled Informal Shoes.TAIGA.Equipped': 44,
    'Comfortable Shoes.TAIGA.Equipped': 46,

    'Stiff Formal Top.DESERT.Equipped': 47,
    'Leather Formal Top.DESERT.Equipped': 48,
    'Formal Top.DESERT.Equipped': 49,

    'Cotton Formal Bottoms.DESERT.Equipped': 50,
    'Cloth Formal Bottoms.DESERT.Equipped': 51,
    'Formal Bottoms.DESERT.Equipped': 52,

    'Embroidered Formal Hat.DESERT.Equipped': 53,
    'Formal Hat.DESERT.Equipped': 54,
    'Large Formal Hat.DESERT.Equipped': 55,

    'Comfortable Formal Shoes.DESERT.Equipped': 56,

    'Stiff Formal Top.SWAMP.Equipped': 57,
    'Leather Formal Top.SWAMP.Equipped': 58,
    'Formal Top.SWAMP.Equipped': 59,

    'Cotton Formal Bottoms.SWAMP.Equipped': 60,
    'Cloth Formal Bottoms.SWAMP.Equipped': 61,
    'Formal Bottoms.SWAMP.Equipped': 62,

    'Embroidered Formal Hat.SWAMP.Equipped': 63,
    'Formal Hat.SWAMP.Equipped': 64,
    'Large Formal Hat.SWAMP.Equipped': 65,

    'Comfortable Formal Shoes.SWAMP.Equipped': 66,

    'Stiff Formal Top.TAIGA.Equipped': 67,
    'Leather Formal Top.TAIGA.Equipped': 68,
    'Formal Top.TAIGA.Equipped': 69,

    'Cotton Formal Bottoms.TAIGA.Equipped': 70,
    'Cloth Formal Bottoms.TAIGA.Equipped': 71,
    'Formal Bottoms.TAIGA.Equipped': 72,

    'Embroidered Formal Hat.TAIGA.Equipped': 73,
    'Formal Hat.TAIGA.Equipped': 74,
    'Large Formal Hat.TAIGA.Equipped': 75,

    'dagger.DESERT.Equipped': 80,
    'bow.DESERT.Equipped': 81,
    'sword.DESERT.Equipped': 82,
    'spear.DESERT.Equipped': 83,
    'axe.DESERT.Equipped': 84,

    'Work Shoes.SWAMP.Equipped': 86,

    'Woolen Informal Top.DESERT.Equipped': 87,
    'Embroidered Informal Top.DESERT.Equipped': 88,
    'Informal Top.DESERT.Equipped': 89,

    'Comfortable Informal Bottoms.DESERT.Equipped': 90,
    'Informal Bottoms.DESERT.Equipped': 91,
    'Embroidered Informal Bottoms.DESERT.Equipped': 92,

    'Woolen Informal Hat.DESERT.Equipped': 93,
    'Large Informal Hat.DESERT.Equipped': 94,
    'Informal Hat.DESERT.Equipped': 95,

    'Bejewelled Informal Shoes.DESERT.Equipped': 96,

    'Woolen Informal Top.SWAMP.Equipped': 97,
    'Embroidered Informal Top.SWAMP.Equipped': 98,
    'Informal Top.SWAMP.Equipped': 99,

    'Comfortable Informal Bottoms.SWAMP.Equipped': 100,
    'Informal Bottoms.SWAMP.Equipped': 101,
    'Embroidered Informal Bottoms.SWAMP.Equipped': 102,

    'Woolen Informal Hat.SWAMP.Equipped': 103,
    'Large Informal Hat.SWAMP.Equipped': 104,
    'Informal Hat.SWAMP.Equipped': 105,

    'Bejewelled Informal Shoes.SWAMP.Equipped': 106,

    'Woolen Informal Top.TAIGA.Equipped': 107,
    'Embroidered Informal Top.TAIGA.Equipped': 108,
    'Informal Top.TAIGA.Equipped': 109,

    'Comfortable Informal Bottoms.TAIGA.Equipped': 110,
    'Informal Bottoms.TAIGA.Equipped': 111,
    'Embroidered Informal Bottoms.TAIGA.Equipped': 112,

    'Woolen Informal Hat.TAIGA.Equipped': 113,
    'Large Informal Hat.TAIGA.Equipped': 114,
    'Informal Hat.TAIGA.Equipped': 115,

    'Bejewelled Informal Shoes.TAIGA.Equipped': 116,

    'Helmet.DESERT.Equipped': 120,
    'Chestplate.DESERT.Equipped': 121,
    'Guantlets.DESERT.Equipped': 122,
    'Grieves.DESERT.Equipped': 123,
    'Shield.DESERT.Equipped': 124,
    'Wooden Shield.DESERT.Equipped': 124,
    'Metal Shield.DESERT.Equipped': 124,

    'Work Shoes.TAIGA.Equipped': 126,

    'Smart Celebration Top.DESERT.Equipped': 127,
    'Celebration Top.DESERT.Equipped': 128,
    'Comfortable Celebration Top.DESERT.Equipped': 129,

    'Cloth Celebration Bottoms.DESERT.Equipped': 130,
    'Comfortable Celebration Bottoms.DESERT.Equipped': 131,
    'Padded Celebration Bottoms.DESERT.Equipped': 132,

    'Celebration Hat.DESERT.Equipped': 133,
    'Comfortable Celebration Hat.DESERT.Equipped': 134,
    'Smart Celebration Hat.DESERT.Equipped': 135,

    'Comfortable Shoes.DESERT.Equipped': 136,

    'Smart Celebration Top.SWAMP.Equipped': 137,
    'Celebration Top.SWAMP.Equipped': 138,
    'Comfortable Celebration Top.SWAMP.Equipped': 139,

    'Cloth Celebration Bottoms.SWAMP.Equipped': 140,
    'Comfortable Celebration Bottoms.SWAMP.Equipped': 141,
    'Padded Celebration Bottoms.SWAMP.Equipped': 142,

    'Celebration Hat.SWAMP.Equipped': 143,
    'Comfortable Celebration Hat.SWAMP.Equipped': 144,
    'Smart Celebration Hat.SWAMP.Equipped': 145,

    'Comfortable Shoes.SWAMP.Equipped': 146,

    'Smart Celebration Top.TAIGA.Equipped': 147,
    'Celebration Top.TAIGA.Equipped': 148,
    'Comfortable Celebration Top.TAIGA.Equipped': 149,

    'Cloth Celebration Bottoms.TAIGA.Equipped': 150,
    'Comfortable Celebration Bottoms.TAIGA.Equipped': 151,
    'Padded Celebration Bottoms.TAIGA.Equipped': 152,

    'Celebration Hat.TAIGA.Equipped': 153,
    'Comfortable Celebration Hat.TAIGA.Equipped': 154,
    'Smart Celebration Hat.TAIGA.Equipped': 155,

    'Comfortable Shoes.TAIGA.Equipped': 156,

    'dagger.SWAMP.Equipped': 160,
    'bow.SWAMP.Equipped': 161,
    'sword.SWAMP.Equipped': 162,
    'spear.SWAMP.Equipped': 163,
    'axe.SWAMP.Equipped': 164,

    'Work Shoes.JUNGLE.Equipped': 166,

    'Cotton Work Top.JUNGLE.Equipped': 167,
    'Hide Work Top.JUNGLE.Equipped': 168,
    'Work Top.JUNGLE.Equipped': 169,

    'Leather Work Bottoms.JUNGLE.Equipped': 170,
    'Woolen Work Bottoms.JUNGLE.Equipped': 171,
    'Cotton Work Bottoms.JUNGLE.Equipped': 172,

    'Leather Work Hat.JUNGLE.Equipped': 173,
    'Work Hat.JUNGLE.Equipped': 174,
    'Hide Work Hat.JUNGLE.Equipped': 175,

    'Smart Formal Shoes.JUNGLE.Equipped': 176,

    'Cotton Work Top.ARID.Equipped': 177,
    'Hide Work Top.ARID.Equipped': 178,
    'Work Top.ARID.Equipped': 179,

    'Leather Work Bottoms.ARID.Equipped': 180,
    'Woolen Work Bottoms.ARID.Equipped': 181,
    'Cotton Work Bottoms.ARID.Equipped': 182,

    'Leather Work Hat.ARID.Equipped': 183,
    'Work Hat.ARID.Equipped': 184,
    'Hide Work Hat.ARID.Equipped': 185,

    'Smart Formal Shoes.ARID.Equipped': 186,

    'Cotton Work Top.OLD-GROWTH.Equipped': 187,
    'Hide Work Top.OLD-GROWTH.Equipped': 188,
    'Work Top.OLD-GROWTH.Equipped': 189,

    'Leather Work Bottoms.OLD-GROWTH.Equipped': 190,
    'Woolen Work Bottoms.OLD-GROWTH.Equipped': 191,
    'Cotton Work Bottoms.OLD-GROWTH.Equipped': 192,

    'Leather Work Hat.OLD-GROWTH.Equipped': 193,
    'Work Hat.OLD-GROWTH.Equipped': 194,
    'Hide Work Hat.OLD-GROWTH.Equipped': 195,

    'Smart Formal Shoes.OLD.Equipped-GROWTH': 196,

    'Helmet.SWAMP.Equipped': 200,
    'Chestplate.SWAMP.Equipped': 201,
    'Guantlets.SWAMP.Equipped': 202,
    'Grieves.SWAMP.Equipped': 203,
    'Shield.SWAMP.Equipped': 204,
    'Wooden Shield.SWAMP.Equipped': 204,
    'Metal Shield.SWAMP.Equipped': 204,

    'Work Shoes.ARID.Equipped': 206,

    'Stiff Formal Top.JUNGLE.Equipped': 207,
    'Leather Formal Top.JUNGLE.Equipped': 208,
    'Formal Top.JUNGLE.Equipped': 209,

    'Cotton Formal Bottoms.JUNGLE.Equipped': 210,
    'Cloth Formal Bottoms.JUNGLE.Equipped': 211,
    'Formal Bottoms.JUNGLE.Equipped': 212,

    'Embroidered Formal Hat.JUNGLE.Equipped': 213,
    'Formal Hat.JUNGLE.Equipped': 214,
    'Large Formal Hat.JUNGLE.Equipped': 215,

    'Comfortable Formal Shoes.JUNGLE.Equipped': 216,

    'Stiff Formal Top.ARID.Equipped': 217,
    'Leather Formal Top.ARID.Equipped': 218,
    'Formal Top.ARID.Equipped': 219,

    'Cotton Formal Bottoms.ARID.Equipped': 220,
    'Cloth Formal Bottoms.ARID.Equipped': 221,
    'Formal Bottoms.ARID.Equipped': 222,

    'Embroidered Formal Hat.ARID.Equipped': 223,
    'Formal Hat.ARID.Equipped': 224,
    'Large Formal Hat.ARID.Equipped': 225,

    'Comfortable Formal Shoes.ARID.Equipped': 226,

    'Stiff Formal Top.OLD.Equipped-GROWTH': 227,
    'Leather Formal Top.OLD.Equipped-GROWTH': 228,
    'Formal Top.OLD.Equipped-GROWTH': 229,

    'Cotton Formal Bottoms.OLD.Equipped-GROWTH': 230,
    'Cloth Formal Bottoms.OLD.Equipped-GROWTH': 231,
    'Formal Bottoms.OLD.Equipped-GROWTH': 232,

    'Embroidered Formal Hat.OLD.Equipped-GROWTH': 233,
    'Formal Hat.OLD.Equipped-GROWTH': 234,
    'Large Formal Hat.OLD.Equipped-GROWTH': 235,

    'Comfortable Formal Shoes.OLD.Equipped-GROWTH': 236,

    'dagger.TAIGA.Equipped': 240,
    'bow.TAIGA.Equipped': 241,
    'sword.TAIGA.Equipped': 242,
    'spear.TAIGA.Equipped': 243,
    'axe.TAIGA.Equipped': 244,

    'Work Shoes.OLD-GROWTH.Equipped': 246,

    'Woolen Informal Top.JUNGLE.Equipped': 247,
    'Embroidered Informal Top.JUNGLE.Equipped': 248,
    'Informal Top.JUNGLE.Equipped': 249,

    'Comfortable Informal Bottoms.JUNGLE.Equipped': 250,
    'Informal Bottoms.JUNGLE.Equipped': 251,
    'Embroidered Informal Bottoms.JUNGLE.Equipped': 252,

    'Woolen Informal Hat.JUNGLE.Equipped': 253,
    'Large Informal Hat.JUNGLE.Equipped': 254,
    'Informal Hat.JUNGLE.Equipped': 255,

    'Bejewelled Informal Shoes.DESERT.Equipped': 256,

    'Woolen Informal Top.ARID.Equipped': 257,
    'Embroidered Informal Top.ARID.Equipped': 258,
    'Informal Top.ARID.Equipped': 259,

    'Comfortable Informal Bottoms.ARID.Equipped': 260,
    'Informal Bottoms.ARID.Equipped': 261,
    'Embroidered Informal Bottoms.ARID.Equipped': 262,

    'Woolen Informal Hat.ARID.Equipped': 263,
    'Large Informal Hat.ARID.Equipped': 264,
    'Informal Hat.ARID.Equipped': 265,

    'Bejewelled Informal Shoes.ARID.Equipped': 266,

    'Woolen Informal Top.OLD-GROWTH.Equipped': 267,
    'Embroidered Informal Top.OLD-GROWTH.Equipped': 268,
    'Informal Top.OLD-GROWTH.Equipped': 269,

    'Comfortable Informal Bottoms.OLD-GROWTH.Equipped': 270,
    'Informal Bottoms.OLD-GROWTH.Equipped': 271,
    'Embroidered Informal Bottoms.OLD-GROWTH.Equipped': 272,

    'Woolen Informal Hat.OLD-GROWTH.Equipped': 273,
    'Large Informal Hat.OLD-GROWTH.Equipped': 274,
    'Informal Hat.OLD-GROWTH.Equipped': 275,

    'Bejewelled Informal Shoes.OLD-GROWTH.Equipped': 276,

    'Helmet.TAIGA.Equipped': 280,
    'Chestplate.TAIGA.Equipped': 281,
    'Guantlets.TAIGA.Equipped': 282,
    'Grieves.TAIGA.Equipped': 283,
    'Shield.TAIGA.Equipped': 284,
    'Wooden Shield.TAIGA.Equipped': 284,
    'Metal Shield.TAIGA.Equipped': 284,

    'Work Shoes.RIVER.Equipped': 286,

    'Smart Celebration Top.JUNGLE.Equipped': 287,
    'Celebration Top.JUNGLE.Equipped': 288,
    'Comfortable Celebration Top.JUNGLE.Equipped': 289,

    'Cloth Celebration Bottoms.JUNGLE.Equipped': 290,
    'Comfortable Celebration Bottoms.JUNGLE.Equipped': 291,
    'Padded Celebration Bottoms.JUNGLE.Equipped': 292,

    'Celebration Hat.JUNGLE.Equipped': 293,
    'Comfortable Celebration Hat.JUNGLE.Equipped': 294,
    'Smart Celebration Hat.JUNGLE.Equipped': 295,

    'Comfortable Shoes.JUNGLE.Equipped': 296,

    'Smart Celebration Top.ARID.Equipped': 297,
    'Celebration Top.ARID.Equipped': 298,
    'Comfortable Celebration Top.ARID.Equipped': 299,

    'Cloth Celebration Bottoms.ARID.Equipped': 300,
    'Comfortable Celebration Bottoms.ARID.Equipped': 301,
    'Padded Celebration Bottoms.ARID.Equipped': 302,

    'Celebration Hat.ARID.Equipped': 303,
    'Comfortable Celebration Hat.ARID.Equipped': 304,
    'Smart Celebration Hat.ARID.Equipped': 305,

    'Comfortable Shoes.ARID.Equipped': 306,

    'Smart Celebration Top.OLD-GROWTH.Equipped': 307,
    'Celebration Top.OLD-GROWTH.Equipped': 308,
    'Comfortable Celebration Top.OLD-GROWTH.Equipped': 309,

    'Cloth Celebration Bottoms.OLD-GROWTH.Equipped': 310,
    'Comfortable Celebration Bottoms.OLD-GROWTH.Equipped': 311,
    'Padded Celebration Bottoms.OLD-GROWTH.Equipped': 312,

    'Celebration Hat.OLD-GROWTH.Equipped': 313,
    'Comfortable Celebration Hat.OLD-GROWTH.Equipped': 314,
    'Smart Celebration Hat.OLD-GROWTH.Equipped': 315,

    'Comfortable Shoes.OLD-GROWTH.Equipped': 316,

    'dagger.JUNGLE.Equipped': 320,
    'bow.JUNGLE.Equipped': 321,
    'sword.JUNGLE.Equipped': 322,
    'spear.JUNGLE.Equipped': 323,
    'axe.JUNGLE.Equipped': 324,

    'Work Shoes.POLAR.Equipped': 325,

    'Cotton Work Top.RIVER.Equipped': 326,
    'Cotton Work Top.RIVER.Equipped': 327,
    'Hide Work Top.RIVER.Equipped': 328,
    'Work Top.RIVER.Equipped': 329,

    'Leather Work Bottoms.RIVER.Equipped': 330,
    'Woolen Work Bottoms.RIVER.Equipped': 331,
    'Cotton Work Bottoms.RIVER.Equipped': 332,

    'Leather Work Hat.RIVER.Equipped': 333,
    'Work Hat.RIVER.Equipped': 334,
    'Hide Work Hat.RIVER.Equipped': 335,

    'Smart Formal Shoes.RIVER.Equipped': 336,

    'Cotton Work Top.POLAR.Equipped': 337,
    'Hide Work Top.POLAR.Equipped': 338,
    'Work Top.POLAR.Equipped': 339,

    'Leather Work Bottoms.POLAR.Equipped': 340,
    'Woolen Work Bottoms.POLAR.Equipped': 341,
    'Cotton Work Bottoms.POLAR.Equipped': 342,

    'Leather Work Hat.POLAR.Equipped': 343,
    'Work Hat.POLAR.Equipped': 344,
    'Hide Work Hat.POLAR.Equipped': 345,

    'Smart Formal Shoes.POLAR.Equipped': 346,

    'Helmet.JUNGLE.Equipped': 360,
    'Chestplate.JUNGLE.Equipped': 361,
    'Guantlets.JUNGLE.Equipped': 362,
    'Grieves.JUNGLE.Equipped': 363,
    'Shield.JUNGLE.Equipped': 364,
    'Wooden Shield.JUNGLE.Equipped': 364,
    'Metal Shield.JUNGLE.Equipped': 364,

    'Stiff Formal Top.RIVER.Equipped': 367,
    'Leather Formal Top.RIVER.Equipped': 368,
    'Formal Top.RIVER.Equipped': 369,

    'Cotton Formal Bottoms.RIVER.Equipped': 370,
    'Cloth Formal Bottoms.RIVER.Equipped': 371,
    'Formal Bottoms.RIVER.Equipped': 372,

    'Embroidered Formal Hat.RIVER.Equipped': 373,
    'Formal Hat.RIVER.Equipped': 374,
    'Large Formal Hat.RIVER.Equipped': 375,

    'Comfortable Formal Shoes.RIVER.Equipped': 376,

    'Stiff Formal Top.POLAR.Equipped': 377,
    'Leather Formal Top.POLAR.Equipped': 378,
    'Formal Top.POLAR.Equipped': 379,

    'Cotton Formal Bottoms.POLAR.Equipped': 380,
    'Cloth Formal Bottoms.POLAR.Equipped': 381,
    'Formal Bottoms.POLAR.Equipped': 382,

    'Embroidered Formal Hat.POLAR.Equipped': 383,
    'Formal Hat.POLAR.Equipped': 384,
    'Large Formal Hat.POLAR.Equipped': 385,

    'Comfortable Formal Shoes.POLAR.Equipped': 386,

    'dagger.ARID.Equipped': 400,
    'bow.ARID.Equipped': 401,
    'sword.ARID.Equipped': 402,
    'spear.ARID.Equipped': 403,
    'axe.ARID.Equipped': 404,

    'Woolen Informal Top.RIVER.Equipped': 407,
    'Embroidered Informal Top.RIVER.Equipped': 408,
    'Informal Top.RIVER.Equipped': 409,

    'Comfortable Informal Bottoms.RIVER.Equipped': 410,
    'Informal Bottoms.RIVER.Equipped': 411,
    'Embroidered Informal Bottoms.RIVER.Equipped': 412,

    'Woolen Informal Hat.RIVER.Equipped': 413,
    'Large Informal Hat.RIVER.Equipped': 414,
    'Informal Hat.RIVER.Equipped': 415,

    'Bejewelled Informal Shoes.RIVER.Equipped': 416,

    'Woolen Informal Top.POLAR.Equipped': 417,
    'Embroidered Informal Top.POLAR.Equipped': 418,
    'Informal Top.POLAR.Equipped': 419,

    'Comfortable Informal Bottoms.POLAR.Equipped': 420,
    'Informal Bottoms.POLAR.Equipped': 421,
    'Embroidered Informal Bottoms.POLAR.Equipped': 422,

    'Woolen Informal Hat.POLAR.Equipped': 423,
    'Large Informal Hat.POLAR.Equipped': 424,
    'Informal Hat.POLAR.Equipped': 425,

    'Bejewelled Informal Shoes.POLAR.Equipped': 426,

    'Helmet.ARID.Equipped': 440,
    'Chestplate.ARID.Equipped': 441,
    'Guantlets.ARID.Equipped': 442,
    'Grieves.ARID.Equipped': 443,
    'Shield.ARID.Equipped': 444,
    'Wooden Shield.ARID.Equipped': 444,
    'Metal Shield.ARID.Equipped': 444,

    'Smart Celebration Top.RIVER.Equipped': 447,
    'Celebration Top.RIVER.Equipped': 448,
    'Comfortable Celebration Top.RIVER.Equipped': 449,

    'Cloth Celebration Bottoms.RIVER.Equipped': 450,
    'Comfortable Celebration Bottoms.RIVER.Equipped': 451,
    'Padded Celebration Bottoms.RIVER.Equipped': 452,

    'Celebration Hat.RIVER.Equipped': 453,
    'Comfortable Celebration Hat.RIVER.Equipped': 454,
    'Smart Celebration Hat.RIVER.Equipped': 455,

    'Comfortable Shoes.RIVER.Equipped': 456,

    'Smart Celebration Top.POLAR.Equipped': 457,
    'Celebration Top.POLAR.Equipped': 458,
    'Comfortable Celebration Top.POLAR.Equipped': 459,

    'Cloth Celebration Bottoms.POLAR.Equipped': 460,
    'Comfortable Celebration Bottoms.POLAR.Equipped': 461,
    'Padded Celebration Bottoms.POLAR.Equipped': 462,

    'Celebration Hat.POLAR.Equipped': 463,
    'Comfortable Celebration Hat.POLAR.Equipped': 464,
    'Smart Celebration Hat.POLAR.Equipped': 465,

    'Comfortable Shoes.POLAR.Equipped': 466,

    //
    // A. DESERT
    // B. SWAMP
    // C. TAIGA
    // D. JUNGLE
    // E. ARID
    // F. OLD-GROWTH
    // G. RIVER
    // H. POLAR

    'dagger.OLD-GROWTH.Equipped': 480,
    'bow.OLD-GROWTH.Equipped': 481,
    'sword.OLD-GROWTH.Equipped': 482,
    'spear.OLD-GROWTH.Equipped': 483,
    'axe.OLD-GROWTH.Equipped': 484,

    'Helmet.OLD-GROWTH.Equipped': 485,
    'Chestplate.OLD-GROWTH.Equipped': 486,
    'Guantlets.OLD-GROWTH.Equipped': 487,
    'Grieves.OLD-GROWTH.Equipped': 488,
    'Shield.OLD-GROWTH.Equipped': 489,
    'Wooden Shield.OLD-GROWTH.Equipped': 489,
    'Metal Shield.OLD-GROWTH.Equipped': 489,

    'dagger.RIVER.Equipped': 490,
    'bow.RIVER.Equipped': 491,
    'sword.RIVER.Equipped': 492,
    'spear.RIVER.Equipped': 493,
    'axe.RIVER.Equipped': 494,

    'Helmet.RIVER.Equipped': 495,
    'Chestplate.RIVER.Equipped': 496,
    'Guantlets.RIVER.Equipped': 497,
    'Grieves.RIVER.Equipped': 498,
    'Shield.RIVER.Equipped': 499,
    'Wooden Shield.RIVER.Equipped': 499,
    'Metal Shield.RIVER.Equipped': 499,

    'dagger.POLAR.Equipped': 530,
    'bow.POLAR.Equipped': 531,
    'sword.POLAR.Equipped': 532,
    'spear.POLAR.Equipped': 533,
    'axe.POLAR.Equipped': 534,

    'Helmet.POLAR.Equipped': 535,
    'Chestplate.POLAR.Equipped': 536,
    'Guantlets.POLAR.Equipped': 537,
    'Grieves.POLAR.Equipped': 538,
    'Shield.POLAR.Equipped': 539,
    'Wooden Shield.POLAR.Equipped': 539,
    'Metal Shield.POLAR.Equipped': 539,

    // Boats Baby
    // "Damaged Wall.POLAR": 3064,
    // DESERT
    // SWAMP
    // TAIGA
    // JUNGLE
    // ARID
    // OLD-GROWTH
    // RIVER
    // POLAR
    // 48 tiles per line. 

    'Wooden Bridge.RIGHT.TAIGA': 3065,
    'Wooden Bridge.LEFT.RIGHT.TAIGA': 3066,
    'Wooden Bridge.LEFT.TAIGA': 3067,

    'Horizontal Brick Bridge.RIGHT.BOTTOM.TAIGA': 3068,
    'Horizontal Brick Bridge.LEFT.RIGHT.BOTTOM.TAIGA': 3069,
    'Horizontal Brick Bridge.LEFT.BOTTOM.TAIGA': 3070,

    'Vertical Brick Bridge.RIGHT.BOTTOM.TAIGA': 3071,

    // 

    'Wooden Rail.BOTTOM.': 3072,
    'Wooden Rail.RIGHT.BOTTOM.': 3073,
    'Wooden Rail.LEFT.RIGHT.BOTTOM.': 3074,
    'Wooden Rail.LEFT.BOTTOM.': 3075,
    'Wooden Rail.RIGHT.': 3076,
    'Wooden Rail.LEFT.RIGHT.': 3077,
    'Wooden Rail.LEFT.': 3078,

    'Metal Rail.BOTTOM.': 3079,
    'Metal Rail.RIGHT.BOTTOM.': 3079,
    'Metal Rail.LEFT.RIGHT.BOTTOM.': 3081,
    'Metal Rail.LEFT.BOTTOM.': 3082,

    'Wooden Bridge.BOTTOM.DESERT': 3083,

    'Wooden Bridge.RIGHT.DESERT': 3084,
    'Wooden Bridge.LEFT.RIGHT.DESERT': 3085,
    'Wooden Bridge.LEFT.DESERT': 3086,

    'Horizontal Brick Bridge.RIGHT.BOTTOM.DESERT': 3087,
    'Horizontal Brick Bridge.LEFT.RIGHT.BOTTOM.DESERT': 3088,
    'Horizontal Brick Bridge.LEFT.BOTTOM.DESERT': 3089,

    'Vertical Brick Bridge.RIGHT.BOTTOM.DESERT': 3090,
    'Vertical Brick Bridge.LEFT.RIGHT.BOTTOM.DESERT': 3091,
    'Vertical Brick Bridge.LEFT.BOTTOM.DESERT': 3092,

    'Horizontal Stone Bridge.RIGHT.BOTTOM.DESERT': 3093,
    'Horizontal Stone Bridge.LEFT.RIGHT.BOTTOM.DESERT': 3094,
    'Horizontal Stone Bridge.LEFT.BOTTOM.DESERT': 3095,

    'Vertical Stone Bridge.RIGHT.BOTTOM.DESERT': 3096,
    'Vertical Stone Bridge.LEFT.RIGHT.BOTTOM.DESERT': 3097,
    'Vertical Stone Bridge.LEFT.BOTTOM.DESERT': 3098,

    'Wooden Bridge.BOTTOM.SWAMP': 3099,

    'Wooden Bridge.RIGHT.SWAMP': 3100,
    'Wooden Bridge.LEFT.RIGHT.SWAMP': 3101,
    'Wooden Bridge.LEFT.SWAMP': 3102,

    'Horizontal Brick Bridge.RIGHT.BOTTOM.SWAMP': 3103,
    'Horizontal Brick Bridge.LEFT.RIGHT.BOTTOM.SWAMP': 3104,
    'Horizontal Brick Bridge.LEFT.BOTTOM.SWAMP': 3105,

    'Vertical Brick Bridge.RIGHT.BOTTOM.SWAMP': 3106,
    'Vertical Brick Bridge.LEFT.RIGHT.BOTTOM.SWAMP': 3107,
    'Vertical Brick Bridge.LEFT.BOTTOM.SWAMP': 3108,

    'Horizontal Stone Bridge.RIGHT.BOTTOM.SWAMP': 3109,
    'Horizontal Stone Bridge.LEFT.RIGHT.BOTTOM.SWAMP': 3110,
    'Horizontal Stone Bridge.LEFT.BOTTOM.SWAMP': 3111,

    'Vertical Stone Bridge.RIGHT.BOTTOM.SWAMP': 3112,
    'Vertical Stone Bridge.LEFT.RIGHT.BOTTOM.SWAMP': 3113,
    'Vertical Stone Bridge.LEFT.BOTTOM.SWAMP': 3114,

    'Wooden Bridge.BOTTOM.TAIGA': 3115,

    'Horizontal Brick Bridge.RIGHT.TOP.BOTTOM.TAIGA': 3116,
    'Horizontal Brick Bridge.LEFT.RIGHT.TOP.BOTTOM.TAIGA': 3117,
    'Horizontal Brick Bridge.LEFT.TOP.BOTTOM.TAIGA': 3118,

    'Vertical Stone Bridge.RIGHT.TOP.BOTTOM.TAIGA': 3119,

    //
    'Wooden Rail.TOP.BOTTOM.': 3120,
    'Wooden Rail.RIGHT.TOP.BOTTOM.': 3121,
    'Wooden Rail.LEFT.RIGHT.TOP.BOTTOM.': 3122,
    'Wooden Rail.LEFT.TOP.BOTTOM.': 3123,
    'Wooden Rail.NONE.': 3124,

    'Metal Rail.NONE.': 3126,

    'Metal Rail.TOP.BOTTOM.': 3127,
    'Metal Rail.RIGHT.TOP.BOTTOM.': 3128,
    'Metal Rail.LEFT.RIGHT.TOP.BOTTOM.': 3129,
    'Metal Rail.LEFT.TOP.BOTTOM.': 3130,

    'Wooden Bridge.TOP.BOTTOM.DESERT': 3131,

    'Horizontal Stone Bridge.Footrail.RIGHT.DESERT': 3132,
    'Horizontal Stone Bridge.Footrail.LEFT.RIGHT.DESERT': 3133,
    'Horizontal Stone Bridge.Footrail.LEFT.DESERT': 3134,

    'Horizontal Brick Bridge.RIGHT.TOP.BOTTOM.DESERT': 3135,
    'Horizontal Brick Bridge.LEFT.RIGHT.TOP.BOTTOM.DESERT': 3136,
    'Horizontal Brick Bridge.LEFT.TOP.BOTTOM.DESERT': 3137,

    'Vertical Brick Bridge.RIGHT.TOP.BOTTOM.DESERT': 3138,
    'Vertical Brick Bridge.LEFT.RIGHT.TOP.BOTTOM.DESERT': 3139,
    'Vertical Brick Bridge.LEFT.TOP.BOTTOM.DESERT': 3140,

    'Horizontal Stone Bridge.RIGHT.TOP.BOTTOM.DESERT': 3141,
    'Horizontal Stone Bridge.LEFT.RIGHT.TOP.BOTTOM.DESERT': 3142,
    'Horizontal Stone Bridge.LEFT.TOP.BOTTOM.DESERT': 3143,

    'Vertical Stone Bridge.RIGHT.TOP.BOTTOM.DESERT': 3144,
    'Vertical Stone Bridge.LEFT.RIGHT.TOP.BOTTOM.DESERT': 3145,
    'Vertical Stone Bridge.LEFT.TOP.BOTTOM.DESERT': 3146,

    'Wooden Bridge.TOP.BOTTOM.SWAMP': 3147,

    'Horizontal Stone Bridge.Footrail.RIGHT.SWAMP': 3148,
    'Horizontal Stone Bridge.Footrail.LEFT.RIGHT.SWAMP': 3149,
    'Horizontal Stone Bridge.Footrail.LEFT.SWAMP': 3150,

    'Horizontal Brick Bridge.RIGHT.TOP.BOTTOM.SWAMP': 3151,
    'Horizontal Brick Bridge.LEFT.RIGHT.TOP.BOTTOM.SWAMP': 3152,
    'Horizontal Brick Bridge.LEFT.TOP.BOTTOM.SWAMP': 3153,

    'Vertical Brick Bridge.RIGHT.TOP.BOTTOM.SWAMP': 3154,
    'Vertical Brick Bridge.LEFT.RIGHT.TOP.BOTTOM.SWAMP': 3155,
    'Vertical Brick Bridge.LEFT.TOP.BOTTOM.SWAMP': 3156,

    'Horizontal Stone Bridge.RIGHT.TOP.BOTTOM.SWAMP': 3157,
    'Horizontal Stone Bridge.LEFT.RIGHT.TOP.BOTTOM.SWAMP': 3158,
    'Horizontal Stone Bridge.LEFT.TOP.BOTTOM.SWAMP': 3159,

    'Vertical Stone Bridge.RIGHT.TOP.BOTTOM.SWAMP': 3160,
    'Vertical Stone Bridge.LEFT.RIGHT.TOP.BOTTOM.SWAMP': 3161,
    'Vertical Stone Bridge.LEFT.TOP.BOTTOM.SWAMP': 3162,

    'Wooden Bridge.TOP.BOTTOM.TAIGA': 3163,

    'Horizontal Brick Bridge.RIGHT.TOP.TAIGA': 3164,
    'Horizontal Brick Bridge.LEFT.RIGHT.TOP.TAIGA': 3165,
    'Horizontal Brick Bridge.LEFT.TOP.TAIGA': 3166,

    'Vertical Stone Bridge.RIGHT.TOP.TAIGA': 3167,

    //
    'Wooden Rail.TOP.': 3168,
    'Wooden Rail.RIGHT.TOP.': 3169,
    'Wooden Rail.LEFT.RIGHT.TOP.': 3170,
    'Wooden Rail.LEFT.TOP.': 3171,
    'Metal Rail.RIGHT.': 3172,
    'Metal Rail.LEFT.RIGHT': 3173,
    'Metal Rail.LEFT.': 3174,
    'Metal Rail.TOP.': 3175,
    'Metal Rail.RIGHT.TOP.': 3176,
    'Metal Rail.LEFT.RIGHT.TOP.': 3177,
    'Metal Rail.LEFT.TOP.': 3178,
    'Wooden Bridge.TOP.DESERT': 3179,
    'Horizontal Brick Bridge.Footrail.RIGHT.DESERT': 3180,
    'Horizontal Brick Bridge.Footrail.LEFT.RIGHT.DESERT': 3181,
    'Horizontal Brick Bridge.Footrail.LEFT.DESERT': 3182,
    'Horizontal Brick Bridge.RIGHT.TOP.DESERT': 3183,
    'Horizontal Brick Bridge.LEFT.RIGHT.TOP.DESERT': 3184,
    'Horizontal Brick Bridge.LEFT.TOP.DESERT': 3185,
    'Vertical Brick Bridge.RIGHT.TOP.DESERT': 3186,
    'Vertical Brick Bridge.LEFT.RIGHT.TOP.DESERT': 3187,
    'Vertical Brick Bridge.LEFT.TOP.DESERT': 3188,
    'Horizontal Stone Bridge.RIGHT.TOP.DESERT': 3189,
    'Horizontal Stone Bridge.LEFT.RIGHT.TOP.DESERT': 3190,
    'Horizontal Stone Bridge.LEFT.TOP.DESERT': 3191,
    'Vertical Stone Bridge.RIGHT.TOP.DESERT': 3192,
    'Vertical Stone Bridge.LEFT.RIGHT.TOP.DESERT': 3193,
    'Vertical Stone Bridge.LEFT.TOP.DESERT': 3194,
    'Wooden Bridge.TOP.SWAMP': 3195,
    'Horizontal Brick Bridge.Footrail.RIGHT.SWAMP': 3196,
    'Horizontal Brick Bridge.Footrail.LEFT.RIGHT.SWAMP': 3197,
    'Horizontal Brick Bridge.Footrail.LEFT.SWAMP': 3198,
    'Horizontal Brick Bridge.RIGHT.TOP.SWAMP': 3199,
    'Horizontal Brick Bridge.LEFT.RIGHT.TOP.SWAMP': 3200,
    'Horizontal Brick Bridge.LEFT.TOP.SWAMP': 3201,
    'Vertical Brick Bridge.RIGHT.TOP.SWAMP': 3202,
    'Vertical Brick Bridge.LEFT.RIGHT.TOP.SWAMP': 3203,
    'Vertical Brick Bridge.LEFT.TOP.SWAMP': 3204,
    'Horizontal Stone Bridge.RIGHT.TOP.SWAMP': 3205,
    'Horizontal Stone Bridge.LEFT.RIGHT.TOP.SWAMP': 3206,
    'Horizontal Stone Bridge.LEFT.TOP.SWAMP': 3207,
    'Vertical Stone Bridge.RIGHT.TOP.SWAMP': 3208,
    'Vertical Stone Bridge.LEFT.RIGHT.TOP.SWAMP': 3209,
    'Vertical Stone Bridge.LEFT.TOP.SWAMP': 3210,
    'Wooden Bridge.TOP.TAIGA': 3211,
    'Horizontal Stone Bridge.Footrail.RIGHT.TAIGA': 3212,
    'Horizontal Stone Bridge.Footrail.LEFT.RIGHT.TAIGA': 3213,
    'Horizontal Stone Bridge.Footrail.LEFT.TAIGA': 3214,
    'Vertical Brick Bridge.LEFT.RIGHT.BOTTOM.TAIGA': 3215,

    //
    
    'Raft.Vertical.A1': 3216,
    'Raft.Horizontal.A1': 3217,
    'Outrigger canoe.Horizontal.A1': 3218,
    'Outrigger canoe.Horizontal.A2': 3219,
    'Outrigger canoe.Vertical.A1': 3220,

    'Horizontal Brick Bridge.RIGHT.BOTTOM.OLD-GROWTH': 3221,
    'Horizontal Brick Bridge.LEFT.RIGHT.BOTTOM.OLD-GROWTH': 3222,
    'Horizontal Brick Bridge.LEFT.BOTTOM.OLD-GROWTH': 3223,

    'Vertical Brick Bridge.RIGHT.BOTTOM.OLD-GROWTH': 3224,
    'Vertical Brick Bridge.LEFT.RIGHT.BOTTOM.OLD-GROWTH': 3225,
    'Vertical Brick Bridge.LEFT.BOTTOM.OLD-GROWTH': 3226,

    'Horizontal Stone Bridge.RIGHT.BOTTOM.OLD-GROWTH': 3227,
    'Horizontal Stone Bridge.LEFT.RIGHT.BOTTOM.OLD-GROWTH': 3228,
    'Horizontal Stone Bridge.LEFT.BOTTOM.OLD-GROWTH': 3229,

    'Vertical Stone Bridge.RIGHT.BOTTOM.OLD-GROWTH': 3230,
    'Vertical Stone Bridge.LEFT.RIGHT.BOTTOM.OLD-GROWTH': 3231,
    'Vertical Stone Bridge.LEFT.BOTTOM.OLD-GROWTH': 3232,

    'Horizontal Brick Bridge.RIGHT.BOTTOM.ARID': 3233,
    'Horizontal Brick Bridge.LEFT.RIGHT.BOTTOM.ARID': 3234,
    'Horizontal Brick Bridge.LEFT.BOTTOM.ARID': 3235,

    'Vertical Brick Bridge.RIGHT.BOTTOM.ARID': 3236,
    'Vertical Brick Bridge.LEFT.RIGHT.BOTTOM.ARID': 3237,
    'Vertical Brick Bridge.LEFT.BOTTOM.ARID': 3238,

    'Horizontal Stone Bridge.RIGHT.BOTTOM.ARID': 3239,
    'Horizontal Stone Bridge.LEFT.RIGHT.BOTTOM.ARID': 3240,
    'Horizontal Stone Bridge.LEFT.BOTTOM.ARID': 3241,

    'Vertical Stone Bridge.RIGHT.BOTTOM.ARID': 3242,
    'Vertical Stone Bridge.LEFT.RIGHT.BOTTOM.ARID': 3243,
    'Vertical Stone Bridge.LEFT.BOTTOM.ARID': 3244,

    'Horizontal Brick Bridge.RIGHT.BOTTOM.JUNGLE': 3245,
    'Horizontal Brick Bridge.LEFT.RIGHT.BOTTOM.JUNGLE': 3246,
    'Horizontal Brick Bridge.LEFT.BOTTOM.JUNGLE': 3247,

    'Vertical Brick Bridge.RIGHT.BOTTOM.JUNGLE': 3248,
    'Vertical Brick Bridge.LEFT.RIGHT.BOTTOM.JUNGLE': 3249,
    'Vertical Brick Bridge.LEFT.BOTTOM.JUNGLE': 3250,

    'Horizontal Stone Bridge.RIGHT.BOTTOM.JUNGLE': 3251,
    'Horizontal Stone Bridge.LEFT.RIGHT.BOTTOM.JUNGLE': 3252,
    'Horizontal Stone Bridge.LEFT.BOTTOM.JUNGLE': 3253,

    'Vertical Stone Bridge.RIGHT.BOTTOM.JUNGLE': 3254,
    'Vertical Stone Bridge.LEFT.RIGHT.BOTTOM.JUNGLE': 3255,
    'Vertical Stone Bridge.LEFT.BOTTOM.JUNGLE': 3256,

    'Horizontal Stone Bridge.RIGHT.BOTTOM.TAIGA': 3257,
    'Horizontal Stone Bridge.LEFT.RIGHT.BOTTOM.TAIGA': 3258,
    'Horizontal Stone Bridge.LEFT.BOTTOM.TAIGA': 3259,
    'Vertical Stone Bridge.RIGHT.BOTTOM.TAIGA': 3260,
    'Vertical Stone Bridge.LEFT.RIGHT.BOTTOM.TAIGA': 3261,
    'Vertical Stone Bridge.LEFT.BOTTOM.TAIGA': 3262,

    'Vertical Brick Bridge.LEFT.RIGHT.TAIGA': 3263,

    //

    'Canoe.Horizontal.A1': 3264,
    'Canoe.Vertical.A1': 3265,
    'Small sail boat.Horizontal.Sail.A1': 3266,
    'Small sail boat.Horizontal.Sail.A2': 3267,
    'Outrigger canoe.Vertical.B1.': 3268,

    'Horizontal Brick Bridge.RIGHT.TOP.BOTTOM.OLD-GROWTH': 3269,
    'Horizontal Brick Bridge.LEFT.RIGHT.TOP.BOTTOM.OLD-GROWTH': 3270,
    'Horizontal Brick Bridge.LEFT.TOP.BOTTOM.OLD-GROWTH': 3271,

    'Vertical Brick Bridge.RIGHT.TOP.BOTTOM.OLD-GROWTH': 3272,
    'Vertical Brick Bridge.LEFT.TOP.RIGHT.BOTTOM.OLD-GROWTH': 3273,
    'Vertical Brick Bridge.LEFT.TOP.BOTTOM.OLD-GROWTH': 3274,

    'Horizontal Stone Bridge.RIGHT.TOP.BOTTOM.OLD-GROWTH': 3275,
    'Horizontal Stone Bridge.LEFT.RIGHT.TOP.BOTTOM.OLD-GROWTH': 3276,
    'Horizontal Stone Bridge.LEFT.TOP.BOTTOM.OLD-GROWTH': 3277,

    'Vertical Stone Bridge.RIGHT.TOP.BOTTOM.OLD-GROWTH': 3278,
    'Vertical Stone Bridge.LEFT.RIGHT.TOP.BOTTOM.OLD-GROWTH': 3279,
    'Vertical Stone Bridge.LEFT.TOP.BOTTOM.OLD-GROWTH': 3280,

    'Horizontal Brick Bridge.RIGHT.TOP.BOTTOM.ARID': 3281,
    'Horizontal Brick Bridge.LEFT.RIGHT.TOP.BOTTOM.ARID': 3282,
    'Horizontal Brick Bridge.LEFT.TOP.BOTTOM.ARID': 3283,

    'Vertical Brick Bridge.RIGHT.TOP.BOTTOM.ARID': 3284,
    'Vertical Brick Bridge.LEFT.RIGHT.TOP.BOTTOM.ARID': 3285,
    'Vertical Brick Bridge.LEFT.TOP.BOTTOM.ARID': 3286,

    'Horizontal Stone Bridge.RIGHT.TOP.BOTTOM.ARID': 3287,
    'Horizontal Stone Bridge.LEFT.TOP.RIGHT.BOTTOM.ARID': 3288,
    'Horizontal Stone Bridge.LEFT.TOP.BOTTOM.ARID': 3289,

    'Vertical Stone Bridge.RIGHT.TOP.BOTTOM.ARID': 3290,
    'Vertical Stone Bridge.LEFT.RIGHT.TOP.BOTTOM.ARID': 3291,
    'Vertical Stone Bridge.LEFT.TOP.BOTTOM.ARID': 3292,

    'Horizontal Brick Bridge.RIGHT.TOP.BOTTOM.JUNGLE': 3293,
    'Horizontal Brick Bridge.LEFT.RIGHT.TOP.BOTTOM.JUNGLE': 3294,
    'Horizontal Brick Bridge.LEFT.TOP.BOTTOM.JUNGLE': 3295,

    'Vertical Brick Bridge.RIGHT.TOP.BOTTOM.JUNGLE': 3296,
    'Vertical Brick Bridge.LEFT.RIGHT.TOP.BOTTOM.JUNGLE': 3297,
    'Vertical Brick Bridge.LEFT.TOP.BOTTOM.JUNGLE': 3298,

    'Horizontal Stone Bridge.RIGHT.TOP.BOTTOM.JUNGLE': 3299,
    'Horizontal Stone Bridge.LEFT.RIGHT.TOP.BOTTOM.JUNGLE': 3300,
    'Horizontal Stone Bridge.LEFT.TOP.BOTTOM.JUNGLE': 3301,

    'Vertical Stone Bridge.RIGHT.TOP.BOTTOM.JUNGLE': 3302,
    'Vertical Stone Bridge.LEFT.RIGHT.TOP.BOTTOM.JUNGLE': 3303,
    'Vertical Stone Bridge.LEFT.TOP.BOTTOM.JUNGLE': 3304,

    'Horizontal Stone Bridge.RIGHT.TOP.BOTTOM.TAIGA': 3305,
    'Horizontal Stone Bridge.LEFT.RIGHT.TOP.BOTTOM.TAIGA': 3306,
    'Horizontal Stone Bridge.LEFT.TOP.BOTTOM.TAIGA': 3307,
    'Vertical Stone Bridge.RIGHT.TOP.BOTTOM.TAIGA': 3308,
    'Vertical Stone Bridge.LEFT.RIGHT.TOP.BOTTOM.TAIGA': 3309,
    'Vertical Stone Bridge.LEFT.TOP.BOTTOM.TAIGA': 3310,

    'Vertical Brick Bridge.LEFT.RIGHT.TOP.TAIGA': 3311,

    // 

    'Rowing boat.Horizontal.A1': 3312,
    'Rowing boat.Vertical.A1': 3313,
    'Small sail boat.Horizontal.B1': 3314,
    'Small sail boat.Horizontal.B2': 3315,
    'Small sail boat.Vertical.A1': 3316,

    'Horizontal Brick Bridge.RIGHT.TOP.OLD-GROWTH': 3317,
    'Horizontal Brick Bridge.LEFT.RIGHT.TOP.OLD-GROWTH': 3318,
    'Horizontal Brick Bridge.LEFT.TOP.OLD-GROWTH': 3319,

    'Vertical Brick Bridge.RIGHT.TOP.OLD-GROWTH': 3320,
    'Vertical Brick Bridge.LEFT.TOP.RIGHT.OLD-GROWTH': 3321,
    'Vertical Brick Bridge.LEFT.TOP.OLD-GROWTH': 3322,

    'Horizontal Stone Bridge.RIGHT.TOP.OLD-GROWTH': 3323,
    'Horizontal Stone Bridge.LEFT.RIGHT.TOP.OLD-GROWTH': 3324,
    'Horizontal Stone Bridge.LEFT.TOP.OLD-GROWTH': 3325,

    'Vertical Stone Bridge.RIGHT.TOP.OLD-GROWTH': 3326,
    'Vertical Stone Bridge.LEFT.RIGHT.TOP.OLD-GROWTH': 3327,
    'Vertical Stone Bridge.LEFT.TOP.OLD-GROWTH': 3328,

    'Horizontal Brick Bridge.RIGHT.TOP.ARID': 3329,
    'Horizontal Brick Bridge.LEFT.RIGHT.TOP.ARID': 3330,
    'Horizontal Brick Bridge.LEFT.TOP.ARID': 3331,

    'Vertical Brick Bridge.RIGHT.TOP.ARID': 3332,
    'Vertical Brick Bridge.LEFT.RIGHT.TOP.ARID': 3333,
    'Vertical Brick Bridge.LEFT.TOP.ARID': 3334,

    'Horizontal Stone Bridge.RIGHT.TOP.ARID': 3335,
    'Horizontal Stone Bridge.LEFT.TOP.RIGHT.ARID': 3336,
    'Horizontal Stone Bridge.LEFT.TOP.ARID': 3337,

    'Vertical Stone Bridge.RIGHT.TOP.ARID': 3338,
    'Vertical Stone Bridge.LEFT.RIGHT.TOP.ARID': 3339,
    'Vertical Stone Bridge.LEFT.TOP.ARID': 3340,

    'Horizontal Brick Bridge.RIGHT.TOP.JUNGLE': 3341,
    'Horizontal Brick Bridge.LEFT.RIGHT.TOP.JUNGLE': 3342,
    'Horizontal Brick Bridge.LEFT.TOP.JUNGLE': 3343,

    'Vertical Brick Bridge.RIGHT.TOP.JUNGLE': 3344,
    'Vertical Brick Bridge.LEFT.RIGHT.TOP.JUNGLE': 3345,
    'Vertical Brick Bridge.LEFT.TOP.JUNGLE': 3346,

    'Horizontal Stone Bridge.RIGHT.TOP.JUNGLE': 3347,
    'Horizontal Stone Bridge.LEFT.RIGHT.TOP.JUNGLE': 3348,
    'Horizontal Stone Bridge.LEFT.TOP.JUNGLE': 3349,

    'Vertical Stone Bridge.RIGHT.TOP.JUNGLE': 3350,
    'Vertical Stone Bridge.LEFT.RIGHT.TOP.JUNGLE': 3351,
    'Vertical Stone Bridge.LEFT.TOP.JUNGLE': 3352,

    'Horizontal Stone Bridge.RIGHT.TOP.TAIGA': 3353,
    'Horizontal Stone Bridge.LEFT.RIGHT.TOP.TAIGA': 3354,
    'Horizontal Stone Bridge.LEFT.TOP.TAIGA': 3355,
    'Vertical Stone Bridge.RIGHT.TOP.TAIGA': 3356,
    'Vertical Stone Bridge.LEFT.RIGHT.TOP.TAIGA': 3357,
    'Vertical Stone Bridge.LEFT.TOP.TAIGA': 3358,

    'Vertical Brick Bridge.LEFT.BOTTOM.TAIGA': 3359,

    //

    'Kayak.Horizontal.A1': 3360,
    'Kayak.Vertical.A1': 3361,
    'Barge.Horizontal.A1': 3362,
    'Barge.Horizontal.A2': 3363,
    'Small sail boat.Vertical.B1': 3364,

    'Horizontal Stone Bridge.Footrail.RIGHT.OLD-GROWTH': 3365,
    'Horizontal Stone Bridge.Footrail.LEFT.RIGHT.OLD-GROWTH': 3366,
    'Horizontal Stone Bridge.Footrail.LEFT.OLD-GROWTH': 3367,

    'Wooden Bridge.RIGHT.OLD-GROWTH': 3368,
    'Wooden Bridge.LEFT.RIGHT.OLD-GROWTH': 3369,
    'Wooden Bridge.LEFT.OLD-GROWTH': 3370,

    'Horizontal Brick Bridge.Footrail.RIGHT.OLD-GROWTH': 3371,
    'Horizontal Brick Bridge.Footrail.LEFT.RIGHT.OLD-GROWTH': 3372,
    'Horizontal Brick Bridge.Footrail.LEFT.OLD-GROWTH': 3373,

    'Wooden Bridge.BOTTOM.OLD-GROWTH': 3374,
    'Wooden Bridge.TOP.BOTTOM.OLD-GROWTH': 3375,
    'Wooden Bridge.TOP.OLD-GROWTH': 3376,

    'Horizontal Stone Bridge.Footrail.RIGHT.ARID': 3377,
    'Horizontal Stone Bridge.Footrail.LEFT.ARID': 3378,
    'Horizontal Stone Bridge.Footrail.LEFT.ARID': 3379,

    'Wooden Bridge.RIGHT.ARID': 3380,
    'Wooden Bridge.LEFT.RIGHT.ARID': 3381,
    'Wooden Bridge.LEFT.ARID': 3382,

    'Horizontal Brick Bridge.Footrail.RIGHT.ARID': 3383,
    'Horizontal Brick Bridge.Footrail.LEFT.RIGHT.ARID': 3384,
    'Horizontal Brick Bridge.Footrail.LEFT.ARID': 3385,

    'Wooden Bridge.BOTTOM.ARID': 3386,
    'Wooden Bridge.TOP.BOTTOM.ARID': 3387,
    'Wooden Bridge.TOP.ARID': 3388,

    'Horizontal Stone Bridge.Footrail.RIGHT.JUNGLE': 3389,
    'Horizontal Stone Bridge.Footrail.LEFT.JUNGLE': 3390,
    'Horizontal Stone Bridge.Footrail.LEFT.JUNGLE': 3391,

    'Wooden Bridge.RIGHT.JUNGLE': 3392,
    'Wooden Bridge.LEFT.RIGHT.JUNGLE': 3393,
    'Wooden Bridge.LEFT.JUNGLE': 3394,

    'Horizontal Brick Bridge.Footrail.RIGHT.JUNGLE': 3395,
    'Horizontal Brick Bridge.Footrail.LEFT.RIGHT.JUNGLE': 3396,
    'Horizontal Brick Bridge.Footrail.LEFT.JUNGLE': 3397,

    'Wooden Bridge.BOTTOM.JUNGLE': 3398,
    'Wooden Bridge.TOP.BOTTOM.JUNGLE': 3399,
    'Wooden Bridge.TOP.JUNGLE': 3400,

    'Horizontal Brick Bridge.Footrail.RIGHT.TAIGA': 3401,
    'Horizontal Brick Bridge.Footrail.LEFT.RIGHT.TAIGA': 3402,
    'Horizontal Brick Bridge.Footrail.LEFT.TAIGA': 3403,

    '': 3404,
    '': 3405,
    'Vertical Brick Bridge.LEFT.TOP.TAIGA': 3406,

    'Vertical Brick Bridge.LEFT.TOP.BOTTOM.TAIGA': 3407,

    // 

    'Sail canoe.Vertical.A1': 3408,
    'Sail canoe.Vertical.A2': 3409,

    'Cutter.Horizontal.A1': 3410,
    'Cutter.Horizontal.A2': 3411,

    'Lugger.Horizontal.A1': 3412,
    'Lugger.Horizontal.A2': 3413,

    'Galley.Horizontal.A1': 3414,
    'Galley.Horizontal.A2': 3415,
    'Galley.Horizontal.A3': 3416,

    'Brigantine.Vertical.A1': 3417,
    'Brigantine.Vertical.A2': 3418,

    'Longboat.Vertical.A1': 3419,

    'Schooner.Vertical.A1': 3420,
    'Schooner.Vertical.A2': 3421,

    'Brigantine.Vertical.C1': 3422,
    '': 3423,
    'Brigantine.Vertical.C2': 3424,

    'Brigantine.Vertical.D1': 3425,
    'Brigantine.Vertical.D2': 3426,
    'Polished rock': 3427,

    'Swamp Road.BOTTOM.': 3428,
    'Swamp Road.RIGHT.BOTTOM.': 3429,
    'Swamp Road.LEFT.RIGHT.BOTTOM.': 3430,
    'Swamp Road.LEFT.BOTTOM.': 3431,

    'Horizontal Brick Bridge.RIGHT.BOTTOM.RIVER': 3432,
    'Horizontal Brick Bridge.LEFT.RIGHT.BOTTOM.RIVER': 3433,
    'Horizontal Brick Bridge.LEFT.BOTTOM.RIVER': 3434,

    'Vertical Brick Bridge.RIGHT.BOTTOM.RIVER': 3435,
    'Vertical Brick Bridge.LEFT.RIGHT.BOTTOM.RIVER': 3436,
    'Vertical Brick Bridge.LEFT.BOTTOM.RIVER': 3437,

    'Horizontal Stone Bridge.RIGHT.BOTTOM.RIVER': 3438,
    'Horizontal Stone Bridge.LEFT.RIGHT.BOTTOM.RIVER': 3439,
    'Horizontal Stone Bridge.LEFT.BOTTOM.RIVER': 3440,

    'Vertical Stone Bridge.RIGHT.BOTTOM.RIVER': 3441,
    'Vertical Stone Bridge.LEFT.RIGHT.BOTTOM.RIVER': 3442,
    'Vertical Stone Bridge.LEFT.BOTTOM.RIVER': 3443,

    'Horizontal Brick Bridge.RIGHT.BOTTOM.POLAR': 3444,
    'Horizontal Brick Bridge.LEFT.RIGHT.BOTTOM.POLAR': 3445,
    'Horizontal Brick Bridge.LEFT.BOTTOM.POLAR': 3446,

    'Vertical Brick Bridge.RIGHT.BOTTOM.POLAR': 3447,
    'Vertical Brick Bridge.LEFT.RIGHT.BOTTOM.POLAR': 3448,
    'Vertical Brick Bridge.LEFT.BOTTOM.POLAR': 3449,

    'Horizontal Stone Bridge.RIGHT.BOTTOM.POLAR': 3450,
    'Horizontal Stone Bridge.LEFT.RIGHT.BOTTOM.POLAR': 3451,
    'Horizontal Stone Bridge.LEFT.BOTTOM.POLAR': 3452,

    'Vertical Stone Bridge.RIGHT.BOTTOM.POLAR': 3453,
    'Vertical Stone Bridge.LEFT.RIGHT.BOTTOM.POLAR': 3454,
    'Vertical Stone Bridge.LEFT.BOTTOM.POLAR': 3455,

    // 

    'Sail canoe.Vertical.B1': 3456,
    'Sail canoe.Vertical.B2': 3457,

    'Cutter.Horizontal.B1': 3458,
    'Cutter.Horizontal.B2': 3459,

    'Lugger.Horizontal.B1': 3460,
    'Lugger.Horizontal.B2': 3461,

    'Galley.Vertical.A1': 3462,

    'Schooner.Horizontal.A1': 3463,
    'Schooner.Horizontal.A2': 3464,
    'Schooner.Horizontal.A3': 3465,

    'Barge.Vertical.A1': 3466,

    'Longboat.Vertical.B1': 3467,

    'Schooner.Vertical.B1': 3468,
    'Schooner.Vertical.B2': 3469,

    'Brigantine.Horizontal.A1': 3470,
    'Brigantine.Horizontal.A2': 3471,
    'Brigantine.Horizontal.A3': 3472,
    'Brigantine.Horizontal.A4': 3473,

    'Polished black rock': 3474,
    'Polished Janssium': 3475,

    'Swamp Road.TOP.BOTTOM.': 3476,
    'Swamp Road.RIGHT.TOP.BOTTOM.': 3477,
    'Swamp Road.LEFT.RIGHT.TOP.BOTTOM.': 3478,
    'Swamp Road.LEFT.TOP.BOTTOM.': 3479,

    'Horizontal Brick Bridge.RIGHT.TOP.BOTTOM.RIVER': 3480,
    'Horizontal Brick Bridge.LEFT.RIGHT.TOP.BOTTOM.RIVER': 3481,
    'Horizontal Brick Bridge.LEFT.TOP.BOTTOM.RIVER': 3482,

    'Vertical Brick Bridge.RIGHT.TOP.BOTTOM.RIVER': 3483,
    'Vertical Brick Bridge.LEFT.RIGHT.TOP.BOTTOM.RIVER': 3484,
    'Vertical Brick Bridge.LEFT.TOP.BOTTOM.RIVER': 3485,

    'Horizontal Stone Bridge.RIGHT.TOP.BOTTOM.RIVER': 3486,
    'Horizontal Stone Bridge.LEFT.RIGHT.TOP.BOTTOM.RIVER': 3487,
    'Horizontal Stone Bridge.LEFT.TOP.BOTTOM.RIVER': 3488,

    'Vertical Stone Bridge.RIGHT.TOP.BOTTOM.RIVER': 3489,
    'Vertical Stone Bridge.LEFT.RIGHT.TOP.BOTTOM.RIVER': 3490,
    'Vertical Stone Bridge.LEFT.TOP.BOTTOM.RIVER': 3491,

    'Horizontal Brick Bridge.RIGHT.TOP.BOTTOM.POLAR': 3492,
    'Horizontal Brick Bridge.LEFT.RIGHT.TOP.BOTTOM.POLAR': 3493,
    'Horizontal Brick Bridge.LEFT.TOP.BOTTOM.POLAR': 3494,

    'Vertical Brick Bridge.RIGHT.TOP.BOTTOM.POLAR': 3495,
    'Vertical Brick Bridge.LEFT.RIGHT.TOP.BOTTOM.POLAR': 3496,
    'Vertical Brick Bridge.LEFT.TOP.BOTTOM.POLAR': 3497,

    'Horizontal Stone Bridge.RIGHT.TOP.BOTTOM.POLAR': 3498,
    'Horizontal Stone Bridge.LEFT.RIGHT.TOP.BOTTOM.POLAR': 3499,
    'Horizontal Stone Bridge.LEFT.TOP.BOTTOM.POLAR': 3500,

    'Vertical Stone Bridge.RIGHT.TOP.BOTTOM.POLAR': 3501,
    'Vertical Stone Bridge.LEFT.RIGHT.TOP.BOTTOM.POLAR': 3502,
    'Vertical Stone Bridge.LEFT.TOP.BOTTOM.POLAR': 3503,

    // 

    'Sail canoe.Horizontal.A1': 3504,
    'Sail canoe.Horizontal.A2': 3505,

    'Cutter.Vertical.A1': 3506,
    'Cutter.Vertical.A2': 3507,

    'Lugger.Vertical.A1': 3508,
    'Lugger.Vertical.A2': 3509,

    'Galley.Vertical.B1': 3510,

    'Schooner.Horizontal.B1': 3511,
    'Schooner.Horizontal.B2': 3512,
    'Schooner.Horizontal.B3': 3513,

    'Barge.Vertical.B1': 3514,

    'Longboat.Vertical.C1': 3515,

    'Schooner.Vertical.C1': 3516,
    'Schooner.Vertical.C2': 3517,

    'Brigantine.Horizontal.B1': 3518,
    'Brigantine.Horizontal.B2': 3519,
    'Brigantine.Horizontal.B3': 3520,
    'Brigantine.Horizontal.B4': 3521,

    'Polished Matsunium': 3522,
    'Polished Sugarium': 3523,

    'Swamp Road.TOP.': 3524,
    'Swamp Road.RIGHT.TOP.': 3525,
    'Swamp Road.LEFT.RIGHT.TOP.': 3526,
    'Swamp Road.LEFT.TOP.': 3527,

    'Horizontal Brick Bridge.RIGHT.TOP.RIVER': 3528,
    'Horizontal Brick Bridge.LEFT.RIGHT.TOP.RIVER': 3529,
    'Horizontal Brick Bridge.LEFT.TOP.RIVER': 3530,

    'Vertical Brick Bridge.RIGHT.TOP.RIVER': 3531,
    'Vertical Brick Bridge.LEFT.RIGHT.TOP.RIVER': 3532,
    'Vertical Brick Bridge.LEFT.TOP.RIVER': 3533,

    'Horizontal Stone Bridge.RIGHT.TOP.RIVER': 3534,
    'Horizontal Stone Bridge.LEFT.RIGHT.TOP.RIVER': 3535,
    'Horizontal Stone Bridge.LEFT.TOP.RIVER': 3536,

    'Vertical Stone Bridge.RIGHT.TOP.RIVER': 3537,
    'Vertical Stone Bridge.LEFT.RIGHT.TOP.RIVER': 3538,
    'Vertical Stone Bridge.LEFT.TOP.RIVER': 3539,

    'Horizontal Brick Bridge.RIGHT.TOP.POLAR': 3540,
    'Horizontal Brick Bridge.LEFT.RIGHT.TOP.POLAR': 3541,
    'Horizontal Brick Bridge.LEFT.TOP.POLAR': 3542,

    'Vertical Brick Bridge.RIGHT.TOP.POLAR': 3543,
    'Vertical Brick Bridge.LEFT.RIGHT.TOP.POLAR': 3544,
    'Vertical Brick Bridge.LEFT.TOP.POLAR': 3545,

    'Horizontal Stone Bridge.RIGHT.TOP.POLAR': 3546,
    'Horizontal Stone Bridge.LEFT.RIGHT.TOP.POLAR': 3547,
    'Horizontal Stone Bridge.LEFT.TOP.POLAR': 3548,

    'Vertical Stone Bridge.RIGHT.TOP.POLAR': 3549,
    'Vertical Stone Bridge.LEFT.RIGHT.TOP.POLAR': 3550,
    'Vertical Stone Bridge.LEFT.TOP.POLAR': 3551,

    //

    'Sail canoe.Horizontal.B1': 3552,
    'Sail canoe.Horizontal.B2': 3553,

    'Cutter.Vertical.B1': 3554,
    'Cutter.Vertical.B2': 3555,

    'Lugger.Vertical.B1': 3556,
    'Lugger.Vertical.B2': 3557,

    'Galley.Vertical.C1': 3558,

    'Longboat.Horizontal.A1': 3559,
    'Longboat.Horizontal.A2': 3560,
    'Longboat.Horizontal.A3': 3561,
    'Longboat.Horizontal.A4': 3562,

    'Longboat.Vertical.D1': 3563,

    'Brigantine.Vertical.B1': 3564,
    'Brigantine.Vertical.B2': 3565,

    'Brigantine.Horizontal.C1': 3566,
    'Brigantine.Horizontal.C2': 3567,
    'Brigantine.Horizontal.C3': 3568,
    'Brigantine.Horizontal.C4': 3569,

    'Polished Exuperium': 3570,
    'Polished Moebium': 3571,

    'Swamp Road.NONE': 3572,
    'Swamp Road.RIGHT.': 3573,
    'Swamp Road.LEFT.RIGHT.': 3574,
    'Swamp Road.LEFT.': 3575,

    'Horizontal Stone Bridge.Footrail.RIGHT.RIVER': 3576,
    'Horizontal Stone Bridge.Footrail.LEFT.RIGHT.RIVER': 3577,
    'Horizontal Stone Bridge.Footrail.LEFT.RIVER': 3578,

    'Wooden Bridge.RIGHT.RIVER': 3579,
    'Wooden Bridge.LEFT.RIGHT.RIVER': 3580,
    'Wooden Bridge.LEFT.RIVER': 3581,

    'Horizontal Brick Bridge.RIGHT.RIVER': 3582,
    'Horizontal Brick Bridge.LEFT.RIGHT.RIVER': 3583,
    'Horizontal Brick Bridge.LEFT.RIVER': 3584,

    'Wooden Bridge.BOTTOM.RIVER': 3585,
    'Wooden Bridge.TOP.BOTTOM.RIVER': 3586,
    'Wooden Bridge.TOP.RIVER': 3587,

    'Horizontal Stone Bridge.Footrail.RIGHT.POLAR': 3588,
    'Horizontal Stone Bridge.Footrail.LEFT.RIGHT.POLAR': 3589,
    'Horizontal Stone Bridge.Footrail.LEFT.POLAR': 3590,

    'Wooden Bridge.RIGHT.POLAR': 3591,
    'Wooden Bridge.LEFT.RIGHT.POLAR': 3592,
    'Wooden Bridge.LEFT.POLAR': 3593,

    'Horizontal Brick Bridge.RIGHT.POLAR': 3594,
    'Horizontal Brick Bridge.LEFT.RIGHT.POLAR': 3595,
    'Horizontal Brick Bridge.LEFT.POLAR': 3596,

    'Wooden Bridge.BOTTOM.POLAR': 3597,
    'Wooden Bridge.TOP.BOTTOM.POLAR': 3598,
    'Wooden Bridge.TOP.POLAR': 3599,

    'Ketch.Vertical.Sail.A1': 3600,
    'Ketch.Horizontal.Sail.A2': 3601,
    'Ketch.Horizontal.Sail.A3': 3602,
    'Ketch.Vertical.Sail.A2': 3603,

    'Ketch.Vertical.A1': 3604,
    'Ketch.Vertical.A2': 3605,

    'Caravel.Horizontal.Sail.A1': 3606,
    'Caravel.Horizontal.Sail.A2': 3607,
    'Caravel.Horizontal.Sail.A3': 3608,

    'Caravel.Vertical.Sail.A1': 3609,
    'Caravel.Vertical.Sail.A2': 3610,

    'Polished Waldium': 3611,
    'Polished Tolkium': 3612,
    'Polished Lowrium': 3613,

    'Farm Plot.Tended': 3614,
    'MINE-WALL.NONE': 3615,

    'Glass-Salt.Unmined': 3616,
    'Granite.Unmined': 3617,
    'Limestone.Unmined': 3618,
    'Claystone.Unmined': 3619,
    'Marble.Unmined': 3620,
    'Basalt.Unmined': 3621,
    'Lead Ore.Unmined': 3622,
    'Gold Ore.Unmined': 3623,
    'Silver Ore.Unmined': 3624,
    'Garnet.Unmined': 3625,
    'Amethyst.Unmined': 3626,
    'Aquamarine.Unmined': 3627,
    'Diamond.Unmined': 3628,
    'Emerald.Unmined': 3629,
    'Moonstone.Unmined': 3630,
    'Ruby.Unmined': 3631,
    'Peridot.Unmined': 3632,
    'Sapphire.Unmined': 3633,
    'Opal.Unmined': 3634,
    'Citrine.Unmined': 3635,
    'Turquoise.Unmined': 3636,
    'Amber.Unmined': 3637,
    'Janssium Ore.Unmined': 3638,
    'Matsunium Ore.Unmined': 3639,
    'Sugarium Ore.Unmined': 3640,
    'Exuperium Ore.Unmined': 3641,
    'Moebium Ore.Unmined': 3642,
    'Waldium Ore.Unmined': 3643,
    'Tolkium Ore.Unmined': 3644,
    'Lowrium Ore.Unmined': 3645,
    'Ursulium Ore.Unmined': 3646,
    'Octavium Ore.Unmined': 3647,

    // 

    'Ketch.Horizontal.Sail.B1': 3648,
    'Ketch.Horizontal.Sail.B2': 3649,
    'Ketch.Horizontal.Sail.B3': 3650,
    'Ketch.Horizontal.Sail.B4': 3651,

    'Ketch.Vertical.B1': 3652,
    'Ketch.Vertical.B2': 3653,

    'Caravel.Horizontal.A1': 3654,
    'Caravel.Horizontal.A2': 3655,
    'Caravel.Horizontal.A3': 3656,

    'Caravel.Vertical.A1': 3657,
    'Caravel.Vertical.A2': 3658,

    'Polished Ursulium': 3659,
    'Polished Octavium': 3660,

    'Farm Plot.Untended': 3661,
    'Farm Plot.Untended.TEST': 3662,
    'Pointy Stick': 3663,

    'Window.DESERT': 3664,
    'Window.SWAMP': 3665,
    'Window.TAIGA': 3666,
    'Window.JUNGLE': 3667,
    'Window.ARID': 3668,
    'Window.OLD-GROWTH': 3669,
    'Window.RIVER': 3670,
    'Window.POLAR': 3671,
    'Window': 3671,

    'Clay Lamp.DESERT.OFF': 3672,
    'Clay Lamp.SWAMP.OFF': 3673,
    'Clay Lamp.TAIGA.OFF': 3674,
    'Clay Lamp.JUNGLE.OFF': 3675,
    'Clay Lamp.ARID.OFF': 3676,
    'Clay Lamp.OLD-GROWTH.OFF': 3677,
    'Clay Lamp.RIVER.OFF': 3678,
    'Clay Lamp.POLAR.OFF': 3679,

    'Stone Lamp.DESERT.OFF': 3680,
    'Stone Lamp.SWAMP.OFF': 3681,
    'Stone Lamp.TAIGA.OFF': 3682,
    'Stone Lamp.JUNGLE.OFF': 3683,
    'Stone Lamp.ARID.OFF': 3684,
    'Stone Lamp.OLD-GROWTH.OFF': 3685,
    'Stone Lamp.RIVER.OFF': 3686,
    'Stone Lamp.POLAR.OFF': 3687,

    'Metal Lamp.DESERT.OFF': 3688,
    'Metal Lamp.SWAMP.OFF': 3689,
    'Metal Lamp.TAIGA.OFF': 3690,
    'Metal Lamp.JUNGLE.OFF': 3691,
    'Metal Lamp.ARID.OFF': 3692,
    'Metal Lamp.OLD-GROWTH.OFF': 3693,
    'Metal Lamp.RIVER.OFF': 3694,
    'Metal Lamp.POLAR.OFF': 3695,

    // 

    'Ketch.Horizontal.A1': 3696,
    'Ketch.Horizontal.A2': 3697,
    'Ketch.Horizontal.A3': 3698,
    'Ketch.Horizontal.A4': 3699,

    'Ketch.Vertical.C1': 3700,
    'Ketch.Vertical.C2': 3701,

    'Caravel.Horizontal.B1': 3702,
    'Caravel.Horizontal.B2': 3703,
    'Caravel.Horizontal.B3': 3704,

    'Caravel.Vertical.B1': 3705,
    'Caravel.Vertical.B2': 3706,

    // 

    'Clay Lamp.DESERT.ON': 3720,
    'Clay Lamp.SWAMP.ON': 3721,
    'Clay Lamp.TAIGA.ON': 3722,
    'Clay Lamp.JUNGLE.ON': 3723,
    'Clay Lamp.ARID.ON': 3724,
    'Clay Lamp.OLD-GROWTH.ON': 3725,
    'Clay Lamp.RIVER.ON': 3726,
    'Clay Lamp.POLAR.ON': 3727,

    'Stone Lamp.DESERT.ON': 3728,
    'Stone Lamp.SWAMP.ON': 3729,
    'Stone Lamp.TAIGA.ON': 3730,
    'Stone Lamp.JUNGLE.ON': 3731,
    'Stone Lamp.ARID.ON': 3732,
    'Stone Lamp.OLD-GROWTH.ON': 3733,
    'Stone Lamp.RIVER.ON': 3734,
    'Stone Lamp.POLAR.ON': 3735,

    'Metal Lamp.DESERT.ON': 3736,
    'Metal Lamp.SWAMP.ON': 3737,
    'Metal Lamp.TAIGA.ON': 3738,
    'Metal Lamp.JUNGLE.ON': 3739,
    'Metal Lamp.ARID.ON': 3740,
    'Metal Lamp.OLD-GROWTH.ON': 3741,
    'Metal Lamp.RIVER.ON': 3742,
    'Metal Lamp.POLAR.ON': 3743,

    // 

    'Ketch.Horizontal.B1': 3744,
    'Ketch.Horizontal.B2': 3745,
    'Ketch.Horizontal.B3': 3746,
    'Ketch.Horizontal.B4': 3747,

    'Ketch.Vertical.D1': 3748,
    'Ketch.Vertical.D2': 3749,

    '': 3750,
    '': 3751,
    '': 3752,

    'Caravel.Vertical.C1': 3753,
    'Caravel.Vertical.C2': 3754,

    'Brush Bottoms.DESERT.Equipped': 38,
    'Brush Bottoms.SWAMP.Equipped': 78,
    'Brush Bottoms.TAIGA.Equipped': 118,
    'Brush Bottoms.JUNGLE.Equipped': 158,
    'Brush Bottoms.ARID.Equipped': 198,
    'Brush Bottoms.OLD-GROWTH.Equipped': 238,
    'Brush Bottoms.RIVER.Equipped': 278,
    'Brush Bottoms.POLAR.Equipped': 318,
    'Brush Top.DESERT.Equipped': 37,
    'Brush Top.SWAMP.Equipped': 77,
    'Brush Top.TAIGA.Equipped': 117,
    'Brush Top.JUNGLE.Equipped': 157,
    'Brush Top.ARID.Equipped': 197,
    'Brush Top.OLD-GROWTH.Equipped': 237,
    'Brush Top.RIVER.Equipped': 277,
    'Brush Top.POLAR.Equipped': 317,

    'Pointy Stick.Equipped': 45,
    'Backpack suit.Equipped': 85,
    'Pot Hat.Equipped': 125,

    // 







    /*
    (x Canoe 1x1
    x Kayak 1x1 
    x Raft 1x1
    x Rowing boat 1x1
    x Outrigger canoe 2x1
    x Small sail boat 2x1
    x Barge 2x1
    x Cutter 2x2
    x Sail canoe 2x2
    x Lugger 2x3 <<<<< I MADE THIS 2x2
    x Galley 3x1
    x Caravel 3x2
    x Schooner 3x3 <<<<< I MADE THIS 3x2
    Ketch 3x4
    Longboat 4x1
    Brigantine 4x2
    Galleon 4x3
    Sloop 5x1
    Brig 5x2)
    */
};

export default tileMap;