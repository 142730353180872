import store from '../redux/store';
import { batchActions } from 'redux-batched-actions';
import client from './client';
import seedrandom from 'seedrandom';

import * as Colyseus from "colyseus.js"; // not necessary if included via <script> tag.

// const host = window.location.href.indexOf('localhost') === -1 ? 'https://coly.settle-gliese.com' : 'ws://localhost:2567';
const host = 'https://coly.settle-gliese.com';

export const colyClient = new Colyseus.Client(host);

import { STAIRCASE_UP_KEY, STAIRCASE_DOWN_KEY, MINE_WALL_KEY, drawMine, drawMineEntrance } from './mine-geography';
import { 
    selectCharacter,
    selectTiles,
    selectMaterialTypes,
    selectTileTypes,
    selectPlantTypes,
    selectMineralTypes,
    selectToolTypes,
    selectMetalTypes,
    selectKeyTypes,
    selectMachineTypes,
    selectFurnitureTypes,
    selectConstructionTypes,
    selectWritingSurfaceTypes,
    selectWritingImplementTypes,
    selectWeaponTypes,
    selectArmourTypes,
    selectClothingTypes,
    selectJewelleryTypes,
    selectFoodTypes,
    selectLockTypes,
    selectAnimalTypes,
    selectPlantProducts,
    selectConstructionRecipes,
    selectBoatTypes,
    selectTentTypes,
    selectWorkshopTypes,
    selectWagonTypes,
    selectBrainchipTypes,
} from '../redux/selectors';

import { getMessagesAsync } from '../redux/actions/messages.actions';
import { startNewGame } from '../redux/actions/user.actions';
import { loadPanelAsync, loadPanelSuccess } from '../redux/actions/panel.actions';
import { clearAllTiles, loadTilesAsync, loadTilesFinished, loadTilesSuccess } from '../redux/actions/tile.actions';

import { clearStairs } from '../redux/actions/stair.actions';

import { getFoodSuccess, getCharacterFoodAsync, addFoodToTileAsync, eatFoodAsync, deleteFoodAsync, addFoodToCharacterAsync } from '../redux/actions/food.actions';
import { getInventorySuccess, getInventoryAsync, addMaterialToTileAsync, addMaterialToCharacterAsync } from '../redux/actions/inventory.actions';
import { tileTypes, createTileAsync } from '../redux/actions/tile.actions';
import { getMineralsSuccess, getMineralsAsync, addMineralToTileAsync, addMineralToCharacterAsync } from '../redux/actions/mineral.actions';
import { getToolsSuccess, getToolsAsync, addToolToTileAsync, addToolToCharacterAsync } from '../redux/actions/tool.actions';
import { getMetalsSuccess, getMetalsAsync, addMetalToTileAsync, addMetalToCharacterAsync } from '../redux/actions/metal.actions';
import { getKeysSuccess, getKeysAsync, addKeyToTileAsync, copyKeyAsync, addKeyToCharacterAsync } from '../redux/actions/key.actions';
import { getWritingSurfacesSuccess, addWritingSurfaceToTileAsync, getWritingSurfacesAsync, editWritingSurfaceAsync, addWritingSurfaceToCharacterAsync } from '../redux/actions/writing-surface.actions';
import { getWritingImplementsSuccess, addWritingImplementToTileAsync, getWritingImplementsAsync, addWritingImplementToCharacterAsync } from '../redux/actions/writing-implement.actions';
import { getWeaponsSuccess, getWeaponsAsync, addWeaponToTileAsync, addWeaponToCharacterAsync } from '../redux/actions/weapon.actions';
import { getArmourSuccess, getArmourAsync, addArmourToTileAsync, addArmourToCharacterAsync } from '../redux/actions/armour.actions';
import { getClothingSuccess, getClothingAsync, addClothingToTileAsync, addClothingToCharacterAsync } from '../redux/actions/clothing.actions';
import { getJewellerySuccess, getJewelleryAsync, addJewelleryToTileAsync, addJewelleryToCharacterAsync } from '../redux/actions/jewellery.actions';
import { getCharacterSuccess, equipWeaponAsync, equipArmourAsync, equipClothingAsync, equipJewelleryAsync, unequipClothingAsync, unequipJewelleryAsync } from '../redux/actions/character.actions';
import { getCharactersAsync, getCharactersSuccess } from '../redux/actions/characters.actions';
import { initialisingPanelStarted, initialisingPanelFinished, showQuantityInput, hideQuantityInput, chooseCharacterToGiveTo, hideCharacterList, closeInventory } from '../redux/actions/keyboard-shortcuts.actions';
import { getDeadCharactersAsync, getDeadCharactersSuccess } from '../redux/actions/dead-characters.actions';
import { getConstructionsAsync, getConstructionsSuccess } from '../redux/actions/construction.actions';
import { getPlantsAsync, getPlantsSuccess } from '../redux/actions/plant.actions';
import { getMachinesAsync } from '../redux/actions/machine.actions';
import { getLocksSuccess, getLocksAsync } from '../redux/actions/lock.actions';
import { getFurnitureSuccess, getFurnitureAsync } from '../redux/actions/furniture.actions';
import { getAnimalsAsync, getAnimalsSuccess } from '../redux/actions/animal.actions';
import { getBoatsAsync, getBoatsSuccess } from '../redux/actions/boat.actions';
import { getBrainchipsAsync, getBrainchipsSuccess } from '../redux/actions/brainchip.actions';
import { getTentsSuccess, getTentsAsync } from '../redux/actions/tent.actions';
import { getWorkshopsAsync, getWorkshopsSuccess } from '../redux/actions/workshop.actions';
import { getWagonsAsync, getWagonsSuccess } from '../redux/actions/wagon.actions';
import { getCoinsAsync } from '../redux/actions/coin.actions';
import { getCharacterLevelsSuccess, getCharacterLevelsAsync } from '../redux/actions/character-levels.actions';
import { getPromptsAsync } from '../redux/actions/prompt.actions';
import { getDiaryEntriesAsync } from '../redux/actions/diary-entries.actions';
import { getOrganisationsSuccess } from '../redux/actions/organisation.actions'
import { getZonesSuccess } from '../redux/actions/zone.actions'
import { getOrdersSuccess, getCompletedOrdersSuccess } from '../redux/actions/order.actions'
import { getCharacterPanelsAsync } from '../redux/actions/character-panel.actions'
import { getCriminalsSuccess } from '../redux/actions/criminal.actions'

import WebhooksService from './Webhooks.service';

const LEAVING_CODES = {
    ON_PANEL_CHANGE: 4000
}

class PanelInitialisationService {
    isEverInitialised = false;
    isInitialised = false;
    isTilesInitialised = false;
    character;
    tiles;
    leaveExistingRoom = undefined;

    dispatchQueue = [];

    addToQueue(action) {
        this.dispatchQueue.push(action);
    }

    constructor(tilemapService, audioService) {
        this.webhooksService = new WebhooksService(tilemapService, audioService);
        this.tilemapService = tilemapService;
        this.audioService = audioService;

        const state = store.getState();

        this.materialTypes = selectMaterialTypes(state);
        this.mineralTypes = selectMineralTypes(state);
        this.plantTypes = selectPlantTypes(state);
        this.tileTypes = selectTileTypes(state);
        this.toolTypes = selectToolTypes(state);
        this.metalTypes = selectMetalTypes(state);
        this.keyTypes = selectKeyTypes(state);
        this.machineTypes = selectMachineTypes(state);
        this.furnitureTypes = selectFurnitureTypes(state);
        this.constructionTypes = selectConstructionTypes(state);
        this.writingSurfaceTypes = selectWritingSurfaceTypes(state);
        this.writingImplementTypes = selectWritingImplementTypes(state);
        this.weaponTypes = selectWeaponTypes(state);
        this.armourTypes = selectArmourTypes(state);
        this.clothingTypes = selectClothingTypes(state);
        this.jewelleryTypes = selectJewelleryTypes(state);
        this.foodTypes = selectFoodTypes(state);
        this.lockTypes = selectLockTypes(state);
        this.animalTypes = selectAnimalTypes(state);
        this.boatTypes = selectBoatTypes(state);
        this.brainchipTypes = selectBrainchipTypes(state);
        this.tentTypes = selectTentTypes(state);
        this.workshopTypes = selectWorkshopTypes(state);
        this.wagonTypes = selectWagonTypes(state);
        this.plantProducts = selectPlantProducts(state);
        this.constructionRecipes = selectConstructionRecipes(state);

        this.plantTypesDictionary = {}
        this.plantTypes.forEach(plantType => {
            this.plantTypesDictionary[plantType._id] = plantType
        });

        this.plantProductsDictionary = {}
        this.plantProducts.forEach(plantProduct => {
            if (!this.plantProductsDictionary[plantProduct.plantTypeId]) {
                this.plantProductsDictionary[plantProduct.plantTypeId] = [];
            }

            this.plantProductsDictionary[plantProduct.plantTypeId].push(plantProduct)
        });

        this.storeSubscription = store.subscribe(async () => {
            const state = store.getState();
            const updatedCharacter = selectCharacter(state);

            if (this.character?._id && updatedCharacter._id !== this.character?._id) {
                if (this.leaveExistingRoom) {
                    this.leaveExistingRoom();
                    this.leaveExistingRoom = undefined;
                }
                this.character = updatedCharacter;
                return;
            }

            if (updatedCharacter && (updatedCharacter?._id !== this.character?._id) || (updatedCharacter.panelId !== this.character?.panelId || updatedCharacter.z !== this.character?.z)) {
                this.character = updatedCharacter;
                this.isInitialised = false;

                if (!updatedCharacter.panelId || updatedCharacter.z === undefined) {
                    this.leaveExistingRoom();
                    return
                }

                if (this.leaveExistingRoom) {
                    let waitTime = new Date().getTime();
                    this.clearPreviousPanel();
                    // waitTime = await adaptiveWait(waitTime, 'clearPreviousPanel');
                    this.leaveExistingRoom();
                    this.leaveExistingRoom = undefined;
                    // waitTime = await adaptiveWait(waitTime, 'leaveExistingRoom');
                }

                colyClient.joinOrCreate("panel", {
                    panelId: updatedCharacter.panelId,
                    characterId: updatedCharacter._id,
                    userId: updatedCharacter.userId,
                    z: updatedCharacter.z,
                    jwt: window.localStorage.getItem('feathers-jwt')
                })
                .then(room => {
                    this.leaveExistingRoom = () => {
                        // This should now come from the coly backend itself?
                        room.leave(LEAVING_CODES.ON_PANEL_CHANGE);
                    }

                    colyClient.room = {
                        ...room,
                        send: (sendName, data) => {
                            room.send(sendName, {
                                ...data,
                                jwt: window.localStorage.getItem('feathers-jwt')
                            })
                        }
                    }

                    // This takes 148ms to execute.
                    room.onStateChange(async (state) => {
                        if (this.isInitialised || (state.panel.id !== this.character.panelId) || (updatedCharacter.z !== this.character.z)) {
                            return;
                        }

                        // Todo - make all of the below code not be blocking somehow...?

                        let waitTime = new Date().getTime();

                        // TODO - handle updates
                        const character = state.characters.find(character => (character._id === updatedCharacter._id))

                        if (!character){
                            console.log('err wat?', state.characters);
                            return
                        }

                        this.isInitialised = true;

                        this.addToQueue(getCharacterSuccess({ character: { ...character } }))

                        store.dispatch(getMessagesAsync({ characterId: character._id }));

                        store.dispatch(getCharacterPanelsAsync({ _id: character._id }));

                        this.audioService.setZIndex(updatedCharacter.z);
                        this.audioService.setBiome(state.panel?.biome);

                        state.panel.characterPanel = state.characterPanels[0];

                        this.addToQueue(loadPanelSuccess(state.panel))

                        // waitTime = await adaptiveWait(waitTime, 'loadPanelSuccess');

                        this.addToQueue(getCharactersSuccess(state.characters))

                        // waitTime = await adaptiveWait(waitTime, 'getCharactersSuccess');

                        this.addToQueue(loadTilesSuccess({
                            response: state.tiles.map(tile => ({
                                ...tile,
                                graffiti: tile.graffiti && JSON.parse(tile.graffiti),
                                pixelsArray: tile.pixelsArray,
                            })),
                            tileTypes: this.tileTypes
                        }))

                        // waitTime = await adaptiveWait(waitTime, 'loadTilesSuccess');

                        this.addToQueue(loadTilesFinished({ response: [] }));

                        // waitTime = await adaptiveWait(waitTime, 'loadTilesFinished');

                        this.addToQueue(getPlantsSuccess(
                            state.plants.map(plant => ({
                                ...plant,
                                plant: this.plantTypesDictionary[plant.plantTypeId],
                                plantProducts: this.plantProductsDictionary[plant.plantTypeId]
                            }))
                        ))

                        // waitTime = await adaptiveWait(waitTime, 'getPlantsSuccess');

                        this.addToQueue(getConstructionsSuccess(
                            state.constructions.map(construction => ({
                                ...construction,
                                construction: this.constructionTypes.find(type => type._id === construction.constructionTypeId),    
                                recipes: this.constructionRecipes?.filter(recipe => recipe.constructionTypeId === construction.constructionTypeId)
                            }))
                        ))

                        // waitTime = await adaptiveWait(waitTime, 'getConstructionsSuccess');

                        this.addToQueue(getDeadCharactersSuccess(state.deadCharacters))

                        // waitTime = await adaptiveWait(waitTime, 'getDeadCharactersSuccess');

                        this.addToQueue(getAnimalsSuccess(
                            state.animals.map(animal => ({
                                ...animal,
                                animal: this.animalTypes.find(type => type._id === animal.animalTypeId)
                            }))
                        ))

                        // waitTime = await adaptiveWait(waitTime, 'getAnimalsSuccess');

                        this.addToQueue(getWagonsSuccess(
                            state.wagons.map(wagon => ({
                                ...wagon,
                                graffiti: wagon.graffiti && JSON.parse(wagon.graffiti),
                                pixelsArray: wagon.pixelsArray,
                                wagonType: this.wagonTypes.find(type => type._id === wagon.wagonTypeId),
                            }))
                        ));

                        // waitTime = await adaptiveWait(waitTime, 'getWagonsSuccess');
                        
                        this.addToQueue(getWorkshopsSuccess(
                            state.workshops.map(workshop => ({
                                ...workshop,
                                workshopType: this.workshopTypes.find(type => type._id === workshop.workshopTypeId)
                            }))
                        ));

                        // waitTime = await adaptiveWait(waitTime, 'getWorkshopsSuccess');
                        
                        this.addToQueue(getBoatsSuccess(
                            state.boats.map(boat => ({
                                ...boat,
                                graffiti: boat.graffiti && JSON.parse(boat.graffiti),
                                pixelsArray: boat.pixelsArray,
                                boatType: this.boatTypes.find(type => type._id === boat.boatTypeId)
                            }))
                        ));

                        // waitTime = await adaptiveWait(waitTime, 'getBoatsSuccess');

                        this.addToQueue(getInventorySuccess(
                            state.materials.map(material => ({
                                ...material,
                                materialType: this.materialTypes.find(type => type._id === material.materialTypeId),
                                plant: this.plantTypes.find(type => type._id === material.plantTypeId),
                                animal: this.animalTypes.find(type => type._id === material.animalTypeId)
                            }))
                        ));

                        // waitTime = await adaptiveWait(waitTime, 'getInventorySuccess');

                        this.addToQueue(getFoodSuccess(
                            state.food.map(food => ({
                                ...food,
                                foodType: this.foodTypes.find(type => type._id === food.foodTypeId)
                            }))
                        ));

                        // waitTime = await adaptiveWait(waitTime, 'getFoodSuccess');

                        this.addToQueue(getToolsSuccess(
                            state.tools.map(tool => ({
                                ...tool,
                                toolType: this.toolTypes.find(type => type._id === tool.toolTypeId)
                            }))
                        ));

                        // waitTime = await adaptiveWait(waitTime, 'getToolsSuccess');
                        
                        this.addToQueue(getMetalsSuccess(
                            state.metals.map(metal => ({
                                ...metal,
                                metalType: this.metalTypes.find(type => type._id === metal.metalTypeId)
                            }))
                        ));

                        // waitTime = await adaptiveWait(waitTime, 'getMetalsSuccess');
                        
                        this.addToQueue(getKeysSuccess(
                            state.keys.map(key => ({
                                ...key,
                                keyType: this.keyTypes.find(type => type._id === key.keyTypeId)
                            }))
                        ));

                        // waitTime = await adaptiveWait(waitTime, 'getKeysSuccess');

                        this.addToQueue(getWritingSurfacesSuccess(
                            state.writingSurfaces.map(writingSurface => ({
                                ...writingSurface,
                                graffiti: writingSurface.graffiti && JSON.parse(writingSurface.graffiti),
                                pixelsArray: writingSurface.pixelsArray,
                                writingSurfaceType: this.writingSurfaceTypes.find(type => type._id === writingSurface.writingSurfaceTypeId)
                            }))
                        ));

                        // waitTime = await adaptiveWait(waitTime, 'getWritingSurfacesSuccess');
                        
                        this.addToQueue(getWritingImplementsSuccess(
                            state.writingImplements.map(writingImplement => ({
                                ...writingImplement,
                                writingImplementType: this.writingImplementTypes.find(type => type._id === writingImplement.writingImplementTypeId)
                            }))
                        ));

                        // waitTime = await adaptiveWait(waitTime, 'getWritingImplementsSuccess');

                        this.addToQueue(getWeaponsSuccess(
                            state.weapons.map(weapon => ({
                                ...weapon,
                                graffiti: weapon.graffiti && JSON.parse(weapon.graffiti),
                                pixelsArray: weapon.pixelsArray,
                                weaponType: this.weaponTypes.find(type => type._id === weapon.weaponTypeId)
                            }))
                        ));

                        // waitTime = await adaptiveWait(waitTime, 'getWeaponsSuccess');
                        
                        this.addToQueue(getArmourSuccess(
                            state.armour.map(armour => ({
                                ...armour,
                                graffiti: armour.graffiti && JSON.parse(armour.graffiti),
                                pixelsArray: armour.pixelsArray,
                                armourType: this.armourTypes.find(type => type._id === armour.armourTypeId)
                            }))
                        ));

                        // waitTime = await adaptiveWait(waitTime, 'getArmourSuccess');

                        this.addToQueue(getClothingSuccess(
                            state.clothing.map(clothing => ({
                                ...clothing,
                                graffiti: clothing.graffiti && JSON.parse(clothing.graffiti),
                                pixelsArray: clothing.pixelsArray,
                                clothingType: this.clothingTypes.find(type => type._id === clothing.clothingTypeId)
                            }))
                        ));

                        // waitTime = await adaptiveWait(waitTime, 'getClothingSuccess');
                        
                        this.addToQueue(getJewellerySuccess(
                            state.jewellery.map(jewellery => ({
                                ...jewellery,
                                graffiti: jewellery.graffiti && JSON.parse(jewellery.graffiti),
                                pixelsArray: jewellery.pixelsArray,
                                jewelleryType: this.jewelleryTypes.find(type => type._id === jewellery.jewelleryTypeId)
                            }))
                        ));

                        // waitTime = await adaptiveWait(waitTime, 'getJewellerySuccess');
                        
                        this.addToQueue(getMineralsSuccess(
                            {
                                response: state.minerals,
                                mineralTypes: this.mineralTypes
                            }
                        ));

                        // waitTime = await adaptiveWait(waitTime, 'getMineralsSuccess');

                        this.addToQueue(getLocksSuccess(
                            state.locks.map(lock => ({
                                ...lock,
                                lockType: this.lockTypes.find(type => type._id === lock.lockTypeId)
                            }))
                        ));

                        // waitTime = await adaptiveWait(waitTime, 'getLocksSuccess');
                        
                        this.addToQueue(getFurnitureSuccess(
                            state.furniture.map(furniture => ({
                                ...furniture,
                                graffiti: furniture.graffiti && JSON.parse(furniture.graffiti),
                                pixelsArray: furniture.pixelsArray,
                                furnitureType: this.furnitureTypes.find(type => type._id === furniture.furnitureTypeId)
                            }))
                        ));

                        // waitTime = await adaptiveWait(waitTime, 'getFurnitureSuccess');

                        this.addToQueue(getCharacterLevelsSuccess(state.characterLevels))
                        // waitTime = await adaptiveWait(waitTime, 'getCharacterLevelsSuccess');

                        this.addToQueue(getOrganisationsSuccess(state.organisations))
                        // waitTime = await adaptiveWait(waitTime, 'getOrganisationsSuccess');

                        this.addToQueue(getOrdersSuccess(state.orders))
                        // waitTime = await adaptiveWait(waitTime, 'getOrdersSuccess');

                        this.addToQueue(getCompletedOrdersSuccess(state.completedOrders))

                        this.addToQueue(getZonesSuccess(state.zones))
                        // waitTime = await adaptiveWait(waitTime, 'getZonesSuccess');

                        this.addToQueue(getBrainchipsSuccess(state.brainchips.map(brainchip => ({
                            ...brainchip,
                            brainchipType: this.brainchipTypes.find(type => type._id === brainchip.brainchipTypeId)
                        }))))

                        this.addToQueue(getCriminalsSuccess(state.crimes))

                        this.drawMine(state.panel);
                        // waitTime = await adaptiveWait(waitTime, 'drawMine');

                        this.webhooksService.watchStateChanges(room.state);
                        // waitTime = await adaptiveWait(waitTime, 'watchStateChanges');

                        store.dispatch(batchActions(this.dispatchQueue))
                        this.dispatchQueue = [];

                        const storeState = store.getState();
                        // This takes 12ms
                        await this.tilemapService.triggerInitialDraw(storeState)
                        // waitTime = await adaptiveWait(waitTime, 'triggerInitialDraw');

                        store.dispatch(initialisingPanelFinished());
                        // waitTime = await adaptiveWait(waitTime, 'initialisingPanelFinished');
                    });
                })
                .catch(e => {
                    console.log("JOIN ERROR", e);
                })
            }
        })
    }

    onDestroy() {
        this.storeSubscription();
    }

    async initialise() {

    }

    clearPreviousPanel() {
        store.dispatch(initialisingPanelStarted())
    }

    drawMine(panel) {
        if (panel) {
            const rng = seedrandom('biome' + panel.x + panel.y);

            if (this.character.z === 0) {
                drawMineEntrance(panel, rng);
                
            } else if (this.character.z < 0) {
                drawMine(panel, rng, this.character.z);
            }
        }
    }
}

export async function adaptiveWait(previousTime, waitString) {
    const currentTime = new Date().getTime();

    const waitMs = 1 * (currentTime - previousTime)

    await sleep(waitMs)

    return new Date().getTime()
}

function sleep(ms) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve('done')
        }, ms)
    })
}

export default PanelInitialisationService;
