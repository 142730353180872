import store from '../redux/store';
import { Map } from 'rot-js';
import * as isEqual from 'is-equal';

import {
    selectVisionRadius,
    selectCharacter,
    selectPanel,
    selectItemTileIcons,
    selectPlants,
    selectConstructions,
    selectTileInventory,
    selectTileFood,
    selectTileMinerals,
    selectTileTools,
    selectTileMetals,
    selectTileKeys,
    selectTileWritingSurfaces,
    selectTileWritingImplements,
    selectTileWeapons,
    selectTileArmour,
    selectTileClothing,
    selectTileJewellery,
    selectEmbarkCharacter,
    selectMineWalls,
    selectStairs,
    selectCurrentTool,
    selectCharacters,
    selectLightSourceCharacters,
    selectForegroundConstructions,
    selectBrainchipTypeByIds,
    selectServerTimeOffset,
} from '../redux/selectors';
import HighlightTilemapService from './Highlight-tilemap.service'
import ForegroundTilemapService from './Foreground-tilemap.service'
import FloorTilemapService from './Floor-tilemap.service'
import BackgroundTilemapService from './Background-tilemap.service'
import tileMap from './tile-map'
import { addRidges, addCoast, addRivers, addLake, PANEL_WIDTH, PANEL_HEIGHT } from './geography'
import { STAIRCASE_UP_KEY, STAIRCASE_DOWN_KEY, MINE_WALL_KEY, drawMine, drawMineEntrance } from './mine-geography';
import seedrandom from 'seedrandom';
import { drawFieldOfView, isWithinUserVision } from './night-radius';
import { getMineWallsSuccess } from '../redux/actions/mine-wall.actions';
import {
    WORLD_WIDTH,
    WORLD_HEIGHT,
    drawTundraClouds,
    drawMistyTundra,
    drawInglenook,
    drawWoneyed,
    drawMountains,
    drawSands,
    drawWastes,
    drawGumboRumblings,
    drawPricklelands,
    drawHeaths,
    drawSteppes,
    drawDots,
    drawTundra,
    drawTundraFern,
    drawTundraThorn,
    drawPits,
    drawPitsAndPlants,
    getMockLayer,
    drawNeedlewoodForest,
    drawIcyWastes,
    drawMazeScrub,
    drawPolarWastes,
    drawPrimeordeal,
    drawPuzzlebox,
    drawThistlewoodForest,
    drawSlabs,
    drawNeighbours,
    getNeighbours,
    getDiagonalNeighbours,
    isEmptyIndex,
    drawCoolTemperateDesertRiver,
    drawWarmTemperateDesertRiver,
    drawSubtropicalDesertRiver,
    drawTropicalDesertRiver,
    drawPainForest,
    drawFurForest,
    drawEvergreenForest,
    drawCrunchyForest,
    drawCrystalisedForest,
    drawDeepestJungle,
    drawDiscordantForest,
    drawFoetalRainforest,
    drawFossilisedForest,
    drawLusciousRainforest,
    drawOldGrowth,
    drawOvergrownFur,
    drawPrimeval,
    drawSwampyForest,
    drawHighSeas,
} from './biome-geography';
import { createMazeFn } from './maze'

import * as tilemapJSON from './tilemap-2.json'

import client from './client';

const RELATIVE_POSITION = {
    LEFT: 'LEFT',
    RIGHT: 'RIGHT',
    TOP: 'TOP',
    BOTTOM: 'BOTTOM',
};

export const BRAINCHIP_TYPE_NAMES = {
    POISON_RESIST: 'Poison Immunity',
    ACID_RESIST: 'Acid Resist',
    SLUGGISH_RESIST: 'Sluggish Resist',
    WEB_RESIST: 'Web Resist',
    FAR_SIGHT: 'Far Sight',
    DETECT_INVISIBILITY: 'Detect Invisibility',
    INVISIBILITY: 'Invisibility',
    BLINDNESS_RESIT: 'Blindness Resist',
    COLD_DEFENCE: 'Cold Defence',
    DEATH_SONG_RESIST: 'Death Song Resist',
    NIGHT_VISION: 'Night Vision',
    UNDETECTABLE: 'Undetectable',
    HEAT_DEFENCE: 'Heat Defence',
    PRONE_RESIST: 'Prone Resist',
    TELEPORT: 'Teleport',
    PHASE: 'Phase',
    VULNERABLE_RESIST: 'Vulnerable Resist',
    WATER_WALK: 'Water Walk',
    WEB_SPITTER: 'Web Spitter',
    ACID_SPITTER: 'Acid Spitter',
    FIRE_BREATH: 'Fire Breath',
    HP_REGEN: 'HP Regen',
    HP_BOOST: 'HP Boost',
    ARMOUR_BOOST: 'Armour Boost',
    STRENGTH_BOOST: 'Strength Boost',
    ACCURACY_BOOST: 'Accuracy Boost',
}

class TilemapService {
    scene;

    farmingTilemapService;
    deadCharactersTilemapService;

    intervals = []

    floor;
    cliff;
    plantLayer;
    wallLayer;
    mineralLayer;
    machineLayer;

    currentTool = {};
    currentActiveBrainchips;
    map;
    panel;
    tiles;
    plants;
    characters;
    minerals;
    machines;
    character;
    mineWalls;
    stairs;
    constructions;

    visibleTiles = [];
    animatedTiles = [];
    tileDrawTime;
    drawIndex = 0;

    ridgePoints;
    visionRadius;

    isBaseLayerDrawn = false;
    isCutscene = false;

    lightEmitters = [];
    lightEmittingCharacters = [];

    layer;

    constructor(scene) {
        this.scene = scene;

        this.layer = getMockLayer();
        this.character = selectCharacter(store.getState())
        this.characters = selectCharacters(store.getState())
        this.constructions = selectConstructions(store.getState());

        this.storeSubscription = store.subscribe(async () => {
            if (!this.backgroundTilemapService || !this.floorTilemapService || !this.foregroundTilemapService) {
                return;
            }

            const state = store.getState();

            const visionRadius = Math.floor(selectVisionRadius(state));

            if (visionRadius !== this.visionRadius) {
                this.visionRadius = visionRadius;
                const visibleTiles = this.calculateVisibleTiles();
                this.backgroundTilemapService.initialDraw(state, undefined, visibleTiles);
                this.floorTilemapService.initialDraw(state, undefined, visibleTiles)
                this.foregroundTilemapService.shadeTiles(undefined, state, visibleTiles);
            }
        })

        this.interval = setInterval(() => {
            if (!this.backgroundTilemapService || !this.floorTilemapService || !this.foregroundTilemapService) {
                return;
            }

            this.lightEmitters = this.lightEmitters.map(({ lightEmitter, lightRadius }) => {
                const newLightRadius = getLightRadius(lightEmitter);

                if (newLightRadius !== lightRadius) {
                    const state = store.getState();
                    const visibleTiles = this.calculateVisibleTiles();
                    this.backgroundTilemapService.initialDraw(state, undefined, visibleTiles);
                    this.floorTilemapService.initialDraw(state, undefined, visibleTiles)
                    this.foregroundTilemapService.shadeTiles(undefined, state, visibleTiles);
                }

                return {
                    lightRadius: newLightRadius,
                    lightEmitter
                }
            })

            this.lightEmittingCharacters = this.lightEmittingCharacters.map(({ character, lightRadius }) => {
                const newLightRadius = getLightRadius({ ...character.lightSource });

                if (newLightRadius !== lightRadius) {
                    const state = store.getState();
                    const visibleTiles = this.calculateVisibleTiles();
                    this.backgroundTilemapService.initialDraw(state, undefined, visibleTiles);
                    this.floorTilemapService.initialDraw(state, undefined, visibleTiles)
                    this.foregroundTilemapService.shadeTiles(undefined, state, visibleTiles);
                }

                return {
                    lightRadius: newLightRadius,
                    character
                }
            })
        }, 500)
    }

    scheduleVisibilityChanges(lightEmitter) {
        this.lightEmitters.push({
            lightEmitter,
            lightRadius: getLightRadius(lightEmitter)
        })
    }

    scheduleCharacterVisibilityChanges(character) {
        this.lightEmittingCharacters.push({
            character,
            lightRadius: getLightRadius(character.lightSource)
        })
    }

    preloadTilemap(scene, options) {
        const { isPrologue, isIntro, isChooseEmbark, isEmbarkWaitingRoom } = options;

        scene.load.spritesheet('humans', 'assets/tilemaps/tiles/humans-3-long.png', { frameWidth: 16, frameHeight: 24 });
        scene.load.spritesheet('ship', 'assets/tilemaps/tiles/tileset-ship.png', { frameWidth: 16, frameHeight: 24 });
        scene.load.spritesheet('biomes', 'assets/tilemaps/tiles/tileset-biomes.png', { frameWidth: 16, frameHeight: 24 });
        scene.load.tilemapTiledJSON('map', tilemapJSON);
    }

    createTilemap(scene, options) {
        let map;

        if (options?.isSecondMap) {
            map = scene.make.tilemap({ key: 'map2', tileWidth: 16, tileHeight: 24 });
        } else {
            map = scene.make.tilemap({ key: 'map', tileWidth: 16, tileHeight: 24 });
        }

        const spaceshipTileset = map.addTilesetImage('ship', null, 16, 24, 0, 0);
        const biomesTileset = map.addTilesetImage('biomes', null, 16, 24, 0, 0);
        const humansTileset = map.addTilesetImage('humans', null, 16, 24, 0, 0);

        this.isCutscene = options?.isCutscene;

        this.map = map;

        if (options?.isCutscene) {
            this.floor = map.createLayer('Floor', spaceshipTileset)
            this.wallLayer = map.createLayer('Walls', spaceshipTileset)
            this.secondBackgroundLayer = map.createLayer('Floor', spaceshipTileset, 0, 0);
            this.plantLayer = map.createLayer('Plants', spaceshipTileset, 0, 0)
        } else {
            this.floor = map.createLayer('Floor', biomesTileset, 0, 0);
            this.cliff = map.createLayer('Cliff', biomesTileset, 0, 0);
            this.wallLayer = map.createLayer('Walls', biomesTileset)
            this.secondBackgroundLayer = map.createLayer('Machines', biomesTileset, 0, 0);
            this.plantLayer = map.createLayer('Plants', biomesTileset, 0, 0)
            this.characterLayer = map.createLayer('Characters', humansTileset)
        }

        if (!options?.isCutscene) {
            this.cloudLayer = map.createLayer('Clouds', biomesTileset, 0, 0);
        }

        if (this.cloudLayer) {
            this.cloudLayer.y = -15;
            this.cloudLayer.setDepth(100);
        }

        this.highlightTilemapService = new HighlightTilemapService(this.floor, scene);

        this.floorTilemapService = new FloorTilemapService(this.floor, scene);
        this.backgroundTilemapService = new BackgroundTilemapService(this.secondBackgroundLayer, scene);
        this.foregroundTilemapService = new ForegroundTilemapService(this.plantLayer, scene, this.cloudLayer, false, this.backgroundTilemapService, this);


        // this.plantLayer.setPipeline('Outline')
        // this.plantLayer.pipeline.set2f('uResolution', this.plantLayer.width, this.plantLayer.height);
        // setInterval(() => {
        //     this.plantLayer.pipeline.set1f('uTime', Math.random());
        // }, 100)

        // this.plantLayer.setPipeline('Grayscale')
        // this.plantLayer.pipeline.set1f('gray', 1);

        return {
            map,
            floor: this.floor,
            plants: this.plantLayer,
            walls: this.wallLayer,
            corpses: this.corpseLayer,
            characters: this.characterLayer,
            machines: this.machineLayer
        }
    }

    calculateVisibleTiles() {
        const state = store.getState();

        const updatedCharacter = this.character;
        const updatedCharacters = this.characters;

        const selectedForegroundConstructions = selectForegroundConstructions(state);
        const updatedForegroundConstructions = Object.values(selectedForegroundConstructions.byId);
        const currentActiveBrainchips = selectBrainchipTypeByIds(state, updatedCharacter.activeBrainchipTypeIds)
        const visionRadius = selectVisionRadius(state)
        const timeOffset = selectServerTimeOffset(state);

        let updatedPlants = selectPlants(state);
        updatedPlants = Object.values(updatedPlants.byId).filter(plant => (!(plant.position.x === updatedCharacter.position.x && plant.position.y === updatedCharacter.position.y)))

        let lightBlockingObjects = [];
        let visibleTiles = [];

        if (updatedCharacter?.z < 0) {
            lightBlockingObjects = selectMineWalls(state);
        }

        const lightSourceCharacters = updatedCharacters.filter(character => {
            return character.lightSource?.expiresAt - new Date().getTime() - timeOffset > 0
        })

        visibleTiles = calculateLightSources(visibleTiles, updatedForegroundConstructions, [], lightSourceCharacters, updatedCharacter);

        const oldVisibleTiles = [ ...visibleTiles ]

        let vision = updatedCharacter.z < 0 ? 1 : visionRadius;

        if (updatedCharacter.blind) {
            // check for blindness resist
            if (currentActiveBrainchips.find(brainchip => (brainchip.name === BRAINCHIP_TYPE_NAMES.BLINDNESS_RESIST))) {
                vision = 10;
            } else {
                vision = 1;
            }
        }

        lightBlockingObjects = [ ...updatedPlants, ...updatedForegroundConstructions.filter(construction => (construction.construction?.name.indexOf('Wall') > -1)), ...lightBlockingObjects ]

        visibleTiles = drawFieldOfView(lightBlockingObjects, updatedCharacter?.position, vision);

        return [ ...oldVisibleTiles, ...visibleTiles ]
    }

    async triggerInitialDraw(state) {
        const selectedForegroundConstructions = selectForegroundConstructions(state);
        const updatedForegroundConstructions = Object.values(selectedForegroundConstructions.byId);

        updatedForegroundConstructions.filter(construction => {
            return construction.lightSourceDuration || construction.construction?.lightSourceDuration
        }).forEach((construction) => (this.scheduleVisibilityChanges(construction)))

        this.character = selectCharacter(state);
        this.characters = selectCharacters(state);

        this.characters.forEach((character) => (this.scheduleCharacterVisibilityChanges(character)))

        const visibleTiles = this.calculateVisibleTiles();
        if (this.foregroundTilemapService) {
            await this.foregroundTilemapService.initialDraw(state, visibleTiles);
            this.backgroundTilemapService.initialDraw(state, undefined, visibleTiles);
            this.floorTilemapService.initialDraw(state, undefined, visibleTiles)
        } else {
            console.error('NO this.foregroundTilemapService SOMEHOW!!')
            setTimeout(async () => {
                await this.foregroundTilemapService.initialDraw(state, visibleTiles);
                this.backgroundTilemapService.initialDraw(state, undefined, visibleTiles);
                this.floorTilemapService.initialDraw(state, undefined, visibleTiles)
            }, 10)
        }
    }

    async addItem(item) {
        if (item.workshopTypeId) {
            this.foregroundTilemapService.addNewWorkshop(item);
            return;
        }

        if (item.boatTypeId) {
            this.foregroundTilemapService.addNewBoat(item);
            return;
        }

        if (item.construction?.name?.indexOf('Floor') > -1) {
            this.backgroundTilemapService.addNewItem(item);
        }

        if (item.lightSourceDuration) {
            this.scheduleVisibilityChanges(item);
        }

        await this.foregroundTilemapService.addNewItem(item);
        // TODO - shade tiles if construction or blocking plant removed
        // this.foregroundTilemapService.shadeTiles();
    }

    updateLocalCharacterValues(character) {
        if (character._id === this.character._id) {
            this.character = character;
        }

        this.characters = [ ...this.characters.filter(filtChar => (filtChar._id !== character._id)), { ...character }];
    }

    async addCharacter(character) {
        this.updateLocalCharacterValues(character);
        const visibleTiles = this.calculateVisibleTiles(character);

        this.scheduleCharacterVisibilityChanges(character);

        await this.foregroundTilemapService.addNewCharacter(character, visibleTiles);

        // TODO - debounce this call
        // TODO - only update necessary tiles instead of all of them :)
        this.backgroundTilemapService.initialDraw(undefined, character, visibleTiles);
        this.floorTilemapService.initialDraw(undefined, character, visibleTiles)
    }

    removeItem(item) {
        if (item.workshopTypeId) {
            this.foregroundTilemapService.removeNewWorkshop(item);
            return;
        }

        if (item.boatTypeId) {
            this.foregroundTilemapService.removeNewBoat(item);
            return;
        }

        this.foregroundTilemapService.removeNewItem(item);
        // TODO - shade tiles if construction or blocking plant removed
        // this.foregroundTilemapService.shadeTiles();
    }

    removeCharacter(character) {
        if (!this.foregroundTilemapService.removeNewCharacter) {
            console.log('but why?', this.foregroundTilemapService)
            return;
        }
        this.foregroundTilemapService.removeNewCharacter(character);
    }

    onDestroy() {
        console.log('DESTROYING TILEMAP SERVICE');

        this.highlightTilemapService.onDestroy();
        this.highlightTilemapService = null;
        delete this.highlightTilemapService

        this.foregroundTilemapService.onDestroy();
        this.foregroundTilemapService = null;
        delete this.foregroundTilemapService

        this.storeSubscription();
        clearInterval(this.interval);
    }

    updateTiles(time, delta) {
        this.updateClouds();

        this.updateUserPlacedTiles(time);
    }

    updateClouds() {
        if (this.cloudLayer) {
            this.cloudLayer.x += 0.1
        }
    }

    updateUserPlacedTiles(time) {
        if (!this.tiles?.byId) {
            return;
        }

        if (!this.tileDrawTime) {
            this.tileDrawTime = time;
        } else if (time - this.timeDrawTime < 1000) {
            return;
        }

        this.timeDrawTime = time;
        this.drawIndex++;

        if (this.drawIndex > 1000) {
            this.drawIndex = 0;
        }

        Object.values(this.tiles.byId).forEach(tile => {
            if (!tile.tileIcons || tile.tileIcons.length === 0) {
                return;
            }

            const tileIconLength = tile.tileIcons.length;
            const tileIcon = tile.tileIcons[(this.drawIndex % tileIconLength + tileIconLength) % tileIconLength];
            
            this.wallLayer.putTileAt(tileMap[tileIcon], tile.position.x, tile.position.y)
        })
    }

    clearWallLayer() {
        if (!this.wallLayer || !this.wallLayer.layer) {
            return;
        }

        for (var x=0; x < PANEL_WIDTH; x++) {
            for (var y=0; y < PANEL_HEIGHT; y++) {
                if (!this.wallLayer.layer.data || !this.wallLayer.layer.data[y] || !this.wallLayer.layer.data[y][x]) {
                    return;
                }

                this.wallLayer.layer.data[y][x].index = -1;
            }
        }
    }
}

function getLightRadius(lightEmitter) {
    const now = new Date().getTime();

    lightEmitter.lightSourceDuration = lightEmitter.lightSourceDuration || lightEmitter.construction?.lightSourceDuration

    if (!lightEmitter?.lightSourceDuration) {
        return undefined;
    }

    const MAX_RADIUS = lightEmitter.lightSourceDuration / 100000;

    let visionRadius = MAX_RADIUS;

    const totalDuration = lightEmitter.expiresAt - lightEmitter.createdAt;
    const elapsedDuration = now - lightEmitter.createdAt;

    if (elapsedDuration > totalDuration) {
        visionRadius = 0;
    } else {
        visionRadius = Math.round(MAX_RADIUS * (1 - (elapsedDuration / lightEmitter.lightSourceDuration) ))
    }

    return visionRadius;
}

export function calculateLightSources(visibleTiles, updatedConstructions, mineWalls, characters, character) {
    const lightBlockingConstructions = updatedConstructions?.filter(construction => (construction.construction?.name.indexOf('Wall') > -1));

    const lightEmittingConstructions = updatedConstructions?.filter(construction => (construction.construction?.name.indexOf('Fire') > -1 || construction.construction?.name.indexOf('Lamp') > -1));
    const now = new Date().getTime();

    lightEmittingConstructions?.forEach(lightEmitter => {
        const position = lightEmitter.position;

        lightEmitter.lightSourceDuration = lightEmitter.lightSourceDuration || lightEmitter.construction?.lightSourceDuration

        const visionRadius = getLightRadius(lightEmitter);

        if (visionRadius > 0) {
            const newVisibleTiles = drawFieldOfView([ ...mineWalls, ...lightBlockingConstructions ], position, visionRadius);

            visibleTiles = [...visibleTiles, ...newVisibleTiles]
        }
    })

    if (characters && characters.length > 0) {
        characters.forEach(character => {
            if (!character.lightSource?.expiresAt) {
                return;
            }

            const position = character.position;

            const visionRadius = getLightRadius({ ...character.lightSource });

            const newVisibleTiles = drawFieldOfView([ ...mineWalls, ...lightBlockingConstructions ], position, visionRadius);

            visibleTiles = [...visibleTiles, ...newVisibleTiles]
        })
    }

    return visibleTiles
}

export default TilemapService;